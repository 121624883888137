import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { SysFl } from './Types'


import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'


interface PageInfo {
    n: number
}

var OpenAccFlag: any;

interface SysConfigViewProps {
    OpenAcc: AxiosInstance,
    SysFl: SysFl,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}


function SysConfigView({ OpenAcc, SysFl = {} as SysFl, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: SysConfigViewProps) {

    const [isAdd, setisAdd] = useState(false)
    const [isEdit, setisEdit] = useState(false)
    const [isDelete, setisDelete] = useState(false)

    const [docList, setDocList] = useState([] as Document[])


    

    if (! ("d_tr_date" in SysFl)) return null


    function buttonPress(buttonName: string)
    {
        if ( buttonName == "new" )
        {
            onNew()
        }
        if ( buttonName == "edit" )
        {
            onEdit()
        }
        if ( buttonName == "delete" )
        {
            setisDelete(true)
        }
    }


    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" disabled={true} icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => buttonPress("new")} >New</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isAdd}
                        onConfirm={ () => { setisAdd(false) } }
                    >
                        <p>
                            NEW : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-3-1" icon="edit" onClick={() => buttonPress("edit")} >Edit</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isEdit}
                        onConfirm={ () => { setisEdit(false) } }
                    >
                        <p>
                            EDIT : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => buttonPress("delete")} >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDelete}
                        onCancel={ () => setisDelete(false) }
                        onConfirm={ () => { onDelete(); setisDelete(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" disabled={true} icon="direction-right" onClick={() => onNextRec()} >Next</Button>
{/*
                
                <FormGroup className="Col-7-2" label="">
                    <a target="_blank" rel="" href={`/#/AccInfo?acCode=${ac.d_acCode}`}>
                    <Button className="Col-7-2" icon="document" onClick={() => {} } >Balance & Portfolio</Button>
                    </a>
                </FormGroup>
*/}             
{/*   
                <Button className="Col-9-2" icon="document" onClick={() => { window.open(`/#/AccInfo?acCode=${ac.d_acCode}`, '_blank') } } >Balance & Portfolio</Button>
*/}                
            </div>
            <div className="Spacing-V-16" />
            {/*
            <div className="Row">
                <FormGroup className="Col-1-2" label="System Date">
                    <InputGroup readOnly value= {utils.dispDate(SysFl.d_tr_date, OpenAccFlag.dateFormat)} />
                </FormGroup>
            </div>
            */}
            <div className="Row">
                <FormGroup className="Col-1-2" label="Client P Prefix/Seq">
                    <InputGroup readOnly value={SysFl.d_p_Pref} />
                    <InputGroup readOnly value={SysFl.d_p_Seq} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Client M Prefix/Seq">
                    <InputGroup readOnly value={SysFl.d_m_Pref} />
                    <InputGroup readOnly value={SysFl.d_m_Seq} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="smtp Server/smtp Port">
                    <InputGroup readOnly value={SysFl.d_smtpServer} />
                    <InputGroup readOnly value={SysFl.d_smtpPort} />
                </FormGroup>
                <FormGroup className="Col-4-3" label="smtp User/smtp Password">
                    <InputGroup readOnly value={SysFl.d_smtpUser} />
                    <InputGroup readOnly type="password" value={SysFl.d_smtpPassword} />
                </FormGroup>
                <FormGroup className="Col-7-3" label="from Email">
                    <InputGroup readOnly value={SysFl.d_fromEmail} />
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-1" label="SMS Account">
                    <InputGroup readOnly value={SysFl.d_smsaccount} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="SMS User">
                    <InputGroup readOnly value={SysFl.d_smsuserid} />
                </FormGroup>
                <FormGroup className="Col-4-2" label="SMS Password">
                    <InputGroup readOnly type="password" value={SysFl.d_smspasswd} />
                </FormGroup>
            </div>
 

         
  
            <div className="Row">
                <FormGroup className="Col-1-2" label="Default Runner">
                    <InputGroup readOnly value={SysFl.d_aeCode} />
                </FormGroup>
                <FormGroup className="Col-1-2" label="Default Commission">
                    <InputGroup readOnly value={SysFl.d_commCode} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Default Interest">
                    <InputGroup readOnly  value={SysFl.d_intCode} />
                </FormGroup>
                <FormGroup className="Col-1-4" label="Password for export imp_all.zip">
                    <InputGroup readOnly type="password" value={SysFl.d_impAllZipPwd} />
                </FormGroup>
            </div>

            
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Send Method" >
                    <InputGroup readOnly value={SysFl.d_sendvmeth} />
                    { SysFl.d_sendvmeth === "1" && <span >電郵驗證 </span> }
                    { SysFl.d_sendvmeth === "2" && <span >SMS 驗證 </span> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Obsolete Day" >
                    <InputGroup readOnly value={SysFl.d_Obsolete_day} />
                </FormGroup>
            </div>  
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Theme" >
                    <InputGroup readOnly value={SysFl.d_theme1} />
                    { SysFl.d_theme1 === "0" && <span >Basic </span> }
                    { SysFl.d_theme1 === "1" && <span >Theme 1 </span> }
                    { SysFl.d_theme1 === "2" && <span >Theme 2 </span> }
                    { SysFl.d_theme1 === "3" && <span >Theme 3 </span> }
                    { SysFl.d_theme1 === "4" && <span >Theme 4 </span> }
                    { SysFl.d_theme1 === "5" && <span >Theme 5 </span> }
                </FormGroup>
                <FormGroup className="Col3-3-2" label="Theme (Regform)" >
                    <InputGroup readOnly value={SysFl.d_theme2} />
                    { SysFl.d_theme2 === "0" && <span >Basic </span> }
                    { SysFl.d_theme2 === "1" && <span >Theme 1 </span> }
                    { SysFl.d_theme2 === "2" && <span >Theme 2 </span> }
                    { SysFl.d_theme2 === "3" && <span >Theme 3 </span> }
                    { SysFl.d_theme2 === "4" && <span >Theme 4 </span> }
                    { SysFl.d_theme2 === "5" && <span >Theme 5 </span> }
                </FormGroup>
             </div>
        </div>
    )
}

interface SysConfigEditProps {
    OpenAcc: AxiosInstance,
    SysFl?: SysFl,
    isNew?: boolean,
    onSave?: (SysFl: SysFl) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function SysConfigEdit({ OpenAcc, SysFl = {} as SysFl, isNew = false, onSave = () => { }, onCancel = () => { } }: SysConfigEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<SysFl>()

    const [def_d_sendvmeth, setdef_d_sendvmeth] = useState(SysFl.d_sendvmeth)
    const [def_d_theme1, setdef_d_theme1] = useState(SysFl.d_theme1)
    const [def_d_theme2, setdef_d_theme2] = useState(SysFl.d_theme2)

  
    const [password, setPassword] = useState("")
    const [type, setType] = useState("password")
    const [icon, setIcon] = useState(eyeOff);


   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_tr_date" in SysFl) {
            reset(SysFl)
        }
    }, [SysFl, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "d_status" })
    }, [register])

    useEffect(() => {
        if ( OpenAccFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    

    if (! isNew && !("d_tr_date" in SysFl)) return null


    const onSubmit = (data: SysFl) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_p_Pref = data.d_p_Pref.toUpperCase();
        data.d_m_Pref = data.d_m_Pref.toUpperCase();

/*
        data.d_StaffRelation = "N"
        if (def_d_StaffRelation) {
            data.d_StaffRelation = "Y";
        } else {
            data.d_StaffRelation = "N";
        }
*/



        (async () => {
            try {
                //console.log("call SysConfigedit data", data)
                //console.log("call SysConfigedit isNew", isNew)
                const res = await OpenAcc.post('/Master', {sb_data: data, cPgmId: "SysConfig", cAction: "SysConfigValidate", isNew: isNew})
                //console.log("SysConfigedit res.data.ok", res.data.ok)
                //console.log("SysConfigedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }


    const handleToggle = () => {
        if (type==='password'){
           setIcon(eye);
           setType('text')
        } else {
           setIcon(eyeOff)
           setType('password')
        }
     }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(ac)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            {/*
            <div className="Row">
                <FormGroup className="Col-1-2" label="System Date">
                    <InputGroup disabled= {true} value= {utils.dispDate(SysFl.d_tr_date, OpenAccFlag.dateFormat)} />
                </FormGroup>
            </div>
            */}
            <div className="Row">
                <FormGroup className="Col-1-2" label="Client P Prefix/Seq" labelFor="inputp_Pref">
                    <InputGroup id="inputp_Pref" name="d_p_Pref" autoFocus={true} defaultValue="" placeholder="P Prefix ..." inputRef={ register({ maxLength: 2}) } />
                    {errors.d_p_Pref && errors.d_p_Pref.types && <span id="Err_msg">{errors.d_p_Pref.types.message}</span>}
                    {errors.d_p_Pref && errors.d_p_Pref.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                    <InputGroup id="inputp_Seq" name="d_p_Seq" defaultValue="" placeholder="Seq ..." inputRef={ register() } />
                    {errors.d_p_Seq && errors.d_p_Seq.types && <span id="Err_msg">{errors.d_p_Seq.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Client M Prefix/Seq" labelFor="inputm_Pref">
                    <InputGroup id="inputm_Pref" name="d_m_Pref" placeholder="M Prefix ..." inputRef={ register({ maxLength: 2}) } />
                    {errors.d_m_Pref && errors.d_m_Pref.types && <span id="Err_msg">{errors.d_m_Pref.types.message}</span>}
                    {errors.d_m_Pref && errors.d_m_Pref.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                    <InputGroup id="inputm_Seq" name="d_m_Seq" defaultValue="" placeholder="Seq..." inputRef={ register() } />
                    {errors.d_m_Seq && errors.d_m_Seq.types && <span id="Err_msg">{errors.d_m_Seq.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="smtp Server/smtp Port" labelFor="inputsmtpServer">
                    <InputGroup id="inputsmtpServer" name="d_smtpServer" placeholder="smtp Server ..." inputRef={ register({ maxLength: 40}) } />
                    {errors.d_smtpServer && errors.d_smtpServer.types && <span id="Err_msg">{errors.d_smtpServer.types.message}</span>}
                    {errors.d_smtpServer && errors.d_smtpServer.type === "maxLength" && <span id="Err_msg">Max length is 40</span>}
                    <InputGroup id="inputm_Seq" name="d_smtpPort" defaultValue="" placeholder="smtp Port ..." inputRef={ register({ maxLength: 5}) } />
                    {errors.d_smtpPort && errors.d_smtpPort.types && <span id="Err_msg">{errors.d_smtpPort.types.message}</span>}
                    {errors.d_smtpPort && errors.d_smtpPort.type === "maxLength" && <span id="Err_msg">Max length is 5</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label="smtp User/smtp Password" labelFor="inputsmtpUser">
                    <InputGroup id="inputsmtpUser" name="d_smtpUser" placeholder="smtp User ..." inputRef={ register({ maxLength: 30}) } />
                    {errors.d_smtpUser && errors.d_smtpUser.types && <span id="Err_msg">{errors.d_smtpUser.types.message}</span>}
                    {errors.d_smtpUser && errors.d_smtpUser.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    <InputGroup id="inputm_Seq" name="d_smtpPassword" type="password" defaultValue="" placeholder="smtp Password ..." inputRef={ register({ maxLength: 30}) } />
                    {errors.d_smtpPassword && errors.d_smtpPassword.types && <span id="Err_msg">{errors.d_smtpPassword.types.message}</span>}
                    {errors.d_smtpPassword && errors.d_smtpPassword.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                </FormGroup>
                <FormGroup className="Col-7-3" label="from Email" labelFor="inputfromEmail">
                    <InputGroup id="inputfromEmail" name="d_fromEmail" placeholder="from Email ..." inputRef={ register({ maxLength: 60}) } />
                    {errors.d_fromEmail && errors.d_fromEmail.types && <span id="Err_msg">{errors.d_fromEmail.types.message}</span>}
                    {errors.d_fromEmail && errors.d_fromEmail.type === "maxLength" && <span id="Err_msg">Max length is 60</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-1" label="SMS Account" labelFor="inputsmsaccount">
                    <InputGroup id="inputsmsaccount" name="d_smsaccount" placeholder="SMS Account ..." inputRef={ register({ maxLength: 10}) } />
                    {errors.d_smsaccount && errors.d_smsaccount.types && <span id="Err_msg">{errors.d_smsaccount.types.message}</span>}
                    {errors.d_smsaccount && errors.d_smsaccount.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="SMS User" labelFor="inputsmsuserid">
                    <InputGroup id="inputsmsuserid" name="d_smsuserid" placeholder="smtp User ..." inputRef={ register({ maxLength: 20}) } />
                    {errors.d_smsuserid && errors.d_smsuserid.types && <span id="Err_msg">{errors.d_smsuserid.types.message}</span>}
                    {errors.d_smsuserid && errors.d_smsuserid.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                </FormGroup>
                <FormGroup className="Col-4-2" label="SMS Password" labelFor="inputsmspasswd">
                    <InputGroup id="inputsmspasswd" name="d_smspasswd"  type="password" defaultValue="" placeholder="SMS Password ..." inputRef={ register({ maxLength: 20}) } />
                    {errors.d_smspasswd && errors.d_smspasswd.types && <span id="Err_msg">{errors.d_smspasswd.types.message}</span>}
                    {errors.d_smspasswd && errors.d_smspasswd.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                </FormGroup>
            </div>

            <div className="Row"> 
                <FormGroup className="Col-1-2" label="Default Runner" labelFor="inputRunner">
                    <InputGroup id="inputRunner" name="d_aeCode" placeholder="Runner ..." inputRef={ register({ maxLength: 10}) } />
                    {errors.d_aeCode && errors.d_aeCode.types && <span id="Err_msg">{errors.d_aeCode.types.message}</span>}
                    {errors.d_aeCode && errors.d_aeCode.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                </FormGroup>
                <FormGroup className="Col-1-2" label="DefaultCommission" labelFor="inputCommission">
                    <InputGroup id="inputRunner" name="d_commCode" placeholder="Commission ..." inputRef={ register({ maxLength: 3}) } />
                    {errors.d_commCode && errors.d_commCode.types && <span id="Err_msg">{errors.d_commCode.types.message}</span>}
                    {errors.d_commCode && errors.d_commCode.type === "maxLength" && <span id="Err_msg">Max length is 3</span>}
                </FormGroup>                
                <FormGroup className="Col-3-2" label="Default Interest" labelFor="inputInterest">
                    <InputGroup id="inputInterest" name="d_intCode" placeholder="Interest Code..." inputRef={ register({ maxLength: 3}) } />
                    {errors.d_intCode && errors.d_intCode.types && <span id="Err_msg">{errors.d_intCode.types.message}</span>}
                    {errors.d_intCode && errors.d_intCode.type === "maxLength" && <span id="Err_msg">Max length is 3</span>}
                </FormGroup>                
             
                <FormGroup className="Col-1-4" label="Password for export imp_all.zip">                
                    <InputGroup id="input_impAllZipPwd" name="d_impAllZipPwd" type={type} defaultValue="" placeholder="Import imp_all.ZIP FILE Password" inputRef={ register({ maxLength: 10,minLength:4}) } >
                    </InputGroup>
                    {errors.d_impAllZipPwd && errors.d_impAllZipPwd.types && <span id="Err_msg">{errors.d_impAllZipPwd.types.message}</span>}
                    {errors.d_impAllZipPwd && errors.d_impAllZipPwd.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                    {errors.d_impAllZipPwd && errors.d_impAllZipPwd.type === "minLength" && <span id="Err_msg">Min length is 4</span>}
                    <span  id ="11" onClick={handleToggle}>
                    <Icon  icon={icon} size={25}/>
                    </span>
                </FormGroup>      

         
            </div>

            <div className="Row">
                <FormGroup className="Col3-1-2" label="Send Method" labelFor="inputd_sendvmeth">
                    <select  id="inputd_sendvmeth" name="d_sendvmeth" value={def_d_sendvmeth} onChange={utils.handleStringChange(s => setdef_d_sendvmeth(s)) }ref={register}>
                        <option value="1">1: 電郵驗證</option>
                        <option value="2">2: SMS 驗證</option>
                    </select>
                    {errors.d_sendvmeth && errors.d_sendvmeth.types && <span id="Err_msg">{errors.d_sendvmeth.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Obsolete Day" >
                    <InputGroup id="inputObsolete_day" name="d_Obsolete_day" placeholder="Obsolete Day ..." inputRef={ register({ maxLength: 2}) } />
                    {errors.d_Obsolete_day && errors.d_Obsolete_day.types && <span id="Err_msg">{errors.d_Obsolete_day.types.message}</span>}
                    {errors.d_Obsolete_day && errors.d_Obsolete_day.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Theme" labelFor="inputd_theme1">
                    <select  id="inputd_theme1" name="d_theme1" value={def_d_theme1} onChange={utils.handleStringChange(s => setdef_d_theme1(s)) }ref={register}>
                        <option value="0">0: Basic </option>
                        <option value="1">1: Theme 1</option>
                        <option value="2">2: Theme 2</option>
                        <option value="3">3: Theme 3</option>
                        <option value="4">4: Theme 4</option>
                        <option value="5">5: Theme 5</option>
                    </select>
                    {errors.d_theme1 && errors.d_theme1.types && <span id="Err_msg">{errors.d_theme1.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col3-3-2" label="Theme (Regform)" labelFor="inputd_theme2">
                    <select  id="inputd_theme2" name="d_theme2" value={def_d_theme2} onChange={utils.handleStringChange(s => setdef_d_theme2(s)) }ref={register}>
                        <option value="0">0: Basic </option>
                        <option value="1">1: Theme 1</option>
                        <option value="2">2: Theme 2</option>
                        <option value="3">3: Theme 3</option>
                        <option value="4">4: Theme 4</option>
                        <option value="5">5: Theme 5</option>
                    </select>
                    {errors.d_theme2 && errors.d_theme2.types && <span id="Err_msg">{errors.d_theme2.types.message}</span>}
                </FormGroup>
            </div>
        </form>
    )
}

interface SysConfigMainProps {
    OpenAcc: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function SysConfigMain({ OpenAcc }: SysConfigMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as SysFl[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("Detail")
    const [currentAc, setCurrentAc] = useState({} as SysFl)
    const [BlankAc, setBlankAc] = useState({} as SysFl)

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //OpenAcc default value
    BlankAc.d_p_Pref = "P"
    BlankAc.d_m_Pref = "M"


    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("SysConfigMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SysConfigMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("SysConfigMain disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("SysConfigMain disp OpenAccFlag: ", OpenAccFlag)
                //console.log("SysConfigMain disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("SysConfigMain disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("SysConfigMain disp OpenAccFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("SysConfigMain disp OpenAccFlag.MGNGP_USER: ", OpenAccFlag.MGNGP_USER)
                //console.log("SysConfigMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                setcardOpen01("cardOpen01-Theme"+OpenAccFlag.DEF_THEME1);
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc, currentMode])




    useEffect(() => {

        setnPage(1)
        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                // usually A/E code is prefixed with C, but some user may use others e.g. Upbest uses A for A/E
                // TODO: Read A/E prefix from config
                // fixed can read true A/E
                const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            by: "",
                            start: "",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                            }
                        },
                        cPgmId: "SysConfig",
                        cAction: "ListRec",
                    });
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                console.log("main res.data", res.data.data)
                console.log("main currentAc", currentAc)
                setCurrentMode("show")

                setshowButton(false)

            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])



    const PrevRecProcess = async (SysFl: SysFl) => {
/*        
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "SysConfig", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
*/        
    }

    const DeleteProcess = async (SysFl: SysFl) => {
/*        
        try {
            //console.log("call DeleteProcess", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "SysConfig", cAction: "DelRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
*/        
    }

    const NextRecProcess = async (SysFl: SysFl) => {
/*        
        try {
            //console.log("call NextRecProcess", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "SysConfig", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
*/        
    }


    const newOnSave = async (SysFl: SysFl) => {
/*        
        try {
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "SysConfig", cAction: "AddRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
*/        
    }

    const editOnSave = async (SysFl: SysFl) => {
        try {
            console.log("call editOnSave", SysFl)
            const res = await OpenAcc.post('/Master', {sb_data: SysFl, cPgmId: "SysConfig", cAction: "UpdateRec"})
            //showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record saved")
/*            
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
*/            
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }



    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <SysConfigView OpenAcc={OpenAcc} SysFl={currentAc} baseUrl={OpenAcc.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></SysConfigView>
    switch (currentMode) {
        case "edit":
            detail = <SysConfigEdit OpenAcc={OpenAcc} SysFl={currentAc} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></SysConfigEdit>
            break
        case "new":
            detail = <SysConfigEdit OpenAcc={OpenAcc} SysFl={BlankAc} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></SysConfigEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>

    return (
        <>
            <form >
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    System Configuration
                    {/*
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                    */}
                    {detail}
                </Card>
            </form>
        </>
    );
}

export default SysConfigMain