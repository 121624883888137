import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, AnchorButton, Button, FileInput, HTMLTable, Position, Toaster, Intent, ProgressBar, Collapse, Checkbox  } from "@blueprintjs/core";
import { Alert, Dialog, Callout } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';

import svgWait2 from './svg/wait2.gif'


var OpenAccFlag: any;


interface OABackupMainProps {
    OpenAcc: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}

interface BackupInfo {
    backupName: string,
    size: number,
    date: string,
    time: string
}


function OABackupMain({ OpenAcc }: OABackupMainProps) {
    
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />

    const [isRestore, setisRestore] = useState(false)
    const [RestoreName, setRestoreName] = useState("")

    const [file1Label, setFile1Label] = useState("Choose Restore file...")
    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)
    const [backupInfoList, setBackupInfoList] = useState([] as BackupInfo[])
    const [backupMsg, setbackupMsg] = useState("")
    const [restorepMsg, setrestorepMsg] = useState("")

    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)


    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
 

    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp OpenAccFlag: ", OpenAccFlag)
                //console.log("SmMastMain disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("SmMastMain disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("SmMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setSETL_DATE(OpenAccFlag.SETL_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                setcardOpen01("cardOpen01-Theme"+OpenAccFlag.DEF_THEME1);
            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])


    useEffect(() => {
        if (taskList.findIndex((t: TaskInfo) => t.status === 0) > -1) {
            //console.log("running task(s) found, refresh tasklist in 1s")
            setTimeout(async () => {
                try {
                    const res = await OpenAcc.post('/tasklist', taskList.map((t) => t.id));
                    //console.log("tasklist res.data.data", res.data.data)
                    setTaskList(res.data.data)
                } catch (error) {
                    utils.showError(error)
                }
            }, 2*1000)
        } else {
            //console.log("all task(s) completed.")
        }
    }, [taskList, OpenAcc])

    useEffect(() => {
        setTimeout(async () => {
            setbackupMsg("")
            setrestorepMsg("")
            try {
                const res = await OpenAcc.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "xProcess",
                });                
                if ( res.data.count > 0 ) {
                    if ( res.data.data[0].x_Porcessing.substr(2,6) == "Backup" ) {
                        setbackupMsg(res.data.data[0].x_Porcessing)
                    }
                    if ( res.data.data[0].x_Porcessing.substr(2,6) == "Restor" ) {
                        setrestorepMsg(res.data.data[0].x_Porcessing)
                    }
                }
            } catch (error) {
                utils.showError(error)
            }
        }, 5*1000)
    }, [taskList, OpenAcc])


    const refreshBackupList = () => {
        (async () => {
            try {
                const res = await OpenAcc.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "backuplist",
                });                
                setBackupInfoList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onOABackup1 = () => {
        //console.log("onOABackup fileInfoList", fileInfoList);
        (async () => {
            try {
                const res = await OpenAcc.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "OABackup1",
                });                
                //console.log("SysMaint res.data.data", res.data.data)
                setTaskList(res.data.data)
                setShowTasks(true)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }


    const onOABackup2 = () => {
        //console.log("onOABackup fileInfoList", fileInfoList);
        (async () => {
            try {
                const res = await OpenAcc.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "OABackup2",
                });                
                //console.log("SysMaint res.data.data", res.data.data)
                setTaskList(res.data.data)
                setShowTasks(true)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }


    const onSBRetore = (RestoreName: string) => {
        //console.log("onSBRetore backupName", backupName);
        (async () => {
            if ( RestoreName.trim().length > 0 )
            {
                try {
                    const res = await OpenAcc.post('/SysMaint',
                        {   sb_data: {
                            RestoreName: RestoreName,
                        },
                        cPgmId: "OARestore",
                    });                
                    //console.log("SysMaint res.data.data", res.data.data)
                    setTaskList(res.data.data)
                    setShowTasks(true)
                } catch (error) {
                    utils.showError(error)
                }
            }
        })()
    }



    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

    }

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }



    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <p>Backup Processing</p>
                    <div>
                        <div>Process : {backupMsg}</div>
                    </div >
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button className="Col-1-2" icon={IconNames.LIST} onClick={onOABackup1}>Backup 1 (Call OABACKUP.BAT)</Button>
                        <Button className="Col-3-2" icon={IconNames.LIST} onClick={onOABackup2}>Backup 2 (Call OAZIP.BAT)</Button>
                        <Button className="Col-5-1" icon={IconNames.LIST} onClick={() => setShowTasks(!showTasks)}>Tasks</Button>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <Collapse isOpen={showTasks}>
                        <p>Tasks</p>
                        <HTMLTable striped interactive condensed>
                            <thead>
                                <th className="TCol-Ref">File Name</th>
                                <th className="TCol-Progress">Progress</th>
                                <th className="TCol-Message">Message</th>
                                <th className="TCol-Log">Log</th>
                            </thead>
                            <tbody>
                                {taskList.map((t, i) =>
                                    <tr key={i}>
                                        <td className="TCol-Ref">{t.ref}</td>
                                        <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                                        <td className="TCol-Message">{t.message}</td>
                                        <td className="TCol-Log">{t.logFile && <a key={i} target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/logdownload?filename=${t.logFile}&inline`}>View</a>}</td>
                                    </tr>
                                )}
                            </tbody>
                        </HTMLTable>
                        <div className="Spacing-V-16"></div>
                    </Collapse>
                    <br></br>
                    <p>Notes</p>
                    <div>
                        <div>Backup 1 (Call OABACKUP.BAT)</div>
                        <div>Backup 2 (Call OAZIP.BAT)</div>
                    </div >
                    <br></br>
                    <p>Restore Processing</p>
                    <div>
                        <div>Process : {restorepMsg}</div>
                    </div >
                    <div className="Spacing-V-8"></div>
                    {/*
                    <div className="Row">
                        <FileInput inputProps={{ name: "file1" }} text={file1Label} onInputChange={utils.handleStringChange((s) => setFile1Label(utils.fileBase(s)))} />
                    </div>
                    */}
                    <Button className="Col-1-1" icon={IconNames.REFRESH} onClick={() => refreshBackupList()}>Refresh Restore File List</Button>
                    <HTMLTable striped interactive condensed>
                        <thead>
                            <tr>
                                <th className="TCol-FileName">Backup File Name</th>
                                <th className="TCol-DateTime">Time</th>
                                <th className="TCol-Records"><div className="Align-R">Size</div></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {backupInfoList.map((f, i) =>
                                <tr key={i}>
                                    <td className="TCol-FileName">{f.backupName}</td>
                                    <td className="TCol-DateTime">{f.date+' '+f.time}</td>
                                    <td className="TCol-Records"><div className="Align-R">{utils.fileSize(f.size)}</div></td>
                                    <td><AnchorButton icon={IconNames.DOWNLOAD} href={`${OpenAcc.defaults.baseURL}/backupdownload?backupName=${f.backupName}`}>Download</AnchorButton></td>
                                    {/* <td><Button icon={IconNames.UNARCHIVE} disabled={false} onClick={() => onSBRetore(f.backupName) }>Restore</Button></td> */}

                                    <td><Button icon={IconNames.UNARCHIVE} disabled={false} onClick={() => { setRestoreName(f.backupName); setisRestore(true) }}>Restore</Button></td>
                                </tr>
                            )}
                        </tbody>
                    </HTMLTable>
                    <div className="Spacing-V-16"></div>
                    <Dialog
                        icon = "warning-sign"
                        title = "Restore"
                        isOpen = {isRestore}
                        onClose = { () => setisRestore(false) }
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <div>Restore the backup {RestoreName}</div>
                            <div>Are you sure to Restore ? </div>
                            <div className="Spacing-V-16"></div>
                            <Button type="submit" onClick={ () => setisRestore(false) }>Cancel</Button>
                            &nbsp; &nbsp; &nbsp;
                            <Button intent={Intent.WARNING} type="submit" onClick={ () => { onSBRetore(RestoreName); setisRestore(false) } }>Restore</Button>
                        </div>
                    </Dialog>
                </Card>
            </form>

        </>
    );
}

export default OABackupMain