import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Classes, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


interface Account {
    d_acCode: string,
    d_acName1: string,
    d_acName2: string,
    d_acAddr1: string,
    d_acAddr2: string,
    d_acAddr3: string,
    d_commCode: string,
    d_intCode: string,
    d_aeCode: string,
    d_tel: string,
    d_offTel: string,
    d_fax: string,
    d_mobile: string,
    d_idNo: string,
    d_email: string,
    d_sendemail: string,
    d_HardCopyStmt: string,
    d_HardCopyMthStmt: string,
    d_openDate: string,
    d_renewDate: string,
    d_gemDate: string,
    d_status: string,
    d_loanLimit: number,
    d_PrintChq: string,
    d_PrintCBBCChq: string,
    d_ChqName1: string,
    d_ChqName2: string,
    d_HighRisk: string,
    d_HighRiskDate: string,
    d_MarginRatio: number,
    d_MarginGroup: string,
    d_BankInfo: string,
    d_BankACNo: string,
    d_AutoCalcAvgPrice: string,
    d_PrnDetailTran: string,
    d_footnote: string,
}


interface Document {
    docType: string,
    fileName: string
}

interface StatusInfo {
    d_StatusCode: string,
    d_StatusDesc: string,
}

interface PageInfo {
    n: number
}

var OpenAccFlag: any;

interface ClAccMastViewProps {
    ac: Account,
    docList?: Document[],
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}

const getDocIcon = (docType: string) => {
    switch (docType.toLowerCase()) {
        case "id":
            return "id-number"
        case "signature":
            return "draw"
    }
    // contract, others
    return "document"
}

const getDocText = (docType: string) => {
    switch (docType.toLowerCase()) {
        case "id":
            return "ID"
        case "signature":
            return "Signature"
        case "contract":
            return "Contract"
        case "others":
            return "Others"
    }
    // contract, others
    return "Unknown"
}

function ClAccMastView({ ac = {} as Account, docList = [] as Document[], baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: ClAccMastViewProps) {

    const [isAdd, setisAdd] = useState(false)
    const [isEdit, setisEdit] = useState(false)
    const [isDelete, setisDelete] = useState(false)

    if (! ("d_acCode" in ac)) return null

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>

    function buttonPress(buttonName: string)
    {
        if ( buttonName == "new" )
        {
            onNew()
        }
        if ( buttonName == "edit" )
        {
            onEdit()
        }
        if ( buttonName == "delete" )
        {
            setisDelete(true)
        }
    }

    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" icon="add" onClick={() => buttonPress("new")} >New</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isAdd}
                        onConfirm={ () => { setisAdd(false) } }
                    >
                        <p>
                            NEW : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-3-1" icon="edit" onClick={() => buttonPress("edit")} >Edit</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isEdit}
                        onConfirm={ () => { setisEdit(false) } }
                    >
                        <p>
                            EDIT : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-4-1" icon="delete" onClick={() => buttonPress("delete")} >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDelete}
                        onCancel={ () => setisDelete(false) }
                        onConfirm={ () => { onDelete(); setisDelete(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
{/*
                
                <FormGroup className="Col-7-2" label="">
                    <a target="_blank" rel="" href={`/#/AccInfo?acCode=${ac.d_acCode}`}>
                    <Button className="Col-7-2" icon="document" onClick={() => {} } >Balance & Portfolio</Button>
                    </a>
                </FormGroup>
*/}                
                <Button className="Col-9-2" icon="document" onClick={() => { window.open(`/#/AccInfo?acCode=${ac.d_acCode}`, '_blank') } } >Balance & Portfolio</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelInfo="*">
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        <InputGroup readOnly value={ac.d_acCode} />
                    </Tooltip>
                </FormGroup>
                <FormGroup className="Col-2-3" label="Client Name">
                    <InputGroup readOnly value={ac.d_acName1} />
                    <InputGroup readOnly value={ac.d_acName2} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="ID No.">
                    <InputGroup readOnly value={ac.d_idNo} />
                </FormGroup>
                <FormGroup className="Col-7-1" label="Status">
                    <InputGroup readOnly value={ac.d_status} />
                </FormGroup>
                <FormGroup className="Col-8-2" label="Open">
                    <InputGroup readOnly value= {utils.dispDate(ac.d_openDate, OpenAccFlag.dateFormat)} />
                </FormGroup>
                <FormGroup className="Col-10-2" label="Renew / GEM">
                    <InputGroup readOnly value={utils.dispDate(ac.d_renewDate, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly value={utils.dispDate(ac.d_gemDate, OpenAccFlag.dateFormat)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="" labelFor="">
                </FormGroup>
                {(OpenAccFlag.CHQ_NAME) && 
                    <FormGroup className="Col-2-3" label="Cheque Name" labelFor="inputChqName1">
                        <InputGroup readOnly value={ac.d_ChqName1} />
                        <InputGroup readOnly value={ac.d_ChqName2} />
                    </FormGroup>
                }
                {(OpenAccFlag.SEL_CQPRT) && 
                    <FormGroup className="Col-5-2" label="Print Cheque" labelFor="inputPrintChq">
                        <RadioGroup
                            name="d_PrintChq"
                            disabled={true}
                            inline={true}
                            selectedValue={ac.d_PrintChq}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                    </FormGroup>
                }
                {(OpenAccFlag.CBBC_USER) && 
                    <FormGroup className="Col-7-2" label="Print CBBC Cheque" labelFor="inputPrintCBBCChq">
                        <RadioGroup
                            name="d_PrintCBBCChq"
                            disabled={true}
                            inline={true}
                            selectedValue={ac.d_PrintCBBCChq}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-4" label="Address">
                    <InputGroup readOnly value={ac.d_acAddr1} />
                    <InputGroup readOnly value={ac.d_acAddr2} />
                    <InputGroup readOnly value={ac.d_acAddr3} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Phone / Office">
                    <InputGroup readOnly value={ac.d_tel} />
                    <InputGroup readOnly value={ac.d_offTel} />
                </FormGroup>
                <FormGroup className="Col-7-2" label="Mobile / Fax">
                    <InputGroup readOnly value={ac.d_mobile} />
                    <InputGroup readOnly value={ac.d_fax} />
                </FormGroup>
                <FormGroup className="Col-9-2" label="Bank / AC No.">
                    <InputGroup readOnly value={ac.d_BankInfo} />
                    <InputGroup  readOnly value={ac.d_BankACNo} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Runner">
                    <InputGroup readOnly value={ac.d_aeCode} />
                </FormGroup>
                <FormGroup className="Col-2-4" label="E-Mail">
                    <InputGroup readOnly value={ac.d_email} />
                </FormGroup>
                <FormGroup className="Col-6-2" label="Send e-mail Statement" labelFor="inputSendemail">
                    <Switch readOnly id="inputSendemail" label="" innerLabelChecked="Send" innerLabel="not Send" checked={(ac.d_sendemail==="Y")} />
                </FormGroup>
                <FormGroup className="Col-8-2" label="Skip Hard Copy Statement" labelFor="inputHardCopyStmt">
                    <Switch readOnly id="inputHardCopyStmt" label="Skip" innerLabelChecked="Yes" innerLabel="No" checked={(ac.d_HardCopyStmt==="Y")} />
                </FormGroup>
                {(OpenAccFlag.HC_MTH_STMT) && 
                    <FormGroup className="Col-10-2" label="Skip Monthly Statement" labelFor="inputHardCopyMthStmt">
                        <Switch readOnly id="inputHardCopyMthStmt" label="Skip" innerLabelChecked="Yes" innerLabel="No" checked={(ac.d_HardCopyMthStmt==="Y")} />
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Commission">
                    <InputGroup readOnly value={ac.d_commCode} />
                </FormGroup>
                <FormGroup className="Col-2-1" label="Interest">
                    <InputGroup readOnly value={ac.d_intCode} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Loan Limit">
                    <InputGroup readOnly value={utils.formatNumber2(ac.d_loanLimit)} />
                </FormGroup>
                { (! OpenAccFlag.MGNGP_USER) &&
                    <div className="Row">
                        <FormGroup className="Col-5-2" label="Margin Ratio" labelFor="inputMarginRatio">
                            <InputGroup readOnly value={utils.formatNumber2(ac.d_MarginRatio)} />
                        </FormGroup>
                    </div>
                }
                {(OpenAccFlag.MGNGP_USER) &&
                    <div className="Row">
                        <FormGroup className="Col-5-1" label="Margin Group" labelFor="inputMarginGroup">
                            <InputGroup readOnly value={ac.d_MarginGroup} />
                        </FormGroup>
                    </div>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Allow High Risk" labelFor="inputHighRisk">
                    <Switch readOnly id="inputHighRisk" label="" innerLabelChecked="Allow" innerLabel="not Allow" checked={(ac.d_HighRisk==="Y")} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="High Risk verified on" labelFor="inputHighRiskDate">
                    <InputGroup readOnly value= {utils.dispDate(ac.d_HighRiskDate, OpenAccFlag.dateFormat)} />
                </FormGroup>
                {(OpenAccFlag.AVG_MUL_PRCE) && 
                    <FormGroup className="Col-5-2" label="Auto Calc Average Price" labelFor="inputAutoCalcAvgPrice">
                        <Switch id="inputAutoCalcAvgPrice" label="Auto Calc" innerLabelChecked="Yes" innerLabel="No" checked={(ac.d_AutoCalcAvgPrice==="Y")} />
                    </FormGroup>
                }
                {(OpenAccFlag.MULTI_PRCE) && (! OpenAccFlag.AVG_MUL_PRCE) && 
                    <FormGroup className="Col-5-2" label="Print Detail Transaction" labelFor="inputPrnDetailTran">
                        <Switch id="inputPrnDetailTran" label="Print Detail" innerLabelChecked="Yes" innerLabel="No" checked={(ac.d_PrnDetailTran==="Y")} />
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-6" label="Footnote">
                    <TextArea readOnly fill rows={4} value={ac.d_footnote} />
                </FormGroup>
            </div>
            <p>Documents</p>
            <div className="Row">
                {docList.map((doc, i) =>
                    <AnchorButton key={i} icon={getDocIcon(doc.docType)} target="_blank" href={`${baseUrl}/documentdownload?doctype=${doc.docType}&filename=${doc.fileName}&inline`}>{getDocText(doc.docType)}</AnchorButton>
                )}
            </div>
        </div>
    )
}

interface ClAccMastEditProps {
    OpenAcc: AxiosInstance,
    ac?: Account,
    aeList?: Account[],
    isNew?: boolean,
    onSave?: (ac: Account) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function ClAccMastEdit({ OpenAcc, ac = {} as Account, aeList = [] as Account[], isNew = false, onSave = () => { }, onCancel = () => { } }: ClAccMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<Account>()

    const [SendemailValue, setSendemailValue] = useState(ac.d_sendemail==="Y")
    const [HardCopyStmtValue, setHardCopyStmtValue] = useState(ac.d_HardCopyStmt==="Y")
    const [HardCopyMthStmtValue, setHardCopyMthStmtValue] = useState(ac.d_HardCopyMthStmt==="Y")
    const [PrintChqValue, setPrintChqValue] = useState(ac.d_PrintChq)
    const [PrintCBBCChqValue, setPrintCBBCChqValue] = useState(ac.d_PrintCBBCChq)
    const [HighRiskValue, setHighRiskValue] = useState(ac.d_HighRisk==="Y")
    const [AutoCalcAvgPriceValue, setAutoCalcAvgPriceValue] = useState(ac.d_AutoCalcAvgPrice==="Y")
    const [PrnDetailTranValue, setPrnDetailTranValue] = useState(ac.d_PrnDetailTran==="Y")

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>
   
   
   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_acCode" in ac) {
            reset(ac)
        }
    }, [ac, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        register({ name: "d_aeCode" })
        register({ name: "d_status" })
    }, [register])

    if (!isNew && !("d_acCode" in ac)) return null

    let idNoLength = 20
    if ( OpenAccFlag.user_key === "TATLEE" ) {
        idNoLength = 22
    }

    let AddrLength = 30
    if ( OpenAccFlag.user_key === "WINFULL" || OpenAccFlag.user_key === "WINGHANG" || OpenAccFlag.user_key === "COMPSHARE" ) {
        AddrLength = 35
    }

    let TelLength = 15
    if ( OpenAccFlag.user_key === "BERICH" || OpenAccFlag.user_key === "CHEERFUL" ) {
        TelLength = 20
    }

    let OffTelLength = 15
    if ( OpenAccFlag.user_key === "CHEERFUL"  ) {
        OffTelLength = 20
    }

    let MobileLength = 15

    let FaxLength = 15

    let EmailLength = 100
    if ( OpenAccFlag.user_key === "PAMIRS"  ) {
            EmailLength = 130
    } else if ( OpenAccFlag.user_key === "COMPSHARE" || OpenAccFlag.user_key === "BELTRADE" ) {
        EmailLength = 200
    }

    let BankInfoLength = 20
    if ( OpenAccFlag.user_key === "WINGHANG"  ) {
        BankInfoLength = 9
    }

    let BankACNoLength = 20
    if ( OpenAccFlag.user_key === "WINGHANG"  ) {
        BankACNoLength = 9
    }


    const onSubmit = (data: Account) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_acCode = data.d_acCode.toUpperCase();

        data.d_sendemail = "Y";
        if (SendemailValue) {
            data.d_sendemail = "Y";
        } else {
            data.d_sendemail = "N";
        }
        data.d_HardCopyStmt = "Y";
        if (HardCopyStmtValue) {
            data.d_HardCopyStmt = "Y";
        } else {
            data.d_HardCopyStmt = "N";
        }
        data.d_HardCopyMthStmt = "Y";
        if (HardCopyMthStmtValue) {
            data.d_HardCopyMthStmt = "Y";
        } else {
            data.d_HardCopyMthStmt = "N";
        }
        data.d_PrintChq = PrintChqValue;
        data.d_PrintCBBCChq = PrintCBBCChqValue;
        data.d_HighRisk = "Y";
        if (HighRiskValue) {
            data.d_HighRisk = "Y";
        } else {
            data.d_HighRisk = "N";
        }
        data.d_AutoCalcAvgPrice = "Y";
        if (AutoCalcAvgPriceValue) {
            data.d_AutoCalcAvgPrice = "Y";
        } else {
            data.d_AutoCalcAvgPrice = "N";
        }
        data.d_PrnDetailTran = "Y";
        if (PrnDetailTranValue) {
            data.d_PrnDetailTran = "Y";
        } else {
            data.d_PrnDetailTran = "N";
        }


        (async () => {
            try {
                //console.log("call ClAccMastedit data", data)
                //console.log("call ClAccMastedit isNew", isNew)
                const res = await OpenAcc.post('/Master', {sb_data: data, cPgmId: "ClAccMast", cAction: "ClValidate", isNew: isNew})
                //console.log("ClAccMastedit res.data.ok", res.data.ok)
                //console.log("ClAccMastedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }

    const acRenderer: ItemRenderer<Account> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${ac.d_acName1}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ac.d_acCode}
                key={ac.d_acCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const acPredicate: ItemPredicate<Account> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.d_acCode} - ${ac.d_acName1}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const acValueRenderer = (ac: Account) => {
        return ac.d_acCode
    }

    const StatusRenderer: ItemRenderer<StatusInfo> = (StatusList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${StatusList.d_StatusDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={StatusList.d_StatusCode}
                key={StatusList.d_StatusCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StatusPredicate: ItemPredicate<StatusInfo> = (query, StatusList, _index, exactMatch) => {
        const normalizedText = `${StatusList.d_StatusCode} - ${StatusList.d_StatusDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StatusValueRenderer = (StatusList: StatusInfo) => {
        return StatusList.d_StatusCode
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(ac)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputAcCode" labelInfo="*">
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        <InputGroup disabled={! isNew} id="inputAcCode" name="d_acCode" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 8 }) } />
                    </Tooltip>    
                    {errors.d_acCode && errors.d_acCode.type === "required" && (<span id="Err_msg">Account No. cannot be Blank</span>)}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="Client Name" labelFor="inputAcName1">
                    <InputGroup id="inputAcName1" name="d_acName1" autoFocus={! isNew} defaultValue="" placeholder="Name 1 ..." inputRef={register({ maxLength: 30}) } />
                    <InputGroup id="inputAcName2" name="d_acName2" defaultValue="" placeholder="Name 2 ..." inputRef={register({ maxLength: 30}) } />
                    {errors.d_acName1 && errors.d_acName1.types && <span id="Err_msg">{errors.d_acName1.types.message}</span>}
                    {errors.d_acName2 && errors.d_acName2.types && <span id="Err_msg">{errors.d_acName2.types.message}</span>}
                    {errors.d_acName1 && errors.d_acName1.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    {errors.d_acName2 && errors.d_acName2.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="ID No." labelFor="inputIdNo">
                    <InputGroup id="inputIdNo" name="d_idNo" defaultValue="" placeholder="ID No. ..." inputRef={register({ maxLength: idNoLength}) } />
                    {errors.d_idNo && errors.d_idNo.types && <span id="Err_msg">{errors.d_idNo.types.message}</span>}
                    {errors.d_idNo && errors.d_idNo.type === "maxLength" && <span id="Err_msg">Max length is {idNoLength}</span>}
                </FormGroup>
                <FormGroup className="Col3-7-1" label="Status" labelFor="inputStatus">
                     <InputGroup id="inputStatus" name="d_status" defaultValue="" placeholder="Status ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_status && errors.d_status.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_status && errors.d_status.types && <span id="Err_msg">{errors.d_status.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-8-2" label="Open" labelFor="inputOpenDate">
                    <InputGroup id="inputOpenDate" name="d_openDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_openDate && errors.d_openDate.types && <span id="Err_msg">{errors.d_openDate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-10-2" label="Renew / GEM" labelFor="inputRenewDate">
                    <InputGroup id="inputRenewDate" name="d_renewDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_renewDate && errors.d_renewDate.types && <span id="Err_msg">{errors.d_renewDate.types.message}</span>}
                    <InputGroup id="inputGemDate" name="d_gemDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_gemDate && errors.d_gemDate.types && <span id="Err_msg">{errors.d_gemDate.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="" labelFor="">
                </FormGroup>
                {(OpenAccFlag.CHQ_NAME) && 
                    <FormGroup className="Col-2-3" label="Cheque Name" labelFor="inputChqName1">
                        <InputGroup id="inputAcName1" name="d_ChqName1" defaultValue="" placeholder="Cheque Name 1 ..." inputRef={register({ maxLength: 30}) } />
                        <InputGroup id="inputAcName2" name="d_ChqName2" defaultValue="" placeholder="Cheque Name 2 ..." inputRef={register({ maxLength: 30}) } />
                        {errors.d_ChqName1 && errors.d_ChqName1.types && <span id="Err_msg">{errors.d_ChqName1.types.message}</span>}
                        {errors.d_ChqName2 && errors.d_ChqName2.types && <span id="Err_msg">{errors.d_ChqName2.types.message}</span>}
                        {errors.d_ChqName1 && errors.d_ChqName1.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                        {errors.d_ChqName2 && errors.d_ChqName2.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    </FormGroup>
                }
                {(OpenAccFlag.SEL_CQPRT) && 
                    <FormGroup className="Col-5-2" label="Print Cheque" labelFor="inputPrintChq">
                        <RadioGroup
                            name="d_PrintChq"
                            inline={true}
                            selectedValue={PrintChqValue}
                            onChange={utils.handleStringChange(s => setPrintChqValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_PrintChq && errors.d_PrintChq.types && <span id="Err_msg">{errors.d_PrintChq.types.message}</span>}
                    </FormGroup>
                }
                {(OpenAccFlag.CBBC_USER) && 
                    <FormGroup className="Col-7-2" label="Print CBBC Cheque" labelFor="inputPrintCBBCChq">
                        <RadioGroup
                            name="d_PrintCBBCChq"
                            inline={true}
                            selectedValue={PrintCBBCChqValue}
                            onChange={utils.handleStringChange(s => setPrintCBBCChqValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_PrintCBBCChq && errors.d_PrintCBBCChq.types && <span id="Err_msg">{errors.d_PrintCBBCChq.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-4" label="Address" labelFor="inputAddr1">
                    <InputGroup id="inputAddr1" name="d_acAddr1" defaultValue="" placeholder="Address ..." inputRef={register({ maxLength: AddrLength}) } />
                    <InputGroup id="inputAddr2" name="d_acAddr2" defaultValue="" placeholder="Address ..." inputRef={register({ maxLength: AddrLength}) } />
                    <InputGroup id="inputAddr3" name="d_acAddr3" defaultValue="" placeholder="Address ..." inputRef={register({ maxLength: AddrLength}) } />
                    {errors.d_acAddr1 && errors.d_acAddr1.types && <span id="Err_msg">{errors.d_acAddr1.types.message}</span>}
                    {errors.d_acAddr2 && errors.d_acAddr2.types && <span id="Err_msg">{errors.d_acAddr2.types.message}</span>}
                    {errors.d_acAddr3 && errors.d_acAddr3.types && <span id="Err_msg">{errors.d_acAddr3.types.message}</span>}
                    {errors.d_acAddr1 && errors.d_acAddr1.type === "maxLength" && <span id="Err_msg">Max length is {AddrLength}</span>}
                    {errors.d_acAddr2 && errors.d_acAddr2.type === "maxLength" && <span id="Err_msg">Max length is {AddrLength}</span>}
                    {errors.d_acAddr3 && errors.d_acAddr3.type === "maxLength" && <span id="Err_msg">Max length is {AddrLength}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="Phone / Office" labelFor="inputTel">
                    <InputGroup id="inputTel" name="d_tel" defaultValue="" placeholder="Phone ..." inputRef={register({ maxLength: TelLength}) } />
                    {errors.d_tel && errors.d_tel.types && <span id="Err_msg">{errors.d_tel.types.message}</span>}
                    {errors.d_tel && errors.d_tel.type === "maxLength" && <span id="Err_msg">Max length is {TelLength}</span>}
                    <InputGroup id="inputOffTel" name="d_offTel" defaultValue="" placeholder="Office Tel ..." inputRef={register({ maxLength: OffTelLength}) } />
                    {errors.d_offTel && errors.d_offTel.types && <span id="Err_msg">{errors.d_offTel.types.message}</span>}
                    {errors.d_offTel && errors.d_offTel.type === "maxLength" && <span id="Err_msg">Max length is {OffTelLength}</span>}
                </FormGroup>
                <FormGroup className="Col-7-2" label="Mobile / Fax" labelFor="inputMobile">
                    <InputGroup id="inputMobile" name="d_mobile" defaultValue="" placeholder="Mobile ..." inputRef={register({ maxLength: MobileLength}) } />
                    {errors.d_mobile && errors.d_mobile.types && <span id="Err_msg">{errors.d_mobile.types.message}</span>}
                    {errors.d_mobile && errors.d_mobile.type === "maxLength" && <span id="Err_msg">Max length is {MobileLength}</span>}
                    <InputGroup id="inputFax" name="d_fax" defaultValue="" placeholder="Fax No. ..." inputRef={register({ maxLength: FaxLength}) } />
                    {errors.d_fax && errors.d_fax.types && <span id="Err_msg">{errors.d_fax.types.message}</span>}
                    {errors.d_fax && errors.d_fax.type === "maxLength" && <span id="Err_msg">Max length is {FaxLength}</span>}
                </FormGroup>
                <FormGroup className="Col-9-2" label="Bank / AC No." labelFor="inputBankInfo">
                    <InputGroup id="inputBankInfo" name="d_BankInfo" defaultValue="" placeholder="Bank ..." inputRef={register({ maxLength: BankInfoLength}) } />
                    {errors.d_BankInfo && errors.d_BankInfo.types && <span id="Err_msg">{errors.d_BankInfo.types.message}</span>}
                    {errors.d_BankInfo && errors.d_BankInfo.type === "maxLength" && <span id="Err_msg">Max length is {BankInfoLength}</span>}
                    <InputGroup id="inputFax" name="d_BankACNo" defaultValue="" placeholder="AC No.. ..." inputRef={register({ maxLength: BankACNoLength}) } />
                    {errors.d_BankACNo && errors.d_BankACNo.types && <span id="Err_msg">{errors.d_BankACNo.types.message}</span>}
                    {errors.d_BankACNo && errors.d_BankACNo.type === "maxLength" && <span id="Err_msg">Max length is {BankACNoLength}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-1" label="Runner" labelFor="inputAeCode">
                    {/*<InputGroup id="inputAeCode" name="aeCode" inputRef={register} />*/}
                    <Suggest
                        inputProps={{ placeholder: "Please choose AE" }}
                        defaultSelectedItem={aeList.find((ae) => ae.d_acCode === ac.d_aeCode)}
                        items={aeList}
                        itemRenderer={acRenderer}
                        itemPredicate={acPredicate}
                        inputValueRenderer={acValueRenderer}
                        onItemSelect={(ac) => { setValue("d_aeCode", ac.d_acCode) }}
                    />
                </FormGroup>
                <FormGroup className="Col-2-4" label="E-Mail" labelFor="inputEmail">
                    <InputGroup id="inputEmail" name="d_email" defaultValue="" placeholder="E-Mail ..." inputRef={register({ maxLength: EmailLength}) } />
                    {errors.d_email && errors.d_email.types && <span id="Err_msg">{errors.d_email.types.message}</span>}
                    {errors.d_email && errors.d_email.type === "maxLength" && <span id="Err_msg">Max length is {EmailLength}</span>}
                </FormGroup>
                <FormGroup className="Col-6-2" label="Send e-mail Statement" labelFor="inputSendemail">
                    <Switch id="inputSendemail" label="" innerLabelChecked="Send" innerLabel="not Send" checked={SendemailValue} onChange={utils.handleBooleanChange(v => setSendemailValue(v))} ref={register}/>
                    {errors.d_sendemail && errors.d_sendemail.types && <span id="Err_msg">{errors.d_sendemail.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-8-2" label="Skip Hard Copy Statement" labelFor="inputHardCopyStmt">
                    <Switch id="inputHardCopyStmt" label="Skip" innerLabelChecked="Yes" innerLabel="No" checked={HardCopyStmtValue} onChange={utils.handleBooleanChange(v => setHardCopyStmtValue(v))} ref={register}/>
                    {errors.d_HardCopyStmt && errors.d_HardCopyStmt.types && <span id="Err_msg">{errors.d_HardCopyStmt.types.message}</span>}
                </FormGroup>
                {(OpenAccFlag.HC_MTH_STMT) && 
                    <FormGroup className="Col-10-2" label="Skip Monthly Statement" labelFor="inputHardCopyMthStmt">
                        <Switch id="inputHardCopyMthStmt" label="Skip" innerLabelChecked="Yes" innerLabel="No" checked={HardCopyMthStmtValue} onChange={utils.handleBooleanChange(v => setHardCopyMthStmtValue(v))} ref={register}/>
                        {errors.d_HardCopyMthStmt && errors.d_HardCopyMthStmt.types && <span id="Err_msg">{errors.d_HardCopyMthStmt.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Commission" labelFor="inputCommCode">
                    <InputGroup id="inputCommCode" name="d_commCode" defaultValue="" placeholder="Commission Code ..." inputRef={register({ maxLength: 2}) } />
                    {errors.d_commCode && errors.d_commCode.types && <span id="Err_msg">{errors.d_commCode.types.message}</span>}
                    {errors.d_commCode && errors.d_commCode.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                </FormGroup>
                <FormGroup className="Col-2-1" label="Interest" labelFor="inputIntCode">
                    <InputGroup id="inputIntCode" name="d_intCode" defaultValue="" placeholder="Interest Code ..." inputRef={register({ maxLength: 2}) } />
                    {errors.d_intCode && errors.d_intCode.types && <span id="Err_msg">{errors.d_intCode.types.message}</span>}
                    {errors.d_intCode && errors.d_intCode.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Loan Limit" labelFor="inputloanLimit">
                    <InputGroup className="inputNumberNoSpin" id="inputloanLimit" name="d_loanLimit" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_loanLimit && errors.d_loanLimit.types && <span id="Err_msg">{errors.d_loanLimit.types.message}</span>}
                </FormGroup>
                { (! OpenAccFlag.MGNGP_USER) &&
                    <div className="Row">
                        <FormGroup className="Col-5-2" label="Margin Ratio" labelFor="inputMarginRatio">
                            <InputGroup className="inputNumberNoSpin" id="inputMarginRatio" name="d_MarginRatio" defaultValue="0" type="number" inputRef={register} />
                            {errors.d_MarginRatio && errors.d_MarginRatio.types && <span id="Err_msg">{errors.d_MarginRatio.types.message}</span>}
                        </FormGroup>
                    </div>
                }
                {(OpenAccFlag.MGNGP_USER) &&
                    <div className="Row">
                        <FormGroup className="Col-5-1" label="Margin Group" labelFor="inputMarginGroup">
                            <InputGroup id="inputMarginGroup" name="d_MarginGroup" defaultValue="" placeholder="Margin Group ..." inputRef={register({ maxLength: 1}) } />
                            {errors.d_MarginGroup && errors.d_MarginGroup.types && <span id="Err_msg">{errors.d_MarginGroup.types.message}</span>}
                            {errors.d_MarginGroup && errors.d_MarginGroup.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                        </FormGroup>
                    </div>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Allow High Risk" labelFor="inputHighRisk">
                    {/* <input id="inputHighRisk" type="checkbox" checked={HighRiskValue} onChange={utils.handleBooleanChange(v => setHighRiskValue(v))} ref={register}/> High Risk */}
                    <Switch id="inputHighRisk" label="" innerLabelChecked="Allow" innerLabel="not Allow" checked={HighRiskValue} onChange={utils.handleBooleanChange(v => setHighRiskValue(v))} ref={register}/>
                    {errors.d_HighRisk && errors.d_HighRisk.types && <span id="Err_msg">{errors.d_HighRisk.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="High Risk verified on" labelFor="inputHighRiskDate">
                    <InputGroup id="inputHighRiskDate" name="d_HighRiskDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_HighRiskDate && errors.d_HighRiskDate.types && <span id="Err_msg">{errors.d_HighRiskDate.types.message}</span>}
                </FormGroup>
                {(OpenAccFlag.AVG_MUL_PRCE) && 
                    <FormGroup className="Col-5-2" label="Auto Calc Average Price" labelFor="inputAutoCalcAvgPrice">
                        <Switch id="inputAutoCalcAvgPrice" label="Auto Calc" innerLabelChecked="Yes" innerLabel="No" checked={AutoCalcAvgPriceValue} onChange={utils.handleBooleanChange(v => setAutoCalcAvgPriceValue(v))} ref={register}/>
                        {errors.d_AutoCalcAvgPrice && errors.d_AutoCalcAvgPrice.types && <span id="Err_msg">{errors.d_AutoCalcAvgPrice.types.message}</span>}
                    </FormGroup>
                }
                {(OpenAccFlag.MULTI_PRCE) && (! OpenAccFlag.AVG_MUL_PRCE) && 
                    <FormGroup className="Col-5-2" label="Print Detail Transaction" labelFor="inputPrnDetailTran">
                        <Switch id="inputPrnDetailTran" label="Print Detail" innerLabelChecked="Yes" innerLabel="No" checked={PrnDetailTranValue} onChange={utils.handleBooleanChange(v => setPrnDetailTranValue(v))} ref={register}/>
                        {errors.d_PrnDetailTran && errors.d_PrnDetailTran.types && <span id="Err_msg">{errors.d_PrnDetailTran.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-6" label="Footnote" labelFor="inputFootnote">
                    {/*<TextArea fill rows={4} name="footnote" inputRef={register} />*/}
                    {/* TextArea inputRef doesn't work */}
                    <textarea className="bp3-input bp3-fill" name="d_footnote" rows={4} ref={register}></textarea>
                </FormGroup>
            </div>
        </form>
    )
}

interface ClAccMastMainProps {
    OpenAcc: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ClAccMastMain({ OpenAcc }: ClAccMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    const [data, setData] = useState([] as Account[])

    const [aeList, setAeList] = useState([] as Account[])
    const [docList, setDocList] = useState([] as Document[])

    const [searchKey, setSearchKey] = useState("acCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchIdNo, setSearchIdNo] = useState("")
    const [searchTel, setSearchTel] = useState("")
    const [searchStatus, setSearchStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as Account)
    const [BlankAc, setBlankAc] = useState({} as Account)

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")

    //OpenAcc default value
    BlankAc.d_sendemail = "Y"
    BlankAc.d_HardCopyStmt = "N"
    BlankAc.d_HardCopyMthStmt = "N"
    BlankAc.d_PrintChq = "N"
    BlankAc.d_PrintCBBCChq = "N"
    BlankAc.d_HighRisk = "Y"
    BlankAc.d_AutoCalcAvgPrice = "Y"
    BlankAc.d_PrnDetailTran = "Y"

    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("ClAccMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("ClAccMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("ClAccMastMain disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("ClAccMastMain disp OpenAccFlag: ", OpenAccFlag)
                //console.log("ClAccMastMain disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("ClAccMastMain disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("ClAccMastMain disp OpenAccFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("ClAccMastMain disp OpenAccFlag.MGNGP_USER: ", OpenAccFlag.MGNGP_USER)
                //console.log("ClAccMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;

                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])

    useEffect(() => {

        setnPage(1)
        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                // usually A/E code is prefixed with C, but some user may use others e.g. Upbest uses A for A/E
                // TODO: Read A/E prefix from config
                // fixed can read true A/E
                const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: "C",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                                aeList: "Y",
                            }
                        },
                        cPgmId: "ClAccMast",
                        cAction: "ListRec",
                    });
                setAeList(res.data.data)
                //setAeList(utils.sortByColumn(res.data.data, "d_acName1"))

            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])


    const PrevRecProcess = async (ac: Account) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "ClAccMast", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }

    const DeleteProcess = async (ac: Account) => {
        try {
            //console.log("call DeleteProcess", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "ClAccMast", cAction: "DelRec"})
            showSuccess("Account No. " + ac.d_acCode + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }

    const NextRecProcess = async (ac: Account) => {
        try {
            //console.log("call NextRecProcess", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "ClAccMast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }


    const newOnSave = async (ac: Account) => {
        try {
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "ClAccMast", cAction: "AddRec"})
            showSuccess("Account Code " + ac.d_acCode + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }

    const editOnSave = async (ac: Account) => {
        try {
            console.log("call editOnSave", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "ClAccMast", cAction: "UpdateRec"})
            showSuccess("Account Code " +ac.d_acCode + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await OpenAcc.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                            idNo: searchIdNo,
                            tel: searchTel,
                            status: searchStatus
                        },
                    },
                    cPgmId: "ClAccMast",
                    cAction: "ListRec",
                });

                //console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.count", res.data.count)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
            } else {
                const res = await OpenAcc.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "ClAccMast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    alert(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            showError(error)
        }
    }

    // lookup account if acCode is passed
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            SortByName: false,
                        },
                        cPgmId: "ClAccMast",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [OpenAcc, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if (nPage > 0 && nPage <= nTotalPage) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                idNo: searchIdNo,
                                tel: searchTel,
                                status: searchStatus
                            },
                            },
                        cPgmId: "ClAccMast",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    showError(error)
                }
            })()
        }
    }, [OpenAcc, nPage])

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <ClAccMastView ac={currentAc} docList={docList} baseUrl={OpenAcc.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></ClAccMastView>
    switch (currentMode) {
        case "edit":
            detail = <ClAccMastEdit OpenAcc={OpenAcc} ac={currentAc} aeList={aeList} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></ClAccMastEdit>
            break
        case "new":
            detail = <ClAccMastEdit OpenAcc={OpenAcc} ac={BlankAc} aeList={aeList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></ClAccMastEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-IDNo">ID</th>
                <th className="TCol-Tel">Phone</th>
                <th className="TCol-AECode">Runner</th>
                <th className="TCol-Status">Status</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((ac, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acName1}</td>
                        <td className="TCol-IDNo" onClick={() => { setCurrentAc(ac) }}>{ac.d_idNo}</td>
                        <td className="TCol-Tel" onClick={() => { setCurrentAc(ac) }}>{ac.d_tel}</td>
                        <td className="TCol-AECode" onClick={() => { setCurrentAc(ac) }}>{ac.d_aeCode}</td>
                        <td className="TCol-Status" onClick={() => { setCurrentAc(ac) }}>{ac.d_status}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>


    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-5" label="Search for Client Master" labelFor="inputSearchValue">
                        <ControlGroup fill>
                            <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                <option value="acCode">Account</option>
                                <option value="acName">Name</option>
                                <option value="aeCode">Runner</option>
                                <option value="_recordNo">Record No.</option>
                            </HTMLSelect>
                            <InputGroup
                                id="inputSearchValue"
                                placeholder="Search"
                                value={searchValue}
                                onChange={utils.handleStringChange(s => setSearchValue(s))}
                                leftIcon="search"
                                rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                            />
                        </ControlGroup>
                    </FormGroup>
                    {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                    <Button className="Col-6-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                </div>
                <Collapse isOpen={showFilters}>
                    <div className="Row">
                        <FormGroup className="Col-1-1" label="ID" labelFor="searchIdNo">
                            <InputGroup id="searchIdNo" value={searchIdNo} onChange={utils.handleStringChange(s => setSearchIdNo(s))} />
                        </FormGroup>
                        <FormGroup className="Col-2-1" label="Tel" labelFor="searchTel">
                            <InputGroup id="searchTel" value={searchTel} onChange={utils.handleStringChange(s => setSearchTel(s))} />
                        </FormGroup>
                        <div className="Col-3-2 Col-H">
                            <FormGroup label="Status" labelFor="searchStatus">
                                <InputGroup className="Input-XS" id="searchStatus" value={searchStatus} onChange={utils.handleStringChange(s => setSearchStatus(s))} />
                            </FormGroup>
                            <FormGroup label="Limit" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup>
                        </div>
                    </div>
                </Collapse>
            </form>
            <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                <Tab id="list" title="List" panel={list} />
                <Tab id="detail" title="Detail" panel={detail} />
            </Tabs>
        </>
    );
}

export default ClAccMastMain