import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, InputGroup, Button, FileInput, HTMLTable, Position, Toaster, Intent, ProgressBar, Collapse, Checkbox  } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';

import svgWait2 from './svg/wait2.gif'

import { Account } from './Types'


var OpenAccFlag: any;


interface DocumentUpload2MainProps {
    OpenAcc: AxiosInstance
    currentRec?: Account
}

const toaster = Toaster.create({
    position: Position.TOP,
})

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


function DocumentUpload2Main({ OpenAcc, currentRec = {} as Account }: DocumentUpload2MainProps) {
    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<Account>()
    
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />

    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)
    const [ExportStatus, setExportStatus] = useState("")

    const [file1Label, setFile1Label] = useState("Choose file  ...  ")
    const [nUploaded, setnUploaded] = useState(0)


    const [RptName, setRptName] = useState("")
    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)


    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
 

    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp OpenAccFlag: ", OpenAccFlag)
                //console.log("SmMastMain disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("SmMastMain disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("SmMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setSETL_DATE(OpenAccFlag.SETL_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])




    const onImporttprocess = () => {
        //console.log("onResetprocess fileInfoList", fileInfoList);

        if ( file1Label == "Choose file  ...  " ) {
            currentRec.UploadFile1 = ""
        } else {
            currentRec.UploadFile1 = file1Label
        }


        if ( currentRec.UploadFile1.length > 0 ) {
    
            (async () => {
                setExportStatus("")
                setRptName("")

                try {
                    const res = await OpenAcc.post('/SysMaint',
                        {   sb_data: currentRec,
                            cPgmId: "ImportIOAprocess",
                        });                
                    //console.log("SysMaint res.data.data", res.data.data)
                    if ( res.data.ok ) {
                        setExportStatus("Import Process was Completed") 
                        setRptName(res.data.RptName)
                        setFile1Label("")
                    } else {
                        if (! res.data.ok) {
                            for (let j = 0; j < res.data.data.length; j++) {
                              
                                setError(res.data.data[j][0], {
                                   message: res.data.data[j][1]
                                }); 
                            }
                            return
                        }
                        setExportStatus("Import Process failure") 
                        setRptName("")
                    }
                } catch (error) {
                    utils.showError(error)
                }
            })()
        } else {
            setError("UploadFile1", {
                message: "Please upload Zip file "
             }); 

        }
    }

/*
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

    }
*/

    const onUpload = (e: FormEvent) => {
        e.preventDefault();

        (async () => {
            try {
                const res = await OpenAcc.post('/importupload',
                    new FormData(e.target as HTMLFormElement), {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setnUploaded(res.data.uploaded)
                utils.showSuccess(`${res.data.uploaded} file(s) uploaded`)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }



    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>

    return (
        <>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>
            <p>Document Upload</p>
            <div className="Spacing-V-8"></div>
            <div className="Spacing-V-16"></div>
            <form className="Import" onSubmit={onUpload}>
                <div className="Row">
                    <span id="DispText3">Upload Zip file from IOA 1 </span>
                    <FileInput inputProps={{ name: "file1" }} text={file1Label} onInputChange={utils.handleStringChange((s) => setFile1Label(utils.fileBase(s)))} />
                </div>
                {errors.UploadFile1 && errors.UploadFile1.types && <span id="Err_msg">{errors.UploadFile1.types.message}</span>}
                <div className="Spacing-V-8"></div>
                <div className="Row">
                    <Button className="Col-1-2" type="submit" icon={IconNames.UPLOAD}>Upload</Button>
                </div>
                <div className="Spacing-V-8"></div>
                    Uploaded File : {nUploaded} pc
                <div className="Spacing-V-16"></div>
                <div className="Spacing-V-16"></div>
                <div className="Row">
                    <Button className="Col-1-2" icon={IconNames.LIST} onClick={onImporttprocess}>Import from IOA1 process</Button>
                </div>
            </form>
            <div className="Spacing-V-16"></div>
            <div>
                <div>{ExportStatus}</div>
            </div >
            <div className="Spacing-V-16"></div>
            <br></br>
            <p>Notes</p>
            <div>
                <div>Import from IOA1 System</div>
            </div >
            {/*
            <ul className="bp3-list">
                <li>All client(s) have Account No. will be export</li>
                <li>After Export, the client(s) (With Account No.) will be deleted</li>
            </ul>
            */}
        </>
    );
}

export default DocumentUpload2Main