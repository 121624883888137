import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem, Utils } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'


import { Account, Document, Jurisdiction } from './Types'

import i18next from 'i18next';

interface PageInfo {
    n: number
}

var OpenAccFlag: any;
var auth_no = 0

interface RegMastViewProps {
    OpenAcc: AxiosInstance,
    ac: Account,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}

const getDocIcon = (docType: string) => {
    switch (docType.toLowerCase()) {
        case "id":
            return "id-number"
        case "address":
            return "office"
        case "bank":
            return "book"
        case "assets":
            return "book"
        case "sign":
            return "book"
        }
    // contract, others
    return "document"
}

const getDocText = (docType: string) => {
    switch (docType.toLowerCase()) {
        case "id":
            return "ID"
        case "address":
            return "Address"
        case "bank":
            return "Bank Doc"
        case "assets":
            return "Assets Doc"
        case "sign":
            return "Sign"
        case "others":
            return "Others"
    }
    // contract, others
    return "Unknown"
}

function RegMastView({ OpenAcc, ac = {} as Account, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: RegMastViewProps) {

    const [isAdd, setisAdd] = useState(false)
    const [isEdit, setisEdit] = useState(false)
    const [isDelete, setisDelete] = useState(false)

    const [docList, setDocList] = useState([] as Document[])

    const { t, i18n } = useTranslation();    

    const [def_d_ClientType, setdef_d_ClientType] = useState(ac.d_ClientType)

    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("DEMO")
    const [dateFormat, setdateFormat] = useState("1")
    const [def_FuncPrview, setdef_FuncPrview] = useState(false)


    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("RegMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("RegMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("RegMastMain disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("RegMastMain disp OpenAccFlag: ", OpenAccFlag)
                //console.log("RegMastMain disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("RegMastMain disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                //console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("RegMastMain disp OpenAccFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("RegMastMain disp OpenAccFlag.MGNGP_USER: ", OpenAccFlag.MGNGP_USER)
                //console.log("RegMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                setdateFormat(OpenAccFlag.dateFormat)

                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                //showError(error)
            }
        })()
    }, [OpenAcc])

    useEffect(() => {
        (async () => {
            try {
                const res = await OpenAcc.post('/documentlist',
                    {
                        idNo: ac.d_idNo ,
                        UploadId: ac.d_UploadId
                    });
                setDocList(res.data.data)
                //console.log("RegMastView DocList count: ",res.data.count) 
                //console.log("RegMastView DocList: ",res.data.data) 
                
            } catch (error) {
                //showErrorBox(error)
            }
        })()
        setdef_d_ClientType(ac.d_ClientType)
    }, [OpenAcc, ac.d_idNo, ac.d_UploadId, ac.d_ClientType])


    ;

    if (! ("d_acCode" in ac)) return null

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>


function buttonPress(buttonName: string)
    {
        if ( buttonName == "new" )
        {
            onNew()
        }
        if ( buttonName == "edit" )
        {
            onEdit()
        }
        if ( buttonName == "delete" )
        {
            setisDelete(true)
        }
    }


    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => buttonPress("new")} >New</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isAdd}
                        onConfirm={ () => { setisAdd(false) } }
                    >
                        <p>
                            NEW : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-3-1" icon="edit" onClick={() => buttonPress("edit")} >Edit</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isEdit}
                        onConfirm={ () => { setisEdit(false) } }
                    >
                        <p>
                            EDIT : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-4-1" icon="delete" onClick={() => buttonPress("delete")} >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDelete}
                        onCancel={ () => setisDelete(false) }
                        onConfirm={ () => { onDelete(); setisDelete(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
{/*
                
                <FormGroup className="Col-7-2" label="">
                    <a target="_blank" rel="" href={`/#/AccInfo?acCode=${ac.d_acCode}`}>
                    <Button className="Col-7-2" icon="document" onClick={() => {} } >Balance & Portfolio</Button>
                    </a>
                </FormGroup>
*/}             
{/*
                <Button className="Col-7-2" icon="document" onClick={() => { window.open(`/#/RegMastview?acCode=${ac.d_acCode}`, '_blank') } } >IOA View</Button>
*/}

            </div>
            <div className="Spacing-V-16" />
            { def_FuncPrview && <div className="Row">
                <div className="DispText2">{t('AppDate')} : {TRAN_DATE}</div>
            </div> }
            { def_FuncPrview && <div className="Spacing-V-16"></div> }
            { ! def_FuncPrview && <div className="Row">
                <FormGroup className="Col-1-2" label={t('Account_No')} >
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        <InputGroup readOnly value={ac.d_acCode} />
                    </Tooltip>
                </FormGroup>
                <FormGroup className="Col-3-3" label={t('Name')}>
                    <InputGroup readOnly value={ac.d_acName1} />
                    <InputGroup readOnly value={ac.d_acName2} />
                </FormGroup>
                <FormGroup className="Col-6-3" label={t('HKIdNo')}>
                    <InputGroup readOnly value={ac.d_idNo} />
                </FormGroup>
                <FormGroup className="Col3-9-1" label={t('Sex')} >
                    <InputGroup readOnly value={ac.d_Sex} />
                </FormGroup>
            </div> }
            { def_FuncPrview && <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-1-2" label={t('NameEng')} >
                    <InputGroup readOnly value={ac.d_acName1} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-3" label={t('NameChi')}>
                    <InputGroup readOnly value={ac.d_acName2} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-6-3" label={t('HKIdNo')}>
                    <InputGroup readOnly value={ac.d_idNo} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-9-1" label={t('Sex')} >
                    <InputGroup readOnly value={ac.d_Sex} />
                </FormGroup>
            </div> }
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('ClientType')} labelFor="InputClientType">
                    <Switch readOnly label="" innerLabelChecked={t('ClientType01')} innerLabel={t('ClientType02')} checked={(ac.d_ClientType==="C")} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('DateofBirth')} labelFor="inputDoB">
                    <InputGroup readOnly value= {utils.dispDate(ac.d_dob, dateFormat)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label={t('Nationality')} labelFor="inputIdNo" >
                    <InputGroup readOnly value={ac.d_National} />
                </FormGroup>
            </div>
            { ! def_FuncPrview && <div className="Row">
                <FormGroup className="Col-1-2" label={t('Input DateTime')}>  
                    <InputGroup readOnly value= {utils.dispDate(ac.d_inpputDate, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly value= {ac.d_inpputTime} />
                </FormGroup>
                <FormGroup className="Col-3-2" label={t('Verify Email DateTime')}>
                    <InputGroup readOnly value= {utils.dispDate(ac.d_verify_Date, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly value= {ac.d_verify_Time} />
                </FormGroup>
                <FormGroup className="Col-5-2" label={t('Authority DateTime/User')}>
                    <InputGroup readOnly value= {utils.dispDate(ac.d_Authority_date, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly value= {ac.d_Authority_time} />
                    <InputGroup readOnly value= {ac.d_Authority_user} />
                </FormGroup>
                <FormGroup className="Col-7-3" label={t('Generate A/C No DateTime/User')}>
                    <InputGroup readOnly value= {utils.dispDate(ac.d_Generate_AC_date, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly value= {ac.d_Generate_AC_time} />
                    <InputGroup readOnly value= {ac.d_Generate_AC_user} />
                </FormGroup>
                <FormGroup className="Col-10-3" label={t('Export CSV DateTime/User')}>
                    <InputGroup readOnly value= {utils.dispDate(ac.d_Export_AC_date, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly value= {ac.d_Export_AC_time} />
                    <InputGroup readOnly value= {ac.d_Export_AC_user} />
                </FormGroup>
            </div> }
            <div className="Row">
                <FormGroup className="Col-1-4" label={t('ResidentialAddress')}>
                    <InputGroup readOnly value={ac.d_acAddr1} />
                    <InputGroup readOnly value={ac.d_acAddr2} />
                    <InputGroup readOnly value={ac.d_acAddr3} />
                </FormGroup>
                <FormGroup className="Col-5-4" label={t('CorrespondenceAddress2')}>
                    <InputGroup readOnly value={ac.d_mailAddr1} />
                    <InputGroup readOnly value={ac.d_mailAddr2} />
                    <InputGroup readOnly value={ac.d_mailAddr3} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-4" label={t('EmailAddress')}>
                    <InputGroup readOnly value={ac.d_email} />
                </FormGroup>
                { ! def_FuncPrview && <FormGroup className="Col-5-2" label={t('Send_e-mail_Statement')} labelFor="inputSendemail">
                    <Switch readOnly id="inputSendemail" label="" innerLabelChecked="Send" innerLabel="not Send" checked={(ac.d_sendemail==="Y")} />
                </FormGroup> }
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label={t('Runner')}>
                    <InputGroup readOnly value={ac.d_aeCode} />
                </FormGroup>
                <FormGroup className="Col-1-2" label={t('Commission')}>
                    <InputGroup readOnly value={ac.d_commCode} />
                </FormGroup>
                {<FormGroup className="Col-3-2" label="Interest">
                    <InputGroup readOnly  value={ac.d_intCode} />
                </FormGroup>}
            </div>

            <div className="Row">
                <FormGroup className="Col3-1-5" label={t('way2GetStmt')} labelFor="inputway2GetStmt">
                    <InputGroup readOnly value={ac.d_way2GetStmt} />
                    { ac.d_way2GetStmt === "1" && <span >{t('way2GetStmt01')} </span> }
                    { ac.d_way2GetStmt === "2" && <span >{t('way2GetStmt02')} </span> }
                    { ac.d_way2GetStmt === "3" && <span >{t('way2GetStmt03')} </span> }
                </FormGroup>
            </div>
            {t('way2GetStmt2')}
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('TelNoRes')} labelFor="inputTel">
                    <InputGroup readOnly value={ac.d_tel} />
                </FormGroup>
                <FormGroup className="Col-3-2" label={t('TelNoOff')} labelFor="inputoffTel">
                    <InputGroup readOnly value={ac.d_offTel} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label={t('MobilePhoneCode')} labelFor="inputMobileArea" labelInfo="*">
                    <InputGroup readOnly value={ac.d_mobileCode} />
                </FormGroup>
                <FormGroup className="Col-3-2" label={t('MobilePhone')} labelFor="inputMobile" labelInfo="*">
                    <InputGroup readOnly value={ac.d_mobile} />
                </FormGroup>
                <FormGroup className="Col-5-2" label={t('Fax')} labelFor="inputFax">
                    <InputGroup readOnly value={ac.d_fax} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>

            <div className="Row">
                <FormGroup className="Col3-1-2" label={t('Employment1')} labelFor="inputEmployment">
                    <InputGroup readOnly value={ac.d_Employment} />
                    { ac.d_Employment === "1" && <span >{t('Employment01')} </span> }
                    { ac.d_Employment === "2" && <span >{t('Employment02')} </span> }
                    { ac.d_Employment === "3" && <span >{t('Employment03')} </span> }
                    { ac.d_Employment === "4" && <span >{t('Employment04')} </span> }
                    { ac.d_Employment === "5" && <span >{t('Employment05')} </span> }
                    { ac.d_Employment === "6" && <span >{t('Employment06')} </span> }
                </FormGroup>
                <FormGroup className="Col-3-4" label={t('Employment2')} labelFor="inputEmploymentOther">
                    <InputGroup readOnly value={ac.d_EmploymentOther} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('Empl_CoName')} labelFor="inputCoName">
                    <InputGroup readOnly value={ac.d_CoName} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('Empl_FinInstruction')} labelFor="InputselfInstruction">
                    {/* <Switch readOnly id="InputisFinInstit" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_isFinInstit==="Y")} /> */}
                    <RadioGroup
                        name="def_d_isFinInstit"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_isFinInstit}
                        onChange={(s) => { }}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('Empl_approveFinInstruction')} labelFor="InputselfInstruction">
                    {/* <Switch readOnly id="InputApproveFinInstit" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')}  checked={(ac.d_ApproveFinInstit==="Y")} /> */}
                    <RadioGroup
                        name="def_d_ApproveFinInstit"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_ApproveFinInstit}
                        onChange={(s) => { }}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('Empl_CoAddr')} labelFor="inputCoAddr">
                    <InputGroup readOnly value={ac.d_CoAddr} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('Empl_CoType')} labelFor="inputCoType">
                    <InputGroup readOnly value={ac.d_CoType} />
                </FormGroup>
                <FormGroup className="Col-4-3" label={t('Empl_CoPost')} labelFor="inputCoPost">
                    <InputGroup readOnly value={ac.d_CoPost} />
                </FormGroup>
            </div>
            { ! def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
            { def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
            <div className="Row">
                <FormGroup className="Col3-1-2" label={t('NetAsset')} labelFor="inputNetAsset">
                    <InputGroup readOnly value={ac.d_NetAsset} />
                    { ac.d_NetAsset === "1" && <span >{t('NetAsset01')} </span> }
                    { ac.d_NetAsset === "2" && <span >{t('NetAsset02')} </span> }
                    { ac.d_NetAsset === "3" && <span >{t('NetAsset03')} </span> }
                    { ac.d_NetAsset === "4" && <span >{t('NetAsset04')} </span> }
                    { ac.d_NetAsset === "5" && <span >{t('NetAsset05')} </span> }
                    { ac.d_NetAsset === "6" && <span >{t('NetAsset06')} </span> }
                    { ac.d_NetAsset === "7" && <span >{t('NetAsset07')} </span> }
                    { ac.d_NetAsset === "8" && <span >{t('NetAsset08')} </span> }
                </FormGroup>
                <FormGroup className="Col3-3-2" label={t('AnnualIncome')} labelFor="inputAnnualIncome">
                    <InputGroup readOnly value={ac.d_AnnualIncome} />
                    { ac.d_AnnualIncome === "1" && <span >{t('AnnualIncome01')} </span> }
                    { ac.d_AnnualIncome === "2" && <span >{t('AnnualIncome02')} </span> }
                    { ac.d_AnnualIncome === "3" && <span >{t('AnnualIncome03')} </span> }
                    { ac.d_AnnualIncome === "4" && <span >{t('AnnualIncome04')} </span> }
                    { ac.d_AnnualIncome === "5" && <span >{t('AnnualIncome05')} </span> }
                    { ac.d_AnnualIncome === "6" && <span >{t('AnnualIncome06')} </span> }
                    { ac.d_AnnualIncome === "7" && <span >{t('AnnualIncome07')} </span> }
                    { ac.d_AnnualIncome === "8" && <span >{t('AnnualIncome08')} </span> }
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('SourceofFund')} labelFor="inputSourceofFund">
                    <InputGroup readOnly value={ac.d_SourceofFund} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('InvestTarget1')} labelFor="inputTarget">
                    <Checkbox name="d_Target1" label={t('InvestTarget01')} readOnly checked={(ac.d_Target1==="Y")}  />
                    <Checkbox name="d_Target2" label={t('InvestTarget02')} readOnly checked={(ac.d_Target2==="Y")}  />
                    <Checkbox name="d_Target3" label={t('InvestTarget03')} readOnly checked={(ac.d_Target3==="Y")}  />
                </FormGroup>
                <FormGroup className="Col-3-3" label={t('InvestTarget2')} labelFor="inputTarget">
                    <Checkbox name="d_Target4" label={t('InvestTarget04')} readOnly checked={(ac.d_Target4==="Y")}  />
                    <Checkbox name="d_Target5" label={t('InvestTarget05')} readOnly checked={(ac.d_Target5==="Y")}  />
                    <Checkbox name="d_Target6" label={t('InvestTarget06')} readOnly checked={(ac.d_Target6==="Y")}  />
                </FormGroup>
                <FormGroup className="Col-6-3" label=" ." labelFor="inputTarget">
                    <Checkbox name="d_Target7" label={t('InvestTarget07')} readOnly checked={(ac.d_Target7==="Y")}  />
                    <Checkbox name="d_Target8" label={t('InvestTarget08')} readOnly checked={(ac.d_Target8==="Y")}  />
                    <Checkbox name="d_Target9" label={t('InvestTarget09')} readOnly checked={(ac.d_Target9==="Y")}  />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <h5 className="bp3-heading">{t('InvestExperience')}</h5>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('InvestExperience01')} labelFor="inputStockYear">
                    <InputGroup readOnly value={ac.d_StockYear} />
                    <InputGroup readOnly value={ac.d_StockCnt} />
                    <InputGroup readOnly value={ac.d_StockAmt} />
                </FormGroup>
                <FormGroup className="Col-4-3" label="." labelFor="">
                    <InputGroup readOnly disabled={true} value={t('InvestExperience05')} />
                    <InputGroup readOnly disabled={true} value={t('InvestExperience06')} />
                    <InputGroup readOnly disabled={true} value={t('InvestExperience07')} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <h5 className="bp3-heading">{t('DerivativeExperience')}</h5>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('DerivativeExperience01')} labelFor="InputHighRiskExp">
                    {/* <Switch readOnly id="InputHighRiskExp" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_HighRiskExp==="Y")} /> */}
                    <RadioGroup
                        name="def_d_HighRiskExp"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_HighRiskExp}
                        onChange={(s) => { }}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('InvestExperience02')} labelFor="inputWarrentYear">
                    <InputGroup readOnly value={ac.d_WarrantYear} />
                    <InputGroup readOnly value={ac.d_WarrantCnt} />
                    <InputGroup readOnly value={ac.d_WarrantAmt} />
                </FormGroup>
                <FormGroup className="Col-4-3" label={t('InvestExperience03')} labelFor="inputOptionYear">
                    <InputGroup readOnly value={ac.d_OptionYear} />
                    <InputGroup readOnly value={ac.d_OptionCnt} />
                    <InputGroup readOnly value={ac.d_OptionAmt} />
                </FormGroup>
                <FormGroup className="Col-7-3" label={t('InvestExperience04')} labelFor="inputFutureYear">
                    <InputGroup readOnly value={ac.d_FutureYear} />
                    <InputGroup readOnly value={ac.d_FutureCnt} />
                    <InputGroup readOnly value={ac.d_FutureAmt} />
                </FormGroup>
                <FormGroup className="Col-10-3" label="." labelFor="">
                    <InputGroup readOnly disabled={true} value={t('InvestExperience05')} />
                    <InputGroup readOnly disabled={true} value={t('InvestExperience06')} />
                    <InputGroup readOnly disabled={true} value={t('InvestExperience07')} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            { (userKey === "ASTRUM")  && <div className="Row">
                <FormGroup className="Col-1-5" label={t('OpenDecld_StaffRelation01')} labelFor="InputStaffRelation">
                {/* <Switch readOnly id="InputStaffRelation" label="" innerLabelChecked={t('cYes01')} innerLabel={t('cNo01')} checked={(ac.d_StaffRelation==="Y")} /> */}
                <RadioGroup
                        name="def_d_StaffRelation"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_StaffRelation}
                        onChange={(s) => { }}
                    >
                        <Radio label={t('cYes01')} value="Y" />
                        <Radio label={t('cNo01')}  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div> }
            { ! (userKey === "ASTRUM")  && <div className="Row">
                <FormGroup className="Col-1-5" label={t('OpenDecld_StaffRelation02')} labelFor="InputStaffRelation">
                    {/* <Switch readOnly id="InputStaffRelation" label="" innerLabelChecked={t('cYes01')} innerLabel={t('cNo01')} checked={(ac.d_StaffRelation==="Y")} /> */}
                    <RadioGroup
                        name="def_d_StaffRelation"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_StaffRelation}
                        onChange={(s) => { }}
                    >
                        <Radio label={t('cYes01')} value="Y" />
                        <Radio label={t('cNo01')}  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div> }
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('OpenDecld_StaffRelation03')} labelFor="inputStaffName">
                    <InputGroup readOnly value={ac.d_StaffName} />
                </FormGroup>
                <FormGroup className="Col-4-2" label={t('OpenDecld_StaffRelation04')} labelFor="inputStaffRelationship">
                    <InputGroup readOnly value={ac.d_StaffRelationship} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('OpenDecld_selfInstruction01')} labelFor="InputselfInstruction">
                    {/* <Switch readOnly id="InputselfInstruction" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_selfInstruction==="Y")} /> */}
                    <RadioGroup
                        name="def_d_selfInstruction"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_selfInstruction}
                        onChange={(s) => { }}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-4" label={t('OpenDecld_selfInstruction02')} labelFor="inputInstructionType">
                    <InputGroup readOnly value={ac.d_InstructionType} />
                </FormGroup>
            </div>
            { (def_d_ClientType == "M") && <div className="Spacing-V-16"></div> }
            { (def_d_ClientType == "M") && <h5 className="bp3-heading">{t('OpenRelatedPersion')}</h5> }
            { (def_d_ClientType == "M") && <div className="Row">
                <FormGroup className="Col-1-5" label={t('OpenhasSpouseClient01')} labelFor="InputhasSpouseClient">
                    {/* <Switch readOnly id="InputhasSpouseClient" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')}  checked={(ac.d_hasSpouseClient==="Y")} /> */}
                    <RadioGroup
                        name="def_d_hasSpouseClient"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_hasSpouseClient}
                        onChange={(s) => { }}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div> }
            { (def_d_ClientType == "M") && <div className="Row">
                <span id="DispText2">{t('OpenhasSpouseClient02')}</span>
            </div> }
            { (def_d_ClientType == "M") && <div className="Row">
                <FormGroup className="Col-1-4" label={t('OpenhasSpouseClient02a')} labelFor="inputSpouseName">
                    <InputGroup readOnly value={ac.d_SpouseName} />
                </FormGroup>
                <FormGroup className="Col-6-1" label={t('OpenhasSpouseClient03')} labelFor="inputSpouseAcNo">
                    <InputGroup readOnly value={ac.d_SpouseAcNo} />
                </FormGroup>
            </div> }
            { (def_d_ClientType == "M") && <div className="Row">
                <FormGroup className="Col-1-7" label={t('OpenVoteRight01')} labelFor="InputVoteRight">
                    {/* <Switch readOnly id="InputVoteRight" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_VoteRight==="Y")} /> */}
                    <RadioGroup
                        name="def_d_VoteRight"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_VoteRight}
                        onChange={(s) => { }}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div> }
            { (def_d_ClientType == "M") && <div className="Row">
                <span id="DispText2">{t('OpenVoteRight02')}</span>
            </div> }
            { (def_d_ClientType == "M") && <div className="Row">
                <FormGroup className="Col-1-3" label={t('OpenVoteRight02a')}  labelFor="inputVoteAc">
                    <InputGroup readOnly value={ac.d_VoteAc1} />
                    <InputGroup readOnly value={ac.d_VoteAc2} />
                </FormGroup>
                <FormGroup className="Col-4-4" label={t('OpenVoteRight03')}  labelFor="inputIoteAcName1">
                    <InputGroup readOnly value={ac.d_VoteAcName1} />
                    <InputGroup readOnly value={ac.d_VoteAcName2} />
                </FormGroup>
                <FormGroup className="Col-8-3" label={t('OpenVoteRight04')}  labelFor="inputSpouseAcNo">
                    <InputGroup readOnly value={ac.d_VoteRelate1} />
                    <InputGroup readOnly value={ac.d_VoteRelate2} />
                </FormGroup>
            </div> }
            { ! def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
            { def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
            <h5 className="bp3-heading">{t('isInternet01')}</h5>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('isInternet02')} labelFor="InputisInternet">
                    {/* <Switch readOnly id="InputisInternet" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_isInternet==="Y")} /> */}
                    <RadioGroup
                        name="def_d_isInternet"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_isInternet}
                        onChange={(s) => { }}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>

            <h5 className="bp3-heading">CRS</h5>
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                    <InputGroup readOnly value={ac.d_JurisdictionofResidence} />
                </FormGroup>
                {/* <FormGroup className="Col-3-3" label="其它 司法管轄區" labelFor="inputd_OtherJurisdictionofResidence">
                    <InputGroup readOnly value={ac.d_OtherJurisdictionofResidence} />
                </FormGroup> */}
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                    <InputGroup readOnly value={ac.d_TIN} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_ReasonA" >
                    <InputGroup readOnly value={ac.d_Juris_ReasonA} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                    <InputGroup readOnly value={ac.d_Juris_Reason} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                    <InputGroup readOnly value={ac.d_JurisdictionofResidence2} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                    <InputGroup readOnly value={ac.d_TIN2} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')} labelFor="inputd_Juris_ReasonA" >
                    <InputGroup readOnly value={ac.d_Juris_Reason2A} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                    <InputGroup readOnly value={ac.d_Juris_Reason2} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                    <InputGroup readOnly value={ac.d_JurisdictionofResidence3} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                    <InputGroup readOnly value={ac.d_TIN3} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_ReasonA" >
                    <InputGroup readOnly value={ac.d_Juris_Reason3A} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                    <InputGroup readOnly value={ac.d_Juris_Reason3} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                    <InputGroup readOnly value={ac.d_JurisdictionofResidence4} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                    <InputGroup readOnly value={ac.d_TIN4} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_ReasonA" >
                    <InputGroup readOnly value={ac.d_Juris_Reason4A} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                    <InputGroup readOnly value={ac.d_Juris_Reason4} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                    <InputGroup readOnly value={ac.d_JurisdictionofResidence5} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                    <InputGroup readOnly value={ac.d_TIN5} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_ReasonA" >
                    <InputGroup readOnly value={ac.d_Juris_Reason5A} />
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                    <InputGroup readOnly value={ac.d_Juris_Reason5} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <h5 className="bp3-heading">W-8BEN</h5>
            <ol>
                <li>
                    <div className="Row vertical_alignment">
                        <span id="DispText4">
                            {t('OpenW8B01')}
                        </span>
                        <span id="DispText5">
                            <InputGroup readOnly value={ac.d_w8b_country} />
                        </span>
                        <span id="DispText6">
                            {t('OpenW8B01a')}
                        </span>
                    </div>
                    <div className="Row vertical_alignment">
                        <span id="DispText6">
                            {t('OpenW8B01b')}
                        </span>
                    </div>
                </li>
                <div className="Spacing-V-16"></div>
                <li>
                    <div className="Row vertical_alignment">
                        <span id="DispText6">
                            {t('OpenW8B02b')}
                        </span>
                    </div>
                    <div className="Row vertical_alignment">
                        <span id="DispText9">
                            {t('OpenW8B02b1')}
                        </span>
                        <span id="DispText11">
                            <InputGroup readOnly value={ac.d_w8b_SR01} />
                        </span>
                        <span id="DispText10">
                            {t('OpenW8B02c')}
                        </span>
                        <span id="DispText11">
                            <InputGroup readOnly value={ac.d_w8b_SR02} />
                        </span>
                    </div>
                    <div className="Row vertical_alignment">
                        <span id="DispText4">
                            {t('OpenW8B02d')}
                        </span>
                        <span id="DispText7">
                            <InputGroup readOnly value={ac.d_w8b_SR03} />
                        </span>
                    </div>
                    <div className="Row vertical_alignment">
                        <span id="DispText6">
                            {t('OpenW8B02e')}
                        </span>
                    </div>
                    <div className="Row vertical_alignment">
                        <span id="DispText7">
                            <InputGroup readOnly value={ac.d_w8b_SR04} />
                        </span>
                    </div>
                </li>
            </ol>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <span id="DispText6">
                    &nbsp; &nbsp; &nbsp; {t('OpenW8B02a')}
                </span>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_01" labelInfo="*">
                    <Checkbox name="d_w8b_01"  label={t('OpenW8B03')} readOnly checked={(ac.d_w8b_01==="Y")}  />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                    <Checkbox name="d_w8b_02"  label={t('OpenW8B04')} readOnly checked={(ac.d_w8b_02==="Y")}  />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                    <Checkbox name="d_w8b_03"  label={t('OpenW8B05')} readOnly checked={(ac.d_w8b_03==="Y")}  />
                </FormGroup>
            </div>
            <ul className="bp3-list">
                <li>{t('OpenW8B05a')}</li>
                <li>{t('OpenW8B05b')}</li>
                <li>{t('OpenW8B05c')}</li>
                <li>{t('OpenW8B05d')}</li>
            </ul>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                    <Checkbox name="d_w8b_04"  label={t('OpenW8B06')} readOnly checked={(ac.d_w8b_04==="Y")}  />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                    <Checkbox name="d_w8b_05"  label={t('OpenW8B07')} readOnly checked={(ac.d_w8b_05==="Y")}  />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                    <Checkbox name="d_w8b_06"  label={t('OpenW8B08')} readOnly checked={(ac.d_w8b_06==="Y")}  />
                </FormGroup>
            </div>
            { ! def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
            { def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('OpenBankInfo01')} labelFor="inputBankInfo" labelInfo="*">
                    <InputGroup readOnly value={ac.d_BankInfo} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('OpenBankInfo02')} labelFor="inputBankACNo" labelInfo="*">
                    <InputGroup readOnly value={ac.d_BankACNo} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <ul>
                <li>{t('BankNote01')}</li>
                <li>{t('BankNote02')}</li>
                {/* <li>{t('BankNote03')}</li> */}
            </ul>

            <div className="Spacing-V-16"></div>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree01')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree==="Y")} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree02')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree2==="Y")} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree03')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree3==="Y")} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree04')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree4==="Y")} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree05')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree5==="Y")} />
                </FormGroup>
            </div>
            { (def_d_ClientType == "M") && <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree06')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree6==="Y")} />
                </FormGroup>
            </div> }
            <div className="Spacing-V-16"></div>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-6" label={t('Remark')}>
                    <TextArea readOnly fill rows={4} value={ac.d_Remark} />
                </FormGroup>
            </div>

            <div className="Spacing-V-16"></div>
            {t('Sign04')}
            <div className="Spacing-V-16"></div>
            <img className="signimg" src={`${baseUrl}/documentdownload?doctype=sign&filename=${ac.d_UploadId}_sign.png&inline`} />
            {/* <img className="signimg" src={`${baseUrl}/documentdownload2?doctype=sign&filename=${ac.d_UploadId}_sign.png&inline`} /> */}
            {/* <img className="signimg" src={`${OpenAcc.defaults.baseURL}/documentdownload2?doctype=sign&filename=${ac.d_UploadId}_sign.png&inline`} /> */}
            <div className="Spacing-V-16"></div>
            <div className="Spacing-V-16"></div>
            <p>{t('Documents')}</p>
            <div className="Row">
                {docList.map((doc, i) =>
                    <AnchorButton key={i} icon={getDocIcon(doc.docType)} target="_blank" href={`${baseUrl}/documentdownload?doctype=${doc.docType}&filename=${doc.fileName}&inline`}>{getDocText(doc.docType)}</AnchorButton>
                    /* <AnchorButton key={i} icon={getDocIcon(doc.docType)} target="_blank" href={`${baseUrl}/documentdownload2?doctype=${doc.docType}&filename=${doc.fileName}&inline`}>{getDocText(doc.docType)}</AnchorButton> */
                )}
            </div>
        </div>
    )
}

interface RegMastEditProps {
    OpenAcc: AxiosInstance,
    ac?: Account,
    aeList?: Account[],
    JurisdictionList?: Jurisdiction[],
    isNew?: boolean,
    onSave?: (ac: Account) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function RegMastEdit({ OpenAcc, ac = {} as Account, aeList = [] as Account[], JurisdictionList = [] as Jurisdiction[], isNew = false, onSave = () => { }, onCancel = () => { } }: RegMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<Account>()
    const { t, i18n } = useTranslation();    

    const [def_d_Sex, setdef_d_Sex] = useState(ac.d_Sex)
    const [def_d_way2GetStmt, setdef_d_way2GetStmt] = useState(ac.d_way2GetStmt)
    const [def_d_Employment, setdef_d_Employment] = useState(ac.d_Employment)
    const [def_d_NetAsset, setdef_d_NetAsset] = useState(ac.d_NetAsset)
    const [def_d_AnnualIncome, setdef_d_AnnualIncome] = useState(ac.d_AnnualIncome)
    const [SendemailValue, setSendemailValue] = useState(ac.d_sendemail==="Y")
    const [def_d_mobileCode, setdef_d_mobileCode] = useState(ac.d_mobileCode)
    const [def_d_StaffRelation, setdef_d_StaffRelation] = useState(ac.d_StaffRelation)
    const [def_d_selfInstruction, setdef_d_selfInstruction] = useState(ac.d_selfInstruction)
    const [def_d_isFinInstit, setdef_d_isFinInstit] = useState(ac.d_isFinInstit)
    const [def_d_ApproveFinInstit, setdef_d_ApproveFinInstit] = useState(ac.d_ApproveFinInstit)

    const [def_d_hasSpouseClient, setdef_d_hasSpouseClient] = useState(ac.d_hasSpouseClient)
    const [def_d_VoteRight, setdef_d_VoteRight] = useState(ac.d_VoteRight)
    const [def_d_isInternet, setdef_d_isInternet] = useState(ac.d_isInternet)
    const [def_d_Target1, setdef_d_Target1] = useState(ac.d_Target1==="Y")
    const [def_d_Target2, setdef_d_Target2] = useState(ac.d_Target2==="Y")
    const [def_d_Target3, setdef_d_Target3] = useState(ac.d_Target3==="Y")
    const [def_d_Target4, setdef_d_Target4] = useState(ac.d_Target4==="Y")
    const [def_d_Target5, setdef_d_Target5] = useState(ac.d_Target5==="Y")
    const [def_d_Target6, setdef_d_Target6] = useState(ac.d_Target6==="Y")
    const [def_d_Target7, setdef_d_Target7] = useState(ac.d_Target7==="Y")
    const [def_d_Target8, setdef_d_Target8] = useState(ac.d_Target8==="Y")
    const [def_d_Target9, setdef_d_Target9] = useState(ac.d_Target9==="Y")
    const [def_d_InvestExp, setdef_d_InvestExp] = useState(ac.d_InvestExp)
    const [def_d_HighRiskExp, setdef_d_HighRiskExp] = useState(ac.d_HighRiskExp)
    const [def_d_Juris_ReasonA, setdef_d_Juris_ReasonA] = useState(ac.d_Juris_ReasonA)
    const [def_d_Juris_Reason2A, setdef_d_Juris_Reason2A] = useState(ac.d_Juris_Reason2A)
    const [def_d_Juris_Reason3A, setdef_d_Juris_Reason3A] = useState(ac.d_Juris_Reason3A)
    const [def_d_Juris_Reason4A, setdef_d_Juris_Reason4A] = useState(ac.d_Juris_Reason4A)
    const [def_d_Juris_Reason5A, setdef_d_Juris_Reason5A] = useState(ac.d_Juris_Reason5A)
    const [def_d_w8b_01, setdef_d_w8b_01] = useState(ac.d_w8b_01==="Y")
    const [def_d_w8b_02, setdef_d_w8b_02] = useState(ac.d_w8b_02==="Y")
    const [def_d_w8b_03, setdef_d_w8b_03] = useState(ac.d_w8b_03==="Y")
    const [def_d_w8b_04, setdef_d_w8b_04] = useState(ac.d_w8b_04==="Y")
    const [def_d_w8b_05, setdef_d_w8b_05] = useState(ac.d_w8b_05==="Y")
    const [def_d_w8b_06, setdef_d_w8b_06] = useState(ac.d_w8b_06==="Y")

    const [def_d_ClientType, setdef_d_ClientType] = useState(ac.d_ClientType==="C")

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>
   
   
   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_acCode" in ac) {
            reset(ac)
        }
    }, [ac, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        register({ name: "d_JurisdictionofResidence" })
        register({ name: "d_JurisdictionofResidence2" })
        register({ name: "d_JurisdictionofResidence3" })
        register({ name: "d_JurisdictionofResidence4" })
        register({ name: "d_JurisdictionofResidence5" })
    }, [register])


    useEffect(() => {
        if ( OpenAccFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])



    if (! isNew && !("d_acCode" in ac)) return null

    let acNoLength = 8

    let idNoLength = 20
    if ( OpenAccFlag.user_key === "TATLEE" ) {
        idNoLength = 22
    }

    let AddrLength = 30
    if ( OpenAccFlag.user_key === "WINFULL" || OpenAccFlag.user_key === "WINGHANG" || OpenAccFlag.user_key === "COMPSHARE" ) {
        AddrLength = 35
    }
    if ( OpenAccFlag.user_key === "ASTRUM"  ) {
        AddrLength = 29
    }

    let TelLength = 15
    if ( OpenAccFlag.user_key === "BERICH" || OpenAccFlag.user_key === "CHEERFUL" ) {
        TelLength = 20
    }

    let OffTelLength = 15
    if ( OpenAccFlag.user_key === "CHEERFUL"  ) {
        OffTelLength = 20
    }

    let MobileLength = 15

    let FaxLength = 15

    let EmailLength = 100
    if ( OpenAccFlag.user_key === "PAMIRS"  ) {
            EmailLength = 130
    } else if ( OpenAccFlag.user_key === "COMPSHARE" || OpenAccFlag.user_key === "BELTRADE" ) {
        EmailLength = 200
    }

    let BankInfoLength = 20
    if ( OpenAccFlag.user_key === "WINGHANG"  ) {
        BankInfoLength = 9
    }

    let BankACNoLength = 20
    if ( OpenAccFlag.user_key === "WINGHANG"  ) {
        BankACNoLength = 9
    }


    const onSubmit = (data: Account) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_acCode = data.d_acCode.toUpperCase();

        data.d_Sex = def_d_Sex
        data.d_way2GetStmt = def_d_way2GetStmt
        data.d_Employment = def_d_Employment
        data.d_NetAsset = def_d_NetAsset
        data.d_AnnualIncome = def_d_AnnualIncome

        data.d_mobileCode = def_d_mobileCode

        data.d_sendemail = "Y";
        if (SendemailValue) {
            data.d_sendemail = "Y";
        } else {
            data.d_sendemail = "N";
        }

        data.d_StaffRelation = def_d_StaffRelation
        data.d_selfInstruction = def_d_selfInstruction


        data.d_isFinInstit = def_d_isFinInstit
        data.d_ApproveFinInstit = def_d_ApproveFinInstit


        data.d_hasSpouseClient = def_d_hasSpouseClient
        data.d_VoteRight = def_d_VoteRight

        data.d_isInternet = def_d_isInternet


        data.d_Target1 = "N"
        if (def_d_Target1) {
            data.d_Target1 = "Y";
        } else {
            data.d_Target1 = "N";
        }
        data.d_Target2 = "N"
        if (def_d_Target2) {
            data.d_Target2 = "Y";
        } else {
            data.d_Target2 = "N";
        }
        data.d_Target3 = "N"
        if (def_d_Target3) {
            data.d_Target3 = "Y";
        } else {
            data.d_Target3 = "N";
        }
        data.d_Target4 = "N"
        if (def_d_Target4) {
            data.d_Target4 = "Y";
        } else {
            data.d_Target4 = "N";
        }
        data.d_Target5 = "N"
        if (def_d_Target5) {
            data.d_Target5 = "Y";
        } else {
            data.d_Target5 = "N";
        }
        data.d_Target6 = "N"
        if (def_d_Target6) {
            data.d_Target6 = "Y";
        } else {
            data.d_Target6 = "N";
        }
        data.d_Target7 = "N"
        if (def_d_Target7) {
            data.d_Target7 = "Y";
        } else {
            data.d_Target7 = "N";
        }
        data.d_Target8 = "N"
        if (def_d_Target8) {
            data.d_Target8 = "Y";
        } else {
            data.d_Target8 = "N";
        }
        data.d_Target9 = "N"
        if (def_d_Target9) {
            data.d_Target9 = "Y";
        } else {
            data.d_Target9 = "N";
        }

        data.d_HighRiskExp = def_d_HighRiskExp

        data.d_InvestExp = def_d_InvestExp

        data.d_Juris_ReasonA = def_d_Juris_ReasonA
        data.d_Juris_Reason2A = def_d_Juris_Reason2A
        data.d_Juris_Reason3A = def_d_Juris_Reason3A
        data.d_Juris_Reason4A = def_d_Juris_Reason4A
        data.d_Juris_Reason5A = def_d_Juris_Reason5A

        data.d_w8b_01 = "Y"
        if (def_d_w8b_01) {
            data.d_w8b_01 = "Y";
        } else {
            data.d_w8b_01 = "N";
        }
        data.d_w8b_02 = "Y"
        if (def_d_w8b_02) {
            data.d_w8b_02 = "Y";
        } else {
            data.d_w8b_02 = "N";
        }
        data.d_w8b_03 = "Y"
        if (def_d_w8b_03) {
            data.d_w8b_03 = "Y";
        } else {
            data.d_w8b_03 = "N";
        }
        data.d_w8b_04 = "Y"
        if (def_d_w8b_04) {
            data.d_w8b_04 = "Y";
        } else {
            data.d_w8b_04 = "N";
        }
        data.d_w8b_05 = "Y"
        if (def_d_w8b_05) {
            data.d_w8b_05 = "Y";
        } else {
            data.d_w8b_05 = "N";
        }
        data.d_w8b_06 = "Y"
        if (def_d_w8b_06) {
            data.d_w8b_06 = "Y";
        } else {
            data.d_w8b_06 = "N";
        }


        data.d_ClientType = "C"
        if (def_d_ClientType) {
            data.d_ClientType = "C";
        } else {
            data.d_ClientType = "M";
        }

        data.d_inpputDate = ac.d_inpputDate
        data.d_inpputTime = ac.d_inpputTime;

        data.lang_reg = i18n.languages.toString() ;
        

        (async () => {
            try {
                //console.log("call RegMastedit data", data)
                //console.log("call RegMastedit isNew", isNew)
                if (OpenAccFlag.user_key === "ASTRUM") {
                    const res = await OpenAcc.post('/Master', {sb_data: data, cPgmId: "OpenAcc", cAction: "OpenAccValidatea", isNew: isNew})
                    //console.log("RegMastedit res.data.ok", res.data.ok)
                    //console.log("RegMastedit res.data.data", res.data.data)

                    if (! res.data.ok) {
                        for (let j = 0; j < res.data.data.length; j++) {
                        
                            setError(res.data.data[j][0], {
                            message: res.data.data[j][1]
                            }); 
                        }
                        return
                    }
                } else {
                    const res = await OpenAcc.post('/Master', {sb_data: data, cPgmId: "OpenAcc", cAction: "OpenAccValidate", isNew: isNew})
                    //console.log("RegMastedit res.data.ok", res.data.ok)
                    //console.log("RegMastedit res.data.data", res.data.data)

                    if (! res.data.ok) {
                        for (let j = 0; j < res.data.data.length; j++) {
                        
                            setError(res.data.data[j][0], {
                            message: res.data.data[j][1]
                            }); 
                        }
                        return
                    }
                }
                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }

    const acRenderer: ItemRenderer<Account> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${ac.d_acName1}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ac.d_acCode}
                key={ac.d_acCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const acPredicate: ItemPredicate<Account> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.d_acCode} - ${ac.d_acName1}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const acValueRenderer = (ac: Account) => {
        return ac.d_acCode
    }



    const JurisdictionRenderer: ItemRenderer<Jurisdiction> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${ac.Jurisdiction}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ac.CountryCode}
                key={ac.CountryCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const JurisdictionPredicate: ItemPredicate<Jurisdiction> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.CountryCode} - ${ac.Jurisdiction}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const JurisdictionValueRenderer = (ac: Jurisdiction) => {
        return ac.CountryCode
    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
        { (OpenAccFlag.user_key === "ASTRUM") && <div > 
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(ac)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('Account_No')} labelFor="inputAcCode" >
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        <InputGroup disabled={false} id="inputAcCode" name="d_acCode" autoFocus={true} defaultValue="" inputRef={register({ maxLength: acNoLength }) } />
                    </Tooltip>    
                    {errors.d_acCode && errors.d_acCode.type === "required" && (<span id="Err_msg">{t('Account_No')} {t('NotBlank')}</span>)}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is {acNoLength}</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-3" label={t('Name')}>
                    <InputGroup disabled={true} value={ac.d_acName1} />
                    <InputGroup disabled={true} value={ac.d_acName2} />
                </FormGroup>
                <FormGroup className="Col-6-3" label={t('HKIdNo')}>
                    <InputGroup disabled={true} value={ac.d_idNo} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('ClientType')} labelFor="InputClientType">
                    <Switch disabled={true} label="" innerLabelChecked={t('ClientType01')} innerLabel={t('ClientType02')} checked={(ac.d_ClientType==="C")} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-6" label={t('Remark')} labelFor="inputRemark">
                    {/*<TextArea fill rows={4} name="footnote" inputRef={register} />*/}
                    {/* TextArea inputRef doesn't work */}
                    <textarea className="bp3-input bp3-fill" name="d_Remark" rows={4} ref={register}></textarea>
                </FormGroup>
            </div>
        </div>}

        { ! (OpenAccFlag.user_key === "ASTRUM") && <div > 
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(ac)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('Account_No')} labelFor="inputAcCode" >
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        <InputGroup disabled={false} id="inputAcCode" name="d_acCode" defaultValue="" inputRef={register({ maxLength: acNoLength }) } />
                    </Tooltip>    
                    {errors.d_acCode && errors.d_acCode.type === "required" && (<span id="Err_msg">{t('Account_No')} {t('NotBlank')}</span>)}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is {acNoLength}</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-3" label={t('Name')} labelFor="inputAcName1">
                    <InputGroup id="inputAcName1" name="d_acName1" autoFocus={true} defaultValue="" placeholder="Name 1 ..." inputRef={register({ maxLength: 30}) } />
                    {errors.d_acName1 && errors.d_acName1.types && <span id="Err_msg">{errors.d_acName1.types.message}</span>}
                    {errors.d_acName1 && errors.d_acName1.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    <InputGroup id="inputAcName2" name="d_acName2" defaultValue="" placeholder="Name 2 ..." inputRef={register({ maxLength: 30}) } />
                    {errors.d_acName2 && errors.d_acName2.types && <span id="Err_msg">{errors.d_acName2.types.message}</span>}
                    {errors.d_acName2 && errors.d_acName2.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                </FormGroup>
                <FormGroup className="Col-6-3" label={t('HKIdNo')} labelFor="inputIdNo">
                    <InputGroup id="inputIdNo" name="d_idNo" defaultValue="" placeholder="ID No. ..." inputRef={register({ maxLength: idNoLength}) } />
                    {errors.d_idNo && errors.d_idNo.types && <span id="Err_msg">{errors.d_idNo.types.message}</span>}
                    {errors.d_idNo && errors.d_idNo.type === "maxLength" && <span id="Err_msg">Max length is {idNoLength}</span>}
                </FormGroup>
                <FormGroup className="Col3-9-1" label={t('Sex')} labelFor="inputd_Sex">
                    <HTMLSelect  id="inputd_Sex" name="d_Sex" value={def_d_Sex} onChange={utils.handleStringChange(s => setdef_d_Sex(s)) }ref={register}>
                        <option value="M">M: {t('Sex1')}</option>
                        <option value="F">F: {t('Sex2')}</option>
                    </HTMLSelect>
                    {errors.d_Sex && errors.d_Sex.types && <span id="Err_msg">{errors.d_Sex.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('ClientType')} labelFor="InputClientType">
                    <Switch id="InputClientType" label="" innerLabelChecked={t('ClientType01')} innerLabel={t('ClientType02')} checked={def_d_ClientType} onChange={utils.handleBooleanChange(v => setdef_d_ClientType(v))} ref={register}/>
                    {errors.d_ClientType && errors.d_ClientType.types && <span id="Err_msg">{errors.d_ClientType.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('DateofBirth')} labelFor="inputDoB">
                    <InputGroup id="inputDoB" name="d_dob" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_dob && errors.d_dob.types && <span id="Err_msg">{errors.d_dob.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label={t('Nationality')} labelFor="inputIdNo" labelInfo="*">
                    <InputGroup id="inputIdNo" name="d_National" defaultValue="" placeholder={t('Nationality')} inputRef={register({ required: true , maxLength: 40}) } />
                    {errors.d_National && errors.d_National.types && <span id="Err_msg">{errors.d_National.types.message}</span>}
                    {errors.d_National && errors.d_National.type === "required" && <span id="Err_msg">{t('Nationality')} {t('NotBlank')}</span>}
                    {errors.d_National && errors.d_National.type === "maxLength" && <span id="Err_msg">{t('Max')} 40 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Input DateTime">
                    <InputGroup readOnly disabled={true} value= {utils.dispDate(ac.d_inpputDate, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly disabled={true} value= {ac.d_inpputTime} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Verify Email DateTime">
                    <InputGroup readOnly disabled={true} value= {utils.dispDate(ac.d_verify_Date, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly disabled={true} value= {ac.d_verify_Time} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Authority DateTime/User">
                    <InputGroup readOnly disabled={true} value= {utils.dispDate(ac.d_Authority_date, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly disabled={true} value= {ac.d_Authority_time} />
                    <InputGroup readOnly disabled={true} value= {ac.d_Authority_user} />
                </FormGroup>
                <FormGroup className="Col-7-3" label="Generate A/C DateTime/User">
                    <InputGroup readOnly disabled={true} value= {utils.dispDate(ac.d_Generate_AC_date, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly disabled={true} value= {ac.d_Generate_AC_time} />
                    <InputGroup readOnly disabled={true} value= {ac.d_Generate_AC_user} />
                </FormGroup>
                <FormGroup className="Col-10-3" label="Export CSV DateTime/User">
                    <InputGroup readOnly disabled={true} value= {utils.dispDate(ac.d_Export_AC_date, OpenAccFlag.dateFormat)} />
                    <InputGroup readOnly disabled={true} value= {ac.d_Export_AC_time} />
                    <InputGroup readOnly disabled={true} value= {ac.d_Export_AC_user} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-4" label={t('ResidentialAddress')} labelFor="inputAddr1">
                    <InputGroup id="inputAddr1" name="d_acAddr1" defaultValue="" placeholder={t('ResidentialAddress')} inputRef={register({required: true , maxLength: AddrLength}) } />
                    {errors.d_acAddr1 && errors.d_acAddr1.type === "required" && <span id="Err_msg">{t('ResidentialAddress')} {t('NotBlank')}</span>}
                    {errors.d_acAddr1 && errors.d_acAddr1.types && <span id="Err_msg">{errors.d_acAddr1.types.message}</span>}
                    {errors.d_acAddr1 && errors.d_acAddr1.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                    <InputGroup id="inputAddr2" name="d_acAddr2" defaultValue="" placeholder={t('ResidentialAddress')} inputRef={register({ maxLength: AddrLength}) } />
                    {errors.d_acAddr2 && errors.d_acAddr2.types && <span id="Err_msg">{errors.d_acAddr2.types.message}</span>}
                    {errors.d_acAddr2 && errors.d_acAddr2.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                    <InputGroup id="inputAddr3" name="d_acAddr3" defaultValue="" placeholder={t('ResidentialAddress')} inputRef={register({ maxLength: AddrLength}) } />
                    {errors.d_acAddr3 && errors.d_acAddr3.types && <span id="Err_msg">{errors.d_acAddr3.types.message}</span>}
                    {errors.d_acAddr3 && errors.d_acAddr3.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-5-4" label={t('CorrespondenceAddress2')} labelFor="inputmailAddr1" >
                    <InputGroup id="inputmailAddr1" name="d_mailAddr1" defaultValue="" placeholder={t('CorrespondenceAddress')} inputRef={register({ maxLength: AddrLength}) } />
                    {errors.d_mailAddr1 && errors.d_mailAddr1.types && <span id="Err_msg">{errors.d_mailAddr1.types.message}</span>}
                    {errors.d_mailAddr1 && errors.d_mailAddr1.type === "required" && <span id="Err_msg">{t('CorrespondenceAddress')} {t('NotBlank')}</span>}
                    {errors.d_mailAddr1 && errors.d_mailAddr1.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                    <InputGroup id="inputmailAddr2" name="d_mailAddr2" defaultValue="" placeholder={t('CorrespondenceAddress')} inputRef={register({ maxLength: AddrLength}) } />
                    {errors.d_mailAddr2 && errors.d_mailAddr2.types && <span id="Err_msg">{errors.d_mailAddr2.types.message}</span>}
                    {errors.d_mailAddr2 && errors.d_mailAddr2.type === "required" && <span id="Err_msg">{t('CorrespondenceAddress')} {t('NotBlank')}</span>}
                    {errors.d_mailAddr2 && errors.d_mailAddr2.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                    <InputGroup id="inputmailAddr3" name="d_mailAddr3" defaultValue="" placeholder={t('CorrespondenceAddress')} inputRef={register({ maxLength: AddrLength}) } />
                    {errors.d_mailAddr3 && errors.d_mailAddr3.types && <span id="Err_msg">{errors.d_mailAddr3.types.message}</span>}
                    {errors.d_mailAddr3 && errors.d_mailAddr3.type === "required" && <span id="Err_msg">{t('CorrespondenceAddress')} {t('NotBlank')}</span>}
                    {errors.d_mailAddr3 && errors.d_mailAddr3.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-4" label={t('EmailAddress')} labelFor="inputEmail">
                    <InputGroup id="inputEmail" name="d_email" defaultValue="" placeholder={t('EmailAddress')} inputRef={register({ required: true , maxLength: EmailLength , pattern: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/ }) } />
                    {errors.d_email && errors.d_email.types && <span id="Err_msg">{errors.d_email.types.message}</span>}
                    {errors.d_email && errors.d_email.type === "required" && <span id="Err_msg">{t('EmailAddress')} {t('NotBlank')}</span>}
                    {errors.d_email && errors.d_email.type === "maxLength" && <span id="Err_msg">{t('Max')} {EmailLength} {t('character')}</span>}
                    {errors.d_email && errors.d_email.type === "pattern" && <span id="Err_msg">{t('EmailAddress')} {t('EmailInvalid')}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label={t('Send_e-mail_Statement')} labelFor="inputSendemail">
                    <Switch id="inputSendemail" label="" innerLabelChecked="Send" innerLabel="not Send" checked={SendemailValue} onChange={utils.handleBooleanChange(v => setSendemailValue(v))} ref={register}/>
                    {errors.d_sendemail && errors.d_sendemail.types && <span id="Err_msg">{errors.d_sendemail.types.message}</span>}
                </FormGroup>
            </div>


            <div className="Row">
                <FormGroup className="Col-1-2" label={t('Runner')} labelFor="inputRunner">
                    <InputGroup id="inputRunner" name="d_aeCode" defaultValue="" placeholder={t('Runner...')} inputRef={register({ required: false , maxLength: 10   }) } />
                    {errors.d_aeCode && errors.d_aeCode.types && <span id="Err_msg">{errors.d_aeCode.types.message}</span>}
                    {errors.d_aeCode && errors.d_aeCode.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                </FormGroup>
                <FormGroup className="Col-1-2" label={t('Commission')} labelFor="inputCommission">
                    <InputGroup id="inputRunner" name="d_commCode" placeholder={t('Commission...')} inputRef={ register({ maxLength: 10}) } />
                    {errors.d_commCode && errors.d_commCode.types && <span id="Err_msg">{errors.d_commCode.types.message}</span>}
                    {errors.d_commCode && errors.d_commCode.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                </FormGroup> 
                {<FormGroup className="Col-3-2" label="Default Interest" labelFor="inputInterest">
                    <InputGroup id="inputInterest" name="d_intCode" placeholder="Interest Code..." inputRef={ register({ maxLength: 10}) } />
                    {errors.d_intCode && errors.d_intCode.types && <span id="Err_msg">{errors.d_intCode.types.message}</span>}
                    {errors.d_intCode && errors.d_intCode.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                </FormGroup>}
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-5" label={t('way2GetStmt')} labelFor="inputway2GetStmt">
                    <HTMLSelect  id="inputway2GetStmt" name="d_way2GetStmt" value={def_d_way2GetStmt} onChange={utils.handleStringChange(s => setdef_d_way2GetStmt(s)) } ref={register}>
                        <option value="1">1: {t('way2GetStmt01')}</option>
                        <option value="2">2: {t('way2GetStmt02')}</option>
                        <option value="3">3: {t('way2GetStmt03')}</option>
                    </HTMLSelect>
                    {errors.d_way2GetStmt && errors.d_way2GetStmt.types && <span id="Err_msg">{errors.d_way2GetStmt.types.message}</span>}
                </FormGroup>
            </div>
            {t('way2GetStmt2')}
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('TelNoRes')} labelFor="inputTel">
                    <InputGroup id="inputTel" name="d_tel" defaultValue="" placeholder={t('TelNoRes')} inputRef={register({ maxLength: TelLength}) } />
                    {errors.d_tel && errors.d_tel.types && <span id="Err_msg">{errors.d_tel.types.message}</span>}
                    {errors.d_tel && errors.d_tel.type === "maxLength" && <span id="Err_msg">{t('Max')} {TelLength} {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label={t('TelNoOff')} labelFor="inputoffTel">
                    <InputGroup id="inputoffTel" name="d_offTel" defaultValue="" placeholder={t('TelNoOff')} inputRef={register({ maxLength: OffTelLength}) } />
                    {errors.d_offTel && errors.d_offTel.types && <span id="Err_msg">{errors.d_offTel.types.message}</span>}
                    {errors.d_offTel && errors.d_offTel.type === "maxLength" && <span id="Err_msg">{t('Max')} {OffTelLength} {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label={t('MobilePhoneCode')} labelFor="inputMobileArea" labelInfo="*">
                    {/* 20211006 Add SMS*/}
                    <HTMLSelect id="inputMobileArea" name="d_mobileCode" value={def_d_mobileCode} className={Classes.FIXED} onChange={utils.handleStringChange(s => setdef_d_mobileCode(s)) }>
                        <option value="852">{t('AreaCodeHKG')} +852</option>
                        <option value="86">{t('AreaCodeCHN')} +86</option>
                        <option value="853">{t('AreaCodeMAC')} +853</option>
                        {/* <option value="886">{t('AreaCodeTWN')} +886</option> */}
                        {/* <option value="">{t('AreaCodeOther')}</option> */}
                    </HTMLSelect>
                    {errors.d_mobileCode && errors.d_mobileCode.types && <span id="Err_msg">{errors.d_mobileCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label={t('MobilePhone')} labelFor="inputMobile" labelInfo="*">
                    <InputGroup id="inputMobile" name="d_mobile" defaultValue="" placeholder={t('MobilePhone')} inputRef={register({ required: true , minLength: 8 , maxLength: MobileLength}) } />
                    {errors.d_mobile && errors.d_mobile.types && <span id="Err_msg">{errors.d_mobile.types.message}</span>}
                    {errors.d_mobile && errors.d_mobile.type === "required" && (<span id="Err_msg">{t('MobilePhone')} {t('NotBlank')}</span>)}
                    {errors.d_mobile && errors.d_mobile.type === "maxLength" && <span id="Err_msg">{t('Max')} {MobileLength} {t('character')}</span>}
                    {errors.d_mobile && errors.d_mobile.type === "minLength" && <span id="Err_msg">{t('Min')} 8 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label={t('Fax')} labelFor="inputFax">
                    <InputGroup id="inputFax" name="d_fax" defaultValue="" placeholder={t('Fax')} inputRef={register({ maxLength: FaxLength}) } />
                    {errors.d_fax && errors.d_fax.types && <span id="Err_msg">{errors.d_fax.types.message}</span>}
                    {errors.d_fax && errors.d_fax.type === "maxLength" && <span id="Err_msg">{t('Max')} {FaxLength} {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label={t('Employment1')} labelFor="inputEmployment" labelInfo="*">
                    <HTMLSelect  id="inputEmployment" name="d_Employment" value={def_d_Employment} onChange={utils.handleStringChange(s => setdef_d_Employment(s)) } ref={register}>
                        <option value="1">1: {t('Employment01')}</option>
                        <option value="2">2: {t('Employment02')}</option>
                        <option value="3">3: {t('Employment03')}</option>
                        <option value="4">4: {t('Employment04')}</option>
                        <option value="5">5: {t('Employment05')}</option>
                        <option value="6">6: {t('Employment06')}</option>
                    </HTMLSelect>
                    {errors.d_Employment && errors.d_Employment.types && <span id="Err_msg">{errors.d_Employment.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-4" label={t('Employment2')} labelFor="inputEduOther">
                    <InputGroup id="inputEduOther" name="d_EmploymentOther" defaultValue="" placeholder={t('Employment2')} inputRef={register({ maxLength: 50}) } />
                    {errors.d_EmploymentOther && errors.d_EmploymentOther.types && <span id="Err_msg">{errors.d_EmploymentOther.types.message}</span>}
                    {errors.d_EmploymentOther && errors.d_EmploymentOther.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('Empl_CoName')} labelFor="inputCoName">
                    <InputGroup id="inputCoName" name="d_CoName" defaultValue="" placeholder={t('Empl_CoName')} inputRef={register({ maxLength: 100}) } />
                    {errors.d_CoName && errors.d_CoName.types && <span id="Err_msg">{errors.d_CoName.types.message}</span>}
                    {errors.d_CoName && errors.d_CoName.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('Empl_FinInstruction')} labelFor="InputisFinInstit">
                    {/* <Switch id="InputisFinInstit" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_isFinInstit} onChange={utils.handleBooleanChange(v => setdef_d_isFinInstit(v))} ref={register}/> */}
                    <RadioGroup
                    name="def_d_isFinInstit"
                    inline={true}
                    selectedValue={def_d_isFinInstit}
                    onChange={utils.handleStringChange(s => setdef_d_isFinInstit(s)) }
                    ref={register}
                >
                    <Radio label={t('cYes')} value="Y" />
                    <Radio label={t('cNo')}  value="N" />
                </RadioGroup>
                    {errors.d_isFinInstit && errors.d_isFinInstit.types && <span id="Err_msg">{errors.d_isFinInstit.types.message}</span>}
                    {errors.d_isFinInstit && errors.d_isFinInstit.type === "required" && (<span id="Err_msg">{t('Empl_FinInstruction')} {t('NotBlank')}</span>)}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('Empl_approveFinInstruction')} labelFor="InputApproveFinInstit">
                    {/* <Switch id="InputApproveFinInstit" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_ApproveFinInstit} onChange={utils.handleBooleanChange(v => setdef_d_ApproveFinInstit(v))} ref={register}/> */}
                    <RadioGroup
                    name="def_d_ApproveFinInstit"
                    inline={true}
                    selectedValue={def_d_ApproveFinInstit}
                    onChange={utils.handleStringChange(s => setdef_d_ApproveFinInstit(s)) }
                    ref={register}
                >
                    <Radio label={t('cYes')} value="Y" />
                    <Radio label={t('cNo')}  value="N" />
                </RadioGroup>
                    {errors.d_ApproveFinInstit && errors.d_ApproveFinInstit.types && <span id="Err_msg">{errors.d_ApproveFinInstit.types.message}</span>}
                    {errors.d_ApproveFinInstit && errors.d_ApproveFinInstit.type === "required" && (<span id="Err_msg">{t('Empl_approveFinInstruction')} {t('NotBlank')}</span>)}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('Empl_CoAddr')} labelFor="inputCoAddr">
                    <InputGroup id="inputCoAddr" name="d_CoAddr" defaultValue="" placeholder={t('Empl_CoAddr')} inputRef={register({ maxLength: 150}) } />
                    {errors.d_CoAddr && errors.d_CoAddr.types && <span id="Err_msg">{errors.d_CoAddr.types.message}</span>}
                    {errors.d_CoAddr && errors.d_CoAddr.type === "maxLength" && <span id="Err_msg">{t('Max')} 150 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('Empl_CoType')} labelFor="inputCoType">
                    <InputGroup id="inputCoType" name="d_CoType" defaultValue="" placeholder={t('Empl_CoType')} inputRef={register({ maxLength: 30}) } />
                    {errors.d_CoType && errors.d_CoType.types && <span id="Err_msg">{errors.d_CoType.types.message}</span>}
                    {errors.d_CoType && errors.d_CoType.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label={t('Empl_CoPost')} labelFor="inputCoPost">
                    <InputGroup id="inputCoPost" name="d_CoPost" defaultValue="" placeholder={t('Empl_CoPost')} inputRef={register({ maxLength: 30}) } />
                    {errors.d_CoPost && errors.d_CoPost.types && <span id="Err_msg">{errors.d_CoPost.types.message}</span>}
                    {errors.d_CoPost && errors.d_CoPost.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>

            <div className="Row">
                <FormGroup className="Col3-1-3" label={t('NetAsset')} labelFor="inputNetAsset">
                    <HTMLSelect  id="inputNetAsset" name="d_NetAsset" value={def_d_NetAsset} onChange={utils.handleStringChange(s => setdef_d_NetAsset(s)) } ref={register}>
                        <option value="1">1: {t('NetAsset01')}</option>
                        <option value="2">2: {t('NetAsset02')}</option>
                        <option value="3">3: {t('NetAsset03')}</option>
                        <option value="4">4: {t('NetAsset04')}</option>
                        <option value="5">5: {t('NetAsset05')}</option>
                        <option value="6">6: {t('NetAsset06')}</option>
                        <option value="7">7: {t('NetAsset07')}</option>
                        <option value="8">8: {t('NetAsset08')}</option>
                    </HTMLSelect>

                    {errors.d_NetAsset && errors.d_NetAsset.types && <span id="Err_msg">{errors.d_NetAsset.types.message}</span>}
                    {errors.d_NetAsset && errors.d_NetAsset.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col3-4-3" label={t('AnnualIncome')} labelFor="inputAnnualIncome">
                    <HTMLSelect  id="inputNetAsset" name="d_AnnualIncome" value={def_d_AnnualIncome} onChange={utils.handleStringChange(s => setdef_d_AnnualIncome(s)) } ref={register}>
                        <option value="1">1: {t('AnnualIncome01')}</option>
                        <option value="2">2: {t('AnnualIncome02')}</option>
                        <option value="3">3: {t('AnnualIncome03')}</option>
                        <option value="4">4: {t('AnnualIncome04')}</option>
                        <option value="5">5: {t('AnnualIncome05')}</option>
                        <option value="6">6: {t('AnnualIncome06')}</option>
                        <option value="7">7: {t('AnnualIncome07')}</option>
                        <option value="8">8: {t('AnnualIncome08')}</option>
                    </HTMLSelect>

                    {errors.d_AnnualIncome && errors.d_AnnualIncome.types && <span id="Err_msg">{errors.d_AnnualIncome.types.message}</span>}
                    {errors.d_AnnualIncome && errors.d_AnnualIncome.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('SourceofFund')} labelFor="inputSourceofFund">
                    <InputGroup id="inputSourceofFund" name="d_SourceofFund" defaultValue="" placeholder={t('SourceofFund')} inputRef={register({ maxLength: 60}) } />
                    {errors.d_SourceofFund && errors.d_SourceofFund.types && <span id="Err_msg">{errors.d_SourceofFund.types.message}</span>}
                    {errors.d_SourceofFund && errors.d_SourceofFund.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label={t('InvestTarget1')} labelFor="inputTarget" labelInfo="*">
                    <Checkbox name="d_Target1" label={t('InvestTarget01')} checked={def_d_Target1} onChange={utils.handleBooleanChange(v => setdef_d_Target1(v))} />
                    {errors.d_Target1 && errors.d_Target1.types && <span id="Err_msg">{errors.d_Target1.types.message}</span>}
                    <Checkbox name="d_Target2" label={t('InvestTarget02')} checked={def_d_Target2} onChange={utils.handleBooleanChange(v => setdef_d_Target2(v))} />
                    {errors.d_Target2 && errors.d_Target2.types && <span id="Err_msg">{errors.d_Target2.types.message}</span>}
                    <Checkbox name="d_Target3" label={t('InvestTarget03')} checked={def_d_Target3} onChange={utils.handleBooleanChange(v => setdef_d_Target3(v))} />
                    {errors.d_Target3 && errors.d_Target3.types && <span id="Err_msg">{errors.d_Target3.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-3" label={t('InvestTarget2')} labelFor="inputTarget">
                    <Checkbox name="d_Target4" label={t('InvestTarget04')} checked={def_d_Target4} onChange={utils.handleBooleanChange(v => setdef_d_Target4(v))} />
                    {errors.d_Target4 && errors.d_Target4.types && <span id="Err_msg">{errors.d_Target4.types.message}</span>}
                    <Checkbox name="d_Target5" label={t('InvestTarget05')} checked={def_d_Target5} onChange={utils.handleBooleanChange(v => setdef_d_Target5(v))} />
                    {errors.d_Target5 && errors.d_Target5.types && <span id="Err_msg">{errors.d_Target5.types.message}</span>}
                    <Checkbox name="d_Target6" label={t('InvestTarget06')} checked={def_d_Target6} onChange={utils.handleBooleanChange(v => setdef_d_Target6(v))} />
                    {errors.d_Target6 && errors.d_Target6.types && <span id="Err_msg">{errors.d_Target6.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-6-3" label=" ." labelFor="inputTarget">
                    <Checkbox name="d_Target7" label={t('InvestTarget07')} checked={def_d_Target7} onChange={utils.handleBooleanChange(v => setdef_d_Target7(v))} />
                    {errors.d_Target7 && errors.d_Target7.types && <span id="Err_msg">{errors.d_Target7.types.message}</span>}
                    <Checkbox name="d_Target8" label={t('InvestTarget08')} checked={def_d_Target8} onChange={utils.handleBooleanChange(v => setdef_d_Target8(v))} />
                    {errors.d_Target8 && errors.d_Target8.types && <span id="Err_msg">{errors.d_Target8.types.message}</span>}
                    <Checkbox name="d_Target9" label={t('InvestTarget09')} checked={def_d_Target9} onChange={utils.handleBooleanChange(v => setdef_d_Target9(v))} />
                    {errors.d_Target9 && errors.d_Target9.types && <span id="Err_msg">{errors.d_Target9.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <h5 className="bp3-heading">{t('InvestExperience')}</h5> 
            {/*
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('InvestExperience')} labelFor="Input_d_InvestExp">
                    <RadioGroup
                    name="def_d_InvestExp"
                    inline={true}
                    selectedValue={def_d_InvestExp}
                    onChange={utils.handleStringChange(s => setdef_d_InvestExp(s)) }
                    ref={register}
                >
                    <Radio label={t('cYes01')} value="Y" />
                    <Radio label={t('cNo01')}  value="N" />
                </RadioGroup>
                    {errors.d_InvestExp && errors.d_InvestExp.types && <span id="Err_msg">{errors.d_InvestExp.types.message}</span>}
                    {errors.d_InvestExp && errors.d_InvestExp.type === "required" && (<span id="Err_msg">{t('InvestExperience')} {t('NotBlank')}</span>)}
                </FormGroup>
            </div>
            */}
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('InvestExperience01')} labelFor="inputStockYear">
                    <InputGroup id="inputStockYear" name="d_StockYear" defaultValue="" placeholder={t('InvestExperience05')} inputRef={register({ maxLength: 3}) } />
                    {errors.d_StockYear && errors.d_StockYear.types && <span id="Err_msg">{errors.d_StockYear.types.message}</span>}
                    {errors.d_StockYear && errors.d_StockYear.type === "maxLength" && <span id="Err_msg">{t('Max')} 3 {t('character')}</span>}
                    <InputGroup id="inputStockCnt" name="d_StockCnt" defaultValue="" placeholder={t('InvestExperience06')} inputRef={register({ maxLength: 5}) } />
                    {errors.d_StockCnt && errors.d_StockCnt.types && <span id="Err_msg">{errors.d_StockCnt.types.message}</span>}
                    {errors.d_StockCnt && errors.d_StockCnt.type === "maxLength" && <span id="Err_msg">{t('Max')} 5 {t('character')}</span>}
                    <InputGroup id="inputStockAmt" name="d_StockAmt" defaultValue="" placeholder={t('InvestExperience07')} inputRef={register({ maxLength: 15}) } />
                    {errors.d_StockAmt && errors.d_StockAmt.types && <span id="Err_msg">{errors.d_StockAmt.types.message}</span>}
                    {errors.d_StockAmt && errors.d_StockAmt.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label="." labelFor="">
                    <InputGroup readOnly disabled={true} value={t('InvestExperience05')} />
                    <InputGroup readOnly disabled={true} value={t('InvestExperience06')} />
                    <InputGroup readOnly disabled={true} value={t('InvestExperience07')} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <h5 className="bp3-heading">{t('DerivativeExperience')}</h5>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('DerivativeExperience01')} labelFor="InputHighRiskExp">
                    {/* <Switch id="InputHighRiskExp" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_HighRiskExp} onChange={utils.handleBooleanChange(v => setdef_d_HighRiskExp(v))} ref={register}/> */}
                    <RadioGroup
                        name="def_d_HighRiskExp"
                        inline={true}
                        selectedValue={def_d_HighRiskExp}
                        onChange={utils.handleStringChange(s => setdef_d_HighRiskExp(s)) }
                        ref={register}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                    {errors.d_HighRiskExp && errors.d_HighRiskExp.types && <span id="Err_msg">{errors.d_HighRiskExp.types.message}</span>}
                    {errors.d_HighRiskExp && errors.d_HighRiskExp.type === "required" && (<span id="Err_msg">{t('DerivativeExperience')} {t('NotBlank')}</span>)}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('InvestExperience02')} labelFor="inputWarrentYear">
                    <InputGroup id="inputWarrentYear" name="d_WarrantYear" defaultValue="" placeholder={t('InvestExperience05')} inputRef={register({ maxLength: 3}) } />
                    {errors.d_WarrantYear && errors.d_WarrantYear.types && <span id="Err_msg">{errors.d_WarrantYear.types.message}</span>}
                    {errors.d_WarrantYear && errors.d_WarrantYear.type === "maxLength" && <span id="Err_msg">{t('Max')} 3 {t('character')}</span>}
                    <InputGroup id="inputWarrentCnt" name="d_WarrantCnt" defaultValue="" placeholder={t('InvestExperience06')} inputRef={register({ maxLength: 5}) } />
                    {errors.d_WarrantCnt && errors.d_WarrantCnt.types && <span id="Err_msg">{errors.d_WarrantCnt.types.message}</span>}
                    {errors.d_WarrantCnt && errors.d_WarrantCnt.type === "maxLength" && <span id="Err_msg">{t('Max')} 5 {t('character')}</span>}
                    <InputGroup id="inputWarrentAmt" name="d_WarrantAmt" defaultValue="" placeholder={t('InvestExperience07')} inputRef={register({ maxLength: 15}) } />
                    {errors.d_WarrantAmt && errors.d_WarrantAmt.types && <span id="Err_msg">{errors.d_WarrantAmt.types.message}</span>}
                    {errors.d_WarrantAmt && errors.d_WarrantAmt.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label={t('InvestExperience03')} labelFor="inputOptionYear">
                    <InputGroup id="inputOptionYear" name="d_OptionYear" defaultValue="" placeholder={t('InvestExperience05')} inputRef={register({ maxLength: 3}) } />
                    {errors.d_OptionYear && errors.d_OptionYear.types && <span id="Err_msg">{errors.d_OptionYear.types.message}</span>}
                    {errors.d_OptionYear && errors.d_OptionYear.type === "maxLength" && <span id="Err_msg">{t('Max')} 3 {t('character')}</span>}
                    <InputGroup id="inputOptionCnt" name="d_OptionCnt" defaultValue="" placeholder={t('InvestExperience06')} inputRef={register({ maxLength: 5}) } />
                    {errors.d_OptionCnt && errors.d_OptionCnt.types && <span id="Err_msg">{errors.d_OptionCnt.types.message}</span>}
                    {errors.d_OptionCnt && errors.d_OptionCnt.type === "maxLength" && <span id="Err_msg">{t('Max')} 5 {t('character')}</span>}
                    <InputGroup id="inputOptionAmt" name="d_OptionAmt" defaultValue="" placeholder={t('InvestExperience07')} inputRef={register({ maxLength: 15}) } />
                    {errors.d_OptionAmt && errors.d_OptionAmt.types && <span id="Err_msg">{errors.d_OptionAmt.types.message}</span>}
                    {errors.d_OptionAmt && errors.d_OptionAmt.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-7-3" label={t('InvestExperience04')} labelFor="inputFutureYear">
                    <InputGroup id="inputFutureYear" name="d_FutureYear" defaultValue="" placeholder={t('InvestExperience05')} inputRef={register({ maxLength: 3}) } />
                    {errors.d_FutureYear && errors.d_FutureYear.types && <span id="Err_msg">{errors.d_FutureYear.types.message}</span>}
                    {errors.d_FutureYear && errors.d_FutureYear.type === "maxLength" && <span id="Err_msg">{t('Max')} 3 {t('character')}</span>}
                    <InputGroup id="inputFutureCnt" name="d_FutureCnt" defaultValue="" placeholder={t('InvestExperience06')} inputRef={register({ maxLength: 5}) } />
                    {errors.d_FutureCnt && errors.d_FutureCnt.types && <span id="Err_msg">{errors.d_FutureCnt.types.message}</span>}
                    {errors.d_FutureCnt && errors.d_FutureCnt.type === "maxLength" && <span id="Err_msg">{t('Max')} 5 {t('character')}</span>}
                    <InputGroup id="inputFutureAmt" name="d_FutureAmt" defaultValue="" placeholder={t('InvestExperience07')} inputRef={register({ maxLength: 15}) } />
                    {errors.d_FutureAmt && errors.d_FutureAmt.types && <span id="Err_msg">{errors.d_FutureAmt.types.message}</span>}
                    {errors.d_FutureAmt && errors.d_FutureAmt.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-10-3" label="." labelFor="">
                    <InputGroup readOnly disabled={true} value={t('InvestExperience05')} />
                    <InputGroup readOnly disabled={true} value={t('InvestExperience06')} />
                    <InputGroup readOnly disabled={true} value={t('InvestExperience07')} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>


            <div className="Spacing-V-16"></div>
            { (OpenAccFlag.user_key === "ASTRUM")  && <div className="Row">
                <FormGroup className="Col-1-5" label={t('OpenDecld_StaffRelation01')} labelFor="InputStaffRelation">
                    {/* <Switch id="InputStaffRelation" label="" innerLabelChecked={t('cYes01')}  innerLabel={t('cNo01')} checked={def_d_StaffRelation} onChange={utils.handleBooleanChange(v => setdef_d_StaffRelation(v))} ref={register}/> */}
                    <RadioGroup
                        name="def_d_StaffRelation"
                        inline={true}
                        selectedValue={def_d_StaffRelation}
                        onChange={utils.handleStringChange(s => setdef_d_StaffRelation(s)) }
                        ref={register}
                    >
                        <Radio label={t('cYes01')} value="Y" />
                        <Radio label={t('cNo01')}  value="N" />
                    </RadioGroup>
                    {errors.d_StaffRelation && errors.d_StaffRelation.types && <span id="Err_msg">{errors.d_StaffRelation.types.message}</span>}
                    {errors.d_StaffRelation && errors.d_StaffRelation.type === "required" && (<span id="Err_msg">{t('OpenDecld_StaffRelation01')} {t('NotBlank')}</span>)}
                </FormGroup>
            </div> }
            { ! (OpenAccFlag.user_key === "ASTRUM")  && <div className="Row">
                <FormGroup className="Col-1-5" label={t('OpenDecld_StaffRelation02')} labelFor="InputStaffRelation">
                    {/* <Switch id="InputStaffRelation" label="" innerLabelChecked={t('cYes01')} innerLabel={t('cNo01')} checked={def_d_StaffRelation} onChange={utils.handleBooleanChange(v => setdef_d_StaffRelation(v))} ref={register}/> */}
                    <RadioGroup
                        name="def_d_StaffRelation"
                        inline={true}
                        selectedValue={def_d_StaffRelation}
                        onChange={utils.handleStringChange(s => setdef_d_StaffRelation(s)) }
                        ref={register}
                    >
                        <Radio label={t('cYes01')} value="Y" />
                        <Radio label={t('cNo01')}  value="N" />
                    </RadioGroup>
                    {errors.d_StaffRelation && errors.d_StaffRelation.types && <span id="Err_msg">{errors.d_StaffRelation.types.message}</span>}
                    {errors.d_StaffRelation && errors.d_StaffRelation.type === "required" && (<span id="Err_msg">{t('OpenDecld_StaffRelation02')} {t('NotBlank')}</span>)}
                </FormGroup>
            </div> }
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('OpenDecld_StaffRelation03')} labelFor="inputStaffName">
                    <InputGroup id="inputStaffName" name="d_StaffName" defaultValue="" placeholder="" inputRef={register({ maxLength: 60}) } />
                    {errors.d_StaffName && errors.d_StaffName.types && <span id="Err_msg">{errors.d_StaffName.types.message}</span>}
                    {errors.d_StaffName && errors.d_StaffName.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-4-2" label={t('OpenDecld_StaffRelation04')}  labelFor="inputStaffRelationship">
                    <InputGroup id="inputStaffRelationship" name="d_StaffRelationship" defaultValue="" placeholder="" inputRef={register({ maxLength: 30}) } />
                    {errors.d_StaffRelationship && errors.d_StaffRelationship.types && <span id="Err_msg">{errors.d_StaffRelationship.types.message}</span>}
                    {errors.d_StaffRelationship && errors.d_StaffRelationship.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('OpenDecld_selfInstruction01')} labelFor="InputselfInstruction">
                    {/* <Switch id="InputselfInstruction" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_selfInstruction} onChange={utils.handleBooleanChange(v => setdef_d_selfInstruction(v))} ref={register}/> */}
                    <RadioGroup
                        name="def_d_selfInstruction"
                        inline={true}
                        selectedValue={def_d_selfInstruction}
                        onChange={utils.handleStringChange(s => setdef_d_selfInstruction(s)) }
                        ref={register}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                    {errors.d_selfInstruction && errors.d_selfInstruction.types && <span id="Err_msg">{errors.d_selfInstruction.types.message}</span>}
                    {errors.d_selfInstruction && errors.d_selfInstruction.type === "required" && (<span id="Err_msg">{t('OpenDecld_selfInstruction01')} {t('NotBlank')}</span>)}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-4" label={t('OpenDecld_selfInstruction02')} labelFor="inputInstructionType">
                    <InputGroup id="inputInstructionType" name="d_InstructionType" defaultValue="" placeholder="" inputRef={register({ maxLength: 50}) } />
                    {errors.d_InstructionType && errors.d_InstructionType.types && <span id="Err_msg">{errors.d_InstructionType.types.message}</span>}
                    {errors.d_InstructionType && errors.d_InstructionType.type === "maxLength" && <span id="Err_msg">最多 50 字元</span>}
                </FormGroup>
            </div>

            <div className="Spacing-V-16"></div>
            <div className="Spacing-V-16"></div>
            <h5 className="bp3-heading">{t('OpenRelatedPersion')}</h5>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('OpenhasSpouseClient01')} labelFor="InputhasSpouseClient">
                    {/* <Switch id="InputhasSpouseClient" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_hasSpouseClient} onChange={utils.handleBooleanChange(v => setdef_d_hasSpouseClient(v))} ref={register}/> */}
                    <RadioGroup
                        name="def_d_hasSpouseClient"
                        inline={true}
                        selectedValue={def_d_hasSpouseClient}
                        onChange={utils.handleStringChange(s => setdef_d_hasSpouseClient(s)) }
                        ref={register}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                    {errors.d_hasSpouseClient && errors.d_hasSpouseClient.types && <span id="Err_msg">{errors.d_hasSpouseClient.types.message}</span>}
                    {errors.d_hasSpouseClient && errors.d_hasSpouseClient.type === "required" && (<span id="Err_msg">{t('OpenRelatedPersion')} {t('NotBlank')}</span>)}
                </FormGroup>
            </div>
            <span id="DispText2">{t('OpenhasSpouseClient02')}</span>
            <div className="Row">
                <FormGroup className="Col-1-4" label={t('OpenhasSpouseClient02a')} labelFor="inputSpouseName">
                    <InputGroup id="inputSpouseName" name="d_SpouseName" defaultValue="" placeholder="" inputRef={register({ maxLength: 60}) } />
                    {errors.d_SpouseName && errors.d_SpouseName.types && <span id="Err_msg">{errors.d_SpouseName.types.message}</span>}
                    {errors.d_SpouseName && errors.d_SpouseName.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-6-1" label={t('OpenhasSpouseClient03')} labelFor="inputSpouseAcNo">
                    <InputGroup id="inputSpouseAcNo" name="d_SpouseAcNo" defaultValue="" placeholder="" inputRef={register({ maxLength: 10}) } />
                    {errors.d_SpouseAcNo && errors.d_SpouseAcNo.types && <span id="Err_msg">{errors.d_SpouseAcNo.types.message}</span>}
                    {errors.d_SpouseAcNo && errors.d_SpouseAcNo.type === "maxLength" && <span id="Err_msg">{t('Max')} 10 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('OpenVoteRight01')} labelFor="InputVoteRight">
                    {/* <Switch id="InputVoteRight" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_VoteRight} onChange={utils.handleBooleanChange(v => setdef_d_VoteRight(v))} ref={register}/> */}
                    <RadioGroup
                        name="def_d_VoteRight"
                        inline={true}
                        selectedValue={def_d_VoteRight}
                        onChange={utils.handleStringChange(s => setdef_d_VoteRight(s)) }
                        ref={register}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                    {errors.d_VoteRight && errors.d_VoteRight.type === "required" && (<span id="Err_msg">{t('OpenVoteRight01')} {t('NotBlank')}</span>)}
                    {errors.d_VoteRight && errors.d_VoteRight.types && <span id="Err_msg">{errors.d_VoteRight.types.message}</span>}
                </FormGroup>
            </div>
            <span id="DispText2">{t('OpenVoteRight02')}</span>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('OpenVoteRight02a')} labelFor="inputVoteAc">
                    <InputGroup id="inputVoteAc1" name="d_VoteAc1" defaultValue="" placeholder="" inputRef={register({ maxLength: 10}) } />
                    {errors.d_VoteAc1 && errors.d_VoteAc1.types && <span id="Err_msg">{errors.d_VoteAc1.types.message}</span>}
                    {errors.d_VoteAc1 && errors.d_VoteAc1.type === "maxLength" && <span id="Err_msg">{t('Max')} 10 {t('character')}</span>}
                    <InputGroup id="inputVoteAc2" name="d_VoteAc2" defaultValue="" placeholder="" inputRef={register({ maxLength: 10}) } />
                    {errors.d_VoteAc2 && errors.d_VoteAc2.types && <span id="Err_msg">{errors.d_VoteAc2.types.message}</span>}
                    {errors.d_VoteAc2 && errors.d_VoteAc2.type === "maxLength" && <span id="Err_msg">{t('Max')} 10 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-4-4" label={t('OpenVoteRight03')} labelFor="inputIoteAcName1">
                    <InputGroup id="inputIoteAcName1" name="d_VoteAcName1" defaultValue="" placeholder="" inputRef={register({ maxLength: 60}) } />
                    {errors.d_VoteAcName1 && errors.d_VoteAcName1.types && <span id="Err_msg">{errors.d_VoteAcName1.types.message}</span>}
                    {errors.d_VoteAcName1 && errors.d_VoteAcName1.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                    <InputGroup id="inputIoteAcName2" name="d_VoteAcName2" defaultValue="" placeholder="" inputRef={register({ maxLength: 60}) } />
                    {errors.d_VoteAcName2 && errors.d_VoteAcName2.types && <span id="Err_msg">{errors.d_VoteAcName2.types.message}</span>}
                    {errors.d_VoteAcName2 && errors.d_VoteAcName2.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                </FormGroup>
                <FormGroup className="Col-8-3" label={t('OpenVoteRight04')} labelFor="inputSpouseAcNo">
                    <InputGroup id="inputSpouseAcNo" name="d_VoteRelate1" defaultValue="" placeholder="" inputRef={register({ maxLength: 30}) } />
                    {errors.d_VoteRelate1 && errors.d_VoteRelate1.types && <span id="Err_msg">{errors.d_VoteRelate1.types.message}</span>}
                    {errors.d_VoteRelate1 && errors.d_VoteRelate1.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                    <InputGroup id="inputSpouseAcNo" name="d_VoteRelate2" defaultValue="" placeholder="" inputRef={register({ maxLength: 30}) } />
                    {errors.d_VoteRelate2 && errors.d_VoteRelate2.types && <span id="Err_msg">{errors.d_VoteRelate2.types.message}</span>}
                    {errors.d_VoteRelate2 && errors.d_VoteRelate2.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <h5 className="bp3-heading">{t('isInternet01')}</h5>
            <div className="Row">
                <FormGroup className="Col-1-5" label={t('isInternet02')} labelFor="InputisInternet">
                    {/* <Switch id="InputisInternet" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_isInternet} onChange={utils.handleBooleanChange(v => setdef_d_isInternet(v))} ref={register}/> */}
                    <RadioGroup
                        name="def_d_isInternet"
                        inline={true}
                        selectedValue={def_d_isInternet}
                        onChange={utils.handleStringChange(s => setdef_d_isInternet(s)) }
                        ref={register}
                    >
                        <Radio label={t('cYes')} value="Y" />
                        <Radio label={t('cNo')}  value="N" />
                    </RadioGroup>
                    {errors.d_isInternet && errors.d_isInternet.types && <span id="Err_msg">{errors.d_isInternet.types.message}</span>}
                    {errors.d_isInternet && errors.d_isInternet.type === "required" && (<span id="Err_msg">{t('isInternet02')} {t('NotBlank')}</span>)}
                    {errors.d_isInternet && errors.d_isInternet.types && <span id="Err_msg">{errors.d_isInternet.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>

            <h5 className="bp3-heading">CRS</h5>
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                    <Suggest
                        inputProps={{ placeholder: "Please choose Jurisdiction" }}
                        defaultSelectedItem={JurisdictionList.find((aj) => aj.CountryCode === ac.d_JurisdictionofResidence)}
                        items={JurisdictionList}
                        itemRenderer={JurisdictionRenderer}
                        itemPredicate={JurisdictionPredicate}
                        inputValueRenderer={JurisdictionValueRenderer}
                        onItemSelect={(ac) => { setValue("d_JurisdictionofResidence", ac.CountryCode) }}
                    />
                    {errors.d_JurisdictionofResidence && errors.d_JurisdictionofResidence.types && <span id="Err_msg">{errors.d_JurisdictionofResidence.types.message}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                    <InputGroup id="inputd_TIN" name="d_TIN" defaultValue="" placeholder="" inputRef={register({ maxLength: 50}) } />
                    {errors.d_TIN && errors.d_TIN.types && <span id="Err_msg">{errors.d_TIN.types.message}</span>}
                    {errors.d_TIN && errors.d_TIN.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')} labelFor="inputd_Juris_ReasonA" >
                    <HTMLSelect  id="inputd_Juris_ReasonA" name="d_Juris_ReasonA" value={def_d_Juris_ReasonA} onChange={utils.handleStringChange(s => setdef_d_Juris_ReasonA(s)) } ref={register}>
                        <option value=""></option>
                        <option value="A">A: A</option>
                        <option value="B">B: B</option>
                        <option value="C">C: C</option>
                    </HTMLSelect>
                    {errors.d_Juris_ReasonA && errors.d_Juris_ReasonA.types && <span id="Err_msg">{errors.d_Juris_ReasonA.types.message}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}   labelFor="inputd_Juris_Reason" >
                    <InputGroup id="inputd_Juris_Reason" name="d_Juris_Reason" placeholder="" inputRef={register({ maxLength: 100}) } />
                    {errors.d_Juris_Reason && errors.d_Juris_Reason.types && <span id="Err_msg">{errors.d_Juris_Reason.types.message}</span>}
                    {errors.d_Juris_Reason && errors.d_Juris_Reason.type === "required" && <span id="Err_msg">{t('JurisdictionofResidence05')} {t('NotBlank')}</span>}
                    {errors.d_Juris_Reason && errors.d_Juris_Reason.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                    <Suggest
                        inputProps={{ placeholder: "Please choose Jurisdiction" }}
                        defaultSelectedItem={JurisdictionList.find((aj) => aj.CountryCode === ac.d_JurisdictionofResidence2)}
                        items={JurisdictionList}
                        itemRenderer={JurisdictionRenderer}
                        itemPredicate={JurisdictionPredicate}
                        inputValueRenderer={JurisdictionValueRenderer}
                        onItemSelect={(ac) => { setValue("d_JurisdictionofResidence2", ac.CountryCode) }}
                    />
                    {errors.d_JurisdictionofResidence2 && errors.d_JurisdictionofResidence2.types && <span id="Err_msg">{errors.d_JurisdictionofResidence2.types.message}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')}labelFor="inputd_TIN">
                    <InputGroup id="inputd_TIN" name="d_TIN2" defaultValue="" placeholder="" inputRef={register({ maxLength: 50}) } />
                    {errors.d_TIN2 && errors.d_TIN2.types && <span id="Err_msg">{errors.d_TIN2.types.message}</span>}
                    {errors.d_TIN2 && errors.d_TIN2.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')} labelFor="inputd_Juris_ReasonA" >
                    <HTMLSelect  id="inputd_Juris_ReasonA" name="d_Juris_Reason2A" value={def_d_Juris_Reason2A} onChange={utils.handleStringChange(s => setdef_d_Juris_Reason2A(s)) } ref={register}>
                        <option value=""></option>
                        <option value="A">A: A</option>
                        <option value="B">B: B</option>
                        <option value="C">C: C</option>
                    </HTMLSelect>
                    {errors.d_Juris_Reason2A && errors.d_Juris_Reason2A.types && <span id="Err_msg">{errors.d_Juris_Reason2A.types.message}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                    <InputGroup id="inputd_Juris_Reason" name="d_Juris_Reason2" placeholder="" inputRef={register({ maxLength: 100}) } />
                    {errors.d_Juris_Reason2 && errors.d_Juris_Reason2.types && <span id="Err_msg">{errors.d_Juris_Reason2.types.message}</span>}
                    {errors.d_Juris_Reason2 && errors.d_Juris_Reason2.type === "required" && <span id="Err_msg">{t('JurisdictionofResidence05')} {t('NotBlank')}</span>}
                    {errors.d_Juris_Reason2 && errors.d_Juris_Reason2.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                    <Suggest
                        inputProps={{ placeholder: "Please choose Jurisdiction" }}
                        defaultSelectedItem={JurisdictionList.find((aj) => aj.CountryCode === ac.d_JurisdictionofResidence3)}
                        items={JurisdictionList}
                        itemRenderer={JurisdictionRenderer}
                        itemPredicate={JurisdictionPredicate}
                        inputValueRenderer={JurisdictionValueRenderer}
                        onItemSelect={(ac) => { setValue("d_JurisdictionofResidence3", ac.CountryCode) }}
                    />
                    {errors.d_JurisdictionofResidence3 && errors.d_JurisdictionofResidence3.types && <span id="Err_msg">{errors.d_JurisdictionofResidence3.types.message}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                    <InputGroup id="inputd_TIN" name="d_TIN3" defaultValue="" placeholder="" inputRef={register({ maxLength: 50}) } />
                    {errors.d_TIN3 && errors.d_TIN3.types && <span id="Err_msg">{errors.d_TIN3.types.message}</span>}
                    {errors.d_TIN3 && errors.d_TIN3.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')}labelFor="inputd_Juris_ReasonA" >
                    <HTMLSelect  id="inputd_Juris_ReasonA" name="d_Juris_Reason3A" value={def_d_Juris_Reason3A} onChange={utils.handleStringChange(s => setdef_d_Juris_Reason3A(s)) } ref={register}>
                        <option value=""></option>
                        <option value="A">A: A</option>
                        <option value="B">B: B</option>
                        <option value="C">C: C</option>
                    </HTMLSelect>
                    {errors.d_Juris_Reason3A && errors.d_Juris_Reason3A.types && <span id="Err_msg">{errors.d_Juris_Reason3A.types.message}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                    <InputGroup id="inputd_Juris_Reason" name="d_Juris_Reason3" placeholder="" inputRef={register({ maxLength: 100}) } />
                    {errors.d_Juris_Reason3 && errors.d_Juris_Reason3.types && <span id="Err_msg">{errors.d_Juris_Reason3.types.message}</span>}
                    {errors.d_Juris_Reason3 && errors.d_Juris_Reason3.type === "required" && <span id="Err_msg">{t('JurisdictionofResidence05')} {t('NotBlank')}</span>}
                    {errors.d_Juris_Reason3 && errors.d_Juris_Reason3.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                    <Suggest
                        inputProps={{ placeholder: "Please choose Jurisdiction" }}
                        defaultSelectedItem={JurisdictionList.find((aj) => aj.CountryCode === ac.d_JurisdictionofResidence4)}
                        items={JurisdictionList}
                        itemRenderer={JurisdictionRenderer}
                        itemPredicate={JurisdictionPredicate}
                        inputValueRenderer={JurisdictionValueRenderer}
                        onItemSelect={(ac) => { setValue("d_JurisdictionofResidence4", ac.CountryCode) }}
                    />
                    {errors.d_JurisdictionofResidence4 && errors.d_JurisdictionofResidence4.types && <span id="Err_msg">{errors.d_JurisdictionofResidence4.types.message}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                    <InputGroup id="inputd_TIN" name="d_TIN4" defaultValue="" placeholder="" inputRef={register({ maxLength: 50}) } />
                    {errors.d_TIN4 && errors.d_TIN4.types && <span id="Err_msg">{errors.d_TIN4.types.message}</span>}
                    {errors.d_TIN4 && errors.d_TIN4.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')} labelFor="inputd_Juris_ReasonA" >
                    <HTMLSelect  id="inputd_Juris_ReasonA" name="d_Juris_Reason4A" value={def_d_Juris_Reason4A} onChange={utils.handleStringChange(s => setdef_d_Juris_Reason4A(s)) } ref={register}>
                        <option value=""></option>
                        <option value="A">A: A</option>
                        <option value="B">B: B</option>
                        <option value="C">C: C</option>
                    </HTMLSelect>
                    {errors.d_Juris_Reason4A && errors.d_Juris_Reason4A.types && <span id="Err_msg">{errors.d_Juris_Reason4A.types.message}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}   labelFor="inputd_Juris_Reason" >
                    <InputGroup id="inputd_Juris_Reason" name="d_Juris_Reason4" placeholder="" inputRef={register({ maxLength: 100}) } />
                    {errors.d_Juris_Reason4 && errors.d_Juris_Reason4.types && <span id="Err_msg">{errors.d_Juris_Reason4.types.message}</span>}
                    {errors.d_Juris_Reason4 && errors.d_Juris_Reason4.type === "required" && <span id="Err_msg">{t('JurisdictionofResidence05')} {t('NotBlank')}</span>}
                    {errors.d_Juris_Reason4 && errors.d_Juris_Reason4.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')}  labelFor="inputd_JurisdictionofResidence">
                    <Suggest
                        inputProps={{ placeholder: "Please choose Jurisdiction" }}
                        defaultSelectedItem={JurisdictionList.find((aj) => aj.CountryCode === ac.d_JurisdictionofResidence5)}
                        items={JurisdictionList}
                        itemRenderer={JurisdictionRenderer}
                        itemPredicate={JurisdictionPredicate}
                        inputValueRenderer={JurisdictionValueRenderer}
                        onItemSelect={(ac) => { setValue("d_JurisdictionofResidence5", ac.CountryCode) }}
                    />
                    {errors.d_JurisdictionofResidence5 && errors.d_JurisdictionofResidence5.types && <span id="Err_msg">{errors.d_JurisdictionofResidence5.types.message}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                    <InputGroup id="inputd_TIN" name="d_TIN5" defaultValue="" placeholder="" inputRef={register({ maxLength: 50}) } />
                    {errors.d_TIN5 && errors.d_TIN5.types && <span id="Err_msg">{errors.d_TIN5.types.message}</span>}
                    {errors.d_TIN5 && errors.d_TIN5.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_ReasonA" >
                    <HTMLSelect  id="inputd_Juris_ReasonA" name="d_Juris_Reason5A" value={def_d_Juris_Reason5A} onChange={utils.handleStringChange(s => setdef_d_Juris_Reason5A(s)) } ref={register}>
                        <option value=""></option>
                        <option value="A">A: A</option>
                        <option value="B">B: B</option>
                        <option value="C">C: C</option>
                    </HTMLSelect>
                    {errors.d_Juris_Reason5A && errors.d_Juris_Reason5A.types && <span id="Err_msg">{errors.d_Juris_Reason5A.types.message}</span>}
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                    <InputGroup id="inputd_Juris_Reason" name="d_Juris_Reason5" placeholder="" inputRef={register({ maxLength: 100}) } />
                    {errors.d_Juris_Reason5 && errors.d_Juris_Reason5.types && <span id="Err_msg">{errors.d_Juris_Reason5.types.message}</span>}
                    {errors.d_Juris_Reason5 && errors.d_Juris_Reason5.type === "required" && <span id="Err_msg">{t('JurisdictionofResidence05')} {t('NotBlank')}</span>}
                    {errors.d_Juris_Reason5 && errors.d_Juris_Reason5.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <span id="DispText2">{t('JurisdictionofResidence06')}</span>
            </div>
            <ul>
                <li>{t('JurisdictionofResidence06a')}</li>
                <li>{t('JurisdictionofResidence06b')}</li>
                <li>{t('JurisdictionofResidence06c')}</li>
            </ul>
            <div className="Spacing-V-16"></div>
            <div className="Spacing-V-16"></div>
            <h5 className="bp3-heading">W-8BEN</h5>
            <ol>
                <li>
                    <div className="Row vertical_alignment">
                        <span id="DispText4">
                            {t('OpenW8B01')}
                        </span>
                        <span id="DispText5">
                            <InputGroup id="inputd_w8b_country" name="d_w8b_country" defaultValue="" placeholder="" inputRef={register({ required: true , maxLength: 60}) } />
                        </span>
                        <span id="DispText6">
                            {t('OpenW8B01a')}
                        </span>
                    </div>
                    <div className="Row vertical_alignment">
                        <span id="DispText6">
                            {t('OpenW8B01b')}
                        </span>
                    </div>
                    {errors.d_w8b_country && errors.d_w8b_country.types && <span id="Err_msg">{errors.d_w8b_country.types.message}</span>}
                    {errors.d_w8b_country && errors.d_w8b_country.type === "required" && (<span id="Err_msg">{t('OpenW8B01c')} {t('NotBlank')}</span>)}
                    {errors.d_w8b_country && errors.d_w8b_country.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                </li>
                <div className="Spacing-V-16"></div>
                <li>
                    <div className="Row vertical_alignment">
                        <span id="DispText6">
                            {t('OpenW8B02b')}
                        </span>
                    </div>
                    <div className="Row vertical_alignment">
                        <span id="DispText9">
                            {t('OpenW8B02b1')}
                        </span>
                        <span id="DispText11">
                            <InputGroup id="inputd_w8b_SR01" name="d_w8b_SR01" defaultValue="" placeholder="" inputRef={register({ maxLength: 20}) } />
                        </span>
                        <span id="DispText10">
                            {t('OpenW8B02c')}
                        </span>
                        <span id="DispText11">
                            <InputGroup id="inputd_w8b_SR02" name="d_w8b_SR02" defaultValue="" placeholder="" inputRef={register({ maxLength: 10}) } />
                        </span>
                    </div>
                    <div className="Row vertical_alignment">
                        <span id="DispText4">
                            {t('OpenW8B02d')}
                        </span>
                        <span id="DispText7">
                            <InputGroup id="inputd_w8b_SR03" name="d_w8b_SR03" defaultValue="" placeholder="" inputRef={register({ maxLength: 100}) } />
                        </span>
                    </div>
                    <div className="Row vertical_alignment">
                        <span id="DispText6">
                            {t('OpenW8B02e')}
                        </span>
                    </div>
                    <div className="Row vertical_alignment">
                        <span id="DispText7">
                            <InputGroup id="inputd_w8b_SR04" name="d_w8b_SR04" defaultValue="" placeholder="" inputRef={register({ maxLength: 100}) } />
                        </span>
                    </div>
                    {errors.d_w8b_SR01 && errors.d_w8b_SR01.types && <span id="Err_msg">{errors.d_w8b_SR01.types.message}</span>}
                    {errors.d_w8b_SR01 && errors.d_w8b_SR01.type === "required" && (<span id="Err_msg"> {t('NotBlank')}</span>)}
                    {errors.d_w8b_SR01 && errors.d_w8b_SR01.type === "maxLength" && <span id="Err_msg">{t('Max')} 20 {t('character')}</span>}
                    {errors.d_w8b_SR02 && errors.d_w8b_SR02.types && <span id="Err_msg">{errors.d_w8b_SR02.types.message}</span>}
                    {errors.d_w8b_SR02 && errors.d_w8b_SR02.type === "required" && (<span id="Err_msg"> {t('NotBlank')}</span>)}
                    {errors.d_w8b_SR02 && errors.d_w8b_SR02.type === "maxLength" && <span id="Err_msg">{t('Max')} 10 {t('character')}</span>}
                    {errors.d_w8b_SR03 && errors.d_w8b_SR03.types && <span id="Err_msg">{errors.d_w8b_SR03.types.message}</span>}
                    {errors.d_w8b_SR03 && errors.d_w8b_SR03.type === "required" && (<span id="Err_msg"> {t('NotBlank')}</span>)}
                    {errors.d_w8b_SR03 && errors.d_w8b_SR03.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                    {errors.d_w8b_SR04 && errors.d_w8b_SR04.types && <span id="Err_msg">{errors.d_w8b_SR04.types.message}</span>}
                    {errors.d_w8b_SR04 && errors.d_w8b_SR04.type === "required" && (<span id="Err_msg"> {t('NotBlank')}</span>)}
                    {errors.d_w8b_SR04 && errors.d_w8b_SR04.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                </li>
            </ol>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <span id="DispText6">
                    &nbsp; &nbsp; &nbsp; {t('OpenW8B02a')}
                </span>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_01" labelInfo="*">
                    <Checkbox name="d_w8b_01"  label={t('OpenW8B03')} disabled={true} checked={def_d_w8b_01} onChange={utils.handleBooleanChange(v => setdef_d_w8b_01(v))} />
                    {errors.d_w8b_01 && errors.d_w8b_01.types && <span id="Err_msg">{errors.d_w8b_01.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                    <Checkbox name="d_w8b_02"  label={t('OpenW8B04')} disabled={true} checked={def_d_w8b_02} onChange={utils.handleBooleanChange(v => setdef_d_w8b_02(v))} />
                    {errors.d_w8b_02 && errors.d_w8b_02.types && <span id="Err_msg">{errors.d_w8b_02.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                    <Checkbox name="d_w8b_03"  label={t('OpenW8B05')} disabled={true} checked={def_d_w8b_03} onChange={utils.handleBooleanChange(v => setdef_d_w8b_03(v))} />
                    {errors.d_w8b_03 && errors.d_w8b_03.types && <span id="Err_msg">{errors.d_w8b_03.types.message}</span>}
                </FormGroup>
            </div>
            <ul className="bp3-list">
                <li>{t('OpenW8B05a')}</li>
                <li>{t('OpenW8B05b')}</li>
                <li>{t('OpenW8B05c')}</li>
                <li>{t('OpenW8B05d')}</li>
            </ul>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                    <Checkbox name="d_w8b_04"  label={t('OpenW8B06')} disabled={true} checked={def_d_w8b_04} onChange={utils.handleBooleanChange(v => setdef_d_w8b_04(v))} />
                    {errors.d_w8b_04 && errors.d_w8b_04.types && <span id="Err_msg">{errors.d_w8b_04.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                    <Checkbox name="d_w8b_05"  label={t('OpenW8B07')} disabled={true} checked={def_d_w8b_05} onChange={utils.handleBooleanChange(v => setdef_d_w8b_05(v))} />
                    {errors.d_w8b_05 && errors.d_w8b_05.types && <span id="Err_msg">{errors.d_w8b_05.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                    <Checkbox name="d_w8b_06"  label={t('OpenW8B08')} disabled={true} checked={def_d_w8b_06} onChange={utils.handleBooleanChange(v => setdef_d_w8b_06(v))} />
                    {errors.d_w8b_06 && errors.d_w8b_06.types && <span id="Err_msg">{errors.d_w8b_06.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('OpenBankInfo01')} labelFor="inputBankInfo" labelInfo="*">
                    <InputGroup id="inputBankInfo" name="d_BankInfo" defaultValue={""} placeholder="" inputRef={register({ required: true , maxLength: BankInfoLength}) } />
                    {errors.d_BankInfo && errors.d_BankInfo.types && <span id="Err_msg">{errors.d_BankInfo.types.message}</span>}
                    {errors.d_BankInfo && errors.d_BankInfo.type === "required" && (<span id="Err_msg">{t('OpenBankInfo01')} {t('NotBlank')}</span>)}
                    {errors.d_BankInfo && errors.d_BankInfo.type === "maxLength" && <span id="Err_msg">{t('Max')} {BankInfoLength} {t('character')}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('OpenBankInfo02')} labelFor="inputBankACNo" labelInfo="*">
                    <InputGroup id="inputBankACNo" name="d_BankACNo" defaultValue={""} placeholder="" inputRef={register({ required: true , maxLength: BankACNoLength}) } />
                    {errors.d_BankACNo && errors.d_BankACNo.types && <span id="Err_msg">{errors.d_BankACNo.types.message}</span>}
                    {errors.d_BankACNo && errors.d_BankACNo.type === "required" && (<span id="Err_msg">{t('OpenBankInfo02')} {t('NotBlank')}</span>)}
                    {errors.d_BankACNo && errors.d_BankACNo.type === "maxLength" && <span id="Err_msg">{t('Max')} {BankACNoLength} {t('character')}</span>}
                </FormGroup>
            </div>

            <div className="Spacing-V-16"></div>
            <ul>
                <li>{t('BankNote01')}</li>
                <li>{t('BankNote02')}</li>
                {/* <li>{t('BankNote03')}</li> */}
            </ul>
            <div className="Spacing-V-16"></div>

            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree01')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree==="Y")} />
                    {errors.d_Agree && errors.d_Agree.types && <span id="Err_msg">{errors.d_Agree.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree02')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree2==="Y")} />
                    {errors.d_Agree2 && errors.d_Agree2.types && <span id="Err_msg">{errors.d_Agree2.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree03')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree3==="Y")} />
                    {errors.d_Agree3 && errors.d_Agree3.types && <span id="Err_msg">{errors.d_Agree3.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree04')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree4==="Y")} />
                    {errors.d_Agree4 && errors.d_Agree4.types && <span id="Err_msg">{errors.d_Agree4.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree05')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree5==="Y")} />
                    {errors.d_Agree5 && errors.d_Agree5.types && <span id="Err_msg">{errors.d_Agree5.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-7" label={t('Agree06')} labelFor="InputAgree">
                    <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree6==="Y")} />
                    {errors.d_Agree6 && errors.d_Agree6.types && <span id="Err_msg">{errors.d_Agree6.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-6" label={t('Remark')} labelFor="inputRemark">
                    <textarea className="bp3-input bp3-fill" name="d_Remark" rows={4} ref={register}></textarea>
                </FormGroup>
            </div>
        </div>}
        </form>
    )
}

interface RegMastMainProps {
    OpenAcc: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function RegMastMain({ OpenAcc }: RegMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    const [data, setData] = useState([] as Account[])

    const [aeList, setAeList] = useState([] as Account[])
    const [JurisdictionList, setJurisdictionList] = useState([] as Jurisdiction[])

    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)

    const [searchVerifiedEmail, setsearchVerifiedEmail] = useState("")
    const [searchAuthority, setsearchAuthority] = useState("")
    const [searchHaveAc, setsearchHaveAc] = useState("")
    const [searchExportGSB, setsearchExportGSB] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    
    const [ZIP_FILE, setZIP_FILE] = useState("")
    const [RptName, setRptName] = useState("")
    const [RptName2, setRptName2] = useState("")
    const [RptName3, setRptName3] = useState("")
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as Account)
    const [BlankAc, setBlankAc] = useState({} as Account)

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [IOANo, setIOANo] = useState("0")

    //OpenAcc default value
    BlankAc.d_sendemail = "Y"



    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("RegMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("RegMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("RegMastMain disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("RegMastMain disp OpenAccFlag: ", OpenAccFlag)
                //console.log("RegMastMain disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("RegMastMain disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("RegMastMain disp OpenAccFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("RegMastMain disp OpenAccFlag.MGNGP_USER: ", OpenAccFlag.MGNGP_USER)
                //console.log("RegMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                setcardOpen01("cardOpen01-Theme"+OpenAccFlag.DEF_THEME1);
                setIOANo(OpenAccFlag.IOANo)

                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])



    useEffect(() => {

        setnPage(1)
        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                // usually A/E code is prefixed with C, but some user may use others e.g. Upbest uses A for A/E
                // TODO: Read A/E prefix from config
                // fixed can read true A/E
                const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: "C",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                                aeList: "Y",
                            }
                        },
                        cPgmId: "OpenAcc",
                        cAction: "ListRec",
                    });
                setAeList(res.data.data)
                //setAeList(utils.sortByColumn(res.data.data, "d_acName1"))
                setshowButton(false)

            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])


    useEffect(() => {

        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                const res = await OpenAcc.post('/Master0',
                    {   sb_data: {
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "OpenAcc",
                        cAction: "ListJurisdiction",
                    });
                setJurisdictionList(res.data.data)
                console.log("ListJurisdiction res.data.data", res.data.data);
                console.log("ListJurisdiction JurisdictionList", JurisdictionList);
                setshowButton(false)
            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])


    const onSelectfile = (InputDateTime: string) => {
        if (InputDateTime.length > 0 ) {
            //console.log("onSelectfile fileName", fileName);
            //console.log("onSelectfile fileInfoList", fileInfoList);
            let newData = [...data]

            for (let j = 0; j < newData.length; j++) {
                if ( newData[j].d_inpputDate +" "+ newData[j].d_inpputTime == InputDateTime ) {
                    if ( newData[j].select == "Y" ) {
                        newData[j].select = "N"
                    } else {
                        newData[j].select = "Y"
                        setshowButton(true)
                    }
                    break
                }
            }
            setData(newData)
            
        }
    }

    const onAuthority = () => {
        setZIP_FILE("")
        setRptName("")
        setRptName2("")
        setRptName3("")
        auth_no = 0
        
        console.log("onAuthority data", data);
/*        
        for (let j = 0; j < data.length; j++) {
            if ( data[j].select == "Y" ) {
                (async () => {
                    try {
                        const res = await OpenAcc.post('/Master0', {sb_data: data[j], cPgmId: "OpenAcc", cAction: "Authority" })
                        //console.log("onAuthority res.data.ok", res.data.ok)
        
                        if (! res.data.ok) {
                            utils.showWarn(res.data.data)
                        } else {
                            auth_no++
                            //console.log("onAuthority auth_no", auth_no)
                        }
                    } catch (error) {
                        showErrorBox(error)
                    }
                })()
            }
        }
*/        
        (async () => {
            try {
                const res = await OpenAcc.post('/Master0', {
                    sb_data: data, 
                    cPgmId: "OpenAcc",
                    cAction: "Authority"
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onGenAcno = () => {
        setZIP_FILE("")
        setRptName("")
        setRptName2("")
        setRptName3("")
        
        console.log("onGenAcno data", data);
/*
        for (let j = 0; j < data.length; j++) {
            if ( data[j].select == "Y" ) {

                (async () => {
                    try {
                        const res = await OpenAcc.post('/Master0', {sb_data: data[j], cPgmId: "OpenAcc", cAction: "GenAcno" })
                        //console.log("onGenAcno res.data.ok", res.data.ok)
        
                        if (! res.data.ok) {
                            utils.showWarn(res.data.data)
                        }
                    } catch (error) {
                        showErrorBox(error)
                    }
                })()
            }
        }
*/        
        (async () => {
            try {
                const res = await OpenAcc.post('/Master0', {
                    sb_data: data, 
                    cPgmId: "OpenAcc",
                    cAction: "GenAcno"
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()

    }

    const onExpAcno = () => {
        setZIP_FILE("")
        setRptName("")
        setRptName2("")
        setRptName3("")
        
        console.log("onExpAcno data", data);

/*        for (let j = 0; j < data.length; j++) {
            if ( data[j].select == "Y" ) {

                (async () => {
                    try {
                        const res = await OpenAcc.post('/Master0', {sb_data: data[j], cPgmId: "OpenAcc", cAction: "ExpAcno" })
                        //console.log("onExpAcno res.data.ok", res.data.ok)
        
                        if (! res.data.ok) {
                            utils.showWarn(res.data.data)
                        }
                    } catch (error) {
                        showErrorBox(error)
                    }
                })()
            }
        }
*/
        (async () => {
            try {
                const res = await OpenAcc.post('/Master0', {
                    sb_data: data, 
                    cPgmId: "OpenAcc",
                    cAction: "ExpAcno"
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
                if ( res.data.count > 0 ) {
                    setZIP_FILE(res.data.ZIP_FILE)
                    setRptName(res.data.RptName)
                    setRptName2(res.data.RptName2)
                    setRptName3(res.data.RptName3)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()

    }


    const onExptoSB = () => {
        setZIP_FILE("")
        setRptName("")
        setRptName2("")
        setRptName3("")
        
        console.log("onExpAcno data", data);

/*        for (let j = 0; j < data.length; j++) {
            if ( data[j].select == "Y" ) {

                (async () => {
                    try {
                        const res = await OpenAcc.post('/Master0', {sb_data: data[j], cPgmId: "OpenAcc", cAction: "ExpAcno" })
                        //console.log("onExpAcno res.data.ok", res.data.ok)
        
                        if (! res.data.ok) {
                            utils.showWarn(res.data.data)
                        }
                    } catch (error) {
                        showErrorBox(error)
                    }
                })()
            }
        }
*/
        (async () => {
            try {
                const res = await OpenAcc.post('/Master0', {
                    sb_data: data, 
                    cPgmId: "OpenAcc",
                    cAction: "ExpToSB"
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }

            } catch (error) {
                utils.showError(error)
            }
        })()

    }


    const PrevRecProcess = async (ac: Account) => {
        setZIP_FILE("")
        setRptName("")
        setRptName2("")
        setRptName3("")
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "OpenAcc", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }

    const DeleteProcess = async (ac: Account) => {
        setZIP_FILE("")
        setRptName("")
        setRptName2("")
        setRptName3("")
        try {
            //console.log("call DeleteProcess", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "OpenAcc", cAction: "DelRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }

    const NextRecProcess = async (ac: Account) => {
        setZIP_FILE("")
        setRptName("")
        setRptName2("")
        setRptName3("")
        try {
            //console.log("call NextRecProcess", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "OpenAcc", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }


    const newOnSave = async (ac: Account) => {
        setZIP_FILE("")
        setRptName("")
        setRptName2("")
        setRptName3("")
        try {
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "OpenAcc", cAction: "AddRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }

    const editOnSave = async (ac: Account) => {
        setZIP_FILE("")
        setRptName("")
        setRptName2("")
        setRptName3("")
        try {
            console.log("call editOnSave", ac)
            const res = await OpenAcc.post('/Master', {sb_data: ac, cPgmId: "OpenAcc", cAction: "UpdateRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            showError(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setZIP_FILE("")
        setRptName("")
        setRptName2("")
        setRptName3("")
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            //m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await OpenAcc.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                            VerifiedEmail: searchVerifiedEmail,
                            Authority: searchAuthority,
                            HaveAc: searchHaveAc,
                            ExportGSB: searchExportGSB,
                        },
                    },
                    cPgmId: "OpenAcc",
                    cAction: "ListRec",
                });

                console.log("ListRec res.data.count", res.data.count)
                console.log("ListRec res.data.data", res.data.data)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
                setshowButton(false)
            } else {
                const res = await OpenAcc.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "OpenAcc",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                    setshowButton(false)
                } else {
                    alert(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            showError(error)
        }
    }

    // lookup account if acCode is passed
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setZIP_FILE("")
            setRptName("")
            setRptName2("")
            setRptName3("")
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            SortByName: false,
                        },
                        cPgmId: "OpenAcc",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
                setshowButton(false)
            })()
        }
    }, [OpenAcc, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if (nPage > 0 && nPage <= nTotalPage) {
    
            setShowWait(true)
            setZIP_FILE("")
            setRptName("")
            setRptName2("")
            setRptName3("")
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                VerifiedEmail: searchVerifiedEmail,
                                Authority: searchAuthority,
                                HaveAc: searchHaveAc,
                                ExportGSB: searchExportGSB,
                            },
                            },
                        cPgmId: "OpenAcc",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }
                    setShowWait(false)
                    setshowButton(false)
        
                } catch (error) {
                    showError(error)
                }
            })()
        }
    }, [OpenAcc, nPage])

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <RegMastView OpenAcc={OpenAcc} ac={currentAc} baseUrl={OpenAcc.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></RegMastView>
    switch (currentMode) {
        case "edit":
            detail = <RegMastEdit OpenAcc={OpenAcc} ac={currentAc} aeList={aeList} JurisdictionList={JurisdictionList} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></RegMastEdit>
            break
        case "new":
            detail = <RegMastEdit OpenAcc={OpenAcc} ac={BlankAc} aeList={aeList} JurisdictionList={JurisdictionList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></RegMastEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            {/* <Button className="Col-1-1" disabled={true} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>  */}
            <Button disabled={! showButton} className="Col-1-1" icon="confirm" onClick={() => { onAuthority() } }>Authority</Button> 
            <Button disabled={! showButton} className="Col-2-2" icon="send-to-map" onClick={() => { onGenAcno() } }>Generate A/C No.</Button> 
            { (userKey == "ASTRUM") && ( IOANo == "0" || IOANo == "2" ) && <Button disabled={! showButton} className="Col-4-2" icon="send-to-map" onClick={() => { onExpAcno() } }>Export CSV to GSB</Button>  }
            { ! (userKey == "ASTRUM") && <Button disabled={! showButton} className="Col-4-2" icon="send-to-map" onClick={() => { onExpAcno() } }>Export CSV to GSB</Button>  }
            {/* RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download csv GSB</a> */}



            {/*<Button disabled={! showButton} className="Col-4-2" icon="send-to-map" onClick={() => { onExptoSB() } }>Open to sb</Button>*/}

            {ZIP_FILE.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${ZIP_FILE}`}>Download imp_all zip hkidr GSBx</a>}
            {/*RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download Txt GSBx</a>*/}
            {/*RptName3.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${RptName3}`}>Download TXT HKIDRCRS</a>*/}
            {RptName2.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${RptName2}`}>Download csv CRS</a>}

            {/*<Button className="Col-4-2" icon="send-to-map" onClick={() =>  {onExGSBX()} }>Export o GSBX</Button>*/}
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-Select">Select</th>
                <th className="TCol-ACCode">Account</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-IDNo">ID No.</th>
                <th className="TCol-Mobile">Mobile</th>
                <th className="TCol-Email">Email</th>
                <th className="TCol-AECODE">Runner</th>
                <th className="TCol-AECODE">Commission</th>
                <th className="TCol-InputDate">Input Date & Time</th>
                <th className="TCol-EmailVDate">Email Verify Date & Time</th>
                <th className="TCol-AuthDate">Authority Date & Time</th>
                <th className="TCol-ExportDate">Export Date & Time</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((ac, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-Select"><Checkbox checked={ac.select=="Y"} onChange={() => onSelectfile(ac.d_inpputDate +" "+ ac.d_inpputTime)} /></td>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acName1}</td>
                        <td className="TCol-IDNo" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_idNo}</td>
                        <td className="TCol-Mobile" onClick={() => { setCurrentAc(ac) }}>{ac.d_mobile}</td>
                        <td className="TCol-Email" onClick={() => { setCurrentAc(ac) }}>{ac.d_email}</td>

                        <td className="TCol-Email" onClick={() => { setCurrentAc(ac) }}>{ac.d_aeCode}</td>
                        <td className="TCol-Email" onClick={() => { setCurrentAc(ac) }}>{ac.d_commCode}</td>

                        <td className="TCol-InputDate" onClick={() => { setCurrentAc(ac) }}>{ac.d_inpputDate +" "+ ac.d_inpputTime }</td>
                        <td className="TCol-EmailVDate" onClick={() => { setCurrentAc(ac) }}>{ac.d_verify_Date +" "+ ac.d_verify_Time }</td>
                        <td className="TCol-AuthDate" onClick={() => { setCurrentAc(ac) }}>{ac.d_Authority_date +" "+ ac.d_Authority_time }</td>
                        <td className="TCol-ExportDate" onClick={() => { setCurrentAc(ac) }}>{ac.d_Export_AC_date +" "+ ac.d_Export_AC_time }</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="Account Registry Master" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="OpenDate">Create DateTime</option>
                                    <option value="acCode">Account No.</option>
                                    <option value="Idno">Id No.</option>
                                    <option value="Email">Email</option>
                                    <option value="acName">Name</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-2 Button-LabeledFormGroup" icon="search" type="submit" >Search/List</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        <Button className="Col-8-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            <FormGroup className="Col3-1-2" label="Verified Email" labelFor="searchVerifiedEmail">
                                <select  id="searchVerifiedEmail" value={searchVerifiedEmail} onChange={utils.handleStringChange(s => setsearchVerifiedEmail(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>
                            <FormGroup className="Col3-3-1" label="Authority" labelFor="searchAuthority">
                                <select  id="searchAuthority" value={searchAuthority} onChange={utils.handleStringChange(s => setsearchAuthority(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>
                            <FormGroup className="Col3-4-1" label="Have A/C No" labelFor="searchHaveAc">
                                <select  id="searchHaveAc" value={searchHaveAc} onChange={utils.handleStringChange(s => setsearchHaveAc(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>
                            <FormGroup className="Col3-5-1" label="Export GSB" labelFor="searchExportGSB">
                                <select  id="searchExportGSB" value={searchExportGSB} onChange={utils.handleStringChange(s => setsearchExportGSB(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>
            </form>
        </>
    );
}

export default RegMastMain