import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, ProgressBar } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';

import svgWait1 from './svg/wait1.svg'
import svgWait2 from './svg/wait2.gif'



interface RptRstInfo {
    Rst_Key: string,
    Rst_Line: string,
}

interface PageInfo {
    n: number
}

var OpenAccFlag: any;


interface UserMastListMainProps {
    OpenAcc: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function UserMastListMain({ OpenAcc }: UserMastListMainProps) {

    let ProgressId2 = ""
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />

    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)

    const [Rstdata, setRstdata] = useState([] as RptRstInfo[])
    const [Rstdata2, setRstdata2] = useState([] as RptRstInfo[])
    const [RptHead1, setRptHead1] = useState("")
    const [RptHead2, setRptHead2] = useState("")
    const [RptName, setRptName] = useState("")
    const [RptNameNo, setRptNameNo] = useState(0)
    const [aRptNameNo, setaRptNameNo] = useState([] as PageInfo[])
    const [ShowWait, setShowWait] = useState(false)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)
    const [ShowOK, setShowOK] = useState(true)
 
    const [s_Userid1, sets_Userid1] = useState("")
    const [s_Userid2, sets_Userid2] = useState("")
    const [ExcelFormatValue, setExcelFormatValue] = useState(false)
    const [CSVFormat, setCSVFormat] = useState("0")
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    //const [cPrintMode, setcPrintMode] = useState("PS")

    const [LineLimit, setLineLimit] = useState(54 - 7)

    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("N")

    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp OpenAccFlag: ", OpenAccFlag)
                //console.log("SmMastMain disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("SmMastMain disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("SmMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setCSVFormat(OpenAccFlag.CSVFormat) ;
                setuserKey(OpenAccFlag.user_key) ;
                setcardOpen01("cardOpen01-Theme"+OpenAccFlag.DEF_THEME1);
                setEnter2tab(OpenAccFlag.Enter2tab);

            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    },  [OpenAccFlag, Enter2tab])


    
    useEffect(() => {
        setShowRpt(false)
        setShowNoRecord(false)
        setShowOK(true)
    }, [s_Userid1, s_Userid2, ExcelFormatValue])

    useEffect(() => {
        const refreshTasks = async () => {
            ProgressId2 = ProgressId
            if ( ProgressId2.trim().length > 0 ) {
                try {
                    const res = await OpenAcc.post('/Progresslist', {p_data: ProgressId2})
                    let pValue = 0
                    if ( res.data.count > 0 ) {
                        if ( res.data.data[0].id === ProgressId2 ) {
                            pValue = res.data.data[0].progressValue / res.data.data[0].progressMax
                            //console.log("Progresslist: ", res.data.data[0].progressValue, res.data.data[0].progressMax) 
                            //console.log("Progresslist pValue: ", pValue) 
                            setProgressValue(pValue)
                        }
                        if (res.data.data[0].status > 0 ) {
                            clearInterval(timer)
                        }
                    }
                } catch (error) {
                    utils.showError(error)
                }
            }
        }
        let timer = setInterval(refreshTasks, 20*1000)
        return () => {clearInterval(timer)}
    }, [OpenAcc, ProgressId, nPage])

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        sets_Userid1(s_Userid1.toUpperCase());
        sets_Userid2(s_Userid2.toUpperCase());

        setShowOK(false)
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)
        setRstdata([])
        setRptHead1("")
        setRptHead2("")
        setRptName("")
        setRptNameNo(0)

        setnPage(1)
        ProgressId2 = utils.genUUID()
        setProgressId(ProgressId2)
        setProgressValue(0)

        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                //console.log("ClAccMastMain onsubmit called", data)
                const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            ProgressId: ProgressId2,
                            s_Userid1: s_Userid1,
                            s_Userid2: s_Userid2,
                            ExcelFormat: ExcelFormatValue,
                            nPage: 1,
                            cPrintMode: "PS",
                            limit: LineLimit,
                        },
                        cPgmId: "UserMast",
                        cAction: "UserMastList",
                    });

                //console.log("res.data.data: ", res.data.data) 
                if ( res.data.count > 0 ) {
                    setRstdata2([])
                    for (let j = 0; j < res.data.Rptdata.length; j++) {
                        Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                    }
                    setShowWait(false)
                    setShowNoRecord(false)
                    setShowRpt(true)
                    setRstdata(Rstdata2)
                    console.log("Rstdata: ", Rstdata) 
                    setRptHead1(res.data.Rpthead1)
                    setRptHead2(res.data.Rpthead2)
                    setRptName(res.data.RptName)
                    setRptNameNo(res.data.RptNameNo)
                    //console.log("RptName: ", RptName) 
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / LineLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / LineLimit); j++ ) {
                            aPageNumber1.push({n: j});
                       }
                        setaPageNumber(aPageNumber1)
                        if (res.data.RptNameNo > 0 ) {
                            for ( let j = 1 ; j <= res.data.RptNameNo; j++ ){
                                aRptNameNo.push({n: j});
                            }
                            setRptName(res.data.RptName.replace(".pdf",""))
                        }                        
                    }
                } else {
                    setShowWait(false)
                    setShowNoRecord(true)
                    setShowRpt(false)
                }
            } catch (error) {
                showError(error)
            }
        })()
    }


    //Press page No 1 2 3 4
    useEffect(() => {
        if (nPage > 0 && nPage <= nTotalPage && RptName.trim().length > 0) {
            // console.log("calling API for Pagination");
            setShowWait(true)
            setShowNoRecord(false)
            setShowRpt(false)
            setRstdata([])
    
            ProgressId2 = utils.genUUID()
            setProgressId(ProgressId2)
            setProgressValue(0)
            
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("ClAccMastMain useEffect nPage > 0  ", nPage)
                    const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            ProgressId: ProgressId2,
                            s_Userid1: s_Userid1,
                            s_Userid2: s_Userid2,
                            ExcelFormat: ExcelFormatValue,
                            nPage: nPage,
                            cPrintMode: "S",
                            limit: LineLimit,
                        },
                        cPgmId: "UserMast",
                        cAction: "UserMastList",
                    });

                    //console.log("res.data.data: ", res.data.data) 
                    if ( res.data.count > 0 ) {
                        setRstdata2([])
                        for (let j = 0; j < res.data.Rptdata.length; j++) {
                            Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                        }
                        setShowWait(false)
                        setShowNoRecord(false)
                        setShowRpt(true)
                        setRstdata(Rstdata2)
                        //console.log("Rstdata: ", Rstdata) 
                    } else {
                        setShowWait(false)
                        setShowNoRecord(true)
                        setShowRpt(false)
                    }
                } catch (error) {
                    showError(error)
                }
            })()
        }
    }, [OpenAcc, nPage])

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} &nbsp; </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} &nbsp; </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} &nbsp; </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} &nbsp; </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} &nbsp; </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    let RptNameaRef = <>
        { RptNameNo > 0 && <div> Download / Print &nbsp;
            {aRptNameNo.map((no, j) => 
                <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${RptName}-${no.n}.pdf`}> <span id="RPageNo2" onClick={() => {} }> {no.n} &nbsp; </span> </a>
            )}
        </div> }
        { RptNameNo <= 0 && <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print </a>}
    </>

    let list = <>
        <div className="Spacing-V-16" />
        {/* {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print </a>} */}
        {RptName.trim().length > 0 && RptNameaRef}
        {ShowRpt && ! ExcelFormatValue && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead id="rpt_th_r">
                <tr >
                    { ! ExcelFormatValue && <th className="RCol-Head_p">
                        {/* {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print</a>} */}
                        {RptName.trim().length > 0 && RptNameaRef}
                    </th>
                    }
                </tr>                    
                <tr>
                    <td className="RCol-Head1">{RptHead1}</td>
                </tr>                    
            </thead>
            <tbody id="rpt_tr">
                {Rstdata.map((rpt, i) =>
                    <tr key={i} onDoubleClick={() => { }}>
                        <td className="RCol-Rpt" onClick={() => {  }}>{rpt.Rst_Line}</td>
                    </tr>
                )}
            </tbody>
            <div className="Spacing-V-16" />
            {ShowRpt && ! ExcelFormatValue && Pagination}
        </HTMLTable>
    </>

    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
    <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                User List
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Start User id" labelFor="inputUserid1">
                            <InputGroup
                                id="inputUserid1"
                                autoFocus
                                placeholder=" User id ..."
                                value={s_Userid1}
                                onChange={utils.handleStringChange(s => sets_Userid1(s))}
                            />
                        </FormGroup>
                        {/*
                        <FormGroup className="Col-3-2" label="User id" labelFor="inputUserid2">
                            <InputGroup
                                id="inputUserid2"
                                placeholder=" User id ..."
                                value={s_Userid2}
                                onChange={utils.handleStringChange(s => sets_Userid2(s))}
                            />
                        </FormGroup> */}
                    </div>
                    <div className="Row">
                        { ! (CSVFormat === "1") && <Switch  id="inputExcelFormat" label="" innerLabelChecked="EXCEL(TAB)" innerLabel="PDF" checked={ExcelFormatValue} onChange={utils.handleBooleanChange(v => setExcelFormatValue(v))} /> }
                        { (CSVFormat === "1") && <Switch  id="inputExcelFormat" label="" innerLabelChecked="EXCEL(CSV)" innerLabel="PDF" checked={ExcelFormatValue} onChange={utils.handleBooleanChange(v => setExcelFormatValue(v))} /> }
                        Format
                    </div>
                    <div className="Row">
                        <Button disabled={! ShowOK} type="submit">OK</Button>
                    </div>
                </Card>
                {/* {ShowWait && <div>{imgWait}</div> } */}
                {ShowWait && <div className="TCol-Progress2">Progress ...</div> }
                {ShowWait && <div className="TCol-Progress2"><ProgressBar stripes={false} animate={false} value={ProgressValue} /></div> }
                {ShowNoRecord && <p>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-3"  >
                            <span id="Err_msg">No Record found </span>
                        </FormGroup>
                    </div>
                </p>
                }
                {ShowRpt && list}
            </form>
        </>
    );
}

export default UserMastListMain