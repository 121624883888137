import React, { useState, useEffect } from 'react';
import './App.scss';

import { HashRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { History } from 'history'

import { FormGroup, AnchorButton, Button, Navbar, Alignment, Popover, Menu, MenuItem, Position, Spinner } from "@blueprintjs/core";

import './OpenAccx.scss';
import Login, { LoginCredential } from './Login';
import ClAccMastMain from './ClAccMast';
import ClACListMain from './ClACList';
import ClLogMain from './ClLog';
import AccInfoMain from './AccInfo';
import RegMastMain from './RegMast';
import RegMastListMain from './RegMastList';
import RegMastViewMain from './RegMastView';
import RegLogMain from './RegLog';
import SBReindexMain from './SBReindex';
import SysConfigMain from './SysConfig';
import UserMastMain from './UserMast';
import UserMastListMain from './UserMastList';
import UserMastLogMain from './UserMastLog';
import UserchgPasswdMain from './UserchgPasswd';
import UserchgPasswd2Main from './UserchgPasswd2';
import UserAccessGroupMain from './UserAccessGroup';
import SBBackupMain from './SBBackup';
import LoginLogMain from './LoginLog';
import ResetprocessMain from './Resetprocess';
import ExportIOAprocessMain from './ExportIOAprocess';
import ExportIOA2processMain from './ExportIOA2process';
import AboutMain from './About';
import RegFormAstrumMain from './RegFormAstrum';
import RegFormDemo1Main from './RegFormDemo1';
import RegFormDemo2Main from './RegFormDemo2';
import Test01Main from './Test01';
import Test02Main from './Test02';
import Test03Main from './Test03';
import Test04Main from './Test04';
import DocumentUpload2Main from './DocumentUpload2';
import TaskMain from './Task';
import * as utils from "./utils";

import md5 from './md5'

import axios from 'axios';

import svgLogo from './svg/logo.svg'
import svgAccount from './svg/client.svg'
import svgMaster from './svg/Master.svg'
import svgDocument from './svg/document.svg'
import svgImport from './svg/import.svg'
import svgUser from './svg/user.svg'
import svgInput from './svg/input.svg'
import svgTask from './svg/task.svg'
import svgbuild from './svg/build.svg'
import svgsort_asc from './svg/sort-asc.svg'

import i18next from 'i18next';

var OpenAccFlag: any;

const OpenAcc = axios.create({
    //baseURL: 'http://localhost:8000',
    baseURL: process.env.NODE_ENV === "development" ? `http://${window.location.hostname}:8051/api` : '/api',
})

const hostId = process.env.NODE_ENV === "development" ? md5(`${window.location.hostname}:8051`).slice(-8) : md5(window.location.host).slice(-8)

// try load config from server, can be a real file or a config in web server
/*
axios.get("config.json").then((res) => {
    //console.log("load config.json from server", res.data)
    OpenAcc.defaults.baseURL = res.data.baseUrl
}).catch(() => {
    // do nothing, use default
})
*/


function App() {

    const pingServer = () => {
        (async () => {
            try {
                await OpenAcc.post('/ping',
                    {});
                //console.log(res.data)
            } catch (error) {
                //utils.showError(error)
                //console.log(error)
            }
        })()
    }

    useEffect(() => {
        pingServer()
        const worker = new Worker('worker.js')
        worker.onmessage = () => {
            pingServer()
        }
        return () => worker.terminate()
    }, [])


    window.onkeydown = (e: KeyboardEvent) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // disable ctrl + s to prevent save page when user trying to submit form while lost focus
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
        }
    }
    const [IPAddr, setIPAddr] = useState("")
    const [userId, setuserId] = useState("")

/*
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('http://api.ipify.org/?format=json')
                const dataIP = await response.json();
                setIPAddr(dataIP["ip"])
                //console.log("App  IPAddr: ", data["ip"])    
            } catch (error) {
                utils.showError(error)
            }
        })() 
    }, [OpenAcc])
*/

    const handleLogin = (credential: LoginCredential, history: History) => {
        (async () => {
            try {
                const res = await OpenAcc.post('/login',
                    {
                        userId: credential.login,
                        password: credential.password,
                        cIPAddr: IPAddr,
                    });
                if (res.data.ok) {
                    //console.log('Bearer '+res.data.token)
                    OpenAcc.defaults.headers = {
                        'Authorization': 'Bearer ' + res.data.token
                    }
                    localStorage.setItem('token', res.data.token)
                    document.cookie = `token_${hostId}=${res.data.token}`
                    setIsLoggedIn(true)
                    setuserId(credential.login)
                    history.push("/")
                } else {
                    //console.log(res.data)
                    utils.showError(res.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const handleLogout = () => {
        (async () => {
            try {
                const res = await OpenAcc.post('/logout',
                {
                    cIPAddr: IPAddr,
                });
            } catch (error) {
                utils.showError(error)
            }
        })()

        OpenAcc.defaults.headers = {}
        localStorage.removeItem('token')
        document.cookie = `token_${hostId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
        setIsLoggedIn(false)
    }

/*
    const token = localStorage.getItem('token')
    let initIsLoggedIn = false
    if (token) {
        OpenAcc.defaults.headers = {
            'Authorization': 'Bearer ' + token
        }
        document.cookie = `token=${token}`
        initIsLoggedIn = true
    }
*/

    let initIsLoggedIn = false

    useEffect(() => {
        console.log("useEffect []");
        const token = localStorage.getItem('token')
        if (token) {
            /*
            svc.defaults.headers = {
                'Authorization': 'Bearer ' + token
            }
            */
            OpenAcc.defaults.headers["Authorization"] = 'Bearer ' + token
            document.cookie = `token_${hostId}=${token}`
            setIsLoggedIn(true);
            initIsLoggedIn = true
        }
    }, [])

    const [isLoggedIn, setIsLoggedIn] = useState(initIsLoggedIn)
    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const { t, i18n } = useTranslation();   

    const [userKey, setuserKey] = useState("")
    const [isIE, setisIE] = useState(false)


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag0');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                i18next.changeLanguage("en") ;

                //IEdected();
                setisIE(false);

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [OpenAcc])

    function IEdected() {
        if ( navigator.userAgent.indexOf('MSIE')!==-1 || navigator.userAgent.indexOf('Trident/') > -1 ) {
            //console.log('IEdected', isIE)
            setisIE(true);
            return true;
        }
        console.log('IEdected', isIE)
        return false;
    }


    return (
        <React.Suspense fallback={<Spinner></Spinner>}>
        <div id="app" className="App">
            {/* <div>{navigator.userAgent}</div> */}
            { isIE && <div >
                <div><span >Error : </span></div>
                <div><span >This OpenAcc system does not support Internet Explorer </span></div>
                <div><span >Please use another browser </span></div>
            </div> }
            {! isIE && <Router>
                <MenuBar isLoggedIn={isLoggedIn} />
                <div className="AppBody">
                    <Switch>
                        { userKey == "ASTRUM" && <Route exact path="/">
                            {isLoggedIn ? <SideMenu /> : <RegFormAstrumMain OpenAcc={OpenAcc} />}
                        </Route> }
                        { userKey == "DEMO" && <Route exact path="/">
                            {isLoggedIn ? <SideMenu /> : <RegFormDemo2Main OpenAcc={OpenAcc} />}
                        </Route> }
                        <Route exact path="/login">
                            <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />
                        </Route>
                        <Route path="/accounts/:acCode?">
                            {isLoggedIn ? <ClAccMastMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClACList">
                            {isLoggedIn ? <ClACListMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClLog">
                            {isLoggedIn ? <ClLogMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/AccInfo">
                            {isLoggedIn ? <AccInfoMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/RegMast">
                            {isLoggedIn ? <RegMastMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/RegMastList">
                            {isLoggedIn ? <RegMastListMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/RegMastView">
                            {isLoggedIn ? <RegMastViewMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/RegMastView2">
                            <RegMastViewMain OpenAcc={OpenAcc} />
                        </Route>
                        <Route path="/RegLog">
                            {isLoggedIn ? <RegLogMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBReindex">
                            {isLoggedIn ? <SBReindexMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SysConfig">
                            {isLoggedIn ? <SysConfigMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserMast">
                            {isLoggedIn ? <UserMastMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserMastList">
                            {isLoggedIn ? <UserMastListMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserMastLog">
                            {isLoggedIn ? <UserMastLogMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserchgPasswd">
                            {isLoggedIn ? <UserchgPasswdMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserchgPasswd2">
                            {isLoggedIn ? <UserchgPasswd2Main OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserAccessGroup">
                            {isLoggedIn ? <UserAccessGroupMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBBackup">
                            {isLoggedIn ? <SBBackupMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/LoginLog">
                            {isLoggedIn ? <LoginLogMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Resetprocess">
                            {isLoggedIn ? <ResetprocessMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ExportIOAprocess">
                            {isLoggedIn ? <ExportIOAprocessMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ExportIOA2process">
                            {isLoggedIn ? <ExportIOA2processMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/About">
                            {isLoggedIn ? <AboutMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        { userKey == "ASTRUM" && <Route exact path="/regformastrum">
                            <RegFormAstrumMain OpenAcc={OpenAcc} />
                        </Route> }
                        { userKey == "DEMO" && <Route exact path="/regformdemo1">
                            <RegFormDemo1Main OpenAcc={OpenAcc} />
                        </Route> }
                        { userKey == "DEMO" && <Route exact path="/regformdemo2">
                            <RegFormDemo2Main OpenAcc={OpenAcc} />
                        </Route> }
                        { userKey == "DEMO" && <Route exact path="/test01">
                            <Test01Main OpenAcc={OpenAcc} />
                        </Route> }
                        { userKey == "DEMO" && <Route exact path="/test02">
                            <Test02Main OpenAcc={OpenAcc} />
                        </Route> }
                        { userKey == "DEMO" && <Route exact path="/test03">
                            <Test03Main OpenAcc={OpenAcc} />
                        </Route> }
                        { userKey == "DEMO" && <Route exact path="/test04">
                            <Test04Main OpenAcc={OpenAcc} />
                        </Route> }
                        <Route exact path="/DocumentUpload">
                            <DocumentUpload2Main OpenAcc={OpenAcc} />
                        </Route>
                        <Route path="/tasks">
                            {isLoggedIn ? <TaskMain OpenAcc={OpenAcc} /> : <Login OpenAcc={OpenAcc} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                    </Switch>
                </div>
            </Router> }
        </div>
        </React.Suspense>
    );
}

function SideMenu() {

    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />
    
    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const { t, i18n } = useTranslation();    
    const [userKey, setuserKey] = useState("")

    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setSETL_DATE(OpenAccFlag.SETL_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [OpenAcc])


    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>

    return (
        <>
            <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>
            <div className="Row">
                <Link to="/RegMast">
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgAccount} alt="Account" />
                        <div>{t('Accounts')}</div>
                    </div>
                </Link>
            </div>
            <div className="Spacing-V-16"></div>
        </>
    )
}

interface MenuBarProps {
    isLoggedIn?: boolean
}

function MenuBar({ isLoggedIn = false } : MenuBarProps) {

    const [showSysAdmin, setshowSysAdmin] = useState(false)
    const [showGeneralUser, setshowGeneralUser] = useState(false)

    const [showExport, setshowExport] = useState(false)

    const [Sys_VerNo, setSys_VerNo] = useState("")
    const [userId, setuserId] = useState("")
    const [showPgmName, setshowPgmName] = useState("")
    const [IPAddr, setIPAddr] = useState("")

    const [MULTI_MKT, setMULTI_MKT] = useState(false)
    const [SING_CURR, setSING_CURR] = useState(false)

    const { t, i18n } = useTranslation();    
    const [userKey, setuserKey] = useState("")
    const [IOANo, setIOANo] = useState("0")

    
    useEffect(() => {
        if ( isLoggedIn ) {
            (async () => {
                try {
                    // get OpenAccFlag from SB.XBS
                    const res = await OpenAcc.post('/OpenAccFlag');
                    //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                    OpenAccFlag = res.data.Flag;
                    //console.log("SmMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                    setSys_VerNo("v"+OpenAccFlag.Sys_VerNo)
                    setuserKey(OpenAccFlag.user_key) ;
                    setuserId( OpenAccFlag.UserID)
                    setMULTI_MKT(OpenAccFlag.MULTI_MKT)
                    setSING_CURR(OpenAccFlag.SING_CURR)
                    setIOANo(OpenAccFlag.IOANo)
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [OpenAcc, isLoggedIn])


    useEffect(() => {
        setshowSysAdmin(false)
        setshowGeneralUser(false)

        ;
{/* */}
        if ( isLoggedIn ) {
            (async () => {
                try {
                    // get UserAccessGroupInfo
                    const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            cUserid2: userId,
                        },
                        cPgmId: "UserAccessGroup",
                        cAction: "GetUserAccessGroupInfo",
                        cUserid2: userId,
                    });
                    //console.log("GetUserAccessGroupInfo res.data.data", res.data.data)
                    if ( res.data.count > 0 ) {
                        setshowSysAdmin( res.data.data[0].d_User_Group1=="Y")
                        setshowGeneralUser( res.data.data[0].d_User_Group2=="Y")
                    }
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }


        //setshowSysAdmin(true)
        //setshowGeneralUser(true)

        {/*
        if ( userId == "Q                   " )
        {
            setshowSysAdmin(true)
        } else {
            setshowGeneralUser(true)
        }
        */}

        if (userKey == "ASTRUM") {
            setshowExport(true)
        }
        if (userKey == "DEMO") {
            setshowExport(true)
        }


    }, [OpenAcc, isLoggedIn, userId, OpenAccFlag])

/*
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('http://api.ipify.org/?format=json')
                const data = await response.json();
                setIPAddr(data["ip"])
                //console.log("App  IPAddr: ", data["ip"])    
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [OpenAcc, isLoggedIn])
*/

    useEffect(() => {
        if ( isLoggedIn && showPgmName.length > 0 ) {
            (async () => {
                try {
                    // get OpenAccFlag from SB.XBS
                    const res = await OpenAcc.post('/PgmEntry', {
                        cPgmName: showPgmName,
                        cIPAddr: IPAddr,
                    });
                    setshowPgmName("")
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [OpenAcc, showPgmName, isLoggedIn])
    

    const imgLogo = <img className="AppLogo" src={svgLogo} alt="Logo" />
    const imgAccount = <img className="MenuIcon" src={svgAccount} alt="Account" />
    const imgMaster = <img className="MenuIcon" src={svgMaster} alt="Master" />
    const imgDocument = <img className="MenuIcon" src={svgDocument} alt="Document" />
    const imgTransaction = <img className="MenuIcon" src={svgInput} alt="Transaction" />
    const imgImport = <img className="MenuIcon" src={svgImport} alt="Import" />
    const imgUser = <img className="MenuIcon" src={svgUser} alt="Login" />
    const imgTask = <img className="MenuIcon" src={svgTask} alt="Task" />
    const imgbuild = <img className="MenuIcon" src={svgbuild} alt="Utility" />
    const imgsort_asc = <img className="MenuIcon" src={svgsort_asc} alt="SBReindex" />
    const history = useHistory()

   
    const menuMaster =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                { showGeneralUser &&
                <MenuItem icon={imgAccount} text="Account Master">
                    <MenuItem icon={imgAccount} text="Account Registry Master" onClick={() => { setshowPgmName("RegMast") }} href="#/RegMast" />
                    <MenuItem icon={imgAccount} text="Account Registry Master List" onClick={() => { setshowPgmName("RegMastList") }} href="#/RegMastList" />
                    <MenuItem icon={imgDocument} text="Account Registry Audit Log" onClick={() => { setshowPgmName("RegLog") }} href="#/RegLog" />
                </MenuItem>
                }
            </Menu>
        }>
            <Button minimal icon={imgMaster} text="Master" />
        </Popover>

    const menuTransaction =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>

            </Menu>
        }>
            <Button minimal icon={imgTransaction} text="Transaction" />
        </Popover>

    const menuReport =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>

            </Menu>
        }>
            {/* remark */}
            <Button minimal icon={imgDocument} text="Report" />
        </Popover>

    const menuSysMain =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                {/* */}
                <MenuItem disabled={false} icon={imgsort_asc} text={t('Reindex')} onClick={() => { setshowPgmName("Reindex") }} href="#/SBReindex" />
                <MenuItem icon={imgDocument} text="Backup , Restore Processing" onClick={() => { setshowPgmName("Backup Processing") }} href="#/SBBackup"/>
                { showSysAdmin &&
                    <MenuItem icon={imgDocument} text="Login Log" onClick={() => { setshowPgmName("LoginLog") }} href="#/LoginLog" />
                }
                <MenuItem icon={imgDocument} text="Reset Process" onClick={() => { setshowPgmName("Resetprocess") }} href="#/Resetprocess" />
                { showExport && showGeneralUser && ( IOANo == "1" || userKey == "DEMO" ) &&
                    <MenuItem icon={imgDocument} text="Export to IOA2 Process" onClick={() => { setshowPgmName("ExportIOAprocess") }} href="#/ExportIOAprocess" />
                }
                { showExport && showGeneralUser && ( IOANo == "2" || userKey == "DEMO" ) &&
                    <MenuItem icon={imgDocument} text="Import from IOA1 Process" onClick={() => { setshowPgmName("ExportIOA2process") }} href="#/ExportIOA2process" />
                }
                { showExport && ( userKey == "ASTRUM" ) && ( IOANo == "2" ) &&
                    <MenuItem icon={imgDocument} text="Goto IOA1" onClick={() => { window.open(`https://ioa-astrum.infocenter.com.hk/#/login`, '_self') }} />
                }
                { showSysAdmin && 
                    <MenuItem icon={imgDocument} text="System Configuration" onClick={() => { setshowPgmName("System Configuration") }} href="#/SysConfig" />
                }
                { showSysAdmin &&
                    <MenuItem icon={imgAccount} text="User Master">
                        <MenuItem icon={imgDocument} text="User Master Setup" onClick={() => { setshowPgmName("User Master Setup") }} href="#/UserMast" />
                        <MenuItem icon={imgDocument} text="User List" onClick={() => { setshowPgmName("User List") }} href="/#UserMastList" />
                        <MenuItem icon={imgDocument} text="User Audit Log" onClick={() => { setshowPgmName("User Audit Log") }} href="#/UserMastLog" />
                        <MenuItem icon={imgDocument} text="Reset User Password" onClick={() => { setshowPgmName("Reset User Password") }} href="#/UserchgPasswd2" />
                        <MenuItem icon={imgDocument} text="User Access Group" onClick={() => { setshowPgmName("User Access Group") }} href="#/UserAccessGroup" />
                </MenuItem> }
                <MenuItem icon={imgDocument} text="Change User Password" onClick={() => { setshowPgmName("Change User Password") }} href="#/UserchgPasswd" />
                <MenuItem icon={imgDocument} text="About" onClick={() => { setshowPgmName("About") }} href="#/About" />
                <MenuItem icon={imgDocument} text="Log Out" onClick={() => { setshowPgmName("Log Out") }} href="#/login" />
            </Menu>

        }>
            <Button minimal icon={imgbuild } text="System Maintenance" />
        </Popover>



    let showuserId = <>
        User Id: { userId }
    </>

    const changeUserLanguage = (lng: string) => {
        (async () => {
            try {
                if (isLoggedIn) {
                    //await svc.post('/userupdatecurrent', {currentLang: lng});
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    let lngList = [
        {lngName: "Eng", lng: "en"},
        {lngName: "繁體", lng: "zh-HK"},
        {lngName: "简体", lng: "zh-CN"},
    ]

    return (
        <>
            {isLoggedIn && <Navbar className="No-Print">
                <Navbar.Group align={Alignment.LEFT}> 
                    <Navbar.Heading><AnchorButton minimal icon={imgLogo} text="OpenAcc" href="#/"/></Navbar.Heading>
                    <Navbar.Divider />
                    {isLoggedIn ? menuMaster : null} 
                    {/* {isLoggedIn ? menuTransaction : null} */}
                    {/* {isLoggedIn ? menuReport : null} */}
                    {isLoggedIn ? menuSysMain : null}
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    { userKey == "ASTRUM" && IOANo == "1" && <div><b>IOA1 </b></div> }
                    { userKey == "ASTRUM" && IOANo == "2" && <div><b>IOA2 for settlement </b></div> }       
                    <div>&nbsp; {Sys_VerNo}</div>
                    <Navbar.Divider />
                    {isLoggedIn && <div > {showuserId} </div>} 
                    {/*
                    <Popover minimal position={Position.BOTTOM_LEFT} content={
                        <Menu>
                            {lngList.map((lng) => <MenuItem key={lng.lng} icon="translate" text={lng.lngName} onClick={() => {i18n.changeLanguage(lng.lng); changeUserLanguage(lng.lng)}} />)}
                        </Menu>
                    }>
                        <Button className="MenuEnv" minimal icon="translate" text="Language/語言" />
                    </Popover>
                    */}
                    {isLoggedIn && <Link to="/tasks"><Button minimal icon={imgTask} /></Link>}
                    {isLoggedIn && <Link to="/login"><Button minimal icon={imgUser} /></Link>}
                </Navbar.Group>
            </Navbar> 
            }
        </>
    )
}

export default App;
