import React, { useState, FormEvent, useEffect } from 'react';
import { Stage, Layer, Line, Text } from 'react-konva';
import { useHistory } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, Button, AnchorButton, FileInput, Radio, RadioGroup, Switch, Checkbox, HTMLSelect, HTMLTable, Position, Toaster, Intent, Callout, MenuItem } from "@blueprintjs/core";
import { Classes, Card, Elevation, Dialog } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { AxiosInstance } from 'axios';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import * as utils from "./utils";
import clearSelection from "./clearSelect";


import svgWait2 from './svg/wait2.gif'
import svgnum1a from './svg/num1a.gif'
import svgnum2a from './svg/num2a.gif'
import svgnum3a from './svg/num3a.gif'
import svgnum4a from './svg/num4a.gif'
import svgnum5a from './svg/num5a.gif'
import svgnum6a from './svg/num6a.gif'
import svgnum7a from './svg/num7a.gif'
import svgnum8a from './svg/num8a.gif'
import svgnum9a from './svg/num9a.gif'
import svgnum10a from './svg/num10a.gif'
import svgnum11a from './svg/num11a.gif'
import svgnum12a from './svg/num12a.gif'
import svgnum1b from './svg/num1b.gif'
import svgnum2b from './svg/num2b.gif'
import svgnum3b from './svg/num3b.gif'
import svgnum4b from './svg/num4b.gif'
import svgnum5b from './svg/num5b.gif'
import svgnum6b from './svg/num6b.gif'
import svgnum7b from './svg/num7b.gif'
import svgnum8b from './svg/num8b.gif'
import svgnum9b from './svg/num9b.gif'
import svgnum10b from './svg/num10b.gif'
import svgnum11b from './svg/num11b.gif'
import svgnum12b from './svg/num12b.gif'
import tick_circle from './svg/tick-circle.svg'
import flow_linear from './svg/flow-linear.svg'
import one_to_one from './svg/one-to-one.svg'



import { Account, Jurisdiction, SignImg } from './Types'

import i18next from 'i18next';

var OpenAccFlag: any;


interface Test01MainProps {
    OpenAcc: AxiosInstance,
    currentRec?: Account
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function Test01Main({ OpenAcc , currentRec = {} as Account }  : Test01MainProps) {
    //const history = useHistory()
    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<Account>()
    const { t, i18n } = useTranslation();    


    const [JurisdictionList, setJurisdictionList] = useState([] as Jurisdiction[])
    const [SignImgRec, setSignImgRec] = useState({} as SignImg)
   
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const imgnum1a = <img className="NumIcon2" src={svgnum1a} alt="num1" />
    const imgnum2a = <img className="NumIcon2" src={svgnum2a} alt="num2" />
    const imgnum3a = <img className="NumIcon2" src={svgnum3a} alt="num3" />
    const imgnum4a = <img className="NumIcon2" src={svgnum4a} alt="num4" />
    const imgnum5a = <img className="NumIcon2" src={svgnum5a} alt="num5" />
    const imgnum6a = <img className="NumIcon2" src={svgnum6a} alt="num6" />
    const imgnum7a = <img className="NumIcon2" src={svgnum7a} alt="num7" />
    const imgnum8a = <img className="NumIcon2" src={svgnum8a} alt="num8" />
    const imgnum9a = <img className="NumIcon2" src={svgnum9a} alt="num9" />
    const imgnum10a = <img className="NumIcon2" src={svgnum10a} alt="num10" />
    const imgnum11a = <img className="NumIcon2" src={svgnum11a} alt="num11" />
    const imgnum12a = <img className="NumIcon2" src={svgnum12a} alt="num12" />
    const imgnum1b = <img className="NumIcon" src={svgnum1b} alt="num1" />
    const imgnum2b = <img className="NumIcon" src={svgnum2b} alt="num2" />
    const imgnum3b = <img className="NumIcon" src={svgnum3b} alt="num3" />
    const imgnum4b = <img className="NumIcon" src={svgnum4b} alt="num4" />
    const imgnum5b = <img className="NumIcon" src={svgnum5b} alt="num5" />
    const imgnum6b = <img className="NumIcon" src={svgnum6b} alt="num6" />
    const imgnum7b = <img className="NumIcon" src={svgnum7b} alt="num7" />
    const imgnum8b = <img className="NumIcon" src={svgnum8b} alt="num8" />
    const imgnum9b = <img className="NumIcon" src={svgnum9b} alt="num9" />
    const imgnum10b = <img className="NumIcon" src={svgnum10b} alt="num10" />
    const imgnum11b = <img className="NumIcon" src={svgnum11b} alt="num11" />
    const imgnum12b = <img className="NumIcon" src={svgnum12b} alt="num12" />
    const imgtick_circle = <img className="tickIcon" src={tick_circle} alt="completed" />
    const imgflow_linear = <img src={flow_linear} alt="Progressing" />
    const imgone_to_one = <img src={one_to_one} alt="Progressing" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const cologo2 = <img className="cologo2" src="pop_cologo2.gif" alt="Co Logo2" />
    
    const [SendVmeth, setSendVmeth] = useState("1")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [cardOpen02, setcardOpen02] = useState("cardOpen02-Theme1")
    const [Enter2tab, setEnter2tab] = useState("N")
    const [isOpenDialog, setisOpenDialog] = useState(false)
    const [pdfAgree, setpdfAgree] = useState("")

    const [ShowWait, setShowWait] = useState(false)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(12)
    const [MoblieDev, setMoblieDev] = useState(false)
    const [lFinishe, setlFinishe] = useState(false)


    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("ASTRUM")



    const [tool, setTool] = React.useState('pen');
    const [lines, setLines] = useState<any>([]);
    const isDrawing = React.useRef(false);
    //const stageRef = React.useRef(null);
    const stageRef: any = React.createRef()
    const [isSigned, setisSigned] = useState(false);


    const handleMouseDown = (e:any) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setLines([...lines, { tool, points: [pos.x, pos.y] }]);
    };
  
    const handleMouseMove = (e:any) => {
        // no drawing - skipping
        if (!isDrawing.current) {
            return;
        }
        e.evt.preventDefault();
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastLine = lines[lines.length - 1];

        if (lastLine) {
            // add point
            lastLine.points = lastLine.points.concat([point.x, point.y]);
                
            // replace last
            lines.splice(lines.length - 1, 1, lastLine);
            setLines(lines.concat());
        }    
        setisSigned(true)
    };
  
    const handleMouseUp = () => {
        isDrawing.current = false;
    };
  

    const handleExport = () => {
        //const uri = stageRef.current.toDataURL();
        const uri = stageRef.current.toDataURL();

        
        console.log("uri", uri);
        
        downloadURI(uri, 'signature.png');
  
        // we also can save uri as file
        // but in the demo on Konva website it will not work
        // because of iframe restrictions
        // but feel free to use it in your apps:
        // downloadURI(uri, 'stage.png');
        
    };
  
    const handleClear = () => {
        isDrawing.current = false;
        setisSigned(false)        
        clearSelection()
        setLines([])
    };

    
    function downloadURI(uri:any, name:string) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        link.click();
    }


    const savesignature = async () => {

        const savesignatureimage_uri = stageRef.current.toDataURL();
        //var image = stageRef.current.toImage()

        console.log("call savesignature savesignatureimage_uri", savesignatureimage_uri)
        //console.log("call savesignature image", image)

        //SignImgRec.idNo = "c23"
        SignImgRec.idNo = def_d_idNo
        SignImgRec.uri = savesignatureimage_uri
        //SignImgRec.signimage = image

        if ( savesignatureimage_uri.length > 0 && SignImgRec.idNo.length > 0 ) {
            try {
                console.log("call savesignature SignImgRec", SignImgRec)
                const res = await OpenAcc.post('/Master0',
                    {   sb_data: SignImgRec,
                        cPgmId: "OpenAcc",
                        cAction: "saveSignImg",
                    });

                //console.log("savesignature res.data", res.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                if ( res.data.ok )
                {
                             
                }
            } catch (error) {
                utils.showError(error)
            }
        } else {
            utils.showError("Insufficient Ddata")
        }

    }

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess(pdf: any) {
      setNumPages(pdf.numPages);
    }
  

      
    const [file1Label, setFile1Label] = useState("Choose file  ...  ")
    const [file1bLabel, setFile1bLabel] = useState("Choose file  ...  ")
    const [file2Label, setFile2Label] = useState("Choose file  ...  ")
    const [file3Label, setFile3Label] = useState("Choose file  ...  ")
    const [nUploaded, setnUploaded] = useState(0)


    //OpenAcc default value
    currentRec.d_ClientType = "C"
    currentRec.d_Sex = "M"
    currentRec.d_mobile = ""
    currentRec.d_mobileCode = "852"
    currentRec.d_way2GetStmt = "1"
    currentRec.d_Employment = "1"
    currentRec.d_NetAsset = "1"
    currentRec.d_AnnualIncome = "1"
    currentRec.d_StaffRelation = "N"
    currentRec.d_selfInstruction = "Y"
    currentRec.d_isFinInstit = "N"
    currentRec.d_ApproveFinInstit = "Y"
    currentRec.d_hasSpouseClient = ""
    currentRec.d_VoteRight = "N"
    currentRec.d_isInternet = "Y"
    currentRec.d_Target1 = "N"
    currentRec.d_Target2 = "N"
    currentRec.d_Target3 = "N"
    currentRec.d_Target4 = "N"
    currentRec.d_Target5 = "N"
    currentRec.d_Target6 = "N"
    currentRec.d_Target7 = "N"
    currentRec.d_Target8 = "N"
    currentRec.d_Target9 = "N"
    currentRec.d_InvestExp = ""
    currentRec.d_HighRiskExp = "N"
    currentRec.d_JurisdictionofResidence = "HK"    
    currentRec.d_JurisdictionofResidence2 = ""    
    currentRec.d_JurisdictionofResidence3 = ""    
    currentRec.d_JurisdictionofResidence4 = ""    
    currentRec.d_JurisdictionofResidence5 = ""    
    currentRec.d_w8b_01 = "N"
    currentRec.d_w8b_02 = "N"
    currentRec.d_w8b_03 = "N"
    currentRec.d_w8b_04 = "N"
    currentRec.d_w8b_05 = "N"
    currentRec.d_Agree = "N"


    const [def_d_ClientType, setdef_d_ClientType] = useState(currentRec.d_ClientType)
    const [def_d_acName1, setdef_d_acName1] = useState(currentRec.d_acName1)
    const [def_d_acName2, setdef_d_acName2] = useState(currentRec.d_acName2)
    const [def_d_idNo, setdef_d_idNo] = useState(currentRec.d_idNo)
    const [def_d_acAddr1, setdef_d_acAddr1] = useState(currentRec.d_acAddr1)
    const [def_d_acAddr2, setdef_d_acAddr2] = useState(currentRec.d_acAddr2)
    const [def_d_acAddr3, setdef_d_acAddr3] = useState(currentRec.d_acAddr3)
    const [def_d_Sex, setdef_d_Sex] = useState(currentRec.d_Sex)
    const [def_d_dob, setdef_d_dob] = useState(currentRec.d_dob)
    const [def_d_National, setdef_d_National] = useState(currentRec.d_National)
    const [def_d_mailAddr1, setdef_d_mailAddr1] = useState(currentRec.d_mailAddr1)
    const [def_d_mailAddr2, setdef_d_mailAddr2] = useState(currentRec.d_mailAddr2)
    const [def_d_mailAddr3, setdef_d_mailAddr3] = useState(currentRec.d_mailAddr3)
    const [def_d_way2GetStmt, setdef_d_way2GetStmt] = useState(currentRec.d_way2GetStmt)
    const [def_d_NetAsset, setdef_d_NetAsset] = useState(currentRec.d_NetAsset)
    const [def_d_AnnualIncome, setdef_d_AnnualIncome] = useState(currentRec.d_AnnualIncome)
    const [def_d_SourceofFund, setdef_d_SourceofFund] = useState(currentRec.d_SourceofFund)
    const [def_d_StaffRelation, setdef_d_StaffRelation] = useState(currentRec.d_StaffRelation)
    const [def_d_StaffName, setdef_d_StaffName] = useState(currentRec.d_StaffName)
    const [def_d_StaffRelationship, setdef_d_StaffRelationship] = useState(currentRec.d_StaffRelationship)
    const [def_d_selfInstruction, setdef_d_selfInstruction] = useState(currentRec.d_selfInstruction)
    const [def_d_InstructionType, setdef_d_InstructionType] = useState(currentRec.d_InstructionType)
    const [def_d_Employment, setdef_d_Employment] = useState(currentRec.d_Employment)
    const [def_d_EmploymentOther, setdef_d_EmploymentOther] = useState(currentRec.d_EmploymentOther)
    const [def_d_CoName, setdef_d_CoName] = useState(currentRec.d_CoName)
    const [def_d_isFinInstit, setdef_d_isFinInstit] = useState(currentRec.d_isFinInstit)
    const [def_d_ApproveFinInstit, setdef_d_ApproveFinInstit] = useState(currentRec.d_ApproveFinInstit)
    const [def_d_CoAddr, setdef_d_CoAddr] = useState(currentRec.d_CoAddr)
    const [def_d_CoType, setdef_d_CoType] = useState(currentRec.d_CoType)
    const [def_d_CoPost, setdef_d_CoPost] = useState(currentRec.d_CoPost)
    const [def_d_BankInfo, setdef_d_BankInfo] = useState(currentRec.d_BankInfo)
    const [def_d_BankACNo, setdef_d_BankACNo] = useState(currentRec.d_BankACNo)
    const [def_d_tel, setdef_d_tel] = useState(currentRec.d_tel)
    const [def_d_offTel, setdef_d_offTel] = useState(currentRec.d_offTel)
    const [def_d_mobile, setdef_d_mobile] = useState(currentRec.d_mobile)
    const [def_d_mobileCode, setdef_d_mobileCode] = useState(currentRec.d_mobileCode)   // 20211006 Add SMS
    const [def_d_fax, setdef_d_fax] = useState(currentRec.d_fax)
    const [def_d_hasSpouseClient, setdef_d_hasSpouseClient] = useState(currentRec.d_hasSpouseClient)
    const [def_d_SpouseName, setdef_d_SpouseName] = useState(currentRec.d_SpouseName)
    const [def_d_SpouseAcNo, setdef_d_SpouseAcNo] = useState(currentRec.d_SpouseAcNo)
    const [def_d_VoteRight, setdef_d_VoteRight] = useState(currentRec.d_VoteRight)
    const [def_d_VoteAc1, setdef_d_VoteAc1] = useState(currentRec.d_VoteAc1)
    const [def_d_VoteAcName1, setdef_d_VoteAcName1] = useState(currentRec.d_VoteAcName1)
    const [def_d_VoteRelate1, setdef_d_VoteRelate1] = useState(currentRec.d_VoteRelate1)
    const [def_d_VoteAc2, setdef_d_VoteAc2] = useState(currentRec.d_VoteAc2)
    const [def_d_VoteAcName2, setdef_d_VoteAcName2] = useState(currentRec.d_VoteAcName2)
    const [def_d_VoteRelate2, setdef_d_VoteRelate2] = useState(currentRec.d_VoteRelate2)
    const [def_d_isInternet, setdef_d_isInternet] = useState(currentRec.d_isInternet)
    const [def_d_JurisdictionofResidence, setdef_d_JurisdictionofResidence] = useState(currentRec.d_JurisdictionofResidence)
    const [def_d_OtherJurisdictionofResidence, setdef_d_OtherJurisdictionofResidence] = useState(currentRec.d_OtherJurisdictionofResidence)
    const [def_d_TIN, setdef_d_TIN] = useState(currentRec.d_TIN)
    const [def_d_Juris_Reason, setdef_d_Juris_Reason] = useState(currentRec.d_Juris_Reason)
    const [def_d_JurisdictionofResidence2, setdef_d_JurisdictionofResidence2] = useState(currentRec.d_JurisdictionofResidence2)
    const [def_d_TIN2, setdef_d_TIN2] = useState(currentRec.d_TIN2)
    const [def_d_Juris_Reason2, setdef_d_Juris_Reason2] = useState(currentRec.d_Juris_Reason2)
    const [def_d_JurisdictionofResidence3, setdef_d_JurisdictionofResidence3] = useState(currentRec.d_JurisdictionofResidence3)
    const [def_d_TIN3, setdef_d_TIN3] = useState(currentRec.d_TIN3)
    const [def_d_Juris_Reason3, setdef_d_Juris_Reason3] = useState(currentRec.d_Juris_Reason3)
    const [def_d_JurisdictionofResidence4, setdef_d_JurisdictionofResidence4] = useState(currentRec.d_JurisdictionofResidence4)
    const [def_d_TIN4, setdef_d_TIN4] = useState(currentRec.d_TIN4)
    const [def_d_Juris_Reason4, setdef_d_Juris_Reason4] = useState(currentRec.d_Juris_Reason4)
    const [def_d_JurisdictionofResidence5, setdef_d_JurisdictionofResidence5] = useState(currentRec.d_JurisdictionofResidence5)
    const [def_d_TIN5, setdef_d_TIN5] = useState(currentRec.d_TIN5)
    const [def_d_Juris_Reason5, setdef_d_Juris_Reason5] = useState(currentRec.d_Juris_Reason5)
    const [def_d_w8b_country, setdef_d_w8b_country] = useState(currentRec.d_w8b_country)
    const [def_d_w8b_01, setdef_d_w8b_01] = useState(currentRec.d_w8b_01==="Y")
    const [def_d_w8b_02, setdef_d_w8b_02] = useState(currentRec.d_w8b_02==="Y")
    const [def_d_w8b_03, setdef_d_w8b_03] = useState(currentRec.d_w8b_03==="Y")
    const [def_d_w8b_04, setdef_d_w8b_04] = useState(currentRec.d_w8b_04==="Y")
    const [def_d_w8b_05, setdef_d_w8b_05] = useState(currentRec.d_w8b_05==="Y")
    const [def_d_Target1, setdef_d_Target1] = useState(currentRec.d_Target1==="Y")
    const [def_d_Target2, setdef_d_Target2] = useState(currentRec.d_Target2==="Y")
    const [def_d_Target3, setdef_d_Target3] = useState(currentRec.d_Target3==="Y")
    const [def_d_Target4, setdef_d_Target4] = useState(currentRec.d_Target4==="Y")
    const [def_d_Target5, setdef_d_Target5] = useState(currentRec.d_Target5==="Y")
    const [def_d_Target6, setdef_d_Target6] = useState(currentRec.d_Target6==="Y")
    const [def_d_Target7, setdef_d_Target7] = useState(currentRec.d_Target7==="Y")
    const [def_d_Target8, setdef_d_Target8] = useState(currentRec.d_Target8==="Y")
    const [def_d_Target9, setdef_d_Target9] = useState(currentRec.d_Target9==="Y")
    const [def_d_InvestExp, setdef_d_InvestExp] = useState(currentRec.d_InvestExp)
    const [def_d_StockYear, setdef_d_StockYear] = useState(currentRec.d_StockYear)
    const [def_d_StockCnt, setdef_d_StockCnt] = useState(currentRec.d_StockCnt)
    const [def_d_StockAmt, setdef_d_StockAmt] = useState(currentRec.d_StockAmt)
    const [def_d_WarrantYear, setdef_d_WarrantYear] = useState(currentRec.d_WarrantYear)
    const [def_d_WarrantCnt, setdef_d_WarrantCnt] = useState(currentRec.d_WarrantCnt)
    const [def_d_WarrantAmt, setdef_d_WarrantAmt] = useState(currentRec.d_WarrantAmt)
    const [def_d_OptionYear, setdef_d_OptionYear] = useState(currentRec.d_OptionYear)
    const [def_d_OptionCnt, setdef_d_OptionCnt] = useState(currentRec.d_OptionCnt)
    const [def_d_OptionAmt, setdef_d_OptionAmt] = useState(currentRec.d_OptionAmt)
    const [def_d_FutureYear, setdef_d_FutureYear] = useState(currentRec.d_FutureYear)
    const [def_d_FutureCnt, setdef_d_FutureCnt] = useState(currentRec.d_FutureCnt)
    const [def_d_FutureAmt, setdef_d_FutureAmt] = useState(currentRec.d_FutureAmt)

    const [def_d_HighRiskExp, setdef_d_HighRiskExp] = useState(currentRec.d_HighRiskExp)

    const [def_d_Agree, setdef_d_Agree] = useState(currentRec.d_Agree==="Y")
    const [def_Uploading, setdef_Uploading] = useState(false)
    const [def_d_Terms1Agree, setdef_d_Terms1Agree] = useState(false)



    const [def_d_email, setdef_d_email] = useState(currentRec.d_email)
    const [def_isResend, setdef_isResend] = useState(false)
    const [def_d_verify_code1, setdef_d_verify_code1] = useState(currentRec.d_verify_code1)

    const [def_d_verify_ok1, setdef_d_verify_ok1] = useState(currentRec.d_verify_ok1)
    const [def_d_verify_Date, setdef_d_verify_Date] = useState(currentRec.d_verify_Date)
    const [def_d_verify_Time, setdef_d_verify_Time] = useState(currentRec.d_verify_Time)
    
    const [def_d_inpputDate, setdef_d_inpputDate] = useState(currentRec.d_inpputDate)
    const [def_d_inpputTime, setdef_d_inpputTime] = useState(currentRec.d_inpputTime)



    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag0');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                setSendVmeth(OpenAccFlag.DEF_SENDVMETH);
                setcardOpen01("cardOpen01-Theme"+OpenAccFlag.DEF_THEME2);
                setcardOpen02("cardOpen02-Theme"+OpenAccFlag.DEF_THEME2);
                setEnter2tab(OpenAccFlag.Enter2tab);

                if ( utils.getDeviceType()) {
                    setMoblieDev(true)
                } else {
                    setMoblieDev(false)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [OpenAcc])


    useEffect(() => {
        if (Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [OpenAccFlag, nPage])
    
    

    useEffect(() => {

        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                const res = await OpenAcc.post('/Master0',
                    {   sb_data: {
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "OpenAcc",
                        cAction: "ListJurisdiction",
                    });
                setJurisdictionList(res.data.data)
            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])


    useEffect(() => {

        if (def_d_ClientType == "C") {
            setdef_d_hasSpouseClient("N")
        } else{
            setdef_d_hasSpouseClient("")
        }


    }, [OpenAcc, def_d_ClientType])





    let idNoLength = 20
/*    
    if ( OpenAccFlag.user_key === "TATLEE" ) {
        idNoLength = 22
    }
*/
    let AddrLength = 29
/*    
    if ( OpenAccFlag.user_key === "WINFULL" || OpenAccFlag.user_key === "WINGHANG" || OpenAccFlag.user_key === "COMPSHARE" ) {
        AddrLength = 35
    }
*/
    let TelLength = 15
/*    
    if ( OpenAccFlag.user_key === "BERICH" || OpenAccFlag.user_key === "CHEERFUL" ) {
        TelLength = 20
    }
*/
    let OffTelLength = 15
/*    
    if ( OpenAccFlag.user_key === "CHEERFUL"  ) {
        OffTelLength = 20
    }
*/
    let MobileLength = 15

    let FaxLength = 15

    let EmailLength = 100
/*    
    if ( OpenAccFlag.user_key === "PAMIRS"  ) {
            EmailLength = 130
    } else if ( OpenAccFlag.user_key === "COMPSHARE" || OpenAccFlag.user_key === "BELTRADE" ) {
        EmailLength = 200
    }
*/
    let BankInfoLength = 20
/*    
    if ( OpenAccFlag.user_key === "WINGHANG"  ) {
        BankInfoLength = 9
    }
*/
    let BankACNoLength = 20
/*    
    if ( OpenAccFlag.user_key === "WINGHANG"  ) {
        BankACNoLength = 9
    }
*/

   const onPrev1a = (data: Account) => {
        //console.log("onPrev1 called", data)

        data.d_ClientType = def_d_ClientType


        currentRec.d_ClientType = data.d_ClientType


        ;        

        PageNo(-1)

    }



    const onValidate1a = (data: Account) => {
        //console.log("onValidate1 called", data)

        data.d_ClientType = def_d_ClientType


        currentRec.d_ClientType = data.d_ClientType



        currentRec.lang_reg = i18n.languages.toString();

        if ( userKey == "ASTRUM" ) {
            if ( def_d_ClientType == "C" ) {
                if ( currentRec.lang_reg.substr(0,2)  == "en" )
                {
                    setpdfAgree("astrum_CashAgreement_E.pdf")
                } else { 
                    setpdfAgree("astrum_CashAgreement_C.pdf")
                }
            }
            if ( def_d_ClientType == "M" ) {
                if ( currentRec.lang_reg.substr(0,2)  == "en" )
                {
                    setpdfAgree("astrum_MarginAgreement_E.pdf")
                } else { 
                    setpdfAgree("astrum_MarginAgreement_C.pdf")
                }
            }
        }
        if ( userKey == "DEMO" ) {
            if ( def_d_ClientType == "C" ) {
                if ( currentRec.lang_reg.substr(0,2)  == "en" )
                {
                    setpdfAgree("pop_CashAgreement_E.pdf")
                } else { 
                    setpdfAgree("pop_CashAgreement_C.pdf")
                }
            }
            if ( def_d_ClientType == "M" ) {
                if ( currentRec.lang_reg.substr(0,2)  == "en" )
                {
                    setpdfAgree("pop_MarginAgreement_E.pdf")
                } else { 
                    setpdfAgree("pop_MarginAgreement_C.pdf")
                }
            }
        }

        ;

        (async () => {
            try {
                console.log("call onValidate1a currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate1a", isNew: true})
                //console.log("onValidate1 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                setisOpenDialog(true)
                //PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }

    const onPrev1 = (data: Account) => {
        //console.log("onPrev1 called", data)

        setdef_d_acName1(data.d_acName1)
        setdef_d_acName2(data.d_acName2)
        setdef_d_idNo(data.d_idNo)
        data.d_Sex = def_d_Sex
        setdef_d_dob(data.d_dob)
        setdef_d_National(data.d_National)

        currentRec.d_acName1 = data.d_acName1
        currentRec.d_acName2 = data.d_acName2
        currentRec.d_idNo = data.d_idNo
        currentRec.d_dob = data.d_dob
        currentRec.d_Sex = data.d_Sex
        currentRec.d_National = data.d_National

        currentRec.lang_reg = i18n.languages.toString()

        ;        

        (async () => {
            try {
                console.log("call onPrev1 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate1", isNew: true})
                //console.log("onPrev2 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(-1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }



    const onValidate1 = (data: Account) => {
        //console.log("onValidate1 called", data)

        setdef_d_acName1(data.d_acName1)
        setdef_d_acName2(data.d_acName2)
        setdef_d_idNo(data.d_idNo)
        data.d_Sex = def_d_Sex
        setdef_d_dob(data.d_dob)
        setdef_d_National(data.d_National)

    

        currentRec.d_acName1 = data.d_acName1
        currentRec.d_acName2 = data.d_acName2
        currentRec.d_idNo = data.d_idNo
        currentRec.d_dob = data.d_dob
        currentRec.d_Sex = data.d_Sex
        currentRec.d_National = data.d_National

        currentRec.lang_reg = i18n.languages.toString()

        ;

        (async () => {
            try {
                console.log("call onValidate1 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate1", isNew: true})
                //console.log("onValidate1 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onPrev2 = (data: Account) => {
        //console.log("onPrev2 called", data)

        setdef_d_acAddr1(data.d_acAddr1)
        setdef_d_acAddr2(data.d_acAddr2)
        setdef_d_acAddr3(data.d_acAddr3)
        setdef_d_mailAddr1(data.d_mailAddr1)
        setdef_d_mailAddr2(data.d_mailAddr2)
        setdef_d_mailAddr3(data.d_mailAddr3)

        setdef_d_email(data.d_email)
        data.d_way2GetStmt = def_d_way2GetStmt

        setdef_d_tel(data.d_tel)
        setdef_d_offTel(data.d_offTel)
        setdef_d_mobile(data.d_mobile)
        data.d_mobileCode = def_d_mobileCode
        setdef_d_fax(data.d_fax)


        currentRec.d_acAddr1 = data.d_acAddr1
        currentRec.d_acAddr2 = data.d_acAddr2
        currentRec.d_acAddr3 = data.d_acAddr3
        currentRec.d_mailAddr1 = data.d_mailAddr1
        currentRec.d_mailAddr2 = data.d_mailAddr2
        currentRec.d_mailAddr3 = data.d_mailAddr3

        currentRec.d_email = data.d_email
        currentRec.d_way2GetStmt = data.d_way2GetStmt

        currentRec.d_tel = data.d_tel
        currentRec.d_offTel = data.d_offTel
        currentRec.d_mobile = data.d_mobile
        currentRec.d_mobileCode = data.d_mobileCode    // 20211006 Add SMS
        currentRec.d_fax = data.d_fax 

        currentRec.lang_reg = i18n.languages.toString()

        ;
        (async () => {
            try {
                console.log("call onPrev2 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate2", isNew: true})
                //console.log("onPrev2 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(-1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }

    const onValidate2 = (data: Account) => {
        //console.log("onValidate2 called", data)

        setdef_d_acAddr1(data.d_acAddr1)
        setdef_d_acAddr2(data.d_acAddr2)
        setdef_d_acAddr3(data.d_acAddr3)
        setdef_d_mailAddr1(data.d_mailAddr1)
        setdef_d_mailAddr2(data.d_mailAddr2)
        setdef_d_mailAddr3(data.d_mailAddr3)

        setdef_d_email(data.d_email)
        data.d_way2GetStmt = def_d_way2GetStmt

        setdef_d_tel(data.d_tel)
        setdef_d_offTel(data.d_offTel)
        setdef_d_mobile(data.d_mobile)
        data.d_mobileCode = def_d_mobileCode
        setdef_d_fax(data.d_fax)


        currentRec.d_acAddr1 = data.d_acAddr1
        currentRec.d_acAddr2 = data.d_acAddr2
        currentRec.d_acAddr3 = data.d_acAddr3
        currentRec.d_mailAddr1 = data.d_mailAddr1
        currentRec.d_mailAddr2 = data.d_mailAddr2
        currentRec.d_mailAddr3 = data.d_mailAddr3

        currentRec.d_email = data.d_email
        currentRec.d_way2GetStmt = data.d_way2GetStmt

        currentRec.d_tel = data.d_tel
        currentRec.d_offTel = data.d_offTel
        currentRec.d_mobile = data.d_mobile
        currentRec.d_mobileCode = data.d_mobileCode    // 20211006 Add SMS
        currentRec.d_fax = data.d_fax 
        
        currentRec.lang_reg = i18n.languages.toString()

        ;

        (async () => {
            try {
                console.log("call onValidate2 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate2", isNew: true})
                //console.log("onValidate2 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }



    const onPrev3 = (data: Account) => {
        //console.log("onPrev3 called", data)

        data.d_Employment = def_d_Employment
        setdef_d_EmploymentOther(data.d_EmploymentOther)
        setdef_d_CoName(data.d_CoName)

        data.d_isFinInstit = def_d_isFinInstit
        data.d_ApproveFinInstit = def_d_ApproveFinInstit


        setdef_d_CoAddr(data.d_CoAddr)
        setdef_d_CoType(data.d_CoType)
        setdef_d_CoPost(data.d_CoPost)


        currentRec.d_Employment = data.d_Employment
        currentRec.d_EmploymentOther = data.d_EmploymentOther
        currentRec.d_CoName = data.d_CoName



        currentRec.d_isFinInstit = data.d_isFinInstit        
        currentRec.d_ApproveFinInstit = data.d_ApproveFinInstit        
        
        currentRec.d_CoAddr = data.d_CoAddr
        currentRec.d_CoType = data.d_CoType
        currentRec.d_CoPost = data.d_CoPost

        currentRec.lang_reg = i18n.languages.toString()

        ;
        (async () => {
            try {
                console.log("call onPrev3 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate3", isNew: true})
                //console.log("onPrev3 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(-1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onValidate3 = (data: Account) => {
        //console.log("onValidate3 called", data)

        data.d_Employment = def_d_Employment
        setdef_d_EmploymentOther(data.d_EmploymentOther)
        setdef_d_CoName(data.d_CoName)

        data.d_isFinInstit = def_d_isFinInstit
        data.d_ApproveFinInstit = def_d_ApproveFinInstit

        setdef_d_CoAddr(data.d_CoAddr)
        setdef_d_CoType(data.d_CoType)
        setdef_d_CoPost(data.d_CoPost)


        currentRec.d_Employment = data.d_Employment
        currentRec.d_EmploymentOther = data.d_EmploymentOther
        currentRec.d_CoName = data.d_CoName


        currentRec.d_isFinInstit = data.d_isFinInstit        
        currentRec.d_ApproveFinInstit = data.d_ApproveFinInstit        

        currentRec.d_CoAddr = data.d_CoAddr
        currentRec.d_CoType = data.d_CoType
        currentRec.d_CoPost = data.d_CoPost

        currentRec.lang_reg = i18n.languages.toString()
        ;

        (async () => {
            try {
                console.log("call onValidate3 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate3", isNew: true})
                //console.log("onValidate3 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }



    const onPrev4 = (data: Account) => {
        //console.log("onPrev4 called", data)

        data.d_NetAsset = def_d_NetAsset
        data.d_AnnualIncome = def_d_AnnualIncome
        setdef_d_SourceofFund(data.d_SourceofFund)

        currentRec.d_NetAsset = data.d_NetAsset
        currentRec.d_AnnualIncome = data.d_AnnualIncome
        currentRec.d_SourceofFund = data.d_SourceofFund

        currentRec.lang_reg = i18n.languages.toString()

        ;
        (async () => {
            try {
                console.log("call onPrev4 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate4", isNew: true})
                //console.log("onPrev4 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(-1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onValidate4 = (data: Account) => {
        //console.log("onValidate4 called", data)

        data.d_NetAsset = def_d_NetAsset
        data.d_AnnualIncome = def_d_AnnualIncome
        setdef_d_SourceofFund(data.d_SourceofFund)

        currentRec.d_NetAsset = data.d_NetAsset
        currentRec.d_AnnualIncome = data.d_AnnualIncome
        currentRec.d_SourceofFund = data.d_SourceofFund

        currentRec.lang_reg = i18n.languages.toString()

        ;

        (async () => {
            try {
                console.log("call onValidate4 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate4", isNew: true})
                //console.log("onValidate4 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }



    const onPrev5 = (data: Account) => {
        //console.log("onPrev5 called", data)

        data.d_Target1 = "N"
        if (def_d_Target1) {
            data.d_Target1 = "Y";
        } else {
            data.d_Target1 = "N";
        }
        data.d_Target2 = "N"
        if (def_d_Target2) {
            data.d_Target2 = "Y";
        } else {
            data.d_Target2 = "N";
        }
        data.d_Target3 = "N"
        if (def_d_Target3) {
            data.d_Target3 = "Y";
        } else {
            data.d_Target3 = "N";
        }
        data.d_Target4 = "N"
        if (def_d_Target4) {
            data.d_Target4 = "Y";
        } else {
            data.d_Target4 = "N";
        }
        data.d_Target5 = "N"
        if (def_d_Target5) {
            data.d_Target5 = "Y";
        } else {
            data.d_Target5 = "N";
        }
        data.d_Target6 = "N"
        if (def_d_Target6) {
            data.d_Target6 = "Y";
        } else {
            data.d_Target6 = "N";
        }
        data.d_Target7 = "N"
        if (def_d_Target7) {
            data.d_Target7 = "Y";
        } else {
            data.d_Target7 = "N";
        }
        data.d_Target8 = "N"
        if (def_d_Target8) {
            data.d_Target8 = "Y";
        } else {
            data.d_Target8 = "N";
        }
        data.d_Target9 = "N"
        if (def_d_Target9) {
            data.d_Target9 = "Y";
        } else {
            data.d_Target9 = "N";
        }

        data.d_InvestExp = def_d_InvestExp
        setdef_d_StockYear(data.d_StockYear)
        setdef_d_StockCnt(data.d_StockCnt)
        setdef_d_StockAmt(data.d_StockAmt)
        setdef_d_WarrantYear(data.d_WarrantYear)
        setdef_d_WarrantCnt(data.d_WarrantCnt)
        setdef_d_WarrantAmt(data.d_WarrantAmt)
        setdef_d_OptionYear(data.d_OptionYear)
        setdef_d_OptionCnt(data.d_OptionCnt)
        setdef_d_OptionAmt(data.d_OptionAmt)
        setdef_d_FutureYear(data.d_FutureYear)
        setdef_d_FutureCnt(data.d_FutureCnt)
        setdef_d_FutureAmt(data.d_FutureAmt)

        data.d_HighRiskExp = def_d_HighRiskExp


        currentRec.d_Target1 = data.d_Target1
        currentRec.d_Target2 = data.d_Target2
        currentRec.d_Target3 = data.d_Target3
        currentRec.d_Target4 = data.d_Target4
        currentRec.d_Target5 = data.d_Target5
        currentRec.d_Target6 = data.d_Target6
        currentRec.d_Target7 = data.d_Target7
        currentRec.d_Target8 = data.d_Target8
        currentRec.d_Target9 = data.d_Target9

        currentRec.d_InvestExp = data.d_InvestExp
        currentRec.d_StockYear = data.d_StockYear
        currentRec.d_StockCnt = data.d_StockCnt
        currentRec.d_StockAmt = data.d_StockAmt
        currentRec.d_WarrantYear = data.d_WarrantYear
        currentRec.d_WarrantCnt = data.d_WarrantCnt
        currentRec.d_WarrantAmt = data.d_WarrantAmt
        currentRec.d_OptionYear = data.d_OptionYear
        currentRec.d_OptionCnt = data.d_OptionCnt
        currentRec.d_OptionAmt = data.d_OptionAmt
        currentRec.d_FutureYear = data.d_FutureYear
        currentRec.d_FutureCnt = data.d_FutureCnt
        currentRec.d_FutureAmt = data.d_FutureAmt

        currentRec.d_HighRiskExp = data.d_HighRiskExp

        currentRec.lang_reg = i18n.languages.toString()

        ;
        (async () => {
            try {
                console.log("call onPrev5 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate5", isNew: true})
                //console.log("onPrev5 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(-1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onValidate5 = (data: Account) => {
        //console.log("onValidate5 called", data)


        data.d_Target1 = "N"
        if (def_d_Target1) {
            data.d_Target1 = "Y";
        } else {
            data.d_Target1 = "N";
        }
        data.d_Target2 = "N"
        if (def_d_Target2) {
            data.d_Target2 = "Y";
        } else {
            data.d_Target2 = "N";
        }
        data.d_Target3 = "N"
        if (def_d_Target3) {
            data.d_Target3 = "Y";
        } else {
            data.d_Target3 = "N";
        }
        data.d_Target4 = "N"
        if (def_d_Target4) {
            data.d_Target4 = "Y";
        } else {
            data.d_Target4 = "N";
        }
        data.d_Target5 = "N"
        if (def_d_Target5) {
            data.d_Target5 = "Y";
        } else {
            data.d_Target5 = "N";
        }
        data.d_Target6 = "N"
        if (def_d_Target6) {
            data.d_Target6 = "Y";
        } else {
            data.d_Target6 = "N";
        }
        data.d_Target7 = "N"
        if (def_d_Target7) {
            data.d_Target7 = "Y";
        } else {
            data.d_Target7 = "N";
        }
        data.d_Target8 = "N"
        if (def_d_Target8) {
            data.d_Target8 = "Y";
        } else {
            data.d_Target8 = "N";
        }
        data.d_Target9 = "N"
        if (def_d_Target9) {
            data.d_Target9 = "Y";
        } else {
            data.d_Target9 = "N";
        }

        data.d_InvestExp = def_d_InvestExp
        setdef_d_StockYear(data.d_StockYear)
        setdef_d_StockCnt(data.d_StockCnt)
        setdef_d_StockAmt(data.d_StockAmt)
        setdef_d_WarrantYear(data.d_WarrantYear)
        setdef_d_WarrantCnt(data.d_WarrantCnt)
        setdef_d_WarrantAmt(data.d_WarrantAmt)
        setdef_d_OptionYear(data.d_OptionYear)
        setdef_d_OptionCnt(data.d_OptionCnt)
        setdef_d_OptionAmt(data.d_OptionAmt)
        setdef_d_FutureYear(data.d_FutureYear)
        setdef_d_FutureCnt(data.d_FutureCnt)
        setdef_d_FutureAmt(data.d_FutureAmt)

        data.d_HighRiskExp = def_d_HighRiskExp


        currentRec.d_Target1 = data.d_Target1
        currentRec.d_Target2 = data.d_Target2
        currentRec.d_Target3 = data.d_Target3
        currentRec.d_Target4 = data.d_Target4
        currentRec.d_Target5 = data.d_Target5
        currentRec.d_Target6 = data.d_Target6
        currentRec.d_Target7 = data.d_Target7
        currentRec.d_Target8 = data.d_Target8
        currentRec.d_Target9 = data.d_Target9

        currentRec.d_InvestExp = data.d_InvestExp
        currentRec.d_StockYear = data.d_StockYear
        currentRec.d_StockCnt = data.d_StockCnt
        currentRec.d_StockAmt = data.d_StockAmt
        currentRec.d_WarrantYear = data.d_WarrantYear
        currentRec.d_WarrantCnt = data.d_WarrantCnt
        currentRec.d_WarrantAmt = data.d_WarrantAmt
        currentRec.d_OptionYear = data.d_OptionYear
        currentRec.d_OptionCnt = data.d_OptionCnt
        currentRec.d_OptionAmt = data.d_OptionAmt
        currentRec.d_FutureYear = data.d_FutureYear
        currentRec.d_FutureCnt = data.d_FutureCnt
        currentRec.d_FutureAmt = data.d_FutureAmt

        currentRec.d_HighRiskExp = data.d_HighRiskExp

        currentRec.lang_reg = i18n.languages.toString()


        ;

        (async () => {
            try {
                console.log("call onValidate5 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate5", isNew: true})
                //console.log("onValidate5 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }



    const onPrev6 = (data: Account) => {
        //console.log("onPrev6 called", data)

        data.d_StaffRelation = def_d_StaffRelation
        setdef_d_StaffName(data.d_StaffName)
        setdef_d_StaffRelationship(data.d_StaffRelationship)

        data.d_selfInstruction = def_d_selfInstruction
        setdef_d_InstructionType(data.d_InstructionType)

        data.d_hasSpouseClient = def_d_hasSpouseClient
        setdef_d_SpouseName(data.d_SpouseName)
        setdef_d_SpouseAcNo(data.d_SpouseAcNo)

        data.d_VoteRight = def_d_VoteRight
        setdef_d_VoteAc1(data.d_VoteAc1)
        setdef_d_VoteAcName1(data.d_VoteAcName1)
        setdef_d_VoteRelate1(data.d_VoteRelate1)
        setdef_d_VoteAc2(data.d_VoteAc2)
        setdef_d_VoteAcName2(data.d_VoteAcName2)
        setdef_d_VoteRelate2(data.d_VoteRelate2)

        data.d_isInternet = def_d_isInternet


        currentRec.d_StaffRelation = data.d_StaffRelation
        currentRec.d_StaffName = data.d_StaffName
        currentRec.d_StaffRelationship = data.d_StaffRelationship

        currentRec.d_selfInstruction = data.d_selfInstruction
        currentRec.d_InstructionType = data.d_InstructionType

        if (def_d_ClientType == "C") {
            setdef_d_hasSpouseClient("N")
        }
        currentRec.d_hasSpouseClient = def_d_hasSpouseClient
        if ( typeof(currentRec.d_hasSpouseClient) === "undefined" ) {
            currentRec.d_hasSpouseClient = "N"
        }
        currentRec.d_SpouseName = def_d_SpouseName
        if ( typeof(currentRec.d_SpouseName) === "undefined" ) {
            currentRec.d_SpouseName = ""
        }
        currentRec.d_SpouseAcNo = def_d_SpouseAcNo
        if ( typeof(currentRec.d_SpouseAcNo) === "undefined" ) {
            currentRec.d_SpouseAcNo = ""
        }

        currentRec.d_VoteRight = def_d_VoteRight
        if ( typeof(currentRec.d_VoteRight) === "undefined" ) {
            currentRec.d_VoteRight = "N"
        }
        currentRec.d_VoteAc1 = def_d_VoteAc1
        if ( typeof(currentRec.d_VoteAc1) === "undefined" ) {
            currentRec.d_VoteAc1 = ""
        }
        currentRec.d_VoteAcName1 = def_d_VoteAcName1
        if ( typeof(currentRec.d_VoteAcName1) === "undefined" ) {
            currentRec.d_VoteAcName1 = ""
        }
        currentRec.d_VoteRelate1 = def_d_VoteRelate1
        if ( typeof(currentRec.d_VoteRelate1) === "undefined" ) {
            currentRec.d_VoteRelate1 = ""
        }
        currentRec.d_VoteAc2 = def_d_VoteAc2
        if ( typeof(currentRec.d_VoteAc2) === "undefined" ) {
            currentRec.d_VoteAc2 = ""
        }
        currentRec.d_VoteAcName2 = def_d_VoteAcName2
        if ( typeof(currentRec.d_VoteAcName2) === "undefined" ) {
            currentRec.d_VoteAcName2 = ""
        }
        currentRec.d_VoteRelate2 = def_d_VoteRelate2
        if ( typeof(currentRec.d_VoteRelate2) === "undefined" ) {
            currentRec.d_VoteRelate2 = ""
        }

        currentRec.d_isInternet = data.d_isInternet

        currentRec.lang_reg = i18n.languages.toString()


        ;
        (async () => {
            try {
                console.log("call onPrev6 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate6", isNew: true})
                //console.log("onPrev6 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(-1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onValidate6 = (data: Account) => {
        //console.log("onValidate6 called", data)

        data.d_StaffRelation = def_d_StaffRelation
        setdef_d_StaffName(data.d_StaffName)
        setdef_d_StaffRelationship(data.d_StaffRelationship)

        data.d_selfInstruction = def_d_selfInstruction
        setdef_d_InstructionType(data.d_InstructionType)

        data.d_hasSpouseClient = def_d_hasSpouseClient
        setdef_d_SpouseName(data.d_SpouseName)
        setdef_d_SpouseAcNo(data.d_SpouseAcNo)

        data.d_VoteRight = def_d_VoteRight
        setdef_d_VoteAc1(data.d_VoteAc1)
        setdef_d_VoteAcName1(data.d_VoteAcName1)
        setdef_d_VoteRelate1(data.d_VoteRelate1)
        setdef_d_VoteAc2(data.d_VoteAc2)
        setdef_d_VoteAcName2(data.d_VoteAcName2)
        setdef_d_VoteRelate2(data.d_VoteRelate2)

        data.d_isInternet = def_d_isInternet



        currentRec.d_StaffRelation = data.d_StaffRelation
        currentRec.d_StaffName = data.d_StaffName
        currentRec.d_StaffRelationship = data.d_StaffRelationship

        currentRec.d_selfInstruction = data.d_selfInstruction
        currentRec.d_InstructionType = data.d_InstructionType

        if (def_d_ClientType == "C") {
            setdef_d_hasSpouseClient("N")
        }
        currentRec.d_hasSpouseClient = def_d_hasSpouseClient
        if ( typeof(currentRec.d_hasSpouseClient) === "undefined" ) {
            currentRec.d_hasSpouseClient = "N"
        }
        currentRec.d_SpouseName = def_d_SpouseName
        if ( typeof(currentRec.d_SpouseName) === "undefined" ) {
            currentRec.d_SpouseName = ""
        }
        currentRec.d_SpouseAcNo = def_d_SpouseAcNo
        if ( typeof(currentRec.d_SpouseAcNo) === "undefined" ) {
            currentRec.d_SpouseAcNo = ""
        }

        currentRec.d_VoteRight = def_d_VoteRight
        if ( typeof(currentRec.d_VoteRight) === "undefined" ) {
            currentRec.d_VoteRight = "N"
        }
        currentRec.d_VoteAc1 = def_d_VoteAc1
        if ( typeof(currentRec.d_VoteAc1) === "undefined" ) {
            currentRec.d_VoteAc1 = ""
        }
        currentRec.d_VoteAcName1 = def_d_VoteAcName1
        if ( typeof(currentRec.d_VoteAcName1) === "undefined" ) {
            currentRec.d_VoteAcName1 = ""
        }
        currentRec.d_VoteRelate1 = def_d_VoteRelate1
        if ( typeof(currentRec.d_VoteRelate1) === "undefined" ) {
            currentRec.d_VoteRelate1 = ""
        }
        currentRec.d_VoteAc2 = def_d_VoteAc2
        if ( typeof(currentRec.d_VoteAc2) === "undefined" ) {
            currentRec.d_VoteAc2 = ""
        }
        currentRec.d_VoteAcName2 = def_d_VoteAcName2
        if ( typeof(currentRec.d_VoteAcName2) === "undefined" ) {
            currentRec.d_VoteAcName2 = ""
        }
        currentRec.d_VoteRelate2 = def_d_VoteRelate2
        if ( typeof(currentRec.d_VoteRelate2) === "undefined" ) {
            currentRec.d_VoteRelate2 = ""
        }

        currentRec.d_isInternet = data.d_isInternet

        currentRec.lang_reg = i18n.languages.toString()




        ;

        (async () => {
            try {
                console.log("call onValidate6 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate6", isNew: true})
                //console.log("onValidate6 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }



    const onPrev7 = (data: Account) => {
        //console.log("onPrev7 called", data)



        //setdef_d_JurisdictionofResidence(data.d_JurisdictionofResidence)
        //setdef_d_OtherJurisdictionofResidence(data.d_OtherJurisdictionofResidence)
        setdef_d_TIN(data.d_TIN)
        setdef_d_Juris_Reason(data.d_Juris_Reason)
        setdef_d_TIN2(data.d_TIN2)
        setdef_d_Juris_Reason2(data.d_Juris_Reason2)
        setdef_d_TIN3(data.d_TIN3)
        setdef_d_Juris_Reason3(data.d_Juris_Reason3)
        setdef_d_TIN4(data.d_TIN4)
        setdef_d_Juris_Reason4(data.d_Juris_Reason4)
        setdef_d_TIN5(data.d_TIN5)
        setdef_d_Juris_Reason5(data.d_Juris_Reason5)



        //currentRec.d_JurisdictionofResidence = data.d_JurisdictionofResidence
        currentRec.d_JurisdictionofResidence = def_d_JurisdictionofResidence
        //currentRec.d_OtherJurisdictionofResidence = data.d_OtherJurisdictionofResidence
        currentRec.d_TIN = data.d_TIN
        currentRec.d_Juris_Reason = data.d_Juris_Reason
        currentRec.d_JurisdictionofResidence2 = def_d_JurisdictionofResidence2
        currentRec.d_TIN2 = data.d_TIN2
        currentRec.d_Juris_Reason2 = data.d_Juris_Reason2
        currentRec.d_JurisdictionofResidence3 = def_d_JurisdictionofResidence3
        currentRec.d_TIN3 = data.d_TIN3
        currentRec.d_Juris_Reason3 = data.d_Juris_Reason3
        currentRec.d_JurisdictionofResidence4 = def_d_JurisdictionofResidence4
        currentRec.d_TIN4 = data.d_TIN4
        currentRec.d_Juris_Reason4 = data.d_Juris_Reason4
        currentRec.d_JurisdictionofResidence5 = def_d_JurisdictionofResidence5
        currentRec.d_TIN5 = data.d_TIN5
        currentRec.d_Juris_Reason5 = data.d_Juris_Reason5

        currentRec.lang_reg = i18n.languages.toString()

        ;
        (async () => {
            try {
                console.log("call onPrev7 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate7", isNew: true})
                //console.log("onPrev7 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(-1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onValidate7 = (data: Account) => {
        //console.log("onValidate7 called", data)


        //setdef_d_JurisdictionofResidence(data.d_JurisdictionofResidence)
        //setdef_d_OtherJurisdictionofResidence(data.d_OtherJurisdictionofResidence)
        setdef_d_TIN(data.d_TIN)
        setdef_d_Juris_Reason(data.d_Juris_Reason)
        setdef_d_TIN2(data.d_TIN2)
        setdef_d_Juris_Reason2(data.d_Juris_Reason2)
        setdef_d_TIN3(data.d_TIN3)
        setdef_d_Juris_Reason3(data.d_Juris_Reason3)
        setdef_d_TIN4(data.d_TIN4)
        setdef_d_Juris_Reason4(data.d_Juris_Reason4)
        setdef_d_TIN5(data.d_TIN5)
        setdef_d_Juris_Reason5(data.d_Juris_Reason5)




        //currentRec.d_JurisdictionofResidence = data.d_JurisdictionofResidence
        currentRec.d_JurisdictionofResidence = def_d_JurisdictionofResidence
        //currentRec.d_OtherJurisdictionofResidence = data.d_OtherJurisdictionofResidence
        currentRec.d_TIN = data.d_TIN
        currentRec.d_Juris_Reason = data.d_Juris_Reason
        currentRec.d_JurisdictionofResidence2 = def_d_JurisdictionofResidence2
        currentRec.d_TIN2 = data.d_TIN2
        currentRec.d_Juris_Reason2 = data.d_Juris_Reason2
        currentRec.d_JurisdictionofResidence3 = def_d_JurisdictionofResidence3
        currentRec.d_TIN3 = data.d_TIN3
        currentRec.d_Juris_Reason3 = data.d_Juris_Reason3
        currentRec.d_JurisdictionofResidence4 = def_d_JurisdictionofResidence4
        currentRec.d_TIN4 = data.d_TIN4
        currentRec.d_Juris_Reason4 = data.d_Juris_Reason4
        currentRec.d_JurisdictionofResidence5 = def_d_JurisdictionofResidence5
        currentRec.d_TIN5 = data.d_TIN5
        currentRec.d_Juris_Reason5 = data.d_Juris_Reason5

        currentRec.lang_reg = i18n.languages.toString()



        ;

        (async () => {
            try {
                console.log("call onValidate7 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate7", isNew: true})
                //console.log("onValidate7 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }



    const onPrev7b = (data: Account) => {
        //console.log("onPrev7b called", data)

        setdef_d_w8b_country(data.d_w8b_country)

        data.d_w8b_01 = "Y"
        if (def_d_w8b_01) {
            data.d_w8b_01 = "Y";
        } else {
            data.d_w8b_01 = "N";
        }
        data.d_w8b_02 = "Y"
        if (def_d_w8b_02) {
            data.d_w8b_02 = "Y";
        } else {
            data.d_w8b_02 = "N";
        }
        data.d_w8b_03 = "Y"
        if (def_d_w8b_03) {
            data.d_w8b_03 = "Y";
        } else {
            data.d_w8b_03 = "N";
        }
        data.d_w8b_04 = "Y"
        if (def_d_w8b_04) {
            data.d_w8b_04 = "Y";
        } else {
            data.d_w8b_04 = "N";
        }
        data.d_w8b_05 = "Y"
        if (def_d_w8b_05) {
            data.d_w8b_05 = "Y";
        } else {
            data.d_w8b_05 = "N";
        }

        currentRec.d_w8b_country = data.d_w8b_country
        currentRec.d_w8b_01 = data.d_w8b_01
        currentRec.d_w8b_02 = data.d_w8b_02
        currentRec.d_w8b_03 = data.d_w8b_03
        currentRec.d_w8b_04 = data.d_w8b_04
        currentRec.d_w8b_05 = data.d_w8b_05

        currentRec.lang_reg = i18n.languages.toString()

        ;
        (async () => {
            try {
                console.log("call onPrev7b currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate7b", isNew: true})
                //console.log("onPrev8 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(-1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onValidate7b = (data: Account) => {
        //console.log("onValidate7b called", data)

        setdef_d_w8b_country(data.d_w8b_country)

        data.d_w8b_01 = "Y"
        if (def_d_w8b_01) {
            data.d_w8b_01 = "Y";
        } else {
            data.d_w8b_01 = "N";
        }
        data.d_w8b_02 = "Y"
        if (def_d_w8b_02) {
            data.d_w8b_02 = "Y";
        } else {
            data.d_w8b_02 = "N";
        }
        data.d_w8b_03 = "Y"
        if (def_d_w8b_03) {
            data.d_w8b_03 = "Y";
        } else {
            data.d_w8b_03 = "N";
        }
        data.d_w8b_04 = "Y"
        if (def_d_w8b_04) {
            data.d_w8b_04 = "Y";
        } else {
            data.d_w8b_04 = "N";
        }
        data.d_w8b_05 = "Y"
        if (def_d_w8b_05) {
            data.d_w8b_05 = "Y";
        } else {
            data.d_w8b_05 = "N";
        }

        currentRec.d_w8b_country = data.d_w8b_country
        currentRec.d_w8b_01 = data.d_w8b_01
        currentRec.d_w8b_02 = data.d_w8b_02
        currentRec.d_w8b_03 = data.d_w8b_03
        currentRec.d_w8b_04 = data.d_w8b_04
        currentRec.d_w8b_05 = data.d_w8b_05


        currentRec.lang_reg = i18n.languages.toString()


        ;

        (async () => {
            try {
                console.log("call onValidate7b currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate7b", isNew: true})
                //console.log("onValidate7 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }



    const onPrev8 = (data: Account) => {
        //console.log("onPrev8 called", data)

        setdef_d_BankInfo(data.d_BankInfo)
        setdef_d_BankACNo(data.d_BankACNo)


        currentRec.d_BankInfo = data.d_BankInfo
        currentRec.d_BankACNo = data.d_BankACNo

        currentRec.lang_reg = i18n.languages.toString()

        ;
        (async () => {
            try {
                console.log("call onPrev8 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate8", isNew: true})
                //console.log("onPrev8 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(-1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onValidate8 = (data: Account) => {
        //console.log("onValidate8 called", data)


        setdef_d_BankInfo(data.d_BankInfo)
        setdef_d_BankACNo(data.d_BankACNo)


        currentRec.d_BankInfo = data.d_BankInfo
        currentRec.d_BankACNo = data.d_BankACNo


        currentRec.lang_reg = i18n.languages.toString()

        setdef_Uploading(false)

        ;

        (async () => {
            try {
                console.log("call onValidate8 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate8", isNew: true})
                //console.log("onValidate7 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }




    const onPrev9 = (data: Account) => {
        //console.log("onPrev9 called", data)

        data.d_Agree = "N"
        if (def_d_Agree) {
            data.d_Agree = "Y";
        } else {
            data.d_Agree = "N";
        }

        currentRec.d_Agree = data.d_Agree

        currentRec.lang_reg = i18n.languages.toString()

        PageNo(-1);

        ;

/*        
        (async () => {
            try {
                console.log("call onPrev8 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate8", isNew: true})
                //console.log("onPrev8 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(-1);

            } catch (error) {
                showErrorBox(error)
            }
        })()
*/

    }


    const onValidate9 = (data: Account) => {
        //console.log("onValidate9 called", data)

        data.d_Agree = "N"
        if (def_d_Agree) {
            data.d_Agree = "Y";
        } else {
            data.d_Agree = "N";
        }



//onValidate1a
    currentRec.d_ClientType = def_d_ClientType


//onValidate1
        currentRec.d_acName1 = def_d_acName1
        currentRec.d_acName2 = def_d_acName2
        currentRec.d_idNo = def_d_idNo
        currentRec.d_dob = def_d_dob
        currentRec.d_Sex = def_d_Sex
        currentRec.d_National = def_d_National

//onValidate2
        currentRec.d_acAddr1 = def_d_acAddr1
        currentRec.d_acAddr2 = def_d_acAddr2
        currentRec.d_acAddr3 = def_d_acAddr3
        currentRec.d_mailAddr1 = def_d_mailAddr1
        currentRec.d_mailAddr2 = def_d_mailAddr2
        currentRec.d_mailAddr3 = def_d_mailAddr3

        currentRec.d_email = def_d_email
        currentRec.d_way2GetStmt = def_d_way2GetStmt

        currentRec.d_tel = def_d_tel
        currentRec.d_offTel = def_d_offTel
        currentRec.d_mobile = def_d_mobile
        currentRec.d_mobileCode = def_d_mobileCode // 20211006 Add SMS
        currentRec.d_fax = def_d_fax

//onValidate3        
        currentRec.d_Employment = def_d_Employment
        currentRec.d_EmploymentOther = def_d_EmploymentOther

        currentRec.d_CoName = def_d_CoName

        currentRec.d_isFinInstit = def_d_isFinInstit
        currentRec.d_ApproveFinInstit = def_d_ApproveFinInstit

        currentRec.d_CoAddr = def_d_CoAddr
        currentRec.d_CoType = def_d_CoType
        currentRec.d_CoPost = def_d_CoPost

//onValidate4        
        currentRec.d_NetAsset = def_d_NetAsset
        currentRec.d_AnnualIncome = def_d_AnnualIncome
        currentRec.d_SourceofFund = def_d_SourceofFund

//onValidate5
        currentRec.d_Target1 = "N"
        if (def_d_Target1) {
            currentRec.d_Target1 = "Y";
        } else {
            currentRec.d_Target1 = "N";
        }
        currentRec.d_Target2 = "N"
        if (def_d_Target2) {
            currentRec.d_Target2 = "Y";
        } else {
            currentRec.d_Target2 = "N";
        }
        currentRec.d_Target3 = "N"
        if (def_d_Target3) {
            currentRec.d_Target3 = "Y";
        } else {
            currentRec.d_Target3 = "N";
        }
        currentRec.d_Target4 = "N"
        if (def_d_Target4) {
            currentRec.d_Target4 = "Y";
        } else {
            currentRec.d_Target4 = "N";
        }
        currentRec.d_Target5 = "N"
        if (def_d_Target5) {
            currentRec.d_Target5 = "Y";
        } else {
            currentRec.d_Target5 = "N";
        }
        currentRec.d_Target6 = "N"
        if (def_d_Target6) {
            currentRec.d_Target6 = "Y";
        } else {
            currentRec.d_Target6 = "N";
        }
        currentRec.d_Target7 = "N"
        if (def_d_Target7) {
            currentRec.d_Target7 = "Y";
        } else {
            currentRec.d_Target7 = "N";
        }
        currentRec.d_Target8 = "N"
        if (def_d_Target8) {
            currentRec.d_Target8 = "Y";
        } else {
            currentRec.d_Target8 = "N";
        }
        currentRec.d_Target9 = "N"
        if (def_d_Target9) {
            currentRec.d_Target9 = "Y";
        } else {
            currentRec.d_Target9 = "N";
        }


        currentRec.d_InvestExp = def_d_InvestExp
        currentRec.d_StockYear = def_d_StockYear
        currentRec.d_StockCnt = def_d_StockCnt
        currentRec.d_StockAmt = def_d_StockAmt
        currentRec.d_WarrantYear = def_d_WarrantYear
        currentRec.d_WarrantCnt = def_d_WarrantCnt
        currentRec.d_WarrantAmt = def_d_WarrantAmt
        currentRec.d_OptionYear = def_d_OptionYear
        currentRec.d_OptionCnt = def_d_OptionCnt
        currentRec.d_OptionAmt = def_d_OptionAmt
        currentRec.d_FutureYear = def_d_FutureYear
        currentRec.d_FutureCnt = def_d_FutureCnt
        currentRec.d_FutureAmt = def_d_FutureAmt

        currentRec.d_HighRiskExp = def_d_HighRiskExp

//onValidate6        
        currentRec.d_StaffRelation = def_d_StaffRelation
        currentRec.d_StaffName = def_d_StaffName
        currentRec.d_StaffRelationship = def_d_StaffRelationship

        currentRec.d_selfInstruction =  def_d_selfInstruction
        currentRec.d_InstructionType = def_d_InstructionType

        currentRec.d_hasSpouseClient = def_d_hasSpouseClient
        if ( typeof(currentRec.d_hasSpouseClient) === "undefined" ) {
            currentRec.d_hasSpouseClient = "N"
        }
        currentRec.d_SpouseName = def_d_SpouseName
        if ( typeof(currentRec.d_SpouseName) === "undefined" ) {
            currentRec.d_SpouseName = ""
        }
        currentRec.d_SpouseAcNo = def_d_SpouseAcNo
        if ( typeof(currentRec.d_SpouseAcNo) === "undefined" ) {
            currentRec.d_SpouseAcNo = ""
        }

        currentRec.d_VoteRight = def_d_VoteRight
        if ( typeof(currentRec.d_VoteRight) === "undefined" ) {
            currentRec.d_VoteRight = "N"
        }
        currentRec.d_VoteAc1 = def_d_VoteAc1
        if ( typeof(currentRec.d_VoteAc1) === "undefined" ) {
            currentRec.d_VoteAc1 = ""
        }
        currentRec.d_VoteAcName1 = def_d_VoteAcName1
        if ( typeof(currentRec.d_VoteAcName1) === "undefined" ) {
            currentRec.d_VoteAcName1 = ""
        }
        currentRec.d_VoteRelate1 = def_d_VoteRelate1
        if ( typeof(currentRec.d_VoteRelate1) === "undefined" ) {
            currentRec.d_VoteRelate1 = ""
        }
        currentRec.d_VoteAc2 = def_d_VoteAc2
        if ( typeof(currentRec.d_VoteAc2) === "undefined" ) {
            currentRec.d_VoteAc2 = ""
        }
        currentRec.d_VoteAcName2 = def_d_VoteAcName2
        if ( typeof(currentRec.d_VoteAcName2) === "undefined" ) {
            currentRec.d_VoteAcName2 = ""
        }
        currentRec.d_VoteRelate2 = def_d_VoteRelate2
        if ( typeof(currentRec.d_VoteRelate2) === "undefined" ) {
            currentRec.d_VoteRelate2 = ""
        }


        currentRec.d_isInternet =  def_d_isInternet

//onValidate7

        currentRec.d_JurisdictionofResidence = def_d_JurisdictionofResidence
        //currentRec.d_OtherJurisdictionofResidence = def_d_OtherJurisdictionofResidence
        currentRec.d_TIN = def_d_TIN
        currentRec.d_Juris_Reason = def_d_Juris_Reason
        currentRec.d_JurisdictionofResidence2 = def_d_JurisdictionofResidence2
        currentRec.d_TIN2 = def_d_TIN2
        currentRec.d_Juris_Reason2 = def_d_Juris_Reason2
        currentRec.d_JurisdictionofResidence3 = def_d_JurisdictionofResidence3
        currentRec.d_TIN3 = def_d_TIN3
        currentRec.d_Juris_Reason3 = def_d_Juris_Reason3
        currentRec.d_JurisdictionofResidence4 = def_d_JurisdictionofResidence4
        currentRec.d_TIN4 = def_d_TIN4
        currentRec.d_Juris_Reason4 = def_d_Juris_Reason4
        currentRec.d_JurisdictionofResidence5 = def_d_JurisdictionofResidence5
        currentRec.d_TIN5 = def_d_TIN5
        currentRec.d_Juris_Reason5 = def_d_Juris_Reason5

//onValidate7b

        currentRec.d_w8b_country = def_d_w8b_country

        currentRec.d_w8b_01 = "Y"
        if (def_d_w8b_01) {
            currentRec.d_w8b_01 = "Y";
        } else {
            currentRec.d_w8b_01 = "N";
        }
        currentRec.d_w8b_02 = "Y"
        if (def_d_w8b_02) {
            currentRec.d_w8b_02 = "Y";
        } else {
            currentRec.d_w8b_02 = "N";
        }
        currentRec.d_w8b_03 = "Y"
        if (def_d_w8b_03) {
            currentRec.d_w8b_03 = "Y";
        } else {
            currentRec.d_w8b_03 = "N";
        }
        currentRec.d_w8b_04 = "Y"
        if (def_d_w8b_04) {
            currentRec.d_w8b_04 = "Y";
        } else {
            currentRec.d_w8b_04 = "N";
        }
        currentRec.d_w8b_05 = "Y"
        if (def_d_w8b_05) {
            currentRec.d_w8b_05 = "Y";
        } else {
            currentRec.d_w8b_05 = "N";
        }


//onValidate8
        currentRec.d_BankInfo = def_d_BankInfo
        currentRec.d_BankACNo = def_d_BankACNo



//onValidate9
        currentRec.d_Agree = "N"
        if (def_d_Agree) {
            currentRec.d_Agree = "Y";
        } else {
            currentRec.d_Agree = "N";
        }





        currentRec.d_verify_ok1 = def_d_verify_ok1
        currentRec.d_verify_Date = def_d_verify_Date
        currentRec.d_verify_Time = def_d_verify_Time
        currentRec.d_FacetoFace = "N"

        currentRec.lang_reg = i18n.languages.toString()


        if ( file1Label == "Choose file  ...  " ) {
            currentRec.UploadFile1 = ""
        } else {
            currentRec.UploadFile1 = file1Label
        }
        if ( file1bLabel == "Choose file  ...  " ) {
            currentRec.UploadFile1b = ""
        } else {
            currentRec.UploadFile1b = file1bLabel
        }
        if ( file2Label == "Choose file  ...  " ) {
            currentRec.UploadFile2 = ""
        } else {
            currentRec.UploadFile2 = file2Label
        }
        if ( file3Label == "Choose file  ...  " ) {
            currentRec.UploadFile3 = ""
        } else {
            currentRec.UploadFile3 = file3Label
        } ;




        savesignature() ;
        
        ;

        (async () => {
            try {
                console.log("call onValidate9 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "AstrumValidate9", isNew: true})
                //console.log("onValidate9 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(currentRec)

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }




    const onNext = (data: Account) => {
    
    }
    
    const onUpload = (e: FormEvent) => {
        e.preventDefault();
        setdef_Uploading(true);
        
        (async () => {
            try {
                const res = await OpenAcc.post('/importupload',
                    new FormData(e.target as HTMLFormElement), {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setdef_Uploading(false)
                setnUploaded(res.data.uploaded)
                utils.showSuccess(`${res.data.uploaded} file(s) uploaded`)

            } catch (error) {
                setdef_Uploading(false)
                utils.showError(error)
            }
        })()
        setdef_Uploading(false)
    }


    const onSave = async (ac: Account) => {
        try {

            console.log("call onSave ac", ac)
            const res = await OpenAcc.post('/Master0', {sb_data: ac, cPgmId: "OpenAcc", cAction: "AddRec"})
            //console.log("onSave res.data.ok", res.data.ok)
            console.log("onSave res.data", res.data)
            //showSuccess("Account Code " + ac.d_idNo + " record added")

            //currentRec.d_inpputDate = res.data.data.d_inpputDate
            //currentRec.d_inpputTime = res.data.data.d_inpputTime
            //setdef_d_inpputDate(currentRec.d_inpputDate)
            //setdef_d_inpputTime(currentRec.d_inpputTime)
        
            console.log("onSave currentRec", currentRec)

            if ( res.data.ok )
            {
                setlFinishe(true)
                utils.showSuccess(t('OpenFinish02'))
            }

        } catch (error) {
            showError(error)
        }
    }

    const onVerify = (lcn: Account) => {
        console.log("onVerify called", lcn)


        setdef_d_email(lcn.d_email)
        setdef_d_mobile(lcn.d_mobile)
        lcn.d_mobileCode = def_d_mobileCode
        setdef_d_verify_code1(lcn.d_verify_code1)

        currentRec.d_acName1 = def_d_acName1
        currentRec.d_acName2 = def_d_acName2
        currentRec.d_idNo = def_d_idNo

        currentRec.d_email = lcn.d_email
        currentRec.d_mobile = lcn.d_mobile
        currentRec.d_mobileCode = lcn.d_mobileCode  // 20211006 Add SMS
        currentRec.d_verify_code1 = lcn.d_verify_code1 
        currentRec.lang_reg = i18n.languages.toString() ;

        (async () => {
            try {
            const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "verifyemailfirst"})
            console.log("verifyemailfirst res.data", res.data)

            if (! res.data.ok) {
                for (let j = 0; j < res.data.data.length; j++) {
                  
                    setError(res.data.data[j][0], {
                       message: res.data.data[j][1]
                    }); 
                }
                return
            }

            currentRec.d_verify_ok1 = res.data.data.d_verify_ok1
            currentRec.d_verify_Date = res.data.data.d_verify_Date
            currentRec.d_verify_Time = res.data.data.d_verify_Time
            setdef_d_verify_ok1(currentRec.d_verify_ok1)
            setdef_d_verify_Date(currentRec.d_verify_Date)
            setdef_d_verify_Time(currentRec.d_verify_Time)

            PageNo(+1);

/*
            if ( res.data.ok )
            {
                setlFinishe(true)
                utils.showSuccess(t('OpenFinish02'))
            }
*/
        } catch (error) {
            utils.showError(error)
        }
        })()
    }


    const onResend1 = async (lcn: Account) => {
        console.log("onResend1 called", lcn)
        
        setdef_isResend(true)


        setdef_d_email(lcn.d_email)
        setdef_d_verify_code1(lcn.d_verify_code1)

        currentRec.d_acName1 = def_d_acName1
        currentRec.d_acName2 = def_d_acName2
        currentRec.d_idNo = def_d_idNo

        currentRec.d_email = lcn.d_email
        currentRec.d_verify_code1 = lcn.d_verify_code1 ;

        currentRec.lang_reg = i18n.languages.toString()

        if ( lcn.d_email.length > 0) {
            try {
                console.log("call onResend1 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "verifyresend"})
                console.log("onResend1 res.data", res.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                if ( res.data.ok )
                {
                    utils.showSuccess(t('VerifyCode') +" "+ t('VerifyCode01') +" "+ lcn.d_email)
                    setError("d_email", {
                        message: t('VerifyCode') +" "+ t('VerifyCode01') +" "+ lcn.d_email
                    }); 
                             
                 } else {
                    utils.showSuccess(t('VerifyCode') +" "+ t('VerifyCode02'))
                    setError("d_email", {
                        message: t('VerifyCode') +" "+ t('VerifyCode02')
                     }); 
                }
            } catch (error) {
                utils.showError(error)
            }
        } else {
            utils.showError("Insufficient Ddata")
        }
    }


    const onResend2 = async (lcn: Account) => {
        console.log("onResend2 called", lcn)
        
        setdef_isResend(true)


        setdef_d_mobile(lcn.d_mobile)
        lcn.d_mobileCode = def_d_mobileCode
        setdef_d_verify_code1(lcn.d_verify_code1)

        currentRec.d_acName1 = def_d_acName1
        currentRec.d_acName2 = def_d_acName2
        currentRec.d_idNo = def_d_idNo

        currentRec.d_email = lcn.d_email
        currentRec.d_mobile = lcn.d_mobile
        currentRec.d_mobileCode = lcn.d_mobileCode  // 20211006 Add SMS
        currentRec.d_verify_code1 = lcn.d_verify_code1 
        currentRec.lang_reg = i18n.languages.toString() ;

        if ( lcn.d_mobile.length > 0) {
            try {
                console.log("call onResend2 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "verifyresend2"})
                console.log("onResend2 res.data", res.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                if ( res.data.ok )
                {
                    utils.showSuccess(t('VerifySMSCode') +" "+ t('VerifyCode01') + " " + def_d_mobileCode + " " + lcn.d_mobile) // 20211008 SMS
                    setError("d_mobile", {
                       // message: "SMS 驗證碼 已經寄出"
                       message: t('VerifySMSCode') +" "+ t('VerifyCode01') + " " + def_d_mobileCode + " " + lcn.d_mobile    // 20211008 SMS
                    }); 
                             
                 } else {
                    utils.showSuccess(t('VerifySMSCode') +" "+ t('VerifyCode02'))
                    setError("d_mobile", {
                        //message: "SMS 驗證碼 寄出失敗"
                        message: t('VerifySMSCode') +" "+ t('VerifyCode02')
                    }); 
                }
            } catch (error) {
                utils.showError(error)
            }
        } else {
            utils.showError("Insufficient Ddata")
        }
    }


    const JurisdictionRenderer: ItemRenderer<Jurisdiction> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${ac.Jurisdiction}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ac.CountryCode}
                key={ac.CountryCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const JurisdictionPredicate: ItemPredicate<Jurisdiction> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.CountryCode} - ${ac.Jurisdiction}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const JurisdictionValueRenderer = (ac: Jurisdiction) => {
        return ac.CountryCode
    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }


    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onValidate1)()
        }
    }

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

 
    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n >= 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


    let CoHead = <>
        <span id="DispCoName2" style={{fontSize: "20px", fontWeight: 500}}> {cologo2}  </span>
        {/*
        <span id="DispCoName2" style={{fontSize: "20px", fontWeight: 500}}> {cologo2} <b> { CO_NAME } </b> </span>
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
        */}
    </>

    let lngList = [
        {lngName: "Eng ", lng: "en"},
        {lngName: "繁體 ", lng: "zh-HK"},
        {lngName: "简体 ", lng: "zh-CN"},
    ]

    return (
        <>
        {/* <form onKeyDown={handleHotkey}> */}
{/*
            { MoblieDev && ( nPage == 0 ) && <div >
                <div className="Spacing-V-16" />
                <div id="DispText2">This Web site not support Phone / Mobile / Tablet Device(s).</div>
                <div id="DispText2">The Phone / Mobile / Tablet Device(s), please download Apps.</div>
                <div className="Spacing-V-16" />
            </div>}
*/}
{/*
            <div>{navigator.platform}</div>
            <div>{navigator.userAgent}</div>
*/}            
            { ! lFinishe && ( nPage == 0 ) && <div >
                <div className="Spacing-V-16" />
                <table style={{width: "100%"}}>
                    <tbody id="lang_tr">
                        <tr >
                            <td className="langtd1" > </td>
                            {lngList.map((lng) =>
                                <td onClick={() => {i18n.changeLanguage(lng.lng)}} > {lng.lngName}</td>
                             )}
                        </tr>
                    </tbody >
                </table>
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenACcount')}
                {/*
                <span ><tbody >
                        <tr >
                            <td > </td>
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                        </tr>
                </tbody></span> */}
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <h4 className="h4-heading">{t('OpenCondit')}</h4>
                    <ul>
                        <li>{t('OpenCondit01')}</li>
                    </ul>
                    <div className="Spacing-V-16" />
                    <div className="Spacing-V-16" />
                    <h3 className="h4-heading">{t('OpenCondit02')}</h3>
                    <ul className="bp3-list">
                        <li>{t('OpenCondit03')}</li>
                        <li>{t('OpenCondit04')}</li>
                        <li>{t('OpenCondit05')}</li>
                        <li>{t('OpenCondit06')}<code className='bp3-code'>{t('OpenCondit07')}</code>{t('OpenCondit08')}</li>
                    </ul>
                    <div className="Spacing-V-16" />
                    <div className="Spacing-V-16" />
                    <div className="Spacing-V-16" />
                    {/* <Button icon="direction-left" disabled={true} onClick={ () =>PageNo(-1) }>{t('Prev')}</Button> */}
                    &nbsp; &nbsp; &nbsp;
                    <Button icon="direction-right" onClick={ () =>PageNo(+1) }>{t('StartOpenACcount')}</Button>
                </Card>
                MarginAgreement_C.pdf 
                <div style={{overflowY: "scroll", height: "500px", width: "960px"}}>
                    <Document
                    //file="test.pdf" // "Cash Client's Agreement (E).pdf"
                    file="astrum_MarginAgreement_C.pdf"
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading="Loading PDF ..."
                    >
                    {[...Array(numPages)].map((_, i) => <Page pageNumber={i+1} width={940}/>)}
                    </Document>
                </div>
            </div>}


            { ! lFinishe && ( nPage == 1 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('EmailVerification')} 
                    &nbsp; &nbsp; &nbsp;
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span> 
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    { (SendVmeth == "1") && <div className="Row">
                        <FormGroup className="Col-1-3" label={t('EmailAddress')} labelFor="inputEmail" labelInfo="*">
                            <InputGroup id="inputEmail" name="d_email" autoFocus={true} type="email" defaultValue="" inputRef={register({ required: true , maxLength: EmailLength , pattern: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/ }) } />
                            {errors.d_email && errors.d_email.types && <span id="Err_msg">{errors.d_email.types.message}</span>}
                            {errors.d_email && errors.d_email.type === "required" && (<span id="Err_msg">{t('EmailAddress')} {t('NotBlank')}</span>)}
                            {errors.d_email && errors.d_email.type === "maxLength" && <span id="Err_msg">{t('Max')} {EmailLength} {t('character')}</span>}
                            {errors.d_email && errors.d_email.type === "pattern" && <span id="Err_msg">{t('EmailAddress')} {t('EmailInvalid')}</span>}
                        </FormGroup>
                        { ! def_isResend && <Button className="Col-4-2 Button-LabeledFormGroup" icon="repeat" onClick={handleSubmit(onResend1)}>{t('Send')} {t('VerifyCode')}</Button> }
                        { def_isResend && <Button className="Col-4-2 Button-LabeledFormGroup" icon="repeat" onClick={handleSubmit(onResend1)}>{t('Resend')} {t('VerifyCode')}</Button> }
                    </div>
                    }
                    { (SendVmeth == "2") && <div className="Row">
                        <FormGroup className="Col-1-2" label={t('MobilePhoneCode')} labelFor="inputMobileArea" labelInfo="*">
                            {/* 20211006 Add SMS*/}
                            <HTMLSelect id="inputMobileArea" name="d_mobileCode" value={def_d_mobileCode} className={Classes.FIXED} onChange={utils.handleStringChange(s => setdef_d_mobileCode(s)) }>
                                <option value="852">{t('AreaCodeHKG')} +852</option>
                                <option value="86">{t('AreaCodeCHN')} +86</option>
                                <option value="853">{t('AreaCodeMAC')} +853</option>
                                {/* <option value="886">{t('AreaCodeTWN')} +886</option>
                                <option value="">{t('AreaCodeOther')}</option> */}
                            </HTMLSelect>
                            {errors.d_mobileCode && errors.d_mobileCode.types && <span id="Err_msg">{errors.d_mobileCode.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label={t('MobilePhone')} labelFor="inputMobile" labelInfo="*">
                            <InputGroup id="inputMobile" name="d_mobile" defaultValue={def_d_mobile} placeholder={t('MobilePhone')} inputRef={register({ required: true , minLength: 8 , maxLength: MobileLength}) } />
                            {errors.d_mobile && errors.d_mobile.types && <span id="Err_msg">{errors.d_mobile.types.message}</span>}
                            {errors.d_mobile && errors.d_mobile.type === "required" && (<span id="Err_msg">{t('MobilePhone')} {t('NotBlank')}</span>)}
                            {errors.d_mobile && errors.d_mobile.type === "maxLength" && <span id="Err_msg">{t('Max')} {MobileLength} {t('character')}</span>}
                            {errors.d_mobile && errors.d_mobile.type === "minLength" && <span id="Err_msg">{t('Min')} 8 {t('character')}</span>}
                        </FormGroup>
                        { ! def_isResend && <Button className="Col-5-2 Button-LabeledFormGroup" icon="repeat" onClick={handleSubmit(onResend2)}>{t('Send')} {t('VerifySMSCode')}</Button> }
                        { def_isResend && <Button className="Col-5-2 Button-LabeledFormGroup" icon="repeat" onClick={handleSubmit(onResend2)}>{t('Resend')} {t('VerifySMSCode')}</Button> }
                    </div>
                    }
                    {/* 20210927 SMS */}
                    { (SendVmeth == "1") && <div className="Row">
                        <FormGroup className="Col-1-2" label={t('VerifyCode')} labelFor="inputCode" labelInfo="*">
                            <InputGroup id="inputCode" name="d_verify_code1" inputRef={register({ maxLength: 10}) } />
                            {errors.d_verify_code1 && errors.d_verify_code1.types && <span id="Err_msg">{errors.d_verify_code1.types.message}</span>}
                            {errors.d_verify_code1 && errors.d_verify_code1.type === "maxLength" && <span id="Err_msg">{t('Max')} 10 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    }
                    { (SendVmeth == "2") && <div className="Row">
                        <FormGroup className="Col-1-2" label={t('VerifySMSCode')} labelFor="inputCode" labelInfo="*">
                            <InputGroup id="inputCode" name="d_verify_code1" inputRef={register({ maxLength: 10}) } />
                            {errors.d_verify_code1 && errors.d_verify_code1.types && <span id="Err_msg">{errors.d_verify_code1.types.message}</span>}
                            {errors.d_verify_code1 && errors.d_verify_code1.type === "maxLength" && <span id="Err_msg">{t('Max')} 10 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    }
                    
                    <div className="Spacing-V-16" />
                    <blockquote className="bp3-blockquote">
                        <span id="DispText2">{t('OpenTerms01')}</span>
                    </blockquote>
                    <div className="Spacing-V-16" />
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onVerify) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>
            </div>}



            { ! lFinishe && ( nPage == 2 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenClientType')}
                    &nbsp; &nbsp; &nbsp;
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span>
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('ClientType')} labelFor="InputClientType" labelInfo="*">
                            {/* <Switch id="InputClientType" autoFocus={true} label="" innerLabelChecked={t('ClientType01')} innerLabel={t('ClientType02')} checked={def_d_ClientType} onChange={utils.handleBooleanChange(v => setdef_d_ClientType(v))} ref={register}/> */}
                            <RadioGroup
                            name="def_d_ClientType"
                            inline={true}
                            selectedValue={def_d_ClientType}
                            onChange={utils.handleStringChange(s => setdef_d_ClientType(s)) }
                            ref={register}
                        >
                            <Radio label={t('ClientType01')} value="C" />
                            <Radio label={t('ClientType02')}  value="M" />
                        </RadioGroup>
                            {errors.d_ClientType && errors.d_ClientType.types && <span id="Err_msg">{errors.d_ClientType.types.message}</span>}
                            {errors.d_ClientType && errors.d_ClientType.type === "required" && (<span id="Err_msg">{t('ClientType')} {t('NotBlank')}</span>)}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <h3 className="h4-heading">{t('ClientType03')}</h3>
                    <div className="Row">
                        <span id="DispText2">{t('ClientType04')}</span>
                    </div>
                    <div className="Spacing-V-16" />
                    <h3 className="h4-heading">{t('ClientType05')}</h3>
                    <div className="Row">
                        <span id="DispText2">{t('ClientType06')}</span>
                    </div>
                    <div className="Spacing-V-16" />
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" disabled={true} onClick={ handleSubmit(onPrev1a) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate1a) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>
            </div>}



            { ! lFinishe && ( nPage == 3 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('PersonInfo')}
                    &nbsp; &nbsp; &nbsp;
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span>
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <FormGroup className="Col-1-3" label={t('NameEng')} labelFor="inputAcName1" labelInfo="*">
                            <InputGroup id="inputAcName1" name="d_acName1" autoFocus={true} defaultValue={def_d_acName1} placeholder={t('NameEng')} inputRef={register({ required: true , maxLength: 30}) } />
                            {errors.d_acName1 && errors.d_acName1.types && <span id="Err_msg">{errors.d_acName1.types.message}</span>}
                            {errors.d_acName1 && errors.d_acName1.type === "required" && (<span id="Err_msg">{t('NameEng')} {t('NotBlank')}</span>)}
                            {errors.d_acName1 && errors.d_acName1.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-3" label={t('NameChi')} labelFor="inputAcName2" labelInfo="*">
                            <InputGroup id="inputAcName2" name="d_acName2" defaultValue={def_d_acName2} placeholder={t('NameChi')} inputRef={register({ required: true ,maxLength: 30}) } />
                            {errors.d_acName2 && errors.d_acName2.types && <span id="Err_msg">{errors.d_acName2.types.message}</span>}
                            {errors.d_acName2 && errors.d_acName2.type === "required" && (<span id="Err_msg">{t('NameChi')} {t('NotBlank')}</span>)}
                            {errors.d_acName2 && errors.d_acName2.type === "maxLength" && <span id="Err_msg">{t('Max')}  30 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label={t('HKIdNo')} labelFor="inputIdNo" labelInfo="*">
                            <InputGroup id="inputIdNo" name="d_idNo" defaultValue={def_d_idNo} placeholder={t('HKIdNo')}  inputRef={register({ required: true , maxLength: idNoLength}) } />
                            {errors.d_idNo && errors.d_idNo.types && <span id="Err_msg">{errors.d_idNo.types.message}</span>}
                            {errors.d_idNo && errors.d_idNo.type === "required" && (<span id="Err_msg">{t('HKIdNo')} {t('NotBlank')}</span>)}
                            {errors.d_idNo && errors.d_idNo.type === "maxLength" && <span id="Err_msg">{t('Max')}  {idNoLength} {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <span id="DispText2">{t('HKIdNo2')}</span>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col3-1-1" label={t('Sex')} labelFor="inputd_Sex" labelInfo="*">
                            <HTMLSelect  id="inputd_Sex" name="d_Sex" value={def_d_Sex} onChange={utils.handleStringChange(s => setdef_d_Sex(s)) } ref={register}>
                                <option value="M">M: {t('Sex1')}</option>
                                <option value="F">F: {t('Sex2')}</option>
                            </HTMLSelect>
                            {errors.d_Sex && errors.d_Sex.types && <span id="Err_msg">{errors.d_Sex.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label={t('DateofBirth')} labelFor="inputDoB" labelInfo="*">
                            <InputGroup id="inputDoB" name="d_dob" type="date" defaultValue={def_d_dob} placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register({ required: true }) } /> 
                            {errors.d_dob && errors.d_dob.types && <span id="Err_msg">{errors.d_dob.types.message}</span>}
                            {errors.d_dob && errors.d_dob.type === "required" && (<span id="Err_msg">{t('DateofBirth')} {t('NotBlank')}</span>)}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label={t('Nationality')} labelFor="inputCountry" labelInfo="*">
                            <InputGroup id="inputCountry" name="d_National" defaultValue={def_d_National} placeholder={t('Nationality')} inputRef={register({ required: true , maxLength: 40}) } />
                            {errors.d_National && errors.d_National.types && <span id="Err_msg">{errors.d_National.types.message}</span>}
                            {errors.d_National && errors.d_National.type === "required" && (<span id="Err_msg">{t('Nationality')} {t('NotBlank')}</span>)}
                            {errors.d_National && errors.d_National.type === "maxLength" && <span id="Err_msg">{t('Max')} 40 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev1) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate1) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>
            </div>}


            { ! lFinishe && ( nPage == 4 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenCommunication')}
                    &nbsp; &nbsp; &nbsp;
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span> 
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <FormGroup className="Col-1-4" label={t('ResidentialAddress')} labelFor="inputAddr1" labelInfo="*">
                            <InputGroup id="inputAddr1" name="d_acAddr1" autoFocus={true} defaultValue={def_d_acAddr1} placeholder={t('ResidentialAddress')} inputRef={register({ required: true , maxLength: AddrLength}) } />
                            {errors.d_acAddr1 && errors.d_acAddr1.types && <span id="Err_msg">{errors.d_acAddr1.types.message}</span>}
                            {errors.d_acAddr1 && errors.d_acAddr1.type === "required" && (<span id="Err_msg">{t('ResidentialAddress')} {t('NotBlank')}</span>)}
                            {errors.d_acAddr1 && errors.d_acAddr1.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                            <InputGroup id="inputAddr2" name="d_acAddr2" defaultValue={def_d_acAddr2} placeholder="" inputRef={register({ required: true , maxLength: AddrLength}) } />
                            {errors.d_acAddr2 && errors.d_acAddr2.types && <span id="Err_msg">{errors.d_acAddr2.types.message}</span>}
                            {errors.d_acAddr2 && errors.d_acAddr2.type === "required" && (<span id="Err_msg">{t('ResidentialAddress')} {t('NotBlank')}</span>)}
                            {errors.d_acAddr2 && errors.d_acAddr2.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                            <InputGroup id="inputAddr3" name="d_acAddr3" defaultValue={def_d_acAddr3} placeholder="" inputRef={register({ maxLength: AddrLength}) } />
                            {errors.d_acAddr3 && errors.d_acAddr3.types && <span id="Err_msg">{errors.d_acAddr3.types.message}</span>}
                            {errors.d_acAddr3 && errors.d_acAddr3.type === "required" && (<span id="Err_msg">{t('ResidentialAddress')} {t('NotBlank')}</span>)}
                            {errors.d_acAddr3 && errors.d_acAddr3.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" label={t('CorrespondenceAddress2')} labelFor="inputmailAddr1" >
                            <InputGroup id="inputmailAddr1" name="d_mailAddr1" defaultValue={def_d_mailAddr1} placeholder={t('CorrespondenceAddress')} inputRef={register({ maxLength: AddrLength}) } />
                            {errors.d_mailAddr1 && errors.d_mailAddr1.types && <span id="Err_msg">{errors.d_mailAddr1.types.message}</span>}
                            {errors.d_mailAddr1 && errors.d_mailAddr1.type === "required" && (<span id="Err_msg">{t('CorrespondenceAddress')} {t('NotBlank')}</span>)}
                            {errors.d_mailAddr1 && errors.d_mailAddr1.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                            <InputGroup id="inputmailAddr2" name="d_mailAddr2" defaultValue={def_d_mailAddr2} placeholder="" inputRef={register({ maxLength: AddrLength}) } />
                            {errors.d_mailAddr2 && errors.d_mailAddr2.types && <span id="Err_msg">{errors.d_mailAddr2.types.message}</span>}
                            {errors.d_mailAddr2 && errors.d_mailAddr2.type === "required" && (<span id="Err_msg">{t('CorrespondenceAddress')} {t('NotBlank')}</span>)}
                            {errors.d_mailAddr2 && errors.d_mailAddr2.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                            <InputGroup id="inputmailAddr3" name="d_mailAddr3" defaultValue={def_d_mailAddr3} placeholder="" inputRef={register({ maxLength: AddrLength}) } />
                            {errors.d_mailAddr3 && errors.d_mailAddr3.types && <span id="Err_msg">{errors.d_mailAddr3.types.message}</span>}
                            {errors.d_mailAddr3 && errors.d_mailAddr3.type === "required" && (<span id="Err_msg">{t('CorrespondenceAddress')} {t('NotBlank')}</span>)}
                            {errors.d_mailAddr3 && errors.d_mailAddr3.type === "maxLength" && <span id="Err_msg">{t('Max')} {AddrLength} {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('EmailAddress')} labelFor="inputEmail" labelInfo="*">
                            <InputGroup id="inputEmail" disabled={SendVmeth == "1"} name="d_email" type="email" defaultValue={def_d_email} placeholder={t('EmailAddress')} inputRef={register({ required: true , maxLength: EmailLength , pattern: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/ }) } />
                            {errors.d_email && errors.d_email.types && <span id="Err_msg">{errors.d_email.types.message}</span>}
                            {errors.d_email && errors.d_email.type === "required" && (<span id="Err_msg">{t('EmailAddress')} {t('NotBlank')}</span>)}
                            {errors.d_email && errors.d_email.type === "maxLength" && <span id="Err_msg">{t('Max')} {EmailLength} {t('character')}</span>}
                            {errors.d_email && errors.d_email.type === "pattern" && <span id="Err_msg">{t('EmailAddress')} {t('EmailInvalid')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col3-1-3" label={t('way2GetStmt')} labelFor="inputway2GetStmt">
                            <HTMLSelect  id="inputway2GetStmt" name="d_way2GetStmt" value={def_d_way2GetStmt} onChange={utils.handleStringChange(s => setdef_d_way2GetStmt(s)) } ref={register}>
                                <option value="1">1: {t('way2GetStmt01')}</option>
                                <option value="2">2: {t('way2GetStmt02')}</option>
                                <option value="3">3: {t('way2GetStmt03')}</option>
                            </HTMLSelect>
                            {errors.d_way2GetStmt && errors.d_way2GetStmt.types && <span id="Err_msg">{errors.d_way2GetStmt.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label={t('TelNoRes')} labelFor="inputTel">
                            <InputGroup id="inputTel" name="d_tel" defaultValue={def_d_tel} placeholder={t('TelNoRes')} inputRef={register({ maxLength: TelLength}) } />
                            {errors.d_tel && errors.d_tel.types && <span id="Err_msg">{errors.d_tel.types.message}</span>}
                            {errors.d_tel && errors.d_tel.type === "maxLength" && <span id="Err_msg">{t('Max')} {TelLength} {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label={t('TelNoOff')} labelFor="inputoffTel">
                            <InputGroup id="inputoffTel" name="d_offTel" defaultValue={def_d_offTel} placeholder={t('TelNoOff')} inputRef={register({ maxLength: OffTelLength}) } />
                            {errors.d_offTel && errors.d_offTel.types && <span id="Err_msg">{errors.d_offTel.types.message}</span>}
                            {errors.d_offTel && errors.d_offTel.type === "maxLength" && <span id="Err_msg">{t('Max')} {OffTelLength} {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label={t('MobilePhoneCode')} labelFor="inputMobileArea" labelInfo="*">
                            {/* 20211006 Add SMS*/}
                            <HTMLSelect id="inputMobileArea" disabled={SendVmeth == "2"} name="d_mobileCode" value={def_d_mobileCode} className={Classes.FIXED} onChange={utils.handleStringChange(s => setdef_d_mobileCode(s)) }>
                                <option value="852">{t('AreaCodeHKG')} +852</option>
                                <option value="86">{t('AreaCodeCHN')} +86</option>
                                <option value="853">{t('AreaCodeMAC')} +853</option>
                                {/* <option value="886">{t('AreaCodeTWN')} +886</option>
                                <option value="">{t('AreaCodeOther')}</option> */}
                            </HTMLSelect>
                            {errors.d_mobileCode && errors.d_mobileCode.types && <span id="Err_msg">{errors.d_mobileCode.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label={t('MobilePhone')} labelFor="inputMobile" labelInfo="*">
                            <InputGroup id="inputMobile" disabled={SendVmeth == "2"} name="d_mobile" defaultValue={def_d_mobile} placeholder={t('MobilePhone')} inputRef={register({ required: true , minLength: 8 , maxLength: MobileLength}) } />
                            {errors.d_mobile && errors.d_mobile.types && <span id="Err_msg">{errors.d_mobile.types.message}</span>}
                            {errors.d_mobile && errors.d_mobile.type === "required" && (<span id="Err_msg">{t('MobilePhone')} {t('NotBlank')}</span>)}
                            {errors.d_mobile && errors.d_mobile.type === "maxLength" && <span id="Err_msg">{t('Max')} {MobileLength} {t('character')}</span>}
                            {errors.d_mobile && errors.d_mobile.type === "minLength" && <span id="Err_msg">{t('Min')} 8 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label={t('Fax')} labelFor="inputFax">
                            <InputGroup id="inputFax" name="d_fax" defaultValue={def_d_fax} placeholder={t('Fax')} inputRef={register({ maxLength: FaxLength}) } />
                            {errors.d_fax && errors.d_fax.types && <span id="Err_msg">{errors.d_fax.types.message}</span>}
                            {errors.d_fax && errors.d_fax.type === "maxLength" && <span id="Err_msg">{t('Max')} {FaxLength} {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <span id="DispText2">{t('MobilePhone2')}</span>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev2) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate2) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>
            </div>}


            { ! lFinishe && ( nPage == 5 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenEmployment')}
                    &nbsp; &nbsp; &nbsp;
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span> 
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <FormGroup className="Col3-1-1" label={t('Employment1')} labelFor="inputEmployment" labelInfo="*">
                            <HTMLSelect  id="inputEmployment" name="d_Employment" autoFocus={true} value={def_d_Employment} onChange={utils.handleStringChange(s => setdef_d_Employment(s)) } ref={register}>
                                <option value="1">1: {t('Employment01')}</option>
                                <option value="2">2: {t('Employment02')}</option>
                                <option value="3">3: {t('Employment03')}</option>
                                <option value="4">4: {t('Employment04')}</option>
                                <option value="5">5: {t('Employment05')}</option>
                                <option value="6">6: {t('Employment06')}</option>
                            </HTMLSelect>
                            {errors.d_Employment && errors.d_Employment.types && <span id="Err_msg">{errors.d_Employment.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-2-4" label={t('Employment2')} labelFor="inputEduOther">
                            <InputGroup id="inputEduOther" name="d_EmploymentOther" defaultValue={def_d_EmploymentOther} placeholder={t('Employment2')} inputRef={register({ maxLength: 50}) } />
                            {errors.d_EmploymentOther && errors.d_EmploymentOther.types && <span id="Err_msg">{errors.d_EmploymentOther.types.message}</span>}
                            {errors.d_EmploymentOther && errors.d_EmploymentOther.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('Empl_CoName')} labelFor="inputCoName">
                            <InputGroup id="inputCoName" name="d_CoName" defaultValue={def_d_CoName} placeholder={t('Empl_CoName')} inputRef={register({ maxLength: 100}) } />
                            {errors.d_CoName && errors.d_CoName.types && <span id="Err_msg">{errors.d_CoName.types.message}</span>}
                            {errors.d_CoName && errors.d_CoName.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('Empl_FinInstruction')} labelFor="InputisFinInstit">
                            {/* <Switch id="InputisFinInstit" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_isFinInstit} onChange={utils.handleBooleanChange(v => setdef_d_isFinInstit(v))} ref={register}/> */}
                            <RadioGroup
                            name="def_d_isFinInstit"
                            inline={true}
                            selectedValue={def_d_isFinInstit}
                            onChange={utils.handleStringChange(s => setdef_d_isFinInstit(s)) }
                            ref={register}
                        >
                            <Radio label={t('cYes')} value="Y" />
                            <Radio label={t('cNo')}  value="N" />
                        </RadioGroup>
                            {errors.d_isFinInstit && errors.d_isFinInstit.types && <span id="Err_msg">{errors.d_isFinInstit.types.message}</span>}
                            {errors.d_isFinInstit && errors.d_isFinInstit.type === "required" && (<span id="Err_msg">{t('Empl_FinInstruction')} {t('NotBlank')}</span>)}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('Empl_approveFinInstruction')} labelFor="InputApproveFinInstit">
                            {/* <Switch id="InputApproveFinInstit" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_ApproveFinInstit} onChange={utils.handleBooleanChange(v => setdef_d_ApproveFinInstit(v))} ref={register}/> */}
                            <RadioGroup
                            name="def_d_ApproveFinInstit"
                            inline={true}
                            selectedValue={def_d_ApproveFinInstit}
                            onChange={utils.handleStringChange(s => setdef_d_ApproveFinInstit(s)) }
                            ref={register}
                        >
                            <Radio label={t('cYes')} value="Y" />
                            <Radio label={t('cNo')}  value="N" />
                        </RadioGroup>
                            {errors.d_ApproveFinInstit && errors.d_ApproveFinInstit.types && <span id="Err_msg">{errors.d_ApproveFinInstit.types.message}</span>}
                            {errors.d_ApproveFinInstit && errors.d_ApproveFinInstit.type === "required" && (<span id="Err_msg">{t('Empl_approveFinInstruction')} {t('NotBlank')}</span>)}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('Empl_CoAddr')} labelFor="inputCoAddr">
                            <InputGroup id="inputCoAddr" name="d_CoAddr" defaultValue={def_d_CoAddr} placeholder={t('Empl_CoAddr')} inputRef={register({ maxLength: 150}) } />
                            {errors.d_CoAddr && errors.d_CoAddr.types && <span id="Err_msg">{errors.d_CoAddr.types.message}</span>}
                            {errors.d_CoAddr && errors.d_CoAddr.type === "maxLength" && <span id="Err_msg">{t('Max')} 150 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label={t('Empl_CoType')} labelFor="inputCoType">
                            <InputGroup id="inputCoType" name="d_CoType" defaultValue={def_d_CoType} placeholder={t('Empl_CoType')} inputRef={register({ maxLength: 30}) } />
                            {errors.d_CoType && errors.d_CoType.types && <span id="Err_msg">{errors.d_CoType.types.message}</span>}
                            {errors.d_CoType && errors.d_CoType.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-3" label={t('Empl_CoPost')} labelFor="inputCoPost">
                            <InputGroup id="inputCoPost" name="d_CoPost" defaultValue={def_d_CoPost} placeholder={t('Empl_CoPost')} inputRef={register({ maxLength: 30}) } />
                            {errors.d_CoPost && errors.d_CoPost.types && <span id="Err_msg">{errors.d_CoPost.types.message}</span>}
                            {errors.d_CoPost && errors.d_CoPost.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev3) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate3) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>                    
            </div>}


            { ! lFinishe && ( nPage == 6 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenFinStatus')}
                    &nbsp; &nbsp; &nbsp;
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span> 
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <FormGroup className="Col3-1-3" label={t('NetAsset')} labelFor="inputNetAsset" labelInfo="*">
                            {/* <InputGroup className="inputNumberNoSpin" id="inputNetAsset" name="d_NetAsset" defaultValue={def_d_NetAsset} type="number" inputRef={register} />  */}
                            {/* <InputGroup id="inputNetAsset" name="d_NetAsset" defaultValue={def_d_NetAsset} placeholder="資產淨值 ..." inputRef={register({ maxLength: 15}) } /> */}
                            <HTMLSelect  id="inputNetAsset" name="d_NetAsset" autoFocus={true} value={def_d_NetAsset} onChange={utils.handleStringChange(s => setdef_d_NetAsset(s)) } ref={register}>
                                <option value="1">1: {t('NetAsset01')}</option>
                                <option value="2">2: {t('NetAsset02')}</option>
                                <option value="3">3: {t('NetAsset03')}</option>
                                <option value="4">4: {t('NetAsset04')}</option>
                                <option value="5">5: {t('NetAsset05')}</option>
                                <option value="6">6: {t('NetAsset06')}</option>
                                <option value="7">7: {t('NetAsset07')}</option>
                                <option value="8">8: {t('NetAsset08')}</option>
                            </HTMLSelect>

                            {errors.d_NetAsset && errors.d_NetAsset.types && <span id="Err_msg">{errors.d_NetAsset.types.message}</span>}
                            {errors.d_NetAsset && errors.d_NetAsset.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col3-1-3" label={t('AnnualIncome')} labelFor="inputAnnualIncome" labelInfo="*">
                            {/* <InputGroup className="inputNumberNoSpin" id="inputAnnualIncome" name="d_AnnualIncome" defaultValue={def_d_AnnualIncome} type="number" inputRef={register} /> */}
                            {/* <InputGroup id="inputAnnualIncome" name="d_AnnualIncome" defaultValue={def_d_AnnualIncome} placeholder="年度收入 ..." inputRef={register({ maxLength: 15}) } /> */}
                            <HTMLSelect  id="inputNetAsset" name="d_AnnualIncome" value={def_d_AnnualIncome} onChange={utils.handleStringChange(s => setdef_d_AnnualIncome(s)) } ref={register}>
                                <option value="1">1: {t('AnnualIncome01')}</option>
                                <option value="2">2: {t('AnnualIncome02')}</option>
                                <option value="3">3: {t('AnnualIncome03')}</option>
                                <option value="4">4: {t('AnnualIncome04')}</option>
                                <option value="5">5: {t('AnnualIncome05')}</option>
                                <option value="6">6: {t('AnnualIncome06')}</option>
                                <option value="7">7: {t('AnnualIncome07')}</option>
                                <option value="8">8: {t('AnnualIncome08')}</option>
                            </HTMLSelect>

                            {errors.d_AnnualIncome && errors.d_AnnualIncome.types && <span id="Err_msg">{errors.d_AnnualIncome.types.message}</span>}
                            {errors.d_AnnualIncome && errors.d_AnnualIncome.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label={t('SourceofFund')} labelFor="inputSourceofFund" labelInfo="*">
                            <InputGroup id="inputSourceofFund" name="d_SourceofFund" defaultValue={def_d_SourceofFund} placeholder={t('SourceofFund')} inputRef={register({ required: true , maxLength: 60}) } />
                            {errors.d_SourceofFund && errors.d_SourceofFund.types && <span id="Err_msg">{errors.d_SourceofFund.types.message}</span>}
                            {errors.d_SourceofFund && errors.d_SourceofFund.type === "required" && (<span id="Err_msg">{t('SourceofFund')} {t('NotBlank')}</span>)}
                            {errors.d_SourceofFund && errors.d_SourceofFund.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                        </FormGroup>
                    </div>

                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev4) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate4) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>                    
            </div>}


            { ! lFinishe && ( nPage == 7 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenInvestment')}
                    &nbsp; &nbsp; &nbsp; 
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span>
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >                
                    <div className="Row">
                        <FormGroup className="Col-1-2" label={t('InvestTarget1')} labelFor="inputTarget" labelInfo="*">
                            <Checkbox name="d_Target1" autoFocus={true} label={t('InvestTarget01')} checked={def_d_Target1} onChange={utils.handleBooleanChange(v => setdef_d_Target1(v))} />
                            {errors.d_Target1 && errors.d_Target1.types && <span id="Err_msg">{errors.d_Target1.types.message}</span>}
                            <Checkbox name="d_Target2" label={t('InvestTarget02')} checked={def_d_Target2} onChange={utils.handleBooleanChange(v => setdef_d_Target2(v))} />
                            {errors.d_Target2 && errors.d_Target2.types && <span id="Err_msg">{errors.d_Target2.types.message}</span>}
                            <Checkbox name="d_Target3" label={t('InvestTarget03')} checked={def_d_Target3} onChange={utils.handleBooleanChange(v => setdef_d_Target3(v))} />
                            {errors.d_Target3 && errors.d_Target3.types && <span id="Err_msg">{errors.d_Target3.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-3" label={t('InvestTarget2')} labelFor="inputTarget">
                            <Checkbox name="d_Target4" label={t('InvestTarget04')} checked={def_d_Target4} onChange={utils.handleBooleanChange(v => setdef_d_Target4(v))} />
                            {errors.d_Target4 && errors.d_Target4.types && <span id="Err_msg">{errors.d_Target4.types.message}</span>}
                            <Checkbox name="d_Target5" label={t('InvestTarget05')} checked={def_d_Target5} onChange={utils.handleBooleanChange(v => setdef_d_Target5(v))} />
                            {errors.d_Target5 && errors.d_Target5.types && <span id="Err_msg">{errors.d_Target5.types.message}</span>}
                            <Checkbox name="d_Target6" label={t('InvestTarget06')} checked={def_d_Target6} onChange={utils.handleBooleanChange(v => setdef_d_Target6(v))} />
                            {errors.d_Target6 && errors.d_Target6.types && <span id="Err_msg">{errors.d_Target6.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-6-2" label=" ." labelFor="inputTarget">
                            <Checkbox name="d_Target7" label={t('InvestTarget07')} checked={def_d_Target7} onChange={utils.handleBooleanChange(v => setdef_d_Target7(v))} />
                            {errors.d_Target7 && errors.d_Target7.types && <span id="Err_msg">{errors.d_Target7.types.message}</span>}
                            <Checkbox name="d_Target8" label={t('InvestTarget08')} checked={def_d_Target8} onChange={utils.handleBooleanChange(v => setdef_d_Target8(v))} />
                            {errors.d_Target8 && errors.d_Target8.types && <span id="Err_msg">{errors.d_Target8.types.message}</span>}
                            <Checkbox name="d_Target9" label={t('InvestTarget09')} checked={def_d_Target9} onChange={utils.handleBooleanChange(v => setdef_d_Target9(v))} />
                            {errors.d_Target9 && errors.d_Target9.types && <span id="Err_msg">{errors.d_Target9.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    {/* <h5 className="bp3-heading">{t('InvestExperience')}</h5> */}
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('InvestExperience')} labelFor="Input_d_InvestExp">
                            <RadioGroup
                            name="def_d_InvestExp"
                            inline={true}
                            selectedValue={def_d_InvestExp}
                            onChange={utils.handleStringChange(s => setdef_d_InvestExp(s)) }
                            ref={register}
                        >
                            <Radio label={t('cYes01')} value="Y" />
                            <Radio label={t('cNo01')}  value="N" />
                        </RadioGroup>
                            {errors.d_InvestExp && errors.d_InvestExp.types && <span id="Err_msg">{errors.d_InvestExp.types.message}</span>}
                            {errors.d_InvestExp && errors.d_InvestExp.type === "required" && (<span id="Err_msg">{t('InvestExperience')} {t('NotBlank')}</span>)}
                        </FormGroup>
                    </div>
                    <div className="Row">
                    <FormGroup className="Col-1-2" label={t('InvestExperience01')} labelFor="inputStockYear">
                            <InputGroup id="inputStockYear" name="d_StockYear" defaultValue={def_d_StockYear} placeholder={t('InvestExperience05')} inputRef={register({ maxLength: 3}) } />
                            {errors.d_StockYear && errors.d_StockYear.types && <span id="Err_msg">{errors.d_StockYear.types.message}</span>}
                            {errors.d_StockYear && errors.d_StockYear.type === "maxLength" && <span id="Err_msg">{t('Max')} 3 {t('character')}</span>}
                            <InputGroup id="inputStockCnt" name="d_StockCnt" defaultValue={def_d_StockCnt} placeholder={t('InvestExperience06')} inputRef={register({ maxLength: 5}) } />
                            {errors.d_StockCnt && errors.d_StockCnt.types && <span id="Err_msg">{errors.d_StockCnt.types.message}</span>}
                            {errors.d_StockCnt && errors.d_StockCnt.type === "maxLength" && <span id="Err_msg">{t('Max')} 5 {t('character')}</span>}
                            <InputGroup id="inputStockAmt" name="d_StockAmt" defaultValue={def_d_StockAmt} placeholder={t('InvestExperience07')} inputRef={register({ maxLength: 15}) } />
                            {errors.d_StockAmt && errors.d_StockAmt.types && <span id="Err_msg">{errors.d_StockAmt.types.message}</span>}
                            {errors.d_StockAmt && errors.d_StockAmt.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label={t('InvestExperience02')} labelFor="inputWarrentYear">
                            <InputGroup id="inputWarrentYear" name="d_WarrantYear" defaultValue={def_d_WarrantYear} placeholder={t('InvestExperience05')} inputRef={register({ maxLength: 3}) } />
                            {errors.d_WarrantYear && errors.d_WarrantYear.types && <span id="Err_msg">{errors.d_WarrantYear.types.message}</span>}
                            {errors.d_WarrantYear && errors.d_WarrantYear.type === "maxLength" && <span id="Err_msg">{t('Max')} 3 {t('character')}</span>}
                            <InputGroup id="inputWarrentCnt" name="d_WarrantCnt" defaultValue={def_d_WarrantCnt} placeholder={t('InvestExperience06')} inputRef={register({ maxLength: 5}) } />
                            {errors.d_WarrantCnt && errors.d_WarrantCnt.types && <span id="Err_msg">{errors.d_WarrantCnt.types.message}</span>}
                            {errors.d_WarrantCnt && errors.d_WarrantCnt.type === "maxLength" && <span id="Err_msg">{t('Max')} 5 {t('character')}</span>}
                            <InputGroup id="inputWarrentAmt" name="d_WarrantAmt" defaultValue={def_d_WarrantAmt} placeholder={t('InvestExperience07')} inputRef={register({ maxLength: 15}) } />
                            {errors.d_WarrantAmt && errors.d_WarrantAmt.types && <span id="Err_msg">{errors.d_WarrantAmt.types.message}</span>}
                            {errors.d_WarrantAmt && errors.d_WarrantAmt.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label={t('InvestExperience03')} labelFor="inputOptionYear">
                            <InputGroup id="inputOptionYear" name="d_OptionYear" defaultValue={def_d_OptionYear} placeholder={t('InvestExperience05')} inputRef={register({ maxLength: 3}) } />
                            {errors.d_OptionYear && errors.d_OptionYear.types && <span id="Err_msg">{errors.d_OptionYear.types.message}</span>}
                            {errors.d_OptionYear && errors.d_OptionYear.type === "maxLength" && <span id="Err_msg">{t('Max')} 3 {t('character')}</span>}
                            <InputGroup id="inputOptionCnt" name="d_OptionCnt" defaultValue={def_d_OptionCnt} placeholder={t('InvestExperience06')} inputRef={register({ maxLength: 5}) } />
                            {errors.d_OptionCnt && errors.d_OptionCnt.types && <span id="Err_msg">{errors.d_OptionCnt.types.message}</span>}
                            {errors.d_OptionCnt && errors.d_OptionCnt.type === "maxLength" && <span id="Err_msg">{t('Max')} 5 {t('character')}</span>}
                            <InputGroup id="inputOptionAmt" name="d_OptionAmt" defaultValue={def_d_OptionAmt} placeholder={t('InvestExperience07')} inputRef={register({ maxLength: 15}) } />
                            {errors.d_OptionAmt && errors.d_OptionAmt.types && <span id="Err_msg">{errors.d_OptionAmt.types.message}</span>}
                            {errors.d_OptionAmt && errors.d_OptionAmt.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-7-2" label={t('InvestExperience04')} labelFor="inputFutureYear">
                            <InputGroup id="inputFutureYear" name="d_FutureYear" defaultValue={def_d_FutureYear} placeholder={t('InvestExperience05')} inputRef={register({ maxLength: 3}) } />
                            {errors.d_FutureYear && errors.d_FutureYear.types && <span id="Err_msg">{errors.d_FutureYear.types.message}</span>}
                            {errors.d_FutureYear && errors.d_FutureYear.type === "maxLength" && <span id="Err_msg">{t('Max')} 3 {t('character')}</span>}
                            <InputGroup id="inputFutureCnt" name="d_FutureCnt" defaultValue={def_d_FutureCnt} placeholder={t('InvestExperience06')} inputRef={register({ maxLength: 5}) } />
                            {errors.d_FutureCnt && errors.d_FutureCnt.types && <span id="Err_msg">{errors.d_FutureCnt.types.message}</span>}
                            {errors.d_FutureCnt && errors.d_FutureCnt.type === "maxLength" && <span id="Err_msg">{t('Max')} 5 {t('character')}</span>}
                            <InputGroup id="inputFutureAmt" name="d_FutureAmt" defaultValue={def_d_FutureAmt} placeholder={t('InvestExperience07')} inputRef={register({ maxLength: 15}) } />
                            {errors.d_FutureAmt && errors.d_FutureAmt.types && <span id="Err_msg">{errors.d_FutureAmt.types.message}</span>}
                            {errors.d_FutureAmt && errors.d_FutureAmt.type === "maxLength" && <span id="Err_msg">{t('Max')} 15 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-9-2" label="." labelFor="">
                            <InputGroup readOnly disabled={true} value="年數" />
                            <InputGroup readOnly disabled={true} value="每年交易宗數" />
                            <InputGroup readOnly disabled={true} value="一般交易金碩 (港元)" />
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <h5 className="bp3-heading">{t('DerivativeExperience')}</h5>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('DerivativeExperience01')} labelFor="InputHighRiskExp">
                            {/* <Switch id="InputHighRiskExp" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_HighRiskExp} onChange={utils.handleBooleanChange(v => setdef_d_HighRiskExp(v))} ref={register}/> */}
                            <RadioGroup
                            name="def_d_HighRiskExp"
                            inline={true}
                            selectedValue={def_d_HighRiskExp}
                            onChange={utils.handleStringChange(s => setdef_d_HighRiskExp(s)) }
                            ref={register}
                        >
                            <Radio label={t('cYes')} value="Y" />
                            <Radio label={t('cNo')}  value="N" />
                        </RadioGroup>
                            {errors.d_HighRiskExp && errors.d_HighRiskExp.types && <span id="Err_msg">{errors.d_HighRiskExp.types.message}</span>}
                            {errors.d_HighRiskExp && errors.d_HighRiskExp.type === "required" && (<span id="Err_msg">{t('DerivativeExperience')} {t('NotBlank')}</span>)}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev5) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate5) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>                    
            </div>}


            { ! lFinishe && ( nPage == 8 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenDeclaration01')}
                    &nbsp; &nbsp; &nbsp; 
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span> 
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >                
                    { (OpenAccFlag.user_key === "ASTRUM")  && <div className="Row">
                        <FormGroup className="Col-1-5" label={t('OpenDecld_StaffRelation01')} labelFor="InputStaffRelation">
                            {/* <Switch id="InputStaffRelation" autoFocus={true} label="" innerLabelChecked={t('cYes01')}  innerLabel={t('cNo01')} checked={def_d_StaffRelation} onChange={utils.handleBooleanChange(v => setdef_d_StaffRelation(v))} ref={register}/> */}
                            <RadioGroup
                            name="def_d_StaffRelation"
                            inline={true}
                            selectedValue={def_d_StaffRelation}
                            onChange={utils.handleStringChange(s => setdef_d_StaffRelation(s)) }
                            ref={register}
                        >
                            <Radio label={t('cYes01')} value="Y" />
                            <Radio label={t('cNo01')}  value="N" />
                        </RadioGroup>
                            {errors.d_StaffRelation && errors.d_StaffRelation.types && <span id="Err_msg">{errors.d_StaffRelation.types.message}</span>}
                            {errors.d_StaffRelation && errors.d_StaffRelation.type === "required" && (<span id="Err_msg">{t('OpenDecld_StaffRelation01')} {t('NotBlank')}</span>)}
                        </FormGroup>
                    </div> }
                    { ! (OpenAccFlag.user_key === "ASTRUM")  && <div className="Row">
                        <FormGroup className="Col-1-5" label={t('OpenDecld_StaffRelation02')} labelFor="InputStaffRelation">
                            {/* <Switch id="InputStaffRelation" label="" innerLabelChecked={t('cYes01')} innerLabel={t('cNo01')} checked={def_d_StaffRelation} onChange={utils.handleBooleanChange(v => setdef_d_StaffRelation(v))} ref={register}/> */}
                            <RadioGroup
                            name="def_d_StaffRelation"
                            inline={true}
                            selectedValue={def_d_StaffRelation}
                            onChange={utils.handleStringChange(s => setdef_d_StaffRelation(s)) }
                            ref={register}
                        >
                            <Radio label={t('cYes01')} value="Y" />
                            <Radio label={t('cNo01')}  value="N" />
                        </RadioGroup>
                            {errors.d_StaffRelation && errors.d_StaffRelation.types && <span id="Err_msg">{errors.d_StaffRelation.types.message}</span>}
                            {errors.d_StaffRelation && errors.d_StaffRelation.type === "required" && (<span id="Err_msg">{t('OpenDecld_StaffRelation02')} {t('NotBlank')}</span>)}
                        </FormGroup>
                    </div> }
                    <div className="Row">
                        <FormGroup className="Col-1-3" label={t('OpenDecld_StaffRelation03')} labelFor="inputStaffName">
                            <InputGroup id="inputStaffName" name="d_StaffName" defaultValue={def_d_StaffName} placeholder="" inputRef={register({ maxLength: 60}) } />
                            {errors.d_StaffName && errors.d_StaffName.types && <span id="Err_msg">{errors.d_StaffName.types.message}</span>}
                            {errors.d_StaffName && errors.d_StaffName.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-2" label={t('OpenDecld_StaffRelation04')} labelFor="inputStaffRelationship">
                            <InputGroup id="inputStaffRelationship" name="d_StaffRelationship" defaultValue={def_d_StaffRelationship} placeholder="" inputRef={register({ maxLength: 30}) } />
                            {errors.d_StaffRelationship && errors.d_StaffRelationship.types && <span id="Err_msg">{errors.d_StaffRelationship.types.message}</span>}
                            {errors.d_StaffRelationship && errors.d_StaffRelationship.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('OpenDecld_selfInstruction01')} labelFor="InputselfInstruction">
                            {/* <Switch id="InputselfInstruction" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_selfInstruction} onChange={utils.handleBooleanChange(v => setdef_d_selfInstruction(v))} ref={register}/> */}
                            <RadioGroup
                            name="def_d_selfInstruction"
                            inline={true}
                            selectedValue={def_d_selfInstruction}
                            onChange={utils.handleStringChange(s => setdef_d_selfInstruction(s)) }
                            ref={register}
                        >
                            <Radio label={t('cYes')} value="Y" />
                            <Radio label={t('cNo')}  value="N" />
                        </RadioGroup>
                            {errors.d_selfInstruction && errors.d_selfInstruction.types && <span id="Err_msg">{errors.d_selfInstruction.types.message}</span>}
                            {errors.d_selfInstruction && errors.d_selfInstruction.type === "required" && (<span id="Err_msg">{t('OpenDecld_selfInstruction01')} {t('NotBlank')}</span>)}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" label={t('OpenDecld_selfInstruction02')} labelFor="inputInstructionType">
                            <InputGroup id="inputInstructionType" name="d_InstructionType" defaultValue={def_d_InstructionType} placeholder="" inputRef={register({ maxLength: 50}) } />
                            {errors.d_InstructionType && errors.d_InstructionType.types && <span id="Err_msg">{errors.d_InstructionType.types.message}</span>}
                            {errors.d_InstructionType && errors.d_InstructionType.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    { (def_d_ClientType == "M") && <div className="Spacing-V-16"></div> }
                    { (def_d_ClientType == "M") && <h5 className="bp3-heading">{t('OpenRelatedPersion')}</h5> }
                    { (def_d_ClientType == "M") && <div className="Row">
                        <FormGroup className="Col-1-5" label={t('OpenhasSpouseClient01')} labelFor="InputhasSpouseClient">
                            {/* <Switch id="InputhasSpouseClient" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_hasSpouseClient} onChange={utils.handleBooleanChange(v => setdef_d_hasSpouseClient(v))} ref={register}/> */}
                            <RadioGroup
                            name="def_d_hasSpouseClient"
                            inline={true}
                            selectedValue={def_d_hasSpouseClient}
                            onChange={utils.handleStringChange(s => setdef_d_hasSpouseClient(s)) }
                            ref={register}
                        >
                            <Radio label={t('cYes')} value="Y" />
                            <Radio label={t('cNo')}  value="N" />
                        </RadioGroup>
                            {errors.d_hasSpouseClient && errors.d_hasSpouseClient.types && <span id="Err_msg">{errors.d_hasSpouseClient.types.message}</span>}
                            {errors.d_hasSpouseClient && errors.d_hasSpouseClient.type === "required" && (<span id="Err_msg">{t('OpenRelatedPersion')} {t('NotBlank')}</span>)}
                        </FormGroup>
                    </div> }
                    { (def_d_ClientType == "M") && <div className="Row">
                        <FormGroup className="Col-1-4" label={t('OpenhasSpouseClient02')} labelFor="inputSpouseName">
                            <InputGroup id="inputSpouseName" name="d_SpouseName" defaultValue={def_d_SpouseName} placeholder="" inputRef={register({ maxLength: 60}) } />
                            {errors.d_SpouseName && errors.d_SpouseName.types && <span id="Err_msg">{errors.d_SpouseName.types.message}</span>}
                            {errors.d_SpouseName && errors.d_SpouseName.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-6-1" label={t('OpenhasSpouseClient03')} labelFor="inputSpouseAcNo">
                            <InputGroup id="inputSpouseAcNo" name="d_SpouseAcNo" defaultValue={def_d_SpouseAcNo} placeholder="" inputRef={register({ maxLength: 10}) } />
                            {errors.d_SpouseAcNo && errors.d_SpouseAcNo.types && <span id="Err_msg">{errors.d_SpouseAcNo.types.message}</span>}
                            {errors.d_SpouseAcNo && errors.d_SpouseAcNo.type === "maxLength" && <span id="Err_msg">{t('Max')} 10 {t('character')}</span>}
                        </FormGroup>
                    </div> }
                    { (def_d_ClientType == "M") && <div className="Row">
                        <FormGroup className="Col-1-5" label={t('OpenVoteRight01')} labelFor="InputVoteRight">
                            {/* <Switch id="InputVoteRight" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_VoteRight} onChange={utils.handleBooleanChange(v => setdef_d_VoteRight(v))} ref={register}/> */}
                            <RadioGroup
                            name="def_d_VoteRight"
                            inline={true}
                            selectedValue={def_d_VoteRight}
                            onChange={utils.handleStringChange(s => setdef_d_VoteRight(s)) }
                            ref={register}
                        >
                            <Radio label={t('cYes')} value="Y" />
                            <Radio label={t('cNo')}  value="N" />
                        </RadioGroup>
                            {errors.d_VoteRight && errors.d_VoteRight.type === "required" && (<span id="Err_msg">{t('OpenVoteRight01')} {t('NotBlank')}</span>)}
                            {errors.d_VoteRight && errors.d_VoteRight.types && <span id="Err_msg">{errors.d_VoteRight.types.message}</span>}
                        </FormGroup>
                    </div> }
                    { (def_d_ClientType == "M") && <div className="Row">
                        <FormGroup className="Col-1-1" label={t('OpenVoteRight02')}  labelFor="inputVoteAc">
                            <InputGroup id="inputVoteAc1" name="d_VoteAc1" defaultValue={def_d_VoteAc1} placeholder="" inputRef={register({ maxLength: 10}) } />
                            {errors.d_VoteAc1 && errors.d_VoteAc1.types && <span id="Err_msg">{errors.d_VoteAc1.types.message}</span>}
                            {errors.d_VoteAc1 && errors.d_VoteAc1.type === "maxLength" && <span id="Err_msg">{t('Max')} 10 {t('character')}</span>}
                            <InputGroup id="inputVoteAc2" name="d_VoteAc2" defaultValue={def_d_VoteAc2} placeholder="" inputRef={register({ maxLength: 10}) } />
                            {errors.d_VoteAc2 && errors.d_VoteAc2.types && <span id="Err_msg">{errors.d_VoteAc2.types.message}</span>}
                            {errors.d_VoteAc2 && errors.d_VoteAc2.type === "maxLength" && <span id="Err_msg">{t('Max')} 10 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-2-4" label={t('OpenVoteRight03')}  labelFor="inputIoteAcName1">
                            <InputGroup id="inputIoteAcName1" name="d_VoteAcName1" defaultValue={def_d_VoteAcName1} placeholder="" inputRef={register({ maxLength: 60}) } />
                            {errors.d_VoteAcName1 && errors.d_VoteAcName1.types && <span id="Err_msg">{errors.d_VoteAcName1.types.message}</span>}
                            {errors.d_VoteAcName1 && errors.d_VoteAcName1.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                            <InputGroup id="inputIoteAcName2" name="d_VoteAcName2" defaultValue={def_d_VoteAcName2} placeholder="" inputRef={register({ maxLength: 60}) } />
                            {errors.d_VoteAcName2 && errors.d_VoteAcName2.types && <span id="Err_msg">{errors.d_VoteAcName2.types.message}</span>}
                            {errors.d_VoteAcName2 && errors.d_VoteAcName2.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-6-3" label={t('OpenVoteRight04')}  labelFor="inputSpouseAcNo">
                            <InputGroup id="inputSpouseAcNo" name="d_VoteRelate1" defaultValue={def_d_VoteRelate1} placeholder="" inputRef={register({ maxLength: 30}) } />
                            {errors.d_VoteRelate1 && errors.d_VoteRelate1.types && <span id="Err_msg">{errors.d_VoteRelate1.types.message}</span>}
                            {errors.d_VoteRelate1 && errors.d_VoteRelate1.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                            <InputGroup id="inputSpouseAcNo" name="d_VoteRelate2" defaultValue={def_d_VoteRelate2} placeholder="" inputRef={register({ maxLength: 30}) } />
                            {errors.d_VoteRelate2 && errors.d_VoteRelate2.types && <span id="Err_msg">{errors.d_VoteRelate2.types.message}</span>}
                            {errors.d_VoteRelate2 && errors.d_VoteRelate2.type === "maxLength" && <span id="Err_msg">{t('Max')} 30 {t('character')}</span>}
                        </FormGroup>
                    </div> }
                    <div className="Spacing-V-16"></div>
                    <h5 className="bp3-heading">{t('isInternet01')}</h5>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('isInternet02')} labelFor="InputisInternet">
                            {/* <Switch id="InputisInternet" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_isInternet} onChange={utils.handleBooleanChange(v => setdef_d_isInternet(v))} ref={register}/> */}
                            <RadioGroup
                            name="def_d_isInternet"
                            inline={true}
                            selectedValue={def_d_isInternet}
                            onChange={utils.handleStringChange(s => setdef_d_isInternet(s)) }
                            ref={register}
                        >
                            <Radio label={t('cYes')} value="Y" />
                            <Radio label={t('cNo')}  value="N" />
                        </RadioGroup>
                            {errors.d_isInternet && errors.d_isInternet.types && <span id="Err_msg">{errors.d_isInternet.types.message}</span>}
                            {errors.d_isInternet && errors.d_isInternet.type === "required" && (<span id="Err_msg">{t('isInternet02')} {t('NotBlank')}</span>)}
                            {errors.d_isInternet && errors.d_isInternet.types && <span id="Err_msg">{errors.d_isInternet.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev6) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate6) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>                    
            </div>}


            { ! lFinishe && ( nPage == 9 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenCRS')}
                    &nbsp; &nbsp; &nbsp; 
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span> 
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <FormGroup className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence" labelInfo="*">
                            {/* <select  id="inputd_JurisdictionofResidence" name="d_JurisdictionofResidence" value={def_d_JurisdictionofResidence} onChange={utils.handleStringChange(s => setdef_d_JurisdictionofResidence(s)) } ref={register}>
                                <option value="HK">HK: HONG KONG</option>
                                <option value="AU">AU: AUSTRALIA</option>
                                <option value="CA">CA: CANADA</option>
                                <option value="CN">CN: CHINA</option>
                                <option value="GB">GB: UNITED KINGDOM</option>
                                <option value="US">US: UNITED STATES</option>
                                <option value="O">O: 其它</option>
                            </select> */}
                            <Suggest
                                inputProps={{ placeholder: "" }}
                                defaultSelectedItem={JurisdictionList.find((aj) => aj.CountryCode === def_d_JurisdictionofResidence)}
                                items={JurisdictionList}
                                itemRenderer={JurisdictionRenderer}
                                itemPredicate={JurisdictionPredicate}
                                inputValueRenderer={JurisdictionValueRenderer}
                                onItemSelect={(ac) => { setdef_d_JurisdictionofResidence(ac.CountryCode) }}
                            />
                            {errors.d_JurisdictionofResidence && errors.d_JurisdictionofResidence.types && <span id="Err_msg">{errors.d_JurisdictionofResidence.types.message}</span>}
                        </FormGroup>
                        {/*
                        <FormGroup className="Col-3-3" label={t('JurisdictionofResidence02')}  labelFor="inputd_OtherJurisdictionofResidence">
                            <InputGroup id="inputd_OtherJurisdictionofResidence" name="d_OtherJurisdictionofResidence" defaultValue={def_d_OtherJurisdictionofResidence} placeholder="" inputRef={register({ maxLength: 50}) } />
                            {errors.d_OtherJurisdictionofResidence && errors.d_OtherJurisdictionofResidence.types && <span id="Err_msg">{errors.d_OtherJurisdictionofResidence.types.message}</span>}
                            {errors.d_OtherJurisdictionofResidence && errors.d_OtherJurisdictionofResidence.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                        </FormGroup> */}
                        <FormGroup className="Col-3-2" label={t('JurisdictionofResidence03')}  labelFor="inputd_TIN" labelInfo="*">
                            <InputGroup id="inputd_TIN" name="d_TIN" defaultValue={def_d_TIN} placeholder="" inputRef={register({required: true , maxLength: 50}) } />
                            {errors.d_TIN && errors.d_TIN.types && <span id="Err_msg">{errors.d_TIN.types.message}</span>}
                            {errors.d_TIN && errors.d_TIN.type === "required" && (<span id="Err_msg">{t('JurisdictionofResidence03')} {t('NotBlank')}</span>)}
                            {errors.d_TIN && errors.d_TIN.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_Reason" >
                            <InputGroup id="inputd_Juris_Reason" name="d_Juris_Reason" defaultValue={def_d_Juris_Reason} placeholder="" inputRef={register({ maxLength: 100}) } />
                            {errors.d_Juris_Reason && errors.d_Juris_Reason.types && <span id="Err_msg">{errors.d_Juris_Reason.types.message}</span>}
                            {errors.d_Juris_Reason && errors.d_Juris_Reason.type === "required" && (<span id="Err_msg">{t('JurisdictionofResidence04')} {t('NotBlank')}</span>)}
                            {errors.d_Juris_Reason && errors.d_Juris_Reason.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <FormGroup className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                            <Suggest
                                inputProps={{ placeholder: "" }}
                                defaultSelectedItem={JurisdictionList.find((aj) => aj.CountryCode === def_d_JurisdictionofResidence2)}
                                items={JurisdictionList}
                                itemRenderer={JurisdictionRenderer}
                                itemPredicate={JurisdictionPredicate}
                                inputValueRenderer={JurisdictionValueRenderer}
                                onItemSelect={(ac) => { setdef_d_JurisdictionofResidence2(ac.CountryCode) }}
                            />
                            {errors.d_JurisdictionofResidence2 && errors.d_JurisdictionofResidence2.types && <span id="Err_msg">{errors.d_JurisdictionofResidence2.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label={t('JurisdictionofResidence03')}  labelFor="inputd_TIN" >
                            <InputGroup id="inputd_TIN" name="d_TIN2" defaultValue={def_d_TIN2} placeholder="" inputRef={register({ maxLength: 50}) } />
                            {errors.d_TIN2 && errors.d_TIN2.types && <span id="Err_msg">{errors.d_TIN2.types.message}</span>}
                            {errors.d_TIN2 && errors.d_TIN2.type === "required" && (<span id="Err_msg">{t('JurisdictionofResidence03')} {t('NotBlank')}</span>)}
                            {errors.d_TIN2 && errors.d_TIN2.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_Reason" >
                            <InputGroup id="inputd_Juris_Reason" name="d_Juris_Reason2" defaultValue={def_d_Juris_Reason2} placeholder="" inputRef={register({ maxLength: 100}) } />
                            {errors.d_Juris_Reason2 && errors.d_Juris_Reason2.types && <span id="Err_msg">{errors.d_Juris_Reason2.types.message}</span>}
                            {errors.d_Juris_Reason2 && errors.d_Juris_Reason2.type === "required" && (<span id="Err_msg">{t('JurisdictionofResidence04')} {t('NotBlank')}</span>)}
                            {errors.d_Juris_Reason2 && errors.d_Juris_Reason2.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <FormGroup className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                            <Suggest
                                inputProps={{ placeholder: "" }}
                                defaultSelectedItem={JurisdictionList.find((aj) => aj.CountryCode === def_d_JurisdictionofResidence3)}
                                items={JurisdictionList}
                                itemRenderer={JurisdictionRenderer}
                                itemPredicate={JurisdictionPredicate}
                                inputValueRenderer={JurisdictionValueRenderer}
                                onItemSelect={(ac) => { setdef_d_JurisdictionofResidence3(ac.CountryCode) }}
                            />
                            {errors.d_JurisdictionofResidence3 && errors.d_JurisdictionofResidence3.types && <span id="Err_msg">{errors.d_JurisdictionofResidence3.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label={t('JurisdictionofResidence03')}  labelFor="inputd_TIN" >
                            <InputGroup id="inputd_TIN" name="d_TIN3" defaultValue={def_d_TIN3} placeholder="" inputRef={register({ maxLength: 50}) } />
                            {errors.d_TIN3 && errors.d_TIN3.types && <span id="Err_msg">{errors.d_TIN3.types.message}</span>}
                            {errors.d_TIN3 && errors.d_TIN3.type === "required" && (<span id="Err_msg">{t('JurisdictionofResidence03')} {t('NotBlank')}</span>)}
                            {errors.d_TIN3 && errors.d_TIN3.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_Reason" >
                            <InputGroup id="inputd_Juris_Reason" name="d_Juris_Reason3" defaultValue={def_d_Juris_Reason3} placeholder="" inputRef={register({ maxLength: 100}) } />
                            {errors.d_Juris_Reason3 && errors.d_Juris_Reason3.types && <span id="Err_msg">{errors.d_Juris_Reason3.types.message}</span>}
                            {errors.d_Juris_Reason3 && errors.d_Juris_Reason3.type === "required" && (<span id="Err_msg">{t('JurisdictionofResidence04')} {t('NotBlank')}</span>)}
                            {errors.d_Juris_Reason3 && errors.d_Juris_Reason3.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <FormGroup className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                            <Suggest
                                inputProps={{ placeholder: "" }}
                                defaultSelectedItem={JurisdictionList.find((aj) => aj.CountryCode === def_d_JurisdictionofResidence4)}
                                items={JurisdictionList}
                                itemRenderer={JurisdictionRenderer}
                                itemPredicate={JurisdictionPredicate}
                                inputValueRenderer={JurisdictionValueRenderer}
                                onItemSelect={(ac) => { setdef_d_JurisdictionofResidence4(ac.CountryCode) }}
                            />
                            {errors.d_JurisdictionofResidence4 && errors.d_JurisdictionofResidence4.types && <span id="Err_msg">{errors.d_JurisdictionofResidence4.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label={t('JurisdictionofResidence03')}  labelFor="inputd_TIN" >
                            <InputGroup id="inputd_TIN" name="d_TIN4" defaultValue={def_d_TIN4} placeholder="" inputRef={register({ maxLength: 50}) } />
                            {errors.d_TIN4 && errors.d_TIN4.types && <span id="Err_msg">{errors.d_TIN4.types.message}</span>}
                            {errors.d_TIN4 && errors.d_TIN4.type === "required" && (<span id="Err_msg">{t('JurisdictionofResidence03')} {t('NotBlank')}</span>)}
                            {errors.d_TIN4 && errors.d_TIN4.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_Reason" >
                            <InputGroup id="inputd_Juris_Reason" name="d_Juris_Reason4" defaultValue={def_d_Juris_Reason4} placeholder="" inputRef={register({ maxLength: 100}) } />
                            {errors.d_Juris_Reason4 && errors.d_Juris_Reason4.types && <span id="Err_msg">{errors.d_Juris_Reason4.types.message}</span>}
                            {errors.d_Juris_Reason4 && errors.d_Juris_Reason4.type === "required" && (<span id="Err_msg">{t('JurisdictionofResidence04')} {t('NotBlank')}</span>)}
                            {errors.d_Juris_Reason4 && errors.d_Juris_Reason4.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <FormGroup className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                            <Suggest
                                inputProps={{ placeholder: "" }}
                                defaultSelectedItem={JurisdictionList.find((aj) => aj.CountryCode === def_d_JurisdictionofResidence5)}
                                items={JurisdictionList}
                                itemRenderer={JurisdictionRenderer}
                                itemPredicate={JurisdictionPredicate}
                                inputValueRenderer={JurisdictionValueRenderer}
                                onItemSelect={(ac) => { setdef_d_JurisdictionofResidence5(ac.CountryCode) }}
                            />
                            {errors.d_JurisdictionofResidence5 && errors.d_JurisdictionofResidence5.types && <span id="Err_msg">{errors.d_JurisdictionofResidence5.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label={t('JurisdictionofResidence03')}  labelFor="inputd_TIN" >
                            <InputGroup id="inputd_TIN" name="d_TIN5" defaultValue={def_d_TIN5} placeholder="" inputRef={register({ maxLength: 50}) } />
                            {errors.d_TIN5 && errors.d_TIN5.types && <span id="Err_msg">{errors.d_TIN5.types.message}</span>}
                            {errors.d_TIN5 && errors.d_TIN5.type === "required" && (<span id="Err_msg">{t('JurisdictionofResidence03')} {t('NotBlank')}</span>)}
                            {errors.d_TIN5 && errors.d_TIN5.type === "maxLength" && <span id="Err_msg">{t('Max')} 50 {t('character')}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_Reason" >
                            <InputGroup id="inputd_Juris_Reason" name="d_Juris_Reason5" defaultValue={def_d_Juris_Reason5} placeholder="" inputRef={register({ maxLength: 100}) } />
                            {errors.d_Juris_Reason5 && errors.d_Juris_Reason5.types && <span id="Err_msg">{errors.d_Juris_Reason5.types.message}</span>}
                            {errors.d_Juris_Reason5 && errors.d_Juris_Reason5.type === "required" && (<span id="Err_msg">{t('JurisdictionofResidence04')} {t('NotBlank')}</span>)}
                            {errors.d_Juris_Reason5 && errors.d_Juris_Reason5.type === "maxLength" && <span id="Err_msg">{t('Max')} 100 {t('character')}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev7) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate7) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>                    
            </div>}


            { ! lFinishe && ( nPage == 10 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenW8B')}
                    &nbsp; &nbsp; &nbsp; 
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span> 
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <span id="DispText4">
                            {t('OpenW8B01')}
                        </span>
                        <span id="DispText5">
                            <InputGroup id="inputd_w8b_country" name="d_w8b_country" autoFocus={true} defaultValue={def_d_w8b_country} placeholder="" inputRef={register({ required: true , maxLength: 60}) } />
                        </span>
                        <span id="DispText6">
                            {t('OpenW8B02')}
                        </span>
                    </div>
                        {errors.d_w8b_country && errors.d_w8b_country.types && <span id="Err_msg">{errors.d_w8b_country.types.message}</span>}
                        {errors.d_w8b_country && errors.d_w8b_country.type === "required" && (<span id="Err_msg">{t('OpenW8B01a')} {t('NotBlank')}</span>)}
                        {errors.d_w8b_country && errors.d_w8b_country.type === "maxLength" && <span id="Err_msg">{t('Max')} 60 {t('character')}</span>}
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_01" labelInfo="*">
                            <Checkbox name="d_w8b_01"  label={t('OpenW8B03')} checked={def_d_w8b_01} onChange={utils.handleBooleanChange(v => setdef_d_w8b_01(v))} />
                            {errors.d_w8b_01 && errors.d_w8b_01.types && <span id="Err_msg">{errors.d_w8b_01.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                            <Checkbox name="d_w8b_02"  label={t('OpenW8B04')} checked={def_d_w8b_02} onChange={utils.handleBooleanChange(v => setdef_d_w8b_02(v))} />
                            {errors.d_w8b_02 && errors.d_w8b_02.types && <span id="Err_msg">{errors.d_w8b_02.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                            <Checkbox name="d_w8b_03"  label={t('OpenW8B05')} checked={def_d_w8b_03} onChange={utils.handleBooleanChange(v => setdef_d_w8b_03(v))} />
                            {errors.d_w8b_03 && errors.d_w8b_03.types && <span id="Err_msg">{errors.d_w8b_03.types.message}</span>}
                        </FormGroup>
                    </div>
                    <ul className="bp3-list">
                        <li>{t('OpenW8B05a')}</li>
                        <li>{t('OpenW8B05b')}</li>
                        <li>{t('OpenW8B05c')}</li>
                    </ul>
                    <div className="Row">
                        <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                            <Checkbox name="d_w8b_04"  label={t('OpenW8B06')} checked={def_d_w8b_04} onChange={utils.handleBooleanChange(v => setdef_d_w8b_04(v))} />
                            {errors.d_w8b_04 && errors.d_w8b_04.types && <span id="Err_msg">{errors.d_w8b_04.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                            <Checkbox name="d_w8b_05"  label={t('OpenW8B07')} checked={def_d_w8b_05} onChange={utils.handleBooleanChange(v => setdef_d_w8b_05(v))} />
                            {errors.d_w8b_05 && errors.d_w8b_05.types && <span id="Err_msg">{errors.d_w8b_05.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev7b) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate7b) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>                    
            </div>}


            { ! lFinishe && ( nPage == 11 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenBankInfo')}
                    &nbsp; &nbsp; &nbsp; 
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum12b}</td>
                        </tr>
                    </tbody></span> 
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <FormGroup className="Col-1-2" label={t('OpenBankInfo01')} labelFor="inputBankInfo" labelInfo="*">
                            <InputGroup id="inputBankInfo" name="d_BankInfo" autoFocus={true} defaultValue={def_d_BankInfo} placeholder="" inputRef={register({ required: true , maxLength: BankInfoLength}) } />
                            {errors.d_BankInfo && errors.d_BankInfo.types && <span id="Err_msg">{errors.d_BankInfo.types.message}</span>}
                            {errors.d_BankInfo && errors.d_BankInfo.type === "required" && (<span id="Err_msg">{t('OpenBankInfo01')} {t('NotBlank')}</span>)}
                            {errors.d_BankInfo && errors.d_BankInfo.type === "maxLength" && <span id="Err_msg">{t('Max')} {BankInfoLength} {t('character')}</span>}

                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label={t('OpenBankInfo02')} labelFor="inputBankACNo" labelInfo="*">
                            <InputGroup id="inputBankACNo" name="d_BankACNo" defaultValue={def_d_BankACNo} placeholder="" inputRef={register({ required: true , maxLength: BankACNoLength}) } />
                            {errors.d_BankACNo && errors.d_BankACNo.types && <span id="Err_msg">{errors.d_BankACNo.types.message}</span>}
                            {errors.d_BankACNo && errors.d_BankACNo.type === "required" && (<span id="Err_msg">{t('OpenBankInfo02')} {t('NotBlank')}</span>)}
                            {errors.d_BankACNo && errors.d_BankACNo.type === "maxLength" && <span id="Err_msg">{t('Max')} {BankACNoLength} {t('character')}</span>}
                        </FormGroup>
                    </div>

                    <div className="Spacing-V-16"></div>
                    <ul>
                        <li>{t('BankNote01')}</li>
                        <li>{t('BankNote02')}</li>
                        {/* <li>{t('BankNote03')}</li> */}
                    </ul>
                    <div className="Spacing-V-16"></div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev8) }>{t('Prev')}</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate8) }>{t('Next')}</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>                    
            </div>}


            { ! lFinishe && ( nPage == 12 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> {t('OpenUpload')}
                    &nbsp; &nbsp; &nbsp; 
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum4b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum5b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum6b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum7b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum8b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum9b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum10b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum11b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum12a}</td>
                        </tr>
                    </tbody></span> 
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label={t('Agree01')} labelFor="InputAgree">
                            <Switch id="InputAgree" autoFocus={true} label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={def_d_Agree} onChange={utils.handleBooleanChange(v => setdef_d_Agree(v))} ref={register}/>
                            {errors.d_Agree && errors.d_Agree.types && <span id="Err_msg">{errors.d_Agree.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <h5 className="bp3-heading">{t('Sign01')}</h5>
                    <div className="Row" >
                        <button onClick={handleExport}>{t('Sign02')}</button> 
                        <button onClick={handleClear}>{t('Sign03')}</button>
                    </div>
                    <div className="Row" >
                    <span id="FreeDrawBox">
                        <Stage
                            ref={stageRef}
                            width={800}
                            height={500}
                            onMouseDown={handleMouseDown}
                            onMousemove={handleMouseMove}
                            onMouseup={handleMouseUp}
                            onTouchStart={handleMouseDown}
                            onTouchMove={handleMouseMove}
                            onTouchEnd={handleMouseUp}
                        >
                            <Layer>
                                {lines.map((line:any, i:number) => (
                                    <Line
                                    key={i}
                                    points={line.points}
                                    stroke="#df4b26"
                                    strokeWidth={5}
                                    tension={0.5}
                                    lineCap="round"
                                    globalCompositeOperation={
                                        line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                    }
                                    />
                                ))}
                            </Layer>
                        </Stage>
                    </span>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Spacing-V-16"></div>
                    <div className="Spacing-V-16"></div>
                    <form className="Import" onSubmit={onUpload}>
                        <div className="Row">
                            <span id="DispText3">{t('Upload01')}</span>
                            <FileInput inputProps={{ name: "file1" }} text={file1Label} onInputChange={utils.handleStringChange((s) => setFile1Label(utils.fileBase(s)))} />
                        </div>
                        {errors.UploadFile1 && errors.UploadFile1.types && <span id="Err_msg">{errors.UploadFile1.types.message}</span>}
                        <div className="Row">
                            <span id="DispText3">{t('Upload02')}</span>
                            <FileInput inputProps={{ name: "file1b" }} text={file1bLabel} onInputChange={utils.handleStringChange((s) => setFile1bLabel(utils.fileBase(s)))} />
                        </div>
                        {errors.UploadFile1b && errors.UploadFile1b.types && <span id="Err_msg">{errors.UploadFile1b.types.message}</span>}
                        <div className="Row">
                            <span id="DispText3">{t('Upload03')}</span>
                            <FileInput inputProps={{ name: "file2" }} text={file2Label} onInputChange={utils.handleStringChange((s) => setFile2Label(utils.fileBase(s)))} />
                        </div>
                        {errors.UploadFile2 && errors.UploadFile2.types && <span id="Err_msg">{errors.UploadFile2.types.message}</span>}
                        <div className="Row">
                            <span id="DispText3">{t('Upload04')}</span>
                            <FileInput inputProps={{ name: "file3" }} text={file3Label} onInputChange={utils.handleStringChange((s) => setFile3Label(utils.fileBase(s)))} />
                        </div>
                        {errors.UploadFile3 && errors.UploadFile3.types && <span id="Err_msg">{errors.UploadFile3.types.message}</span>}
                        <div className="Spacing-V-8"></div>
                        <div className="Row">
                            <Button className="Col-1-2" type="submit" icon={IconNames.UPLOAD} disabled={def_Uploading}>{t('Upload05')}</Button>
                        </div>
                        <div className="Spacing-V-8"></div>
                        {t('Upload06')} : {nUploaded} {t('Upload07')} 
                    </form>
                    <div className="Spacing-V-16"></div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () => PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev9) }>{t('Prev')}</Button>
                        <Button icon="direction-right" disabled={! def_d_Agree || ! isSigned} onClick={handleSubmit(onValidate9) }>完成</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>                    
            </div>}


            { lFinishe && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}>{t('OpenFinish')}</div>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <span id="DispText2">{t('OpenFinish01')} {def_d_acName1} {def_d_acName2} , {t('OpenFinish02')}</span>
                </div>
                <div className="Row">
                    <span id="DispText2">{t('OpenFinish03')}<code className='bp3-code'>{t('OpenFinish04')}</code>{t('OpenFinish05')}</span>
                </div>
                <div className="Spacing-V-16" />
            </div>}

            <Dialog
                icon = "warning-sign"
                title = {t('OpenNotes')}
                isOpen = {isOpenDialog}
                style= {{width: "980px"}}
                onClose = { () => setisOpenDialog(false) }
            >
                <div className={Classes.DIALOG_BODY} >
                <div className="Spacing-V-16" />
                {/*
                <ul>
                    <li>{t('OpenNote01')}</li>
                    <li>{t('OpenNote04')}</li>
                </ul>
                */}
                <div style={{overflowY: "scroll", height: "500px", width: "960px"}}> 
                    <Document
                    //file="test.pdf" // "Cash Client's Agreement (E).pdf"
                    file={pdfAgree}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading="Loading PDF ..."
                    >
                    {[...Array(numPages)].map((_, i) => <Page pageNumber={i+1} width={940}/>)}
                    </Document>
                </div>
                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
                    {/* <Button type="submit" onClick={ () => setisOpenDialog(false) }>Cancel</Button> */}
                    &nbsp; &nbsp; &nbsp;
                    <Button intent={Intent.SUCCESS} type="submit" onClick={ () => { PageNo(+1); setisOpenDialog(false) } }>{t('cAgree')}</Button>
                </div>
            </Dialog>

        {/* </form> */}
        </>
    );
}



export default Test01Main