import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { UserMast } from './Types'


interface PageInfo {
    n: number
}

var OpenAccFlag: any;



interface UserchgPasswd2MainProps {
    OpenAcc: AxiosInstance,
    currentRec?: UserMast
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function UserchgPasswd2Main({ OpenAcc , currentRec = {} as UserMast }: UserchgPasswd2MainProps) {
    //const history = useHistory()
    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<UserMast>()

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as UserMast[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as UserMast)
    const [BlankAc, setBlankAc] = useState({} as UserMast)

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [UserId, setUserId] = useState("")



    const [def_d_Userid, setdef_d_Userid] = useState("")
    const [def_d_User_Password1, setdef_d_User_Password1] = useState("")
    const [def_d_User_Password2, setdef_d_User_Password2] = useState("")
    const [def_d_User_Password3, setdef_d_User_Password3] = useState("")




    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("UserchgPasswd2Main disp user_key: ", res.data.Flag.user_key)
                //console.log("UserchgPasswd2Main disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("UserchgPasswd2Main disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("UserchgPasswd2Main disp OpenAccFlag: ", OpenAccFlag)
                //console.log("UserchgPasswd2Main disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("UserchgPasswd2Main disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("UserchgPasswd2Main disp OpenAccFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("UserchgPasswd2Main disp OpenAccFlag.MGNGP_USER: ", OpenAccFlag.MGNGP_USER)
                //console.log("UserchgPasswd2Main disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                setcardOpen01("cardOpen01-Theme"+OpenAccFlag.DEF_THEME1);
                setUserId(OpenAccFlag.UserID) ;
                setdef_d_Userid(OpenAccFlag.UserID) ;

            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])



    const onSubmit = (data: UserMast) => {
        //console.log("onSubmit called", data)


    

        currentRec.d_Userid = data.d_Userid
        //currentRec.d_User_Password1 = data.d_User_Password1
        currentRec.d_User_Password2 = data.d_User_Password2
        currentRec.d_User_Password3 = data.d_User_Password3

        ;

        (async () => {
            try {
                console.log("call onSubmit currentRec", currentRec)
                const res = await OpenAcc.post('/Master', {sb_data: currentRec, cPgmId: "UserMast", cAction: "ChgPasswdValidate2" })
                console.log("onSubmit res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(currentRec);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onSave = async (UserMast: UserMast) => {
        try {
            console.log("call OnSave", UserMast)
            const res = await OpenAcc.post('/Master', {sb_data: UserMast, cPgmId: "UserMast", cAction: "ChgPasswdUpdate2" })
            if ( res.data.ok) {
                showSuccess("User id " + UserMast.d_Userid + " Password saved")
                alert("User id " + UserMast.d_Userid + " Password saved")
                window.open(`/#/`, '_self')
            }
            

        } catch (error) {
            showError(error)
        }
    }


    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            //handleSubmit(onValidate1)()
        }
    }


    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }


    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


    
    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>

    return (
        <>
            <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                Reset User Password
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-2" label="User id" labelFor="inputd_Userid">
                        <InputGroup id="inputd_Userid" name="d_Userid" autoFocus={true} defaultValue={def_d_Userid} placeholder="User id ..." inputRef={register({ required: true , maxLength: 20}) } />
                        {errors.d_Userid && errors.d_Userid.types && <span id="Err_msg">{errors.d_Userid.types.message}</span>}
                        {errors.d_Userid && errors.d_Userid.type === "required" && (<span id="Err_msg">User id cannot be blank</span>)}
                        {errors.d_Userid && errors.d_Userid.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-3" label="New Password" labelFor="inpud_User_Password">
                    <InputGroup id="inpud_User_Password" name="d_User_Password2" type="password"  defaultValue="" placeholder="New Password ..." inputRef={ register({ required: true , maxLength: 20}) } />
                        {errors.d_User_Password2 && errors.d_User_Password2.types && <span id="Err_msg">{errors.d_User_Password2.types.message}</span>}
                        {errors.d_User_Password2 && errors.d_User_Password2.type === "required" && (<span id="Err_msg">User Password cannot be blank</span>)}
                        {errors.d_User_Password2 && errors.d_User_Password2.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-3" label="Re-enter New Password" labelFor="inpud_User_Password">
                    <InputGroup id="inpud_User_Password" name="d_User_Password3" type="password"  defaultValue="" placeholder="Re-enter New Password ..." inputRef={ register({ required: true , maxLength: 20}) } />
                        {errors.d_User_Password3 && errors.d_User_Password3.types && <span id="Err_msg">{errors.d_User_Password3.types.message}</span>}
                        {errors.d_User_Password3 && errors.d_User_Password3.type === "required" && (<span id="Err_msg">User Password cannot be blank</span>)}
                        {errors.d_User_Password3 && errors.d_User_Password3.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
                <div className="Row">
                    <Button className="Col-1-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                </div>
                <div className="Spacing-V-16" />
            </Card>
        </>
    );
}

export default UserchgPasswd2Main