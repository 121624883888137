import React, { useState, FormEvent, useEffect } from 'react';


import { FormGroup, InputGroup, HTMLTable, Position, Toaster, Intent } from "@blueprintjs/core";
import { ProgressBar } from "@blueprintjs/core";

import Axios, { AxiosInstance } from 'axios';

import * as utils from "./utils";

import svgWait2 from './svg/wait2.gif'



interface RptRstInfo {
    Rst_Key: string,
    Rst_Line: string,
}

interface PageInfo {
    n: number
}

var OpenAccFlag: any;


interface AccInfoMainProps {
    OpenAcc: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function AccInfoMain({ OpenAcc }: AccInfoMainProps) {
    
    let url = document.location.toString()
    let acCode = utils.getUrlParameter('acCode', url); 
    
    let ProgressId2 = ""
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />

    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)

    const [Rstdata, setRstdata] = useState([] as RptRstInfo[])
    const [Rstdata2, setRstdata2] = useState([] as RptRstInfo[])
    const [RptHead1, setRptHead1] = useState("")
    const [RptHead2, setRptHead2] = useState("")
    const [RptName, setRptName] = useState("")
    const [ShowWait, setShowWait] = useState(false)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)


    const [s_sortby, sets_sortby] = useState("A")
    const [s_ClCode1, sets_ClCode1] = useState("")
    const [s_ClCode2, sets_ClCode2] = useState("")
    const [s_AeCode1, sets_AeCode1] = useState("")
    const [s_AeCode2, sets_AeCode2] = useState("")
    const [ExcelFormatValue, setExcelFormatValue] = useState(false)
    const [CSVFormat, setCSVFormat] = useState("0")
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [LineLimit, setLineLimit] = useState(54 - 7)

    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")

    //console.log("main acCode: ", acCode)


    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp OpenAccFlag: ", OpenAccFlag)
                //console.log("SmMastMain disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("SmMastMain disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("SmMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setSETL_DATE(OpenAccFlag.SETL_DATE ) ;
                setCSVFormat(OpenAccFlag.CSVFormat) ;
                setuserKey(OpenAccFlag.user_key) ;
            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])


    useEffect(() => {
        const refreshTasks = async () => {
            ProgressId2 = ProgressId
            try {
                const res = await OpenAcc.post('/Progresslist', {p_data: ProgressId2})
                let pValue = 0
                if ( res.data.count > 0 ) {
                    if ( res.data.data[0].id === ProgressId2 ) {
                        pValue = res.data.data[0].progressValue / res.data.data[0].progressMax
                        //console.log("Progresslist: ", res.data.data[0].progressValue, res.data.data[0].progressMax) 
                        //console.log("Progresslist pValue: ", pValue) 
                        setProgressValue(pValue)
                    }
                    if (res.data.data[0].status > 0 ) {
                        clearInterval(timer)
                    }
                }
            } catch (error) {
                utils.showError(error)
            }
        }
        let timer = setInterval(refreshTasks, 20*1000)
        return () => {clearInterval(timer)}
    }, [OpenAcc, ProgressId])


    useEffect(() => {
        if ( acCode.trim().length > 0 ) {
            sets_ClCode1(s_ClCode1.toUpperCase());
            sets_ClCode2(s_ClCode2.toUpperCase());

            setShowWait(true)
            setShowNoRecord(false)
            setShowRpt(false)
            setRstdata([])
            setRptHead1("")
            setRptHead2("")
            setRptName("")

            setnPage(1)
            ProgressId2 = utils.genUUID()
            setProgressId(ProgressId2)
            setProgressValue(0)

            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                try {
                    //console.log("SmMastMain onsubmit called", data)
                    const res = await OpenAcc.post('/Report',
                        {   sb_data: {
                                ProgressId: ProgressId2,
                                s_sortby: s_sortby,
                                s_ClCode1: acCode,
                                s_ClCode2: s_ClCode2,
                                s_AeCode1: s_AeCode1,
                                s_AeCode2: s_AeCode2,
                                ExcelFormat: ExcelFormatValue,
                                nPage: 1,
                                cPrintMode: "PS",
                                limit: LineLimit,
                                PgmName: "AccInfo",
                            },
                            cPgmId: "PortfolioEvaluation",
                            
                        });

                    //console.log("res.data.data: ", res.data.data) 
                    if ( res.data.count > 0 ) {
                        setRstdata2([])
                        for (let j = 0; j < res.data.Rptdata.length; j++) {
                            Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                        }
                        setShowWait(false)
                        setShowNoRecord(false)
                        setShowRpt(true)
                        setRstdata(Rstdata2)
                        //console.log("Rstdata: ", Rstdata) 
                        setRptHead1(res.data.Rpthead1)
                        setRptHead2(res.data.Rpthead2)
                        setRptName(res.data.RptName)

                        const aPageNumber1 = []
                        if ( res.data.count > 0 ) {
                            setnTotalPage(Math.ceil(res.data.count / LineLimit))
                            for ( let j = 1; j <= Math.ceil(res.data.count / LineLimit); j++ ) {
                                aPageNumber1.push({n: j});
                            }
                            setaPageNumber(aPageNumber1)
                        }
               
                    } else {
                        setShowWait(false)
                        setShowNoRecord(true)
                        setShowRpt(false)
                    }
                } catch (error) {
                    showError(error)
                }
            })()
        }
    }, [OpenAcc])


    //Press page No 1 2 3 4
    useEffect(() => {
        if (nPage > 0 && nPage <= nTotalPage && RptName.trim().length > 0) {
            // console.log("calling API for Pagination");
            setShowWait(true)
            setShowNoRecord(false)
            setShowRpt(false)
            setRstdata([])

            ProgressId2 = utils.genUUID()
            setProgressId(ProgressId2)
            setProgressValue(0)

            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await OpenAcc.post('/Report',
                    {   sb_data: {
                            ProgressId: ProgressId2,
                            s_sortby: s_sortby,
                            s_ClCode1: acCode,
                            s_ClCode2: s_ClCode2,
                            s_AeCode1: s_AeCode1,
                            s_AeCode2: s_AeCode2,
                            ExcelFormat: ExcelFormatValue,
                            nPage: nPage,
                            cPrintMode: "S",
                            limit: LineLimit,
                            PgmName: "AccInfo",
                        },
                        cPgmId: "PortfolioEvaluation",
                    });

                    //console.log("res.data.data: ", res.data.data) 
                    if ( res.data.count > 0 ) {
                        setRstdata2([])
                        for (let j = 0; j < res.data.Rptdata.length; j++) {
                            Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                        }
                        setShowWait(false)
                        setShowNoRecord(false)
                        setShowRpt(true)
                        setRstdata(Rstdata2)
                        //console.log("Rstdata: ", Rstdata) 
                    } else {
                        setShowWait(false)
                        setShowNoRecord(true)
                        setShowRpt(false)
                    }
                } catch (error) {
                    showError(error)
                }
            })()
        }
    }, [OpenAcc, nPage])
    


    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    let list = <>
        <div className="Spacing-V-16" />
        {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print </a>}
        {ShowRpt && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead id="rpt_th_r">
                <tr >
                    <th className="RCol-Head_p">
                        {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${OpenAcc.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print</a>}
                    </th>
                </tr>                    
                <tr>
                    <td className="RCol-Head1">{RptHead1}</td>
                </tr>                    
                <tr>
                    <td className="RCol-Head1">{RptHead2}</td>
                </tr>                    
            </thead>
            <tbody id="rpt_tr">
                {Rstdata.map((rpt, i) =>
                    <tr key={i} onDoubleClick={() => { }}>
                        <td className="RCol-Rpt" onClick={() => {  }}>{rpt.Rst_Line}</td>
                    </tr>
                )}
            </tbody>
            <div className="Spacing-V-16" />
            {ShowRpt && Pagination}
        </HTMLTable>
    </>

    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>

    return (
        <>
            <form >
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                Account Balance & Portfolio
                <div className="Spacing-V-16" />
                {/* {ShowWait && <div>{imgWait}</div> } */}
                {ShowWait && <div className="TCol-Progress2">Progress ...</div> }
                {ShowWait && <div className="TCol-Progress2"><ProgressBar stripes={false} animate={false} value={ProgressValue} /></div> }
                {ShowNoRecord && <p>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-3"  >
                            <span id="Err_msg">No Record found </span>
                        </FormGroup>
                    </div>
                </p>
                }
                {ShowRpt && list}
            </form>
        </>
    );
}

export default AccInfoMain