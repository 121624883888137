import React, { useState, FormEvent, useEffect, useCallback } from 'react';
import { Stage, Layer, Line, Text } from 'react-konva';
import { useHistory } from 'react-router-dom';
import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, Button, AnchorButton, FileInput, Switch, Checkbox, HTMLTable, Position, Toaster, Intent, Callout,MenuItem } from "@blueprintjs/core";
import { Classes, Card, Elevation, Dialog, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DateInput2 } from "@blueprintjs/datetime2";
import { format, parse, addDays} from "date-fns";

import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import { AxiosInstance } from 'axios';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import * as utils from "./utils";
import clearSelection from "./clearSelect";

import svgWait2 from './svg/wait2.gif'
import svgnum1a from './svg/num1a.gif'
import svgnum2a from './svg/num2a.gif'
import svgnum3a from './svg/num3a.gif'
import svgnum1b from './svg/num1b.gif'
import svgnum2b from './svg/num2b.gif'
import svgnum3b from './svg/num3b.gif'
import tick_circle from './svg/tick-circle.svg'
import flow_linear from './svg/flow-linear.svg'
import one_to_one from './svg/one-to-one.svg'

import { Account, Jurisdiction, SignImg,Nationality } from './Types'

import i18next from 'i18next';

var OpenAccFlag: any;


interface RegFormDemo2MainProps {
    OpenAcc: AxiosInstance,
    currentRec?: Account
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function RegFormDemo2Main({ OpenAcc , currentRec = {} as Account }  : RegFormDemo2MainProps) {
    //const history = useHistory()
    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<Account>()
    const { t, i18n } = useTranslation();
    
    const [SignImgRec, setSignImgRec] = useState({} as SignImg)

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const imgnum1a = <img className="NumIcon2" src={svgnum1a} alt="num1" />
    const imgnum2a = <img className="NumIcon2" src={svgnum2a} alt="num2" />
    const imgnum3a = <img className="NumIcon2" src={svgnum3a} alt="num3" />
    const imgnum1b = <img className="NumIcon" src={svgnum1b} alt="num1" />
    const imgnum2b = <img className="NumIcon" src={svgnum2b} alt="num2" />
    const imgnum3b = <img className="NumIcon" src={svgnum3b} alt="num3" />
    const imgtick_circle = <img className="tickIcon" src={tick_circle} alt="completed" />
    const imgflow_linear = <img src={flow_linear} alt="Progressing" />
    const imgone_to_one = <img src={one_to_one} alt="Progressing" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const cologo2 = <img className="cologo2" src="pop_cologo2.gif" alt="Co Logo2" />
    
    const [SendVmeth, setSendVmeth] = useState("1")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [cardOpen02, setcardOpen02] = useState("cardOpen02-Theme1")
    const [Enter2tab, setEnter2tab] = useState("N")

    const [ShowWait, setShowWait] = useState(false)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(3)
    const [lFinishe, setlFinishe] = useState(false)


    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("DEMO")

    const [NationalityList, setNationalityList] = useState([] as Nationality[]) 

    const [tool, setTool] = React.useState('pen');
    const [lines, setLines] = useState<any>([]);
    const isDrawing = React.useRef(false);
    //const stageRef = React.useRef(null);
    const stageRef: any = React.createRef()
    const [isSigned, setisSigned] = useState(false);

    const handleMouseDown = (e:any) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setLines([...lines, { tool, points: [pos.x, pos.y] }]);
    };
  
    const handleMouseMove = (e:any) => {
        // no drawing - skipping
        if (!isDrawing.current) {
            return;
        }
        e.evt.preventDefault();
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastLine = lines[lines.length - 1];

        if (lastLine) {
            // add point
            lastLine.points = lastLine.points.concat([point.x, point.y]);
                
            // replace last
            lines.splice(lines.length - 1, 1, lastLine);
            setLines(lines.concat());
        }    
        setisSigned(true)
    };
  
    const handleMouseUp = () => {
        isDrawing.current = false;
    };
  

    const handleExport = () => {
        //const uri = stageRef.current.toDataURL();
        const uri = stageRef.current.toDataURL();

        
        console.log("uri", uri);
        
        downloadURI(uri, 'signname.png');
  
        // we also can save uri as file
        // but in the demo on Konva website it will not work
        // because of iframe restrictions
        // but feel free to use it in your apps:
        // downloadURI(uri, 'stage.png');
        
    };
  
    const handleClear = () => {
        isDrawing.current = false;
        setisSigned(false)        
        clearSelection()
        setLines([])
    };


    function downloadURI(uri:any, name:string) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        link.click();
    }
    const NationalityRenderer: ItemRenderer<Nationality> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        
        var text = '';

        currentRec.lang_reg = i18n.languages.toString();
        if ( currentRec.lang_reg.substr(0,2)  == "en" )
        {
            text = `${ac.Country},${ac.CountryCode}`; 
        } else if ( currentRec.lang_reg.substr(0,5)  == "zh-HK" ) { 
            text = `${ac.Country}(${ac.CountryHK}),${ac.CountryCode}`; 
        } else  { 
            text = `${ac.Country}(${ac.CountryCN}),${ac.CountryCode}`; 
        }
        
        // text = `${ac.Country}(${ac.CountryHK}),${ac.CountryCode}`; 
        return (
            <MenuItem 
                active={modifiers.active}
                disabled={modifiers.disabled}                
                key={ac.CountryCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />           
        );
    };
    const NationalityPredicate: ItemPredicate<Nationality> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.CountryCode} - ${ac.Country}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };
    const NationalityValueRenderer = (ac: Nationality) => {
        return ac.CountryCode
    }

    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }



    const savesignature = async () => {

        const savesignatureimage_uri = stageRef.current.toDataURL();
        //var image = stageRef.current.toImage()

        console.log("call savesignature savesignatureimage_uri", savesignatureimage_uri)
        //console.log("call savesignature image", image)

        //SignImgRec.idNo = "c23"
        SignImgRec.idNo = def_d_idNo
        SignImgRec.uri = savesignatureimage_uri
        //SignImgRec.signimage = image

        if ( savesignatureimage_uri.length > 0 && SignImgRec.idNo.length > 0 ) {
            try {
                console.log("call savesignature SignImgRec", SignImgRec)
                const res = await OpenAcc.post('/Master0',
                    {   sb_data: SignImgRec,
                        cPgmId: "OpenAcc",
                        cAction: "saveSignImg",
                    });

                //console.log("savesignature res.data", res.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                if ( res.data.ok )
                {
                             
                }
            } catch (error) {
                utils.showError(error)
            }
        } else {
            utils.showError("Insufficient Ddata")
        }

    }

    


    const [file1Label, setFile1Label] = useState("...")
    const [file1bLabel, setFile1bLabel] = useState("...")
    const [file2Label, setFile2Label] = useState("...")
    const [file3Label, setFile3Label] = useState("...")
    const [file4Label, setFile4Label] = useState("...")
    const [nUploaded, setnUploaded] = useState(0)

    //OpenAcc default value
    currentRec.d_idNo = ""
    currentRec.d_dob = utils.formatNumber(new Date())
    currentRec.d_StaffRelation = "N"
    currentRec.d_selfInstruction = "Y"
    currentRec.d_isFinInstit = "N"
    currentRec.d_ApproveFinInstit = "Y"
    currentRec.d_hasSpouseClient = "N"
    currentRec.d_VoteRight = "N"
    currentRec.d_isInternet = "Y"
    currentRec.d_Target1 = "N"
    currentRec.d_Target2 = "N"
    currentRec.d_Target3 = "N"
    currentRec.d_Target4 = "N"
    currentRec.d_Target5 = "N"
    currentRec.d_Target6 = "N"
    currentRec.d_Target7 = "N"
    currentRec.d_Target8 = "N"
    currentRec.d_Target9 = "N"
    currentRec.d_HighRiskExp = "N"
    currentRec.d_Agree = "N"
    currentRec.d_National = ""

    const [def_d_acName1, setdef_d_acName1] = useState(currentRec.d_acName1)
    const [def_d_acName2, setdef_d_acName2] = useState(currentRec.d_acName2)
    const [def_d_idNo, setdef_d_idNo] = useState(currentRec.d_idNo)
    const [def_d_acAddr1, setdef_d_acAddr1] = useState(currentRec.d_acAddr1)
    const [def_d_acAddr2, setdef_d_acAddr2] = useState(currentRec.d_acAddr2)
    const [def_d_acAddr3, setdef_d_acAddr3] = useState(currentRec.d_acAddr3)
    const [def_d_dob, setdef_d_dob] = useState(currentRec.d_dob)
    const [def_d_National, setdef_d_National] = useState(currentRec.d_National)
    const [def_d_mailAddr1, setdef_d_mailAddr1] = useState(currentRec.d_mailAddr1)
    const [def_d_mailAddr2, setdef_d_mailAddr2] = useState(currentRec.d_mailAddr2)
    const [def_d_mailAddr3, setdef_d_mailAddr3] = useState(currentRec.d_mailAddr3)
    const [def_d_way2GetStmt, setdef_d_way2GetStmt] = useState(currentRec.d_way2GetStmt)
    const [def_d_NetAsset, setdef_d_NetAsset] = useState(currentRec.d_NetAsset)
    const [def_d_AnnualIncome, setdef_d_AnnualIncome] = useState(currentRec.d_AnnualIncome)
    const [def_d_SourceofFund, setdef_d_SourceofFund] = useState(currentRec.d_SourceofFund)
    const [def_d_StaffRelation, setdef_d_StaffRelation] = useState(currentRec.d_StaffRelation==="Y")
    const [def_d_StaffName, setdef_d_StaffName] = useState(currentRec.d_StaffName)
    const [def_d_StaffRelationship, setdef_d_StaffRelationship] = useState(currentRec.d_StaffRelationship)
    const [def_d_selfInstruction, setdef_d_selfInstruction] = useState(currentRec.d_selfInstruction==="Y")
    const [def_d_InstructionType, setdef_d_InstructionType] = useState(currentRec.d_InstructionType)
    const [def_d_Employment, setdef_d_Employment] = useState(currentRec.d_Employment)
    const [def_d_EmploymentOther, setdef_d_EmploymentOther] = useState(currentRec.d_EmploymentOther)
    const [def_d_CoName, setdef_d_CoName] = useState(currentRec.d_CoName)
    const [def_d_isFinInstit, setdef_d_isFinInstit] = useState(currentRec.d_isFinInstit==="Y")
    const [def_d_ApproveFinInstit, setdef_d_ApproveFinInstit] = useState(currentRec.d_ApproveFinInstit==="Y")
    const [def_d_CoAddr, setdef_d_CoAddr] = useState(currentRec.d_CoAddr)
    const [def_d_CoType, setdef_d_CoType] = useState(currentRec.d_CoType)
    const [def_d_CoPost, setdef_d_CoPost] = useState(currentRec.d_CoPost)
    const [def_d_BankInfo, setdef_d_BankInfo] = useState(currentRec.d_BankInfo)
    const [def_d_BankACNo, setdef_d_BankACNo] = useState(currentRec.d_BankACNo)
    const [def_d_tel, setdef_d_tel] = useState(currentRec.d_tel)
    const [def_d_offTel, setdef_d_offTel] = useState(currentRec.d_offTel)
    const [def_d_mobile, setdef_d_mobile] = useState(currentRec.d_mobile)
    const [def_d_fax, setdef_d_fax] = useState(currentRec.d_fax)
    const [def_d_hasSpouseClient, setdef_d_hasSpouseClient] = useState(currentRec.d_hasSpouseClient==="Y")
    const [def_d_SpouseName, setdef_d_SpouseName] = useState(currentRec.d_SpouseName)
    const [def_d_SpouseAcNo, setdef_d_SpouseAcNo] = useState(currentRec.d_SpouseAcNo)
    const [def_d_VoteRight, setdef_d_VoteRight] = useState(currentRec.d_VoteRight==="Y")
    const [def_d_VoteAc1, setdef_d_VoteAc1] = useState(currentRec.d_VoteAc1)
    const [def_d_VoteAcName1, setdef_d_VoteAcName1] = useState(currentRec.d_VoteAcName1)
    const [def_d_VoteRelate1, setdef_d_VoteRelate1] = useState(currentRec.d_VoteRelate1)
    const [def_d_VoteAc2, setdef_d_VoteAc2] = useState(currentRec.d_VoteAc2)
    const [def_d_VoteAcName2, setdef_d_VoteAcName2] = useState(currentRec.d_VoteAcName2)
    const [def_d_VoteRelate2, setdef_d_VoteRelate2] = useState(currentRec.d_VoteRelate2)
    const [def_d_isInternet, setdef_d_isInternet] = useState(currentRec.d_isInternet==="Y")
    const [def_d_Target1, setdef_d_Target1] = useState(currentRec.d_Target1==="Y")
    const [def_d_Target2, setdef_d_Target2] = useState(currentRec.d_Target2==="Y")
    const [def_d_Target3, setdef_d_Target3] = useState(currentRec.d_Target3==="Y")
    const [def_d_Target4, setdef_d_Target4] = useState(currentRec.d_Target4==="Y")
    const [def_d_Target5, setdef_d_Target5] = useState(currentRec.d_Target5==="Y")
    const [def_d_Target6, setdef_d_Target6] = useState(currentRec.d_Target6==="Y")
    const [def_d_Target7, setdef_d_Target7] = useState(currentRec.d_Target7==="Y")
    const [def_d_Target8, setdef_d_Target8] = useState(currentRec.d_Target8==="Y")
    const [def_d_Target9, setdef_d_Target9] = useState(currentRec.d_Target9==="Y")
    const [def_d_StockYear, setdef_d_StockYear] = useState(currentRec.d_StockYear)
    const [def_d_StockCnt, setdef_d_StockCnt] = useState(currentRec.d_StockCnt)
    const [def_d_StockAmt, setdef_d_StockAmt] = useState(currentRec.d_StockAmt)
    const [def_d_WarrantYear, setdef_d_WarrantYear] = useState(currentRec.d_WarrantYear)
    const [def_d_WarrantCnt, setdef_d_WarrantCnt] = useState(currentRec.d_WarrantCnt)
    const [def_d_WarrantAmt, setdef_d_WarrantAmt] = useState(currentRec.d_WarrantAmt)
    const [def_d_OptionYear, setdef_d_OptionYear] = useState(currentRec.d_OptionYear)
    const [def_d_OptionCnt, setdef_d_OptionCnt] = useState(currentRec.d_OptionCnt)
    const [def_d_OptionAmt, setdef_d_OptionAmt] = useState(currentRec.d_OptionAmt)
    const [def_d_FutureYear, setdef_d_FutureYear] = useState(currentRec.d_FutureYear)
    const [def_d_FutureCnt, setdef_d_FutureCnt] = useState(currentRec.d_FutureCnt)
    const [def_d_FutureAmt, setdef_d_FutureAmt] = useState(currentRec.d_FutureAmt)

    const [def_d_HighRiskExp, setdef_d_HighRiskExp] = useState(currentRec.d_HighRiskExp==="Y")

    const [def_d_Agree, setdef_d_Agree] = useState(currentRec.d_Agree==="Y")



    const [def_d_email, setdef_d_email] = useState(currentRec.d_email)
    const [def_ResendTxt, setdef_ResendTxt] = useState("送出 電郵驗証碼")
    const [def_d_verify_code1, setdef_d_verify_code1] = useState(currentRec.d_verify_code1)

    const [def_d_inpputDate, setdef_d_inpputDate] = useState(currentRec.d_inpputDate)
    const [def_d_inpputTime, setdef_d_inpputTime] = useState(currentRec.d_inpputTime)


    const Mindate1 = new Date(1800, 0, 1)
    const Maxdate1 = addDays(new Date(), 365*5)

    
    const dateFnsFormat = "dd-MM-yyyy";
    const formatDate = useCallback((date: Date) => format(date, dateFnsFormat), []);
    const parseDate = useCallback((str: string) => parse(str, dateFnsFormat, new Date()), []);
    const [CountryDesc, setCountryDesc] = useState("")


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag0');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                
                //set lang to zh-hk
                i18next.changeLanguage("zh-HK")
                
                setSendVmeth(OpenAccFlag.DEF_SENDVMETH);
                setcardOpen01("cardOpen01-Theme"+OpenAccFlag.DEF_THEME2);
                setcardOpen02("cardOpen02-Theme"+OpenAccFlag.DEF_THEME2);
                setEnter2tab(OpenAccFlag.Enter2tab);

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [OpenAcc])

    useEffect(() => {

        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                const res = await OpenAcc.post('/Master0',
                    {   sb_data: {
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "OpenAcc",
                        cAction: "ListNationality",
                    });
            
                 //   Nationality
                setNationalityList(res.data.data)
                //console.log("NationalityList",res.data.data)
            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])
 
    useEffect(() => {
        if (Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [OpenAccFlag, nPage])
    
        
    /*
    useEffect(() => {
        setCountryDesc("")
       
        let CountryItem3 = NationalityList.findIndex((CountryItem: Nationality) => CountryItem.CountryCode === OpenAcc.d_CountryCode)
        console.log("ClMastView CountryDesc.find ac.d_CountryCode : ", OpenAcc.d_CountryCode) 
        console.log("ClMastView CountryDesc.find CountryItem3.d_Country : ", NationalityList[CountryItem3]) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {

            currentRec.lang_reg = i18n.languages.toString();
            
            if ( currentRec.lang_reg.substr(0,2)  == "en" )
            {
                setCountryDesc(NationalityList[CountryItem3].Country)

            } else if ( currentRec.lang_reg.substr(0,5)  == "zh-HK" ) { 
                setCountryDesc(NationalityList[CountryItem3].CountryHK)
            } else { 
                setCountryDesc(NationalityList[CountryItem3].CountryCN)
            }     
           // console.log("ClMastView CountryIssuanceDesc.find currentRec.lang_reg : ", currentRec.lang_reg,i18n.languages.toString()) 
        }
      
    }, [OpenAcc])
    */

    function setCountry1(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        setCountryDesc("")

        let CountryItem = NationalityList.findIndex((CountryItem: Nationality) => CountryItem.CountryCode === text)
 
        if ( CountryItem == 0 || CountryItem > 0) {
  
            currentRec.lang_reg = i18n.languages.toString();
            if ( currentRec.lang_reg.substr(0,2)  == "en" )
            {
                setCountryDesc(NationalityList[CountryItem].Country)
            } else if ( currentRec.lang_reg.substr(0,5)  == "zh-HK" ) { 
                setCountryDesc(NationalityList[CountryItem].CountryHK)
            } else  { 
                setCountryDesc(NationalityList[CountryItem].CountryCN)
            }
       
        }
        return setCountry1;
    }

    let idNoLength = 20
/*    
    if ( OpenAccFlag.user_key === "TATLEE" ) {
        idNoLength = 22
    }
*/
    let AddrLength = 30
/*    
    if ( OpenAccFlag.user_key === "WINFULL" || OpenAccFlag.user_key === "WINGHANG" || OpenAccFlag.user_key === "COMPSHARE" ) {
        AddrLength = 35
    }
*/
    let TelLength = 15
/*    
    if ( OpenAccFlag.user_key === "BERICH" || OpenAccFlag.user_key === "CHEERFUL" ) {
        TelLength = 20
    }
*/
    let OffTelLength = 15
/*    
    if ( OpenAccFlag.user_key === "CHEERFUL"  ) {
        OffTelLength = 20
    }
*/
    let MobileLength = 15

    let FaxLength = 15

    let EmailLength = 100
/*    
    if ( OpenAccFlag.user_key === "PAMIRS"  ) {
            EmailLength = 130
    } else if ( OpenAccFlag.user_key === "COMPSHARE" || OpenAccFlag.user_key === "BELTRADE" ) {
        EmailLength = 200
    }
*/
    let BankInfoLength = 20
/*    
    if ( OpenAccFlag.user_key === "WINGHANG"  ) {
        BankInfoLength = 9
    }
*/
    let BankACNoLength = 20
/*    
    if ( OpenAccFlag.user_key === "WINGHANG"  ) {
        BankACNoLength = 9
    }
*/

   const onPrev1 = (data: Account) => {
        //console.log("onPrev1 called", data)
     
        setdef_d_acName1(data.d_acName1)
        setdef_d_acName2(data.d_acName2)
        setdef_d_idNo(data.d_idNo)
        //setdef_d_dob(data.d_dob)
        data.d_dob = def_d_dob
        if ( data.d_dob === null) {
            data.d_dob = "    -  -  "
        }

      
        //setdef_d_National(data.d_National)
        data.d_National = def_d_National
        setdef_d_acAddr1(data.d_acAddr1)
        setdef_d_acAddr2(data.d_acAddr2)
        setdef_d_acAddr3(data.d_acAddr3)
        setdef_d_mailAddr1(data.d_mailAddr1)
        setdef_d_mailAddr2(data.d_mailAddr2)
        setdef_d_mailAddr3(data.d_mailAddr3)
        setdef_d_way2GetStmt(data.d_way2GetStmt)
        setdef_d_NetAsset(data.d_NetAsset)
        setdef_d_AnnualIncome(data.d_AnnualIncome)
        setdef_d_SourceofFund(data.d_SourceofFund)

        data.d_StaffRelation = "N"
        if (def_d_StaffRelation) {
            data.d_StaffRelation = "Y";
        } else {
            data.d_StaffRelation = "N";
        }
        setdef_d_StaffName(data.d_StaffName)
        setdef_d_StaffRelationship(data.d_StaffRelationship)

        data.d_selfInstruction = "Y"
        if (def_d_selfInstruction) {
            data.d_selfInstruction = "Y";
        } else {
            data.d_selfInstruction = "N";
        }
        setdef_d_InstructionType(data.d_InstructionType)

        setdef_d_Employment(data.d_Employment)
        setdef_d_EmploymentOther(data.d_EmploymentOther)
        setdef_d_CoName(data.d_CoName)

        data.d_isFinInstit = "N"
        if (def_d_isFinInstit) {
            data.d_isFinInstit = "Y";
        } else {
            data.d_isFinInstit = "N";
        }

        data.d_ApproveFinInstit = "Y"
        if (def_d_ApproveFinInstit) {
            data.d_ApproveFinInstit = "Y";
        } else {
            data.d_ApproveFinInstit = "N";
        }

        setdef_d_CoAddr(data.d_CoAddr)
        setdef_d_CoType(data.d_CoType)
        setdef_d_CoPost(data.d_CoPost)

        setdef_d_BankInfo(data.d_BankInfo)
        setdef_d_BankACNo(data.d_BankACNo)
        setdef_d_tel(data.d_tel)
        setdef_d_offTel(data.d_offTel)
        setdef_d_mobile(data.d_mobile)
        setdef_d_fax(data.d_fax)

        
        PageNo(-1)

    }



    const onValidate1 = (data: Account) => {
        //console.log("onValidate1 called", data)

        setdef_d_acName1(data.d_acName1)
        setdef_d_acName2(data.d_acName2)
        setdef_d_idNo(data.d_idNo)
        //setdef_d_dob(data.d_dob)
        data.d_dob = def_d_dob
        if ( data.d_dob === null) {
            data.d_dob = "    -  -  "
        }        

        if ( data.d_National === undefined) {
            data.d_National = ""
        }        

        currentRec.d_idNo = def_d_idNo

        currentRec.d_National = def_d_National.trim() //data.d_National

  
        setdef_d_acAddr1(data.d_acAddr1)
        setdef_d_acAddr2(data.d_acAddr2)
        setdef_d_acAddr3(data.d_acAddr3)
        setdef_d_mailAddr1(data.d_mailAddr1)
        setdef_d_mailAddr2(data.d_mailAddr2)
        setdef_d_mailAddr3(data.d_mailAddr3)
        setdef_d_way2GetStmt(data.d_way2GetStmt)
        setdef_d_NetAsset(data.d_NetAsset)
        setdef_d_AnnualIncome(data.d_AnnualIncome)
        setdef_d_SourceofFund(data.d_SourceofFund)

        data.d_StaffRelation = "N"
        if (def_d_StaffRelation) {
            data.d_StaffRelation = "Y";
        } else {
            data.d_StaffRelation = "N";
        }
        setdef_d_StaffName(data.d_StaffName)
        setdef_d_StaffRelationship(data.d_StaffRelationship)

        data.d_selfInstruction = "Y"
        if (def_d_selfInstruction) {
            data.d_selfInstruction = "Y";
        } else {
            data.d_selfInstruction = "N";
        }
        setdef_d_InstructionType(data.d_InstructionType)

        setdef_d_Employment(data.d_Employment)
        setdef_d_EmploymentOther(data.d_EmploymentOther)
        setdef_d_CoName(data.d_CoName)

        data.d_isFinInstit = "N"
        if (def_d_isFinInstit) {
            data.d_isFinInstit = "Y";
        } else {
            data.d_isFinInstit = "N";
        }

        data.d_ApproveFinInstit = "Y"
        if (def_d_ApproveFinInstit) {
            data.d_ApproveFinInstit = "Y";
        } else {
            data.d_ApproveFinInstit = "N";
        }

        setdef_d_CoAddr(data.d_CoAddr)
        setdef_d_CoType(data.d_CoType)
        setdef_d_CoPost(data.d_CoPost)

        setdef_d_BankInfo(data.d_BankInfo)
        setdef_d_BankACNo(data.d_BankACNo)
        setdef_d_tel(data.d_tel)
        setdef_d_offTel(data.d_offTel)
        setdef_d_mobile(data.d_mobile)
        setdef_d_fax(data.d_fax)
    
   

        currentRec.d_acName1 = data.d_acName1
        currentRec.d_acName2 = data.d_acName2
        currentRec.d_idNo = data.d_idNo
        currentRec.d_dob = data.d_dob
      //setdef_d_National(data.d_National)
 

   
        currentRec.d_acAddr1 = data.d_acAddr1
        currentRec.d_acAddr2 = data.d_acAddr2
        currentRec.d_acAddr3 = data.d_acAddr3
        currentRec.d_mailAddr1 = data.d_mailAddr1
        currentRec.d_mailAddr2 = data.d_mailAddr2
        currentRec.d_mailAddr3 = data.d_mailAddr3
        currentRec.d_way2GetStmt = data.d_way2GetStmt
        currentRec.d_NetAsset = data.d_NetAsset
        currentRec.d_AnnualIncome = data.d_AnnualIncome
        currentRec.d_SourceofFund = data.d_SourceofFund

        currentRec.d_StaffRelation = "N"
        if (def_d_StaffRelation) {
            currentRec.d_StaffRelation = "Y";
        } else {
            currentRec.d_StaffRelation = "N";
        }
        currentRec.d_StaffName = data.d_StaffName
        currentRec.d_StaffRelationship = data.d_StaffRelationship

        currentRec.d_selfInstruction = "Y"
        if (def_d_selfInstruction) {
            currentRec.d_selfInstruction = "Y";
        } else {
            currentRec.d_selfInstruction = "N";
        }
        currentRec.d_InstructionType = data.d_InstructionType

        currentRec.d_Employment = data.d_Employment
        currentRec.d_EmploymentOther = data.d_EmploymentOther

        currentRec.d_CoName = data.d_CoName
        currentRec.d_isFinInstit = "N"
        if (def_d_isFinInstit) {
            currentRec.d_isFinInstit = "Y";
        } else {
            currentRec.d_isFinInstit = "N";
        }
        currentRec.d_ApproveFinInstit = "Y"
        if (def_d_ApproveFinInstit) {
            currentRec.d_ApproveFinInstit = "Y";
        } else {
            currentRec.d_ApproveFinInstit = "N";
        }
        currentRec.d_CoAddr = data.d_CoAddr
        currentRec.d_CoType = data.d_CoType
        currentRec.d_CoPost = data.d_CoPost

        currentRec.d_BankInfo = data.d_BankInfo
        currentRec.d_BankACNo = data.d_BankACNo
        currentRec.d_tel = data.d_tel
        currentRec.d_offTel = data.d_offTel
        currentRec.d_mobile = data.d_mobile
        currentRec.d_fax = data.d_fax 

        currentRec.lang_reg = i18n.languages.toString();
        ;

        (async () => {
            try {
                console.log("call onValidate1 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "OpenAccValidate1", isNew: true})
                //console.log("onValidate1 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onPrev2 = (data: Account) => {
        //console.log("onPrev2 called", data)


        data.d_hasSpouseClient = "N"
        if (def_d_hasSpouseClient) {
            data.d_hasSpouseClient = "Y";
        } else {
            data.d_hasSpouseClient = "N";
        }
        setdef_d_SpouseName(data.d_SpouseName)
        setdef_d_SpouseAcNo(data.d_SpouseAcNo)

        data.d_VoteRight = "N"
        if (def_d_VoteRight) {
            data.d_VoteRight = "Y";
        } else {
            data.d_VoteRight = "N";
        }
        setdef_d_VoteAc1(data.d_VoteAc1)
        setdef_d_VoteAcName1(data.d_VoteAcName1)
        setdef_d_VoteRelate1(data.d_VoteRelate1)
        setdef_d_VoteAc2(data.d_VoteAc2)
        setdef_d_VoteAcName2(data.d_VoteAcName2)
        setdef_d_VoteRelate2(data.d_VoteRelate2)

        data.d_isInternet = "Y"
        if (def_d_isInternet) {
            data.d_isInternet = "Y";
        } else {
            data.d_isInternet = "N";
        }
    
         data.d_National = def_d_National            
          
  
        data.d_Target1 = "N"
        if (def_d_Target1) {
            data.d_Target1 = "Y";
        } else {
            data.d_Target1 = "N";
        }
        data.d_Target2 = "N"
        if (def_d_Target2) {
            data.d_Target2 = "Y";
        } else {
            data.d_Target2 = "N";
        }
        data.d_Target3 = "N"
        if (def_d_Target3) {
            data.d_Target3 = "Y";
        } else {
            data.d_Target3 = "N";
        }
        data.d_Target4 = "N"
        if (def_d_Target4) {
            data.d_Target4 = "Y";
        } else {
            data.d_Target4 = "N";
        }
        data.d_Target5 = "N"
        if (def_d_Target5) {
            data.d_Target5 = "Y";
        } else {
            data.d_Target5 = "N";
        }
        data.d_Target6 = "N"
        if (def_d_Target6) {
            data.d_Target6 = "Y";
        } else {
            data.d_Target6 = "N";
        }
        data.d_Target7 = "N"
        if (def_d_Target7) {
            data.d_Target7 = "Y";
        } else {
            data.d_Target7 = "N";
        }
        data.d_Target8 = "N"
        if (def_d_Target8) {
            data.d_Target8 = "Y";
        } else {
            data.d_Target8 = "N";
        }
        data.d_Target9 = "N"
        if (def_d_Target9) {
            data.d_Target9 = "Y";
        } else {
            data.d_Target9 = "N";
        }

        setdef_d_StockYear(data.d_StockYear)
        setdef_d_StockCnt(data.d_StockCnt)
        setdef_d_StockAmt(data.d_StockAmt)
        setdef_d_WarrantYear(data.d_WarrantYear)
        setdef_d_WarrantCnt(data.d_WarrantCnt)
        setdef_d_WarrantAmt(data.d_WarrantAmt)
        setdef_d_OptionYear(data.d_OptionYear)
        setdef_d_OptionCnt(data.d_OptionCnt)
        setdef_d_OptionAmt(data.d_OptionAmt)
        setdef_d_FutureYear(data.d_FutureYear)
        setdef_d_FutureCnt(data.d_FutureCnt)
        setdef_d_FutureAmt(data.d_FutureAmt)

        data.d_HighRiskExp = "N"
        if (def_d_HighRiskExp) {
            data.d_HighRiskExp = "Y";
        } else {
            data.d_HighRiskExp = "N";
        }

        data.d_Agree = "N"
        if (def_d_Agree) {
            data.d_Agree = "Y";
        } else {
            data.d_Agree = "N";
        }


        PageNo(-1)

    }

    const onValidate2 = (data: Account) => {
        //console.log("onValidate2 called", data)

        data.d_hasSpouseClient = "N"
        if (def_d_hasSpouseClient) {
            data.d_hasSpouseClient = "Y";
        } else {
            data.d_hasSpouseClient = "N";
        }
        setdef_d_SpouseName(data.d_SpouseName)
        setdef_d_SpouseAcNo(data.d_SpouseAcNo)

        data.d_VoteRight = "N"
        if (def_d_VoteRight) {
            data.d_VoteRight = "Y";
        } else {
            data.d_VoteRight = "N";
        }
        setdef_d_VoteAc1(data.d_VoteAc1)
        setdef_d_VoteAcName1(data.d_VoteAcName1)
        setdef_d_VoteRelate1(data.d_VoteRelate1)
        setdef_d_VoteAc2(data.d_VoteAc2)
        setdef_d_VoteAcName2(data.d_VoteAcName2)
        setdef_d_VoteRelate2(data.d_VoteRelate2)

        data.d_isInternet = "Y"
        if (def_d_isInternet) {
            data.d_isInternet = "Y";
        } else {
            data.d_isInternet = "N";
        }


        data.d_Target1 = "N"
        if (def_d_Target1) {
            data.d_Target1 = "Y";
        } else {
            data.d_Target1 = "N";
        }
        data.d_Target2 = "N"
        if (def_d_Target2) {
            data.d_Target2 = "Y";
        } else {
            data.d_Target2 = "N";
        }
        data.d_Target3 = "N"
        if (def_d_Target3) {
            data.d_Target3 = "Y";
        } else {
            data.d_Target3 = "N";
        }
        data.d_Target4 = "N"
        if (def_d_Target4) {
            data.d_Target4 = "Y";
        } else {
            data.d_Target4 = "N";
        }
        data.d_Target5 = "N"
        if (def_d_Target5) {
            data.d_Target5 = "Y";
        } else {
            data.d_Target5 = "N";
        }
        data.d_Target6 = "N"
        if (def_d_Target6) {
            data.d_Target6 = "Y";
        } else {
            data.d_Target6 = "N";
        }
        data.d_Target7 = "N"
        if (def_d_Target7) {
            data.d_Target7 = "Y";
        } else {
            data.d_Target7 = "N";
        }
        data.d_Target8 = "N"
        if (def_d_Target8) {
            data.d_Target8 = "Y";
        } else {
            data.d_Target8 = "N";
        }
        data.d_Target9 = "N"
        if (def_d_Target9) {
            data.d_Target9 = "Y";
        } else {
            data.d_Target9 = "N";
        }

        setdef_d_StockYear(data.d_StockYear)
        setdef_d_StockCnt(data.d_StockCnt)
        setdef_d_StockAmt(data.d_StockAmt)
        setdef_d_WarrantYear(data.d_WarrantYear)
        setdef_d_WarrantCnt(data.d_WarrantCnt)
        setdef_d_WarrantAmt(data.d_WarrantAmt)
        setdef_d_OptionYear(data.d_OptionYear)
        setdef_d_OptionCnt(data.d_OptionCnt)
        setdef_d_OptionAmt(data.d_OptionAmt)
        setdef_d_FutureYear(data.d_FutureYear)
        setdef_d_FutureCnt(data.d_FutureCnt)
        setdef_d_FutureAmt(data.d_FutureAmt)

        data.d_HighRiskExp = "N"
        if (def_d_HighRiskExp) {
            data.d_HighRiskExp = "Y";
        } else {
            data.d_HighRiskExp = "N";
        }

        data.d_Agree = "N"
        if (def_d_Agree) {
            data.d_Agree = "Y";
        } else {
            data.d_Agree = "N";
        }





        currentRec.d_acName1 = def_d_acName1
        currentRec.d_acName2 = def_d_acName2
        currentRec.d_idNo = def_d_idNo
        currentRec.d_dob = def_d_dob
        currentRec.d_National = def_d_National
        currentRec.d_acAddr1 = def_d_acAddr1
        currentRec.d_acAddr2 = def_d_acAddr2
        currentRec.d_acAddr3 = def_d_acAddr3
        currentRec.d_mailAddr1 = def_d_mailAddr1
        currentRec.d_mailAddr2 = def_d_mailAddr2
        currentRec.d_mailAddr3 = def_d_mailAddr3
        currentRec.d_way2GetStmt = def_d_way2GetStmt
        currentRec.d_NetAsset = def_d_NetAsset
        currentRec.d_AnnualIncome = def_d_AnnualIncome
        currentRec.d_SourceofFund = def_d_SourceofFund

        currentRec.d_StaffRelation = "N"
        if (def_d_StaffRelation) {
            currentRec.d_StaffRelation = "Y";
        } else {
            currentRec.d_StaffRelation = "N";
        }
        currentRec.d_StaffName = def_d_StaffName
        currentRec.d_StaffRelationship = def_d_StaffRelationship

        currentRec.d_selfInstruction = "Y"
        if (def_d_selfInstruction) {
            currentRec.d_selfInstruction = "Y";
        } else {
            currentRec.d_selfInstruction = "N";
        }
        currentRec.d_InstructionType = def_d_InstructionType

        currentRec.d_Employment = def_d_Employment
        currentRec.d_EmploymentOther = def_d_EmploymentOther

        currentRec.d_CoName = def_d_CoName
        currentRec.d_isFinInstit = "N"
        if (def_d_isFinInstit) {
            currentRec.d_isFinInstit = "Y";
        } else {
            currentRec.d_isFinInstit = "N";
        }
        currentRec.d_ApproveFinInstit = "Y"
        if (def_d_ApproveFinInstit) {
            currentRec.d_ApproveFinInstit = "Y";
        } else {
            currentRec.d_ApproveFinInstit = "N";
        }
        currentRec.d_CoAddr = def_d_CoAddr
        currentRec.d_CoType = def_d_CoType
        currentRec.d_CoPost = def_d_CoPost

        currentRec.d_BankInfo = def_d_BankInfo
        currentRec.d_BankACNo = def_d_BankACNo
        currentRec.d_tel = def_d_tel
        currentRec.d_offTel = def_d_offTel
        currentRec.d_mobile = def_d_mobile
        currentRec.d_fax = def_d_fax

        currentRec.d_hasSpouseClient = data.d_hasSpouseClient
        currentRec.d_SpouseName = data.d_SpouseName
        currentRec.d_SpouseAcNo = data.d_SpouseAcNo

        currentRec.d_VoteRight = data.d_VoteRight
        currentRec.d_VoteAc1 = data.d_VoteAc1
        currentRec.d_VoteAcName1 = data.d_VoteAcName1
        currentRec.d_VoteRelate1 = data.d_VoteRelate1
        currentRec.d_VoteAc2 = data.d_VoteAc2
        currentRec.d_VoteAcName2 = data.d_VoteAcName2
        currentRec.d_VoteRelate2 = data.d_VoteRelate2

        currentRec.d_isInternet = data.d_isInternet

        currentRec.d_Target1 = data.d_Target1
        currentRec.d_Target2 = data.d_Target2
        currentRec.d_Target3 = data.d_Target3
        currentRec.d_Target4 = data.d_Target4
        currentRec.d_Target5 = data.d_Target5
        currentRec.d_Target6 = data.d_Target6
        currentRec.d_Target7 = data.d_Target7
        currentRec.d_Target8 = data.d_Target8
        currentRec.d_Target9 = data.d_Target9

        currentRec.d_StockYear = data.d_StockYear
        currentRec.d_StockCnt = data.d_StockCnt
        currentRec.d_StockAmt = data.d_StockAmt
        currentRec.d_WarrantYear = data.d_WarrantYear
        currentRec.d_WarrantCnt = data.d_WarrantCnt
        currentRec.d_WarrantAmt = data.d_WarrantAmt
        currentRec.d_OptionYear = data.d_OptionYear
        currentRec.d_OptionCnt = data.d_OptionCnt
        currentRec.d_OptionAmt = data.d_OptionAmt
        currentRec.d_FutureYear = data.d_FutureYear
        currentRec.d_FutureCnt = data.d_FutureCnt
        currentRec.d_FutureAmt = data.d_FutureAmt

        currentRec.d_HighRiskExp = data.d_HighRiskExp
        currentRec.d_Agree = data.d_Agree



        currentRec.lang_reg = i18n.languages.toString();

        if ( file1Label == "..." ) {
            currentRec.UploadFile1 = ""
        } else {
            currentRec.UploadFile1 = file1Label
        }
        if ( file1bLabel == "..." ) {
            currentRec.UploadFile1b = ""
        } else {
            currentRec.UploadFile1b = file1bLabel
        }
        if ( file2Label == "..." ) {
            currentRec.UploadFile2 = ""
        } else {
            currentRec.UploadFile2 = file2Label
        }
        if ( file3Label == "..." ) {
            currentRec.UploadFile3 = ""
        } else {
            currentRec.UploadFile3 = file3Label
        } 
        if ( file4Label == "..." ) {
            currentRec.UploadFile4 = ""
        } else {
            currentRec.UploadFile4 = file4Label
        } 

        currentRec.d_tempRec = ""

        
        savesignature() ;

        

        (async () => {
            try {
                console.log("call onValidate2 currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "OpenAcc", cAction: "OpenAccValidate2", isNew: true})
                //console.log("onValidate2 res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(currentRec)

                PageNo(+1);

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }

    const onNext = (data: Account) => {
    
    }
    
    const onUpload = (e: FormEvent) => {
        e.preventDefault();

        (async () => {
            try {
                const res = await OpenAcc.post('/importupload',
                    new FormData(e.target as HTMLFormElement), {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setnUploaded(res.data.uploaded)
                utils.showSuccess(`${res.data.uploaded} file(s) uploaded`)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onSave = async (ac: Account) => {
        try {
            console.log("call onSave ac", ac)
            const res = await OpenAcc.post('/Master0', {sb_data: ac, cPgmId: "OpenAcc", cAction: "AddRec"})
            //console.log("onSave res.data.ok", res.data.ok)
            console.log("onSave res.data", res.data)
            //showSuccess("Account Code " + ac.d_idNo + " record added")

            currentRec.d_National = def_d_National       
                 
            currentRec.d_inpputDate = res.data.data.d_inpputDate
            currentRec.d_inpputTime = res.data.data.d_inpputTime
            setdef_d_inpputDate(currentRec.d_inpputDate)
            setdef_d_inpputTime(currentRec.d_inpputTime)
        
            console.log("onSave currentRec", currentRec)

        } catch (error) {
            showError(error)
        }
    }

    const onVerify = (lcn: Account) => {
        console.log("onVerify called", lcn)


        setdef_d_email(lcn.d_email)
        setdef_d_verify_code1(lcn.d_verify_code1)

        currentRec.d_acName1 = def_d_acName1
        currentRec.d_acName2 = def_d_acName2
        currentRec.d_idNo = def_d_idNo

        currentRec.d_email = lcn.d_email
        currentRec.d_verify_code1 = lcn.d_verify_code1
        
        currentRec.d_inpputDate = def_d_inpputDate ;
        currentRec.d_inpputTime = def_d_inpputTime ;
        
        currentRec.lang_reg = i18n.languages.toString() ;

        (async () => {
            try {
            const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "verifyemail"})
            console.log("verifyemail res.data", res.data)

            if (! res.data.ok) {
                for (let j = 0; j < res.data.data.length; j++) {
                  
                    setError(res.data.data[j][0], {
                       message: res.data.data[j][1]
                    }); 
                }
                return
            }

            if ( res.data.ok )
            {
                setlFinishe(true)
                utils.showSuccess("開戶登記 已經完成")
            }

        } catch (error) {
            utils.showError(error)
        }
        })()
    }
/*
    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        register({ name: "d_National",required:true})
    }, [register])

*/

    const onResend = async (lcn: Account) => {
        console.log("onResend called", lcn)
        
        setdef_ResendTxt("重寄 電郵驗証碼")

        setdef_d_email(lcn.d_email)
        setdef_d_verify_code1(lcn.d_verify_code1)

        currentRec.d_acName1 = def_d_acName1
        currentRec.d_acName2 = def_d_acName2
        currentRec.d_idNo = def_d_idNo

        currentRec.d_email = lcn.d_email
        currentRec.d_verify_code1 = lcn.d_verify_code1 ;

        currentRec.lang_reg = i18n.languages.toString()

        if ( lcn.d_email.length > 0) {
            try {
                console.log("call onResend currentRec", currentRec)
                const res = await OpenAcc.post('/Master0', {sb_data: currentRec, cPgmId: "verifyresend"})
                console.log("onResend res.data", res.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                if ( res.data.ok )
                {
                    utils.showSuccess("電郵驗証碼 已經寄出")
                    setError("d_email", {
                        message: "電郵驗証碼 已經寄出"
                     }); 
                 } else {
                    utils.showSuccess("電郵驗証碼 寄出失敗")
                    setError("d_email", {
                        message: "電郵驗証碼 寄出失敗"
                     }); 
                }
            } catch (error) {
                utils.showError(error)
            }
        } else {
            utils.showError("Insufficient Ddata")
        }
    }


    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onValidate1)()
        }
    }

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

 
    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n >= 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


    let CoHead = <>
        <span id="DispCoName2" style={{fontSize: "20px", fontWeight: 500}}> {cologo2} <b> { CO_NAME } </b> </span>
        {/*
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
        */}
    </>

    return (
        <>
        {/* <form onKeyDown={handleHotkey}> */}

            { ! lFinishe && ( nPage == 0 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> 開戶 三步曲 
                    &nbsp; &nbsp; &nbsp;
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                        </tr>
                    </tbody></span>
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <h4 className="h4-heading">開戶條件</h4>
                    <ul>
                        <li>年滿18歲</li>
                    </ul>
                    <div className="Spacing-V-16" />
                    <div className="Spacing-V-16" />
                    <h3 className="h4-heading">需準備下列文件</h3>
                    <ul className="bp3-list">
                        <li>香港身份證或護照</li>
                        <li>3個月內之住址證明，如煤氣單，銀行月結單等</li>
                        <li>銀行戶口證明及存款證明</li>
                        <li>首次存入不少於<code className='bp3-code'>HK$10,000</code>資金</li>
                    </ul>
                    <div className="Spacing-V-16" />
                    <div className="Spacing-V-16" />
                    &nbsp; &nbsp; &nbsp;
                    <Button icon="direction-right" onClick={ () =>PageNo(+1) }>開始開戶</Button>
                    <div className="Spacing-V-16" />
                    <div className="Spacing-V-16" />
                    <span id="DispText2"><a target="_blank" rel="noopener noreferrer" href={`/#/RegFormDemo1`}>Demo1</a></span>
                    {/*
                    <div className="Spacing-V-16" />
                    <div >
                        <span id="DispText2"><a target="_blank" rel="noopener noreferrer" href={`/#/documentUpload`}>上傳 開戶入數紙 / 後補文件</a></span>
                    </div>
                    */}
                </Card>
            </div>}


            { ! lFinishe && ( nPage == 1 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> 開戶 - 個人資料 
                    &nbsp; &nbsp; &nbsp;
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3b}</td>
                        </tr>
                    </tbody></span>
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="客戶姓名" labelFor="inputAcName1" labelInfo="*">
                            <InputGroup id="inputAcName1" name="d_acName1" autoFocus={true} defaultValue={def_d_acName1} placeholder="姓名 1 ..." inputRef={register({ required: true , maxLength: 30}) } />
                            {errors.d_acName1 && errors.d_acName1.types && <span id="Err_msg">{errors.d_acName1.types.message}</span>}
                            {errors.d_acName1 && errors.d_acName1.type === "required" && (<span id="Err_msg">客戶姓名 不能空白</span>)}
                            {errors.d_acName1 && errors.d_acName1.type === "maxLength" && <span id="Err_msg">最多 30 字元</span>}
                            <InputGroup id="inputAcName2" name="d_acName2" defaultValue={def_d_acName2} placeholder="姓名 2 ..." inputRef={register({ maxLength: 30}) } />
                            {errors.d_acName2 && errors.d_acName2.types && <span id="Err_msg">{errors.d_acName2.types.message}</span>}
                            {errors.d_acName2 && errors.d_acName2.type === "required" && (<span id="Err_msg">客戶姓名 不能空白</span>)}
                            {errors.d_acName2 && errors.d_acName2.type === "maxLength" && <span id="Err_msg">最多 30 字元</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-2" label="身份證號碼/護照號碼" labelFor="inputIdNo" labelInfo="*">
                            <InputGroup id="inputIdNo" name="d_idNo" defaultValue={def_d_idNo} placeholder="身份證號碼/護照號碼 ..." inputRef={register({ required: true , maxLength: idNoLength}) } />
                            {errors.d_idNo && errors.d_idNo.types && <span id="Err_msg">{errors.d_idNo.types.message}</span>}
                            {errors.d_idNo && errors.d_idNo.type === "required" && (<span id="Err_msg">身份證號碼 不能空白</span>)}
                            {errors.d_idNo && errors.d_idNo.type === "maxLength" && <span id="Err_msg">最多 {idNoLength} 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="出生日期" labelFor="inputDoB" labelInfo="*">
                            {/* <InputGroup id="inputDoB" name="d_dob" type="date" defaultValue={def_d_dob} placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register({ required: true }) } /> */}
                            <DateInput2
                                canClearSelection={true}
                                clearButtonText="Clear"
                                closeOnSelection={true}
                                todayButtonText="Today"
                                showActionsBar={true}
                                popoverProps={{ placement: "bottom" }}
                                rightElement={<Icon icon="calendar" style={{ padding: 7, marginLeft: -5 }} />}
                                minDate={Mindate1}
                                maxDate={Maxdate1}
                                formatDate={formatDate}
                                onChange={(date: any) => {
                                    setdef_d_dob(date)
                                } }
                                parseDate={parseDate}
                                placeholder={dateFnsFormat}
                                value={def_d_dob}
                            />
                            {errors.d_dob && errors.d_dob.types && <span id="Err_msg">{errors.d_dob.types.message}</span>}
                            {errors.d_dob && errors.d_dob.type === "required" && (<span id="Err_msg">{t('DateofBirth')} {t('NotBlank')}</span>)}
                        </FormGroup>
                        {/*
                        <FormGroup className="Col-4-2" label="國藉" labelFor="inputIdNo" labelInfo="*">
                            <InputGroup id="inputIdNo" name="d_National" defaultValue={def_d_National} placeholder="國藉 ..." inputRef={register({ required: true , maxLength: 40}) } />
                            {errors.d_National && errors.d_National.types && <span id="Err_msg">{errors.d_National.types.message}</span>}
                            {errors.d_National && errors.d_National.type === "required" && (<span id="Err_msg">國藉 不能空白</span>)}
                            {errors.d_National && errors.d_National.type === "maxLength" && <span id="Err_msg">最多 40 字元</span>}
                        </FormGroup>
                        */}

                            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-1-2" label={t('Nationality')} labelFor="inputCountry" labelInfo="*">      
                            <Suggest
                                inputProps={{ placeholder: "" }}
                                defaultSelectedItem={NationalityList.find((aj) => aj.CountryCode ===def_d_National)}
                                items={NationalityList}
                                itemRenderer={NationalityRenderer}
                                itemPredicate={NationalityPredicate}
                                inputValueRenderer={NationalityValueRenderer}
                                onItemSelect={(ac) => {setValue("d_National", ac.CountryCode); setdef_d_National(ac.CountryCode);setCountry1(ac.CountryCode) }}
                            />
                            <div>
                                {errors.d_National && errors.d_National.types && <span id="Err_msg">{errors.d_National.types.message}</span>}
                                {errors.d_National && errors.d_National.type === "required" && (<span id="Err_msg">國藉 不能空白</span>)}
                                {errors.d_National && errors.d_National.type === "maxLength" && <span id="Err_msg">最多 40 字元</span>}               
            
                            </div>
                            <div>
                                <div id="DispText">{CountryDesc}</div>
                            </div>
                        </FormGroup> 
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" label="住宅地址" labelFor="inputAddr1" labelInfo="*">
                            <InputGroup id="inputAddr1" name="d_acAddr1" defaultValue={def_d_acAddr1} placeholder="住宅地址 ..." inputRef={register({ required: true , maxLength: AddrLength}) } />
                            {errors.d_acAddr1 && errors.d_acAddr1.types && <span id="Err_msg">{errors.d_acAddr1.types.message}</span>}
                            {errors.d_acAddr1 && errors.d_acAddr1.type === "required" && (<span id="Err_msg">住宅地址 不能空白</span>)}
                            {errors.d_acAddr1 && errors.d_acAddr1.type === "maxLength" && <span id="Err_msg">最多 {AddrLength} 字元</span>}
                            <InputGroup id="inputAddr2" name="d_acAddr2" defaultValue={def_d_acAddr2} placeholder="住宅地址 ..." inputRef={register({ required: true , maxLength: AddrLength}) } />
                            {errors.d_acAddr2 && errors.d_acAddr2.types && <span id="Err_msg">{errors.d_acAddr2.types.message}</span>}
                            {errors.d_acAddr2 && errors.d_acAddr2.type === "required" && (<span id="Err_msg">住宅地址 不能空白</span>)}
                            {errors.d_acAddr2 && errors.d_acAddr2.type === "maxLength" && <span id="Err_msg">最多 {AddrLength} 字元</span>}
                            <InputGroup id="inputAddr3" name="d_acAddr3" defaultValue={def_d_acAddr3} placeholder="住宅地址 ..." inputRef={register({ maxLength: AddrLength}) } />
                            {errors.d_acAddr3 && errors.d_acAddr3.types && <span id="Err_msg">{errors.d_acAddr3.types.message}</span>}
                            {errors.d_acAddr3 && errors.d_acAddr3.type === "required" && (<span id="Err_msg">住宅地址 不能空白</span>)}
                            {errors.d_acAddr3 && errors.d_acAddr3.type === "maxLength" && <span id="Err_msg">最多 {AddrLength} 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" label="通訊地址 (如有不同)" labelFor="inputmailAddr1" >
                            <InputGroup id="inputmailAddr1" name="d_mailAddr1" defaultValue={def_d_mailAddr1} placeholder="通訊地址 ..." inputRef={register({ maxLength: AddrLength}) } />
                            {errors.d_mailAddr1 && errors.d_mailAddr1.types && <span id="Err_msg">{errors.d_mailAddr1.types.message}</span>}
                            {errors.d_mailAddr1 && errors.d_mailAddr1.type === "required" && (<span id="Err_msg">通訊地址 不能空白</span>)}
                            {errors.d_mailAddr1 && errors.d_mailAddr1.type === "maxLength" && <span id="Err_msg">最多 {AddrLength} 字元</span>}
                            <InputGroup id="inputmailAddr2" name="d_mailAddr2" defaultValue={def_d_mailAddr2} placeholder="通訊地址 ..." inputRef={register({ maxLength: AddrLength}) } />
                            {errors.d_mailAddr2 && errors.d_mailAddr2.types && <span id="Err_msg">{errors.d_mailAddr2.types.message}</span>}
                            {errors.d_mailAddr2 && errors.d_mailAddr2.type === "required" && (<span id="Err_msg">通訊地址 不能空白</span>)}
                            {errors.d_mailAddr2 && errors.d_mailAddr2.type === "maxLength" && <span id="Err_msg">最多 {AddrLength} 字元</span>}
                            <InputGroup id="inputmailAddr3" name="d_mailAddr3" defaultValue={def_d_mailAddr3} placeholder="通訊地址 ..." inputRef={register({ maxLength: AddrLength}) } />
                            {errors.d_mailAddr3 && errors.d_mailAddr3.types && <span id="Err_msg">{errors.d_mailAddr3.types.message}</span>}
                            {errors.d_mailAddr3 && errors.d_mailAddr3.type === "required" && (<span id="Err_msg">通訊地址 不能空白</span>)}
                            {errors.d_mailAddr3 && errors.d_mailAddr3.type === "maxLength" && <span id="Err_msg">最多 {AddrLength} 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col3-1-3" label="郵寄方式 (所有通訊包括客戶結單將寄往)" labelFor="inputway2GetStmt">
                            <select  id="inputway2GetStmt" name="d_way2GetStmt" value={def_d_way2GetStmt} onChange={utils.handleStringChange(s => setdef_d_way2GetStmt(s)) }ref={register}>
                                <option value="1">1: 電子郵箱</option>
                                <option value="2">2: 住宅地址</option>
                                <option value="3">3: 通訊地址</option>
                            </select>
                            {errors.d_way2GetStmt && errors.d_way2GetStmt.types && <span id="Err_msg">{errors.d_way2GetStmt.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="資產淨值" labelFor="inputNetAsset">
                            {/* <InputGroup className="inputNumberNoSpin" id="inputNetAsset" name="d_NetAsset" defaultValue={def_d_NetAsset} type="number" inputRef={register} />  */}
                            <InputGroup id="inputNetAsset" name="d_NetAsset" defaultValue={def_d_NetAsset} placeholder="資產淨值 ..." inputRef={register({ maxLength: 15}) } />
                            {errors.d_NetAsset && errors.d_NetAsset.types && <span id="Err_msg">{errors.d_NetAsset.types.message}</span>}
                            {errors.d_NetAsset && errors.d_NetAsset.type === "maxLength" && <span id="Err_msg">最多 15 字元</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="年度收入" labelFor="inputAnnualIncome">
                            {/* <InputGroup className="inputNumberNoSpin" id="inputAnnualIncome" name="d_AnnualIncome" defaultValue={def_d_AnnualIncome} type="number" inputRef={register} /> */}
                            <InputGroup id="inputAnnualIncome" name="d_AnnualIncome" defaultValue={def_d_AnnualIncome} placeholder="年度收入 ..." inputRef={register({ maxLength: 15}) } />
                            {errors.d_AnnualIncome && errors.d_AnnualIncome.types && <span id="Err_msg">{errors.d_AnnualIncome.types.message}</span>}
                            {errors.d_AnnualIncome && errors.d_AnnualIncome.type === "maxLength" && <span id="Err_msg">最多 15 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="資金來源" labelFor="inputSourceofFund">
                            <InputGroup id="inputSourceofFund" name="d_SourceofFund" defaultValue={def_d_SourceofFund} placeholder="資金來源 ..." inputRef={register({ maxLength: 60}) } />
                            {errors.d_SourceofFund && errors.d_SourceofFund.types && <span id="Err_msg">{errors.d_SourceofFund.types.message}</span>}
                            {errors.d_SourceofFund && errors.d_SourceofFund.type === "maxLength" && <span id="Err_msg">最多 60 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    { (OpenAccFlag.user_key === "ASTRUM")  && <div className="Row">
                        <FormGroup className="Col-1-5" label="與 阿仕特朗資本管理有限公司或任何集團公司的員工有無任何闗係" labelFor="InputStaffRelation">
                            <Switch id="InputStaffRelation" label="" innerLabelChecked="有" innerLabel="無" checked={def_d_StaffRelation} onChange={utils.handleBooleanChange(v => setdef_d_StaffRelation(v))} ref={register}/>
                            {errors.d_StaffRelation && errors.d_StaffRelation.types && <span id="Err_msg">{errors.d_StaffRelation.types.message}</span>}
                        </FormGroup>
                    </div> }
                    { ! (OpenAccFlag.user_key === "ASTRUM")  && <div className="Row">
                        <FormGroup className="Col-1-5" label="與 本公司或任何集團公司的員工有無任何闗係" labelFor="InputStaffRelation">
                            <Switch id="InputStaffRelation" label="" innerLabelChecked="有" innerLabel="無" checked={def_d_StaffRelation} onChange={utils.handleBooleanChange(v => setdef_d_StaffRelation(v))} ref={register}/>
                            {errors.d_StaffRelation && errors.d_StaffRelation.types && <span id="Err_msg">{errors.d_StaffRelation.types.message}</span>}
                        </FormGroup>
                    </div> }
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="如有,請列明員工姓名" labelFor="inputStaffName">
                            <InputGroup id="inputStaffName" name="d_StaffName" defaultValue={def_d_StaffName} placeholder="員工姓名 ..." inputRef={register({ maxLength: 60}) } />
                            {errors.d_StaffName && errors.d_StaffName.types && <span id="Err_msg">{errors.d_StaffName.types.message}</span>}
                            {errors.d_StaffName && errors.d_StaffName.type === "maxLength" && <span id="Err_msg">最多 60 字元</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-2" label="關係" labelFor="inputStaffRelationship">
                            <InputGroup id="inputStaffRelationship" name="d_StaffRelationship" defaultValue={def_d_StaffRelationship} placeholder="關係 ..." inputRef={register({ maxLength: 30}) } />
                            {errors.d_StaffRelationship && errors.d_StaffRelationship.types && <span id="Err_msg">{errors.d_StaffRelationship.types.message}</span>}
                            {errors.d_StaffRelationship && errors.d_StaffRelationship.type === "maxLength" && <span id="Err_msg">最多 30 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="閣下是否以當事人及實益擁有人的身份買賣或以其他方式交易該等 閣下指示本公司買賣的證劵 ?" labelFor="InputselfInstruction">
                            <Switch id="InputselfInstruction" label="" innerLabelChecked="是" innerLabel="否" checked={def_d_selfInstruction} onChange={utils.handleBooleanChange(v => setdef_d_selfInstruction(v))} ref={register}/>
                            {errors.d_selfInstruction && errors.d_selfInstruction.types && <span id="Err_msg">{errors.d_selfInstruction.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" label="如否,請指明 閣下以甚麽身份買賣或交易該等證劵" labelFor="inputInstructionType">
                            <InputGroup id="inputInstructionType" name="d_InstructionType" defaultValue={def_d_InstructionType} placeholder="交易身份 ..." inputRef={register({ maxLength: 50}) } />
                            {errors.d_InstructionType && errors.d_InstructionType.types && <span id="Err_msg">{errors.d_InstructionType.types.message}</span>}
                            {errors.d_InstructionType && errors.d_InstructionType.type === "maxLength" && <span id="Err_msg">最多 50 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <FormGroup className="Col3-1-1" label="就業情況" labelFor="inputEmployment">
                            <select  id="inputEmployment" name="d_Employment" value={def_d_Employment} onChange={utils.handleStringChange(s => setdef_d_Employment(s)) }ref={register}>
                                <option value="1">1: 全職</option>
                                <option value="2">2: 兼職</option>
                                <option value="3">3: 自僱</option>
                                <option value="4">4: 退休</option>
                                <option value="5">5: 無業</option>
                                <option value="6">6: 其它</option>
                            </select>
                            {errors.d_Employment && errors.d_Employment.types && <span id="Err_msg">{errors.d_Employment.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-2-4" label="其它就業情況" labelFor="inputEduOther">
                            <InputGroup id="inputEduOther" name="d_EmploymentOther" defaultValue={def_d_EmploymentOther} placeholder="其它就業情況 ..." inputRef={register({ maxLength: 50}) } />
                            {errors.d_EmploymentOther && errors.d_EmploymentOther.types && <span id="Err_msg">{errors.d_EmploymentOther.types.message}</span>}
                            {errors.d_EmploymentOther && errors.d_EmploymentOther.type === "maxLength" && <span id="Err_msg">最多 50 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="任職公司" labelFor="inputCoName">
                            <InputGroup id="inputCoName" name="d_CoName" defaultValue={def_d_CoName} placeholder="任職公司 ..." inputRef={register({ maxLength: 100}) } />
                            {errors.d_CoName && errors.d_CoName.types && <span id="Err_msg">{errors.d_CoName.types.message}</span>}
                            {errors.d_CoName && errors.d_CoName.type === "maxLength" && <span id="Err_msg">最多 100 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="閣下是否任職於銀行或財務機構,而該公司從事任何受證券及期貨事務監察委員會或香港金融管理局監管的活動 ?" labelFor="InputselfInstruction">
                            <Switch id="InputisFinInstit" label="" innerLabelChecked="是" innerLabel="否" checked={def_d_isFinInstit} onChange={utils.handleBooleanChange(v => setdef_d_isFinInstit(v))} ref={register}/>
                            {errors.d_isFinInstit && errors.d_isFinInstit.types && <span id="Err_msg">{errors.d_isFinInstit.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="如是,閣下是否同意本公司將會知會 閣下的僱主並取得他們有闗 閣下開立及維持本賬戶的同意 ?" labelFor="InputselfInstruction">
                            <Switch id="InputApproveFinInstit" label="" innerLabelChecked="是" innerLabel="否" checked={def_d_ApproveFinInstit} onChange={utils.handleBooleanChange(v => setdef_d_ApproveFinInstit(v))} ref={register}/>
                            {errors.d_ApproveFinInstit && errors.d_ApproveFinInstit.types && <span id="Err_msg">{errors.d_ApproveFinInstit.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="公司地址" labelFor="inputCoAddr">
                            <InputGroup id="inputCoAddr" name="d_CoAddr" defaultValue={def_d_CoName} placeholder="公司地址 ..." inputRef={register({ maxLength: 150}) } />
                            {errors.d_CoAddr && errors.d_CoAddr.types && <span id="Err_msg">{errors.d_CoAddr.types.message}</span>}
                            {errors.d_CoAddr && errors.d_CoAddr.type === "maxLength" && <span id="Err_msg">最多 150 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="業務性質" labelFor="inputCoType">
                            <InputGroup id="inputCoType" name="d_CoType" defaultValue={def_d_CoType} placeholder="業務性質 ..." inputRef={register({ maxLength: 30}) } />
                            {errors.d_CoType && errors.d_CoType.types && <span id="Err_msg">{errors.d_CoType.types.message}</span>}
                            {errors.d_CoType && errors.d_CoType.type === "maxLength" && <span id="Err_msg">最多 30 字元</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-3" label="職位" labelFor="inputCoPost">
                            <InputGroup id="inputCoPost" name="d_CoPost" defaultValue={def_d_CoPost} placeholder="職位 ..." inputRef={register({ maxLength: 30}) } />
                            {errors.d_CoPost && errors.d_CoPost.types && <span id="Err_msg">{errors.d_CoPost.types.message}</span>}
                            {errors.d_CoPost && errors.d_CoPost.type === "maxLength" && <span id="Err_msg">最多 30 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="往來銀行" labelFor="inputBankInfo">
                            <InputGroup id="inputBankInfo" name="d_BankInfo" defaultValue={def_d_BankInfo} placeholder="往來銀行 ..." inputRef={register({ maxLength: BankInfoLength}) } />
                            {errors.d_BankInfo && errors.d_BankInfo.types && <span id="Err_msg">{errors.d_BankInfo.types.message}</span>}
                            {errors.d_BankInfo && errors.d_BankInfo.type === "maxLength" && <span id="Err_msg">Max length is {BankInfoLength}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="銀行賬號" labelFor="inputBankACNo">
                            <InputGroup id="inputBankACNo" name="d_BankACNo" defaultValue={def_d_BankACNo} placeholder="銀行賬號 ..." inputRef={register({ maxLength: BankACNoLength}) } />
                            {errors.d_BankACNo && errors.d_BankACNo.types && <span id="Err_msg">{errors.d_BankACNo.types.message}</span>}
                            {errors.d_BankACNo && errors.d_BankACNo.type === "maxLength" && <span id="Err_msg">Max length is {BankACNoLength}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="住宅電話號碼" labelFor="inputTel">
                            <InputGroup id="inputTel" name="d_tel" defaultValue={def_d_tel} placeholder="住宅電話號碼 ..." inputRef={register({ maxLength: TelLength}) } />
                            {errors.d_tel && errors.d_tel.types && <span id="Err_msg">{errors.d_tel.types.message}</span>}
                            {errors.d_tel && errors.d_tel.type === "maxLength" && <span id="Err_msg">Max length is {TelLength}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="辦公室電話號碼" labelFor="inputoffTel">
                            <InputGroup id="inputoffTel" name="d_offTel" defaultValue={def_d_offTel} placeholder="辦公室電話號碼 ..." inputRef={register({ maxLength: OffTelLength}) } />
                            {errors.d_offTel && errors.d_offTel.types && <span id="Err_msg">{errors.d_offTel.types.message}</span>}
                            {errors.d_offTel && errors.d_offTel.type === "maxLength" && <span id="Err_msg">Max length is {OffTelLength}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="手提電話號碼" labelFor="inputMobile">
                            <InputGroup id="inputMobile" name="d_mobile" defaultValue={def_d_mobile} placeholder="手提電話號碼 ..." inputRef={register({ maxLength: MobileLength}) } />
                            {errors.d_mobile && errors.d_mobile.types && <span id="Err_msg">{errors.d_mobile.types.message}</span>}
                            {errors.d_mobile && errors.d_mobile.type === "maxLength" && <span id="Err_msg">Max length is {MobileLength}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="傳真號碼" labelFor="inputFax">
                            <InputGroup id="inputFax" name="d_fax" defaultValue={def_d_fax} placeholder="傳真號碼 ..." inputRef={register({ maxLength: FaxLength}) } />
                            {errors.d_fax && errors.d_fax.types && <span id="Err_msg">{errors.d_fax.types.message}</span>}
                            {errors.d_fax && errors.d_fax.type === "maxLength" && <span id="Err_msg">Max length is {FaxLength}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () =>PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev1) }>前一步</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onValidate1) }>下一步</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>
            </div>}

            { ! lFinishe && ( nPage == 2 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> 開戶 - 其它資料 
                    &nbsp; &nbsp; &nbsp;
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2a}</td>
                            <td >{imgflow_linear}</td>
                            <td >{imgnum3b}</td>
                        </tr>
                    </tbody></span>
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    相連人士
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="閣下是否本公司另一保證金客戶的配偶 ?" labelFor="InputhasSpouseClient">
                            <Switch id="InputhasSpouseClient" autoFocus={true} label="" innerLabelChecked="是" innerLabel="否" checked={def_d_hasSpouseClient} onChange={utils.handleBooleanChange(v => setdef_d_hasSpouseClient(v))} ref={register}/>
                            {errors.d_hasSpouseClient && errors.d_hasSpouseClient.types && <span id="Err_msg">{errors.d_hasSpouseClient.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" label="配偶姓名" labelFor="inputSpouseName">
                            <InputGroup id="inputSpouseName" name="d_SpouseName" defaultValue={def_d_SpouseName} placeholder="配偶姓名 ..." inputRef={register({ maxLength: 60}) } />
                            {errors.d_SpouseName && errors.d_SpouseName.types && <span id="Err_msg">{errors.d_SpouseName.types.message}</span>}
                            {errors.d_SpouseName && errors.d_SpouseName.type === "maxLength" && <span id="Err_msg">最多 60 字元</span>}
                        </FormGroup>
                        <FormGroup className="Col-6-1" label="賬戶號碼" labelFor="inputSpouseAcNo">
                            <InputGroup id="inputSpouseAcNo" name="d_SpouseAcNo" defaultValue={def_d_SpouseAcNo} placeholder="配偶賬戶號碼 ..." inputRef={register({ maxLength: 10}) } />
                            {errors.d_SpouseAcNo && errors.d_SpouseAcNo.types && <span id="Err_msg">{errors.d_SpouseAcNo.types.message}</span>}
                            {errors.d_SpouseAcNo && errors.d_SpouseAcNo.type === "maxLength" && <span id="Err_msg">最多 10 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="閣下是否單獨或與 閣下的配偶共同控制本公司另一保證金客戶百份之三十五或以上的投票權 ?" labelFor="InputVoteRight">
                            <Switch id="InputVoteRight" label="" innerLabelChecked="是" innerLabel="否" checked={def_d_VoteRight} onChange={utils.handleBooleanChange(v => setdef_d_VoteRight(v))} ref={register}/>
                            {errors.d_VoteRight && errors.d_VoteRight.types && <span id="Err_msg">{errors.d_VoteRight.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-1" label="帳戶號碼" labelFor="inputVoteAc">
                            <InputGroup id="inputVoteAc1" name="d_VoteAc1" defaultValue={def_d_VoteAc1} placeholder="帳戶號碼 ..." inputRef={register({ maxLength: 10}) } />
                            {errors.d_VoteAc1 && errors.d_VoteAc1.types && <span id="Err_msg">{errors.d_VoteAc1.types.message}</span>}
                            {errors.d_VoteAc1 && errors.d_VoteAc1.type === "maxLength" && <span id="Err_msg">最多 10 字元</span>}
                            <InputGroup id="inputVoteAc2" name="d_VoteAc2" defaultValue={def_d_VoteAc2} placeholder="帳戶號碼 ..." inputRef={register({ maxLength: 10}) } />
                            {errors.d_VoteAc2 && errors.d_VoteAc2.types && <span id="Err_msg">{errors.d_VoteAc2.types.message}</span>}
                            {errors.d_VoteAc2 && errors.d_VoteAc2.type === "maxLength" && <span id="Err_msg">最多 10 字元</span>}
                        </FormGroup>
                        <FormGroup className="Col-2-4" label="帳戶持有人名稱" labelFor="inputIoteAcName1">
                            <InputGroup id="inputIoteAcName1" name="d_VoteAcName1" defaultValue={def_d_VoteAcName1} placeholder="帳戶持有人名稱 ..." inputRef={register({ maxLength: 60}) } />
                            {errors.d_VoteAcName1 && errors.d_VoteAcName1.types && <span id="Err_msg">{errors.d_VoteAcName1.types.message}</span>}
                            {errors.d_VoteAcName1 && errors.d_VoteAcName1.type === "maxLength" && <span id="Err_msg">最多 60 字元</span>}
                            <InputGroup id="inputIoteAcName2" name="d_VoteAcName2" defaultValue={def_d_VoteAcName2} placeholder="帳戶持有人名稱 ..." inputRef={register({ maxLength: 60}) } />
                            {errors.d_VoteAcName2 && errors.d_VoteAcName2.types && <span id="Err_msg">{errors.d_VoteAcName2.types.message}</span>}
                            {errors.d_VoteAcName2 && errors.d_VoteAcName2.type === "maxLength" && <span id="Err_msg">最多 60 字元</span>}
                        </FormGroup>
                        <FormGroup className="Col-6-3" label="帳戶持有人闗係" labelFor="inputSpouseAcNo">
                            <InputGroup id="inputSpouseAcNo" name="d_VoteRelate1" defaultValue={def_d_VoteRelate1} placeholder="帳戶持有人闗係 ..." inputRef={register({ maxLength: 30}) } />
                            {errors.d_VoteRelate1 && errors.d_VoteRelate1.types && <span id="Err_msg">{errors.d_VoteRelate1.types.message}</span>}
                            {errors.d_VoteRelate1 && errors.d_VoteRelate1.type === "maxLength" && <span id="Err_msg">最多 30 字元</span>}
                            <InputGroup id="inputSpouseAcNo" name="d_VoteRelate2" defaultValue={def_d_VoteRelate2} placeholder="帳戶持有人闗係 ..." inputRef={register({ maxLength: 30}) } />
                            {errors.d_VoteRelate2 && errors.d_VoteRelate2.types && <span id="Err_msg">{errors.d_VoteRelate2.types.message}</span>}
                            {errors.d_VoteRelate2 && errors.d_VoteRelate2.type === "maxLength" && <span id="Err_msg">最多 30 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    電子證券交易服務 - 互聯網服務
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="閣下要求本公司為 閣下在本公司所開立的賬戶提供互繴網服務. 閣下同意須接受附錄二所載的互繴網服務條款約束." labelFor="InputisInternet">
                            <Switch id="InputisInternet" label="" innerLabelChecked="是" innerLabel="否" checked={def_d_isInternet} onChange={utils.handleBooleanChange(v => setdef_d_isInternet(v))} ref={register}/>
                            {errors.d_isInternet && errors.d_isInternet.types && <span id="Err_msg">{errors.d_isInternet.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-1" label="投資目標" labelFor="inputTarget">
                            <Checkbox name="d_Target1" label="收入" checked={def_d_Target1} onChange={utils.handleBooleanChange(v => setdef_d_Target1(v))} />
                            {errors.d_Target1 && errors.d_Target1.types && <span id="Err_msg">{errors.d_Target1.types.message}</span>}
                            <Checkbox name="d_Target2" label="期權長倉" checked={def_d_Target2} onChange={utils.handleBooleanChange(v => setdef_d_Target2(v))} />
                            {errors.d_Target2 && errors.d_Target2.types && <span id="Err_msg">{errors.d_Target2.types.message}</span>}
                            <Checkbox name="d_Target3" label="對沖" checked={def_d_Target3} onChange={utils.handleBooleanChange(v => setdef_d_Target3(v))} />
                            {errors.d_Target3 && errors.d_Target3.types && <span id="Err_msg">{errors.d_Target3.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-2-2" label="(可撰擇多個項目)" labelFor="inputTarget">
                            <Checkbox name="d_Target4" label="資本增值" checked={def_d_Target4} onChange={utils.handleBooleanChange(v => setdef_d_Target4(v))} />
                            {errors.d_Target4 && errors.d_Target4.types && <span id="Err_msg">{errors.d_Target4.types.message}</span>}
                            <Checkbox name="d_Target5" label="沽出備兌期權" checked={def_d_Target5} onChange={utils.handleBooleanChange(v => setdef_d_Target5(v))} />
                            {errors.d_Target5 && errors.d_Target5.types && <span id="Err_msg">{errors.d_Target5.types.message}</span>}
                            <Checkbox name="d_Target6" label="期權投資" checked={def_d_Target6} onChange={utils.handleBooleanChange(v => setdef_d_Target6(v))} />
                            {errors.d_Target6 && errors.d_Target6.types && <span id="Err_msg">{errors.d_Target6.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-2" label=" ." labelFor="inputTarget">
                            <Checkbox name="d_Target7" label="短期增長" checked={def_d_Target7} onChange={utils.handleBooleanChange(v => setdef_d_Target7(v))} />
                            {errors.d_Target7 && errors.d_Target7.types && <span id="Err_msg">{errors.d_Target7.types.message}</span>}
                            <Checkbox name="d_Target8" label="沽出無備兌期權" checked={def_d_Target8} onChange={utils.handleBooleanChange(v => setdef_d_Target8(v))} />
                            {errors.d_Target8 && errors.d_Target8.types && <span id="Err_msg">{errors.d_Target8.types.message}</span>}
                            <Checkbox name="d_Target9" label="期權策略" checked={def_d_Target9} onChange={utils.handleBooleanChange(v => setdef_d_Target9(v))} />
                            {errors.d_Target9 && errors.d_Target9.types && <span id="Err_msg">{errors.d_Target9.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    投資經驗
                    <div className="Row">
                    <FormGroup className="Col-1-2" label="股票" labelFor="inputStockYear">
                            <InputGroup id="inputStockYear" name="d_StockYear" defaultValue={def_d_StockYear} placeholder="年數" inputRef={register({ maxLength: 3}) } />
                            {errors.d_StockYear && errors.d_StockYear.types && <span id="Err_msg">{errors.d_StockYear.types.message}</span>}
                            {errors.d_StockYear && errors.d_StockYear.type === "maxLength" && <span id="Err_msg">最多 3 字元</span>}
                            <InputGroup id="inputStockCnt" name="d_StockCnt" defaultValue={def_d_StockCnt} placeholder="每年交易宗數" inputRef={register({ maxLength: 5}) } />
                            {errors.d_StockCnt && errors.d_StockCnt.types && <span id="Err_msg">{errors.d_StockCnt.types.message}</span>}
                            {errors.d_StockCnt && errors.d_StockCnt.type === "maxLength" && <span id="Err_msg">最多 5 字元</span>}
                            <InputGroup id="inputStockAmt" name="d_StockAmt" defaultValue={def_d_StockAmt} placeholder="一般交易金碩 (港元)" inputRef={register({ maxLength: 15}) } />
                            {errors.d_StockAmt && errors.d_StockAmt.types && <span id="Err_msg">{errors.d_StockAmt.types.message}</span>}
                            {errors.d_StockAmt && errors.d_StockAmt.type === "maxLength" && <span id="Err_msg">最多 15 字元</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="認股權證" labelFor="inputWarrentYear">
                            <InputGroup id="inputWarrentYear" name="d_WarrantYear" defaultValue={def_d_WarrantYear} placeholder="年數" inputRef={register({ maxLength: 3}) } />
                            {errors.d_WarrantYear && errors.d_WarrantYear.types && <span id="Err_msg">{errors.d_WarrantYear.types.message}</span>}
                            {errors.d_WarrantYear && errors.d_WarrantYear.type === "maxLength" && <span id="Err_msg">最多 3 字元</span>}
                            <InputGroup id="inputWarrentCnt" name="d_WarrantCnt" defaultValue={def_d_WarrantCnt} placeholder="每年交易宗數" inputRef={register({ maxLength: 5}) } />
                            {errors.d_WarrantCnt && errors.d_WarrantCnt.types && <span id="Err_msg">{errors.d_WarrantCnt.types.message}</span>}
                            {errors.d_WarrantCnt && errors.d_WarrantCnt.type === "maxLength" && <span id="Err_msg">最多 5 字元</span>}
                            <InputGroup id="inputWarrentAmt" name="d_WarrantAmt" defaultValue={def_d_WarrantAmt} placeholder="一般交易金碩 (港元)" inputRef={register({ maxLength: 15}) } />
                            {errors.d_WarrantAmt && errors.d_WarrantAmt.types && <span id="Err_msg">{errors.d_WarrantAmt.types.message}</span>}
                            {errors.d_WarrantAmt && errors.d_WarrantAmt.type === "maxLength" && <span id="Err_msg">最多 15 字元</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="期權" labelFor="inputOptionYear">
                            <InputGroup id="inputOptionYear" name="d_OptionYear" defaultValue={def_d_OptionYear} placeholder="年數" inputRef={register({ maxLength: 3}) } />
                            {errors.d_OptionYear && errors.d_OptionYear.types && <span id="Err_msg">{errors.d_OptionYear.types.message}</span>}
                            {errors.d_OptionYear && errors.d_OptionYear.type === "maxLength" && <span id="Err_msg">最多 3 字元</span>}
                            <InputGroup id="inputOptionCnt" name="d_OptionCnt" defaultValue={def_d_OptionCnt} placeholder="每年交易宗數" inputRef={register({ maxLength: 5}) } />
                            {errors.d_OptionCnt && errors.d_OptionCnt.types && <span id="Err_msg">{errors.d_OptionCnt.types.message}</span>}
                            {errors.d_OptionCnt && errors.d_OptionCnt.type === "maxLength" && <span id="Err_msg">最多 5 字元</span>}
                            <InputGroup id="inputOptionAmt" name="d_OptionAmt" defaultValue={def_d_OptionAmt} placeholder="一般交易金碩 (港元)" inputRef={register({ maxLength: 15}) } />
                            {errors.d_OptionAmt && errors.d_OptionAmt.types && <span id="Err_msg">{errors.d_OptionAmt.types.message}</span>}
                            {errors.d_OptionAmt && errors.d_OptionAmt.type === "maxLength" && <span id="Err_msg">最多 15 字元</span>}
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="期貨" labelFor="inputFutureYear">
                            <InputGroup id="inputFutureYear" name="d_FutureYear" defaultValue={def_d_FutureYear} placeholder="年數" inputRef={register({ maxLength: 3}) } />
                            {errors.d_FutureYear && errors.d_FutureYear.types && <span id="Err_msg">{errors.d_FutureYear.types.message}</span>}
                            {errors.d_FutureYear && errors.d_FutureYear.type === "maxLength" && <span id="Err_msg">最多 3 字元</span>}
                            <InputGroup id="inputFutureCnt" name="d_FutureCnt" defaultValue={def_d_FutureCnt} placeholder="每年交易宗數" inputRef={register({ maxLength: 5}) } />
                            {errors.d_FutureCnt && errors.d_FutureCnt.types && <span id="Err_msg">{errors.d_FutureCnt.types.message}</span>}
                            {errors.d_FutureCnt && errors.d_FutureCnt.type === "maxLength" && <span id="Err_msg">最多 5 字元</span>}
                            <InputGroup id="inputFutureAmt" name="d_FutureAmt" defaultValue={def_d_FutureAmt} placeholder="一般交易金碩 (港元)" inputRef={register({ maxLength: 15}) } />
                            {errors.d_FutureAmt && errors.d_FutureAmt.types && <span id="Err_msg">{errors.d_FutureAmt.types.message}</span>}
                            {errors.d_FutureAmt && errors.d_FutureAmt.type === "maxLength" && <span id="Err_msg">最多 15 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    衍生産品買賣經驗
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="閣下在過往 3年內有否在交易所或交易所以外進行 5個或以上的衍生産品或結構性産品買賣 ?" labelFor="InputHighRiskExp">
                            <Switch id="InputHighRiskExp" label="" innerLabelChecked="是" innerLabel="否" checked={def_d_HighRiskExp} onChange={utils.handleBooleanChange(v => setdef_d_HighRiskExp(v))} ref={register}/>
                            {errors.d_HighRiskExp && errors.d_HighRiskExp.types && <span id="Err_msg">{errors.d_HighRiskExp.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="本人確認以上所提供的資料均為真實, 完備及準確. 如上述資料有任何重大變更, 本人將通知 貴公`司." labelFor="InputAgree">
                            <Switch id="InputAgree" label="" innerLabelChecked="是" innerLabel="否" checked={def_d_Agree} onChange={utils.handleBooleanChange(v => setdef_d_Agree(v))} ref={register}/>
                            {errors.d_Agree && errors.d_Agree.types && <span id="Err_msg">{errors.d_Agree.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <h5 className="bp3-heading">請簽名 及 下載簽名</h5>
                    <div className="Row" >
                        <button onClick={handleExport}>下載簽名</button> 
                        <button onClick={handleClear}>清除</button>
                    </div>
                    <div className="Row" >
                        <span id="FreeDrawBox">
                        <Stage
                            ref={stageRef}
                            width={800}
                            height={500}
                            onMouseDown={handleMouseDown}
                            onMousemove={handleMouseMove}
                            onMouseup={handleMouseUp}
                            onTouchStart={handleMouseDown}
                            onTouchMove={handleMouseMove}
                            onTouchEnd={handleMouseUp}
                        >
                            <Layer>
                                {lines.map((line:any, i:number) => (
                                    <Line
                                    key={i}
                                    points={line.points}
                                    stroke="#df4b26"
                                    strokeWidth={5}
                                    tension={0.5}
                                    lineCap="round"
                                    globalCompositeOperation={
                                        line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                    }
                                    />
                                ))}
                            </Layer>
                        </Stage>
                    </span>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <div className="Spacing-V-16"></div>
                    <form className="Import" onSubmit={onUpload}>
                        <div className="Row">
                            <span id="DispText3">身份證明文件-正面</span>
                            <FileInput inputProps={{ name: "file1" }} text={file1Label} onInputChange={utils.handleStringChange((s) => setFile1Label(utils.fileBase(s)))} />
                        </div>
                        {errors.UploadFile1 && errors.UploadFile1.types && <span id="Err_msg">{errors.UploadFile1.types.message}</span>}
                        <div className="Row">
                            <span id="DispText3">身份證明文件-背面</span>
                            <FileInput inputProps={{ name: "file1b" }} text={file1bLabel} onInputChange={utils.handleStringChange((s) => setFile1bLabel(utils.fileBase(s)))} />
                        </div>
                        {errors.UploadFile1b && errors.UploadFile1b.types && <span id="Err_msg">{errors.UploadFile1b.types.message}</span>}
                        <div className="Row">
                            <span id="DispText3">住址證明文件</span>
                            <FileInput inputProps={{ name: "file2" }} text={file2Label} onInputChange={utils.handleStringChange((s) => setFile2Label(utils.fileBase(s)))} />
                        </div>
                        {errors.UploadFile2 && errors.UploadFile2.types && <span id="Err_msg">{errors.UploadFile2.types.message}</span>}
                        <div className="Row">
                            <span id="DispText3">銀行戶口證明文件</span>
                            <FileInput inputProps={{ name: "file3" }} text={file3Label} onInputChange={utils.handleStringChange((s) => setFile3Label(utils.fileBase(s)))} />
                        </div>
                        {errors.UploadFile3 && errors.UploadFile3.types && <span id="Err_msg">{errors.UploadFile3.types.message}</span>}
                        <div className="Spacing-V-8"></div>
                        <div className="Row">
                            <Button className="Col-1-2" type="submit" icon={IconNames.UPLOAD}>上載文件</Button>
                        </div>
                        <div className="Spacing-V-8"></div>
                        已上載文件 : {nUploaded} 份 
                    </form>
                    <div className="Spacing-V-16"></div>
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        {/*
                        <Button icon="direction-left" onClick={ () => PageNo(-1) }>前一步</Button>
                        */}
                        <Button icon="direction-left" onClick={ handleSubmit(onPrev2) }>前一步</Button>
                        <Button icon="direction-right" disabled={! def_d_Agree || ! isSigned} onClick={ handleSubmit(onValidate2) }>確定</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>
            </div>}

            { ! lFinishe && ( nPage == 3 ) && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}> 開戶 - 電郵驗証 
                    &nbsp; &nbsp; &nbsp;
                    <span ><tbody >
                        <tr >
                            <td >{imgnum1b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum2b}</td>
                            <td >{imgone_to_one}</td>
                            <td >{imgnum3a}</td>
                        </tr>
                    </tbody></span>
                </div>
                <div className="Spacing-V-16"></div>
                <Card className={cardOpen02} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="電郵地址" labelFor="inputEmail" labelInfo="*">
                            <InputGroup id="inputEmail" name="d_email" defaultValue="" inputRef={register({ required: true , maxLength: EmailLength}) } />
                            {errors.d_email && errors.d_email.types && <span id="Err_msg">{errors.d_email.types.message}</span>}
                            {errors.d_email && errors.d_email.type === "required" && (<span id="Err_msg">電郵地址 不能空白</span>)}
                            {errors.d_email && errors.d_email.type === "maxLength" && <span id="Err_msg">最多 {EmailLength} 字元</span>}
                        </FormGroup>
                        <Button className="Col-4-2 Button-LabeledFormGroup" icon="repeat" onClick={handleSubmit(onResend)}>{def_ResendTxt}</Button>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="電郵驗証碼" labelFor="inputCode">
                            <InputGroup id="inputCode" name="d_verify_code1" inputRef={register({ maxLength: 10}) } />
                            {errors.d_verify_code1 && errors.d_verify_code1.types && <span id="Err_msg">{errors.d_verify_code1.types.message}</span>}
                            {errors.d_verify_code1 && errors.d_verify_code1.type === "maxLength" && <span id="Err_msg">最多 10 字元</span>}
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <Button icon="direction-left" disabled={true} onClick={ () =>PageNo(-1) }>前一步</Button>
                        <Button icon="direction-right" onClick={ handleSubmit(onVerify) }>驗証</Button>
                    </div>
                    <div className="Spacing-V-16" />
                </Card>
            </div>}

            { lFinishe && <div >
                <div > {CoHead} </div>
                <div style={{fontSize: "21px", fontWeight: 700}}>開戶 - 完成</div>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <span id="DispText2">恭喜您, {def_d_acName1} {def_d_acName2} , 開戶登記已經完成 </span>
                </div>
                <div className="Row">
                    <span id="DispText2">請存入 HK$10,000 資金到本公司戶口</span>
                </div>
                <div className="Spacing-V-16" />
            </div>}

        {/* </form> */}
        </>
    );
}



export default RegFormDemo2Main