import React, { useState, FormEvent, useEffect } from 'react';
import { FormGroup, InputGroup, Button, Position, Toaster, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { AxiosInstance } from 'axios';

import { useHistory } from 'react-router-dom';
import { History } from 'history'

import * as utils from "./utils"

import "./Login.scss"

import imgLogin from "./svg/login.svg"

var OpenAccFlag: any;

interface LoginProps {
    OpenAcc: AxiosInstance
    isLoggedIn?: boolean
    onLogin?: (credential: LoginCredential, history: History) => void,
    onLogout?: () => void
}

const toaster = Toaster.create({
    position: Position.TOP,
})

export interface LoginCredential {
    login: string,
    password: string
}

function Login({ OpenAcc, isLoggedIn = false, onLogin = () => { }, onLogout = () => { } }: LoginProps) {

    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const history = useHistory()
    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [Enter2tab, setEnter2tab] = useState("N")


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag0');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                setEnter2tab(OpenAccFlag.Enter2tab);

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [OpenAcc])

    
    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [OpenAccFlag, Enter2tab])


    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>


    if (isLoggedIn) {
        return (
            <>
                <div > {CoHead} </div>
                <div className="Login">
                    <img className="LoginImage" src={imgLogin} alt="Login" />
                    <div className="Spacing-V-16"></div>
                    <Button icon={IconNames.LOG_OUT} onClick={() => onLogout()}>Logout</Button>
                </div>
            </>
        )
    } else {
        
        const handleSubmit = (e: FormEvent) => {
            e.preventDefault()
            //console.log("login: ", login, password) 

            onLogin({ login: login, password: password }, history)
        }
        

        return (
            <>
                <div > {CoHead} </div>
                <form className="Login">
                    <img className="LoginImage" src={imgLogin} alt="Login" />
                    <div className="Spacing-V-16"></div>
                    <FormGroup label="Login" labelFor="inputLogin">
                        <InputGroup autoFocus value={login} onChange={utils.handleStringChange(s => setLogin(s))} />
                    </FormGroup>
                    <FormGroup label="Password" labelFor="inputPassword">
                        <InputGroup id="inputPassword" type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                    </FormGroup>
                    <div className="Spacing-V-16"></div>
                    <Button type="submit" icon={IconNames.LOG_IN} onClick={handleSubmit}>Login</Button>
                </form>
            </>
        )
    }

}

export default Login