import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, InputGroup, Button, HTMLTable, Position, Toaster, Intent, ProgressBar, Collapse, Checkbox  } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';

import svgWait2 from './svg/wait2.gif'


var OpenAccFlag: any;


interface ResetprocessMainProps {
    OpenAcc: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


function ResetprocessMain({ OpenAcc }: ResetprocessMainProps) {
    
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />

    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)
    const [ResetStatus, setResetStatus] = useState("")

    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)


    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
 

    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp OpenAccFlag: ", OpenAccFlag)
                //console.log("SmMastMain disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("SmMastMain disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("SmMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setSETL_DATE(OpenAccFlag.SETL_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                setcardOpen01("cardOpen01-Theme"+OpenAccFlag.DEF_THEME1);
            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])



    
    const onResetprocess = () => {
        //console.log("onResetprocess fileInfoList", fileInfoList);
        (async () => {
            setResetStatus("")
            try {
                const res = await OpenAcc.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "Resetprocess",
                });                
                //console.log("SysMaint res.data.data", res.data.data)
                if ( res.data.ok ) {
                    setResetStatus("Reset Process is OK") 
                } else {
                    setResetStatus("No xProcess file") 
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

    }

    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }



    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <p>Reset Process Status when the Process is Running</p>
                    <div>
                        <div>{ResetStatus}</div>
                    </div >
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button className="Col-1-2" icon={IconNames.LIST} onClick={onResetprocess}>Reset Process Status</Button>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <br></br>
                    <p>Notes</p>
                    <div>
                        <div>Reset Process Status</div>
                    </div >
                </Card>
            </form>
        </>
    );
}

export default ResetprocessMain